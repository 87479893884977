import React, { useMemo } from 'react';
import { Modal, Icon } from 'semantic-ui-react';

const ModalExampleTopAligned = props => {
    let { graphWidget, config } = props;
    const mConfig = { ...config, width: window.innerWidth * 0.9, height: window.innerHeight * 0.7, modalOpen: true };
    return (
        <Modal trigger={<Icon name="search plus" size="tiny" />} centered={true} size="fullscreen">
            <Modal.Content>
                <Modal.Description>{React.cloneElement(graphWidget, mConfig)}</Modal.Description>
            </Modal.Content>
        </Modal>
    );
};

export default ModalExampleTopAligned;

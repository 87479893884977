// Freigegebene Produktionsaufträge
import React from 'react';
import { Segment } from 'semantic-ui-react';
import styled from 'styled-components';
import { formatForSmartTextSearch } from '../../../Utils/Tables';
import { buildTableColumns } from './TableColumnsDeclaration';
import TableTemplate from './TableTemplate';
import SelectResponsibleModal from './SelectResponsibleModal/SelectResponsibleModal';
import { biRequestWithoutCache } from '../../UtilComponents/requestsUtils';

const FPOTable = props => {
    const { setFilter, sorts, setSort, filters } = props;
    const [data, setData] = React.useState(null);
    const [selectedField, setSelectedField] = React.useState('ppsordernumber'); // for table colum filter
    const [loading, setLoading] = React.useState(false);
    const [orderResponsible, setOrderResponsible] = React.useState({});
    const [responsibleModalOpen, setResponsibleModalOpen] = React.useState(false);
    const [refreshTable, setRefreshTable] = React.useState('');
    const [currentPage, setCurrentPage] = React.useState(0);

    React.useEffect(() => {
        const getIssues = async () => {
            try {
                setLoading(true);
                const response = await biRequestWithoutCache(`d8h/get_free_production_orders`, filters);
                setData(response);
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        };
        getIssues();
    }, [filters, sorts, refreshTable]);

    const handleCellClick = ({ production_orders_responsible_id, ppsordernumber, responsible_id }) => {
        setOrderResponsible({
            id: production_orders_responsible_id,
            orderNumber: ppsordernumber,
            responsibleId: responsible_id,
        });
        setResponsibleModalOpen(true);
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Details',
                columns: buildTableColumns(setFilter, setSort, sorts, handleCellClick),
            },
        ],
        [sorts],
    );
    // text search for table columns context specific infomation and logic
    const searchTableOptions = [
        { key: 'ppsordernumber', text: 'PA-Nummer', value: 'ppsordernumber' },
        { key: 'designation', text: 'Bezeichnung', value: 'designation' },
        { key: 'article_number', text: 'Artikelnummer', value: 'article_number' },
        { key: 'article_group', text: 'Artikelgruppe', value: 'article_group' },
    ];

    const searchTableOptionsLogic = () => {
        const input = document.getElementById('textToSearchFor');
        if (!input.value) return;

        setFilter({
            nameToDisplay: searchTableOptions.find(obj => obj.value === selectedField).text,
            attribute: selectedField,
            operator: '~*',
            value: [`${input.value}`],
        });
    };
    // text search for table columns context specific infomation and logic
    return React.useMemo(() => {
        return (
            <>
                <SelectResponsibleModal
                    open={responsibleModalOpen}
                    setOpen={setResponsibleModalOpen}
                    orderResponsible={orderResponsible}
                    setRefreshTable={setRefreshTable}
                />
                <Segment100 loading={loading}>
                    {columns && data ? (
                        <TableTemplate
                            columns={columns}
                            data={data}
                            loading={loading}
                            setSelectedField={setSelectedField}
                            selectedField={selectedField}
                            searchTableOptions={searchTableOptions}
                            searchTableOptionsLogic={searchTableOptionsLogic}
                            setFilter={setFilter}
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                        />
                    ) : (
                        <TablePlaceholder />
                    )}
                </Segment100>
            </>
        );
    }, [data, loading, selectedField, responsibleModalOpen]);
};

const Segment100 = styled(Segment)`
    width: 100% !important;
`;
const TablePlaceholder = styled.div`
    width: 100%;
`;

export default FPOTable;

import React from 'react';
import { List, Form, Button, Divider, Checkbox, Icon, Grid, Popup } from 'semantic-ui-react';
import {
    deleteData,
    getCurrentUserId,
    getData,
    insertSimpleData,
    updateData,
} from '../../UtilComponents/requestsUtils';
import StandardDatePicker, { formatDateToDE, formatDateDEStringForSQL } from '../../UtilComponents/StandardDatePicker';
import SInput from '../../UtilComponents/SearchableInput';
import { queryUsers } from '../Queries';
import StatusForm from './StatusForm';

const comp = ({ issue_id, department_id, setDepartmentUpdated, formData, issue_status }) => {
    const formInitialState = {
        action_id: '',
        action_description: '',
        department_id,
        action_deadline: '',
        action_responsible_id: '',
    };
    const [currentAction, setCurrentAction] = React.useState(formInitialState);

    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [updateList, setUpdateList] = React.useState('');

    // for the main form
    const handleChange = (evt, data) => {
        const newObjState = Object.assign({}, currentAction, { [data.name]: data.value });
        setCurrentAction(newObjState);
    };

    // handling form submition
    const submitForm = () => {
        if (issue_status === 3) {
            alert('Die Meldung darf nicht bearbeitet werden.');
        } else {
            setLoading(true);
            currentAction.action_id ? updateAction() : createAction();
        }
    };

    const resetForm = () => {
        setCurrentAction(formInitialState);
    };

    const createAction = async () => {
        const body = {
            issue_id: issue_id,
            action_description: currentAction.action_description,
            action_department_id: department_id,
        };
        if (currentAction.action_responsible_id) {
            body.action_responsible_id = currentAction.action_responsible_id;
        }
        if (currentAction.action_deadline) {
            body.action_deadline = formatDateDEStringForSQL(currentAction.action_deadline);
        }

        try {
            await insertSimpleData('crud/changes/post_change_action', body);
            setCurrentAction(formInitialState);
            setLoading(false);
            setUpdateList('afterCreation' + Date.now().toString());
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const updateAction = async () => {
        const body = {
            issue_id: issue_id,
            action_description: currentAction.action_description,
            action_department_id: department_id,
            action_responsible_id: currentAction.action_responsible_id,
            action_deadline:
                currentAction.action_deadline == null ? null : formatDateDEStringForSQL(currentAction.action_deadline),
        };
        const where = { action_id: currentAction.action_id };
        try {
            await updateData('crud/changes/update_change_action', body, where);
            setLoading(false);
            setUpdateList('afterUpdate' + Date.now().toString());
            setCurrentAction(formInitialState);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const deleteAction = async actionId => {
        const where = { action_id: actionId };
        try {
            await deleteData('crud/changes/delete_change_action', where);
            setLoading(false);
            setUpdateList('afterDeletion' + Date.now().toString());
            setCurrentAction(formInitialState);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    React.useEffect(() => {
        const getActions = async () => {
            setLoading(true);
            setData([]);
            try {
                const response = await getData(
                    `crud/changes/get_change_actions?issue_id=${issue_id}&action_department_id=${department_id}`,
                );
                setData(response);
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        };
        getActions();
    }, [updateList, department_id]);

    return (
        <>
            <StatusForm
                issue_id={issue_id}
                issue_type_id={formData.issue_type_id}
                department_id={department_id}
                setDepartmentUpdated={setDepartmentUpdated}
                issue_status={issue_status}
            ></StatusForm>
            <Popup
                content="Angezeigte Aufgaben können durch das Bearbeitungssymbol Mitarbeitern zugewiesen werden. Nicht benötigte Aufgaben können durch das x entfernt werden. Der jeweilige Mitarbeiter kann nach Erledigung der Aufgabe diese dann vorne abhaken. Durch das Eingabefeld unten können auch neue Aufgaben erstellt und zugewiesen werden"
                trigger={
                    <h2 style={{ textAlign: 'center' }}>
                        {formData.issue_type_id == 10 ? 'Aufgaben' : 'Aufgaben nach Freigabe'}
                    </h2>
                }
                position="top center"
                wide="very"
            />
            <Divider />
            <List relaxed="very">
                {data.map(obj => {
                    return (
                        <Action
                            {...obj}
                            setCurrentAction={setCurrentAction}
                            deleteAction={deleteAction}
                            setUpdateList={setUpdateList}
                            setDepartmentUpdated={setDepartmentUpdated}
                            issue_status={issue_status}
                        />
                    );
                })}
            </List>
            <Divider />
            <br />
            <Form>
                <Form.TextArea
                    label={currentAction.action_id ? 'Aufgabe bearbeiten' : 'Neue Aufgabe'}
                    name="action_description"
                    value={currentAction.action_description}
                    onChange={handleChange}
                    rows={2}
                />
                <Form.Group>
                    <SInput
                        label="Verantwortlicher"
                        placeholder="Verantwortlicher auswählen"
                        name="action_responsible_id"
                        value={currentAction.action_responsible_id}
                        onChange={handleChange}
                        query={queryUsers}
                        width={4}
                        required={false}
                    />

                    <StandardDatePicker
                        label="Termin"
                        name="action_deadline"
                        value={currentAction.action_deadline}
                        handleChange={handleChange}
                        width={4}
                        fluid={false}
                        required={true}
                        classNames="four wide field"
                    />
                </Form.Group>
            </Form>
            <br />
            <Button.Group fluid>
                <Button
                    primary
                    onClick={submitForm}
                    disabled={currentAction.action_description.length === 0 || loading}
                >
                    Aufgabe speichern
                </Button>
                <Button disabled={loading} onClick={resetForm}>
                    Abbrechen
                </Button>
            </Button.Group>
        </>
    );
};

export default comp;

const Action = props => {
    const [done, setDone] = React.useState(props.done);
    const { issue_status } = props;
    const updateActionDoneState = async newValue => {
        if (issue_status === 3) {
            alert('Die Meldung darf nicht bearbeitet werden.');
        } else {
            try {
                let body;
                if (newValue == true) {
                    body = {
                        done: newValue,
                        done_date: new Date().toISOString(),
                        action_actual_executor_id: getCurrentUserId(),
                    };
                } else {
                    body = { done: newValue, done_date: null, action_actual_executor_id: null };
                }

                const where = { action_id: props.action_id };

                await updateData('crud/changes/update_change_action', body, where);

                props.setUpdateList('afterActionDone' + Date.now().toString());
                props.setDepartmentUpdated('afterActionDone' + Date.now().toString());
            } catch (error) {
                console.log(error);
            }
        }
    };

    const updateAction = () => {
        props.setCurrentAction({
            action_id: props.action_id,
            action_description: props.action_description,
            action_responsible_id: props.action_responsible_id,
            action_deadline: props.action_deadline,
        });
    };

    return (
        <List.Item key={props.action_id}>
            <List.Content>
                <Grid columns={16}>
                    <Grid.Row>
                        <Grid.Column width={1}>
                            <Checkbox
                                checked={done}
                                onChange={() => updateActionDoneState(!done)}
                                disabled={props.responsible_name === ''}
                            />
                        </Grid.Column>
                        <Grid.Column width={14}>{props.action_description}</Grid.Column>
                        <Grid.Column width={1}>
                            <Icon name="edit" link onClick={updateAction} size="large" style={{ marginRight: '8px' }} />
                            <Icon
                                name="remove"
                                link
                                color="red"
                                onClick={() => props.deleteAction(props.action_id)}
                                size="large"
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </List.Content>
            <List.Content>
                <Grid columns={16}>
                    <Grid.Row style={{ paddingTop: '0px' }}>
                        <Grid.Column width={1}></Grid.Column>
                        <Grid.Column width={4}>
                            Verantwortlicher : <strong>{props.responsible_name}</strong>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            Deadline : <strong>{formatDateToDE(props.action_deadline)}</strong>
                        </Grid.Column>
                        <Grid.Column width={3}>
                            Erledigt am :<strong>{formatDateToDE(props.done_date)}</strong>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            durch : <strong>{props.executor_name}</strong>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </List.Content>
        </List.Item>
    );
};

import React, { useState } from 'react';
import { Grid } from 'semantic-ui-react';
import { COLORS } from '../global-styles';
import { get, set, findIndex } from 'lodash';
import produce from 'immer';
import styled from 'styled-components';
import ResourcesViewTable from './ResourcesViewTable';
import DUDI from './DUDI';
import FiltersView from '../FiltersView';
import useDimensions from 'react-use-dimensions';
import AVG_STATUS from './GraphicWidgets/PIE_AVG_STATUS';
import TERMINTREUE from './GraphicWidgets/PIE_TERMINTREUE';
import HISTOGRAM_SOLL_IST from './GraphicWidgets/HISTOGRAM_SOLL_IST';
import LINE_RUCKSTAND from './GraphicWidgets/LINE_RUCKSTAND';
import DLZ from './GraphicWidgets/DLZ';
import TA_ABGANG from './GraphicWidgets/TA_ABGANG';
import TA_REL from './GraphicWidgets/TA_REL';
import TA_ZUGANG from './GraphicWidgets/TA_ZUGANG';
import GRAPHtest from './GraphicWidgets/test';
import KAPAZITAETSEINHALTUNG from './GraphicWidgets/KAPAZITAETSEINHALTUNG';
import HintCard from './HintCard';
import { areFiltersValidInContext } from '../Utils/filters';
const ResourcesView = props => {
    const {
        filterOpen,
        filters,
        setFilters,
        setFilter,
        removeFilter,
        filteredPAs,
        applyFilteredPAs,
        removeFilteredPAs,
        sessionID,
        userID,
        tab,
    } = props;
    const [ref, { width }] = useDimensions();
    const colWidth = (width || 600) / 3 - 20;
    const [bestandHeute, setBestandHeute] = useState(0);
    const [mLeistung, setMLeistung] = useState(0);
    const [mReichweite, setMReichweite] = useState(0);
    const [sorts, setSorts] = useState([]);
    const [loadingExcel, setLoadingExcel] = useState();
    const [hintCardsLoading, setHintCardsLoading] = useState(true);

    const useFilters = areFiltersValidInContext(filters, tab);

    const setSort = item => {
        setSorts(
            produce(sorts, draft => {
                const existingIndex = findIndex(draft, ['id', get(item, 'id', '')]);
                if (existingIndex >= 0) {
                    get(item, 'Order') === get(draft[existingIndex], 'Order')
                        ? set(item, 'Order', 'desc')
                        : set(item, 'Order', 'asc');
                    draft[existingIndex] = item;
                } else {
                    // sort by just one column at the time, when sorting by multiple columns draft.push(item);
                    draft[0] = item;
                }
            }),
        );
    };

    const renderWidget = (Widget, title) => {
        return (
            <Widget
                title={title}
                setFilter={setFilter}
                useFilters={useFilters}
                removeFilter={removeFilter}
                filters={filters}
                width={colWidth}
                sessionID={sessionID}
                userID={userID}
            />
        );
    };

    return (
        <TabContent>
            <FiltersView
                filterOpen={filterOpen}
                filters={filters}
                setFilters={setFilters}
                setFilter={setFilter}
                removeFilter={removeFilter}
                filteredPAs={filteredPAs}
                applyFilteredPAs={applyFilteredPAs}
                removeFilteredPAs={removeFilteredPAs}
                sessionID={sessionID}
                userID={userID}
                tab={tab}
            />
            <ContentWrapper ref={ref} width={width}>
                <ResourcesViewTable
                    useFilters={useFilters}
                    filters={filters}
                    setFilter={setFilter}
                    removeFilter={removeFilter}
                    applyFilteredPAs={applyFilteredPAs}
                    sorts={sorts}
                    setSort={setSort}
                    loadingExcel={loadingExcel}
                    setLoadingExcel={setLoadingExcel}
                    sessionID={sessionID}
                    userID={userID}
                />

                <Grid>
                    <Grid.Column width={3}>
                        <HintCard title="Systembestand" value={bestandHeute} unit="h" loading={hintCardsLoading} />
                        <HintCard title="mittl. Leistung" value={mLeistung} unit="h/d" loading={hintCardsLoading} />
                        <HintCard title="mittl. Reichweite" value={mReichweite} unit="d" loading={hintCardsLoading} />
                    </Grid.Column>
                    <Grid.Column width={13}>
                        <DUDI
                            useFilters={useFilters}
                            filters={filters}
                            title="DUDI"
                            setBestandHeute={setBestandHeute}
                            setMLeistung={setMLeistung}
                            setMReichweite={setMReichweite}
                            setHintCardsLoading={setHintCardsLoading}
                            sessionID={sessionID}
                            userID={userID}
                        />
                    </Grid.Column>
                </Grid>

                <KnzSection ref={ref} width={colWidth}>
                    {renderWidget(AVG_STATUS, 'Anzahl dargestellter AVG')}
                    {renderWidget(HISTOGRAM_SOLL_IST, 'Planzeiten Soll-Ist')}
                    {renderWidget(LINE_RUCKSTAND, 'Rückstand')}
                    {/* {renderWidget(GRAPHtest, 'Placeholder')} */}
                    {renderWidget(KAPAZITAETSEINHALTUNG, 'Kapazitätseinhaltung')}
                    {renderWidget(DLZ, 'DLZ')}
                    {renderWidget(TERMINTREUE, 'Termintreue')}
                    {renderWidget(TA_ZUGANG, 'TA Zugang')}
                    {renderWidget(TA_REL, 'rel. TA')}
                    {renderWidget(TA_ABGANG, 'TA Abgang')}
                </KnzSection>
            </ContentWrapper>
        </TabContent>
    );
};

const TabContent = styled.div`
    th {
        color: ${COLORS.BLUE}!important;
    }
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 0 1 auto;
    padding-right: 1rem;
    width: calc(100vw - 270px);
    & .ui.three.statistics {
        margin: 1rem 1rem;
    }
`;

const KnzSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    padding-right: -1rem;
    > * {
        flex: 0 0 ${props => props.width || 300}px !important;
        margin: 1rem 0 !important;
        padding: 1rem 0 !important;
        overflow: hidden;
        > * {
            padding: 0 !important;
            margin: 0 10px 0 0 !important;
        }
    }
`;

export default ResourcesView;

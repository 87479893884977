import React from 'react';
import { Form, Button } from 'semantic-ui-react';
import { getData, insertSimpleData, updateData } from '../../UtilComponents/requestsUtils';

const defaultFormData = {
    improvements_proposal_details_id: '',
    improvements_proposal_current_state: '',
    improvements_proposal_suggestion_for_improvement: '',
    improvements_proposal_advantages: '',
};

const defaultFormFieldsValidity = {
    improvements_proposal_current_state: true,
    improvements_proposal_suggestion_for_improvement: true,
    improvements_proposal_advantages: true,
};

const ImprovementsProposalDetailsForm = ({ issue_id, issue_status }) => {
    const [loading, setLoading] = React.useState(false);
    const [formData, setFormData] = React.useState(defaultFormData);
    const [formFieldsValidity, setFormFieldsValidity] = React.useState(defaultFormFieldsValidity);

    const handleChange = (event, data) => {
        const newObjState = Object.assign({}, formData, { [data.name]: data.value });
        setFormData(newObjState);
    };

    const isFormValid = () => {
        if (!Object.values(formFieldsValidity).every(i => i === true)) return false;
        return true;
    };

    React.useEffect(() => {
        const getImprovementsProposalDetails = async () => {
            setLoading(true);
            try {
                const response = await getData(
                    `crud/issues/get_issue_improvements_proposal_details?issue_id=${issue_id}`,
                );
                if (response[0]) {
                    setFormData(response[0]);
                }
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        };
        getImprovementsProposalDetails();
    }, []);

    const createDetails = async () => {
        setLoading(true);
        try {
            const { improvements_proposal_details_id, ...rest } = formData;
            const body = {
                ...rest,
                issue_id: issue_id,
            };
            const response = await insertSimpleData('crud/issues/post_issue_improvements_proposal_details', body);
            setFormData({ ...response });

            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const updateDetails = async () => {
        setLoading(true);
        try {
            const { issue_id, improvements_proposal_details_id, ...rest } = formData;
            const response = await updateData('crud/issues/update_issue_improvements_proposal_details', rest, {
                improvements_proposal_details_id: improvements_proposal_details_id,
            });
            setFormData({ ...response });
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const submitForm = () => {
        if (issue_status === 3) {
            alert('Die Meldung darf nicht bearbeitet werden.');
        } else {
            !formData.improvements_proposal_details_id ? createDetails() : updateDetails();
        }
    };

    return (
        <Form loading={loading}>
            <Form.TextArea
                label="Aktueller Zustand​"
                name="improvements_proposal_current_state"
                value={formData.improvements_proposal_current_state}
                onChange={handleChange}
                width={16}
                rows={5}
            />
            <Form.TextArea
                label="Verbesserungsvorschlag​"
                name="improvements_proposal_suggestion_for_improvement"
                value={formData.improvements_proposal_suggestion_for_improvement}
                onChange={handleChange}
                width={16}
                rows={5}
            />
            <Form.TextArea
                label="Vorteile​"
                name="improvements_proposal_advantages"
                value={formData.improvements_proposal_advantages}
                onChange={handleChange}
                width={16}
                rows={5}
            />
            <Button fluid primary disabled={!isFormValid()} onClick={submitForm}>
                Speichern
            </Button>
        </Form>
    );
};

export default ImprovementsProposalDetailsForm;

import React from 'react';
import { List, Form, Button, Divider, Checkbox, Icon, Popup, Grid, Segment } from 'semantic-ui-react';
import {
    deleteData,
    getCurrentUserId,
    getData,
    insertSimpleData,
    updateData,
} from '../../UtilComponents/requestsUtils';
import SearchableInput from '../../UtilComponents/SearchableInput';
import StandardDatePicker, { formatDateDEStringForSQL, formatDateToDE } from '../../UtilComponents/StandardDatePicker';
import { queryUsers } from '../Queries';

const comp = ({ issue_id, numberOfTabsToShow, setNumberOfTabsToShow, issue_status }) => {
    const formInitialState = { id: '', corrective_action: '', responsible_id: '', action_deadline: '' };
    const [currentAction, setCurrentAction] = React.useState(formInitialState);

    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState('');
    const [updateList, setUpdateList] = React.useState('');

    const handleChange = (evt, data) => {
        const newObjState = Object.assign({}, currentAction, { [data.name]: data.value });
        setCurrentAction(newObjState);
    };

    const resetForm = () => {
        setCurrentAction(formInitialState);
    };

    // handling form submition
    const submitForm = () => {
        if (issue_status === 3) {
            alert('Die Meldung darf nicht bearbeitet werden.');
        } else {
            setLoading(true);
            currentAction.id ? updateAction() : createAction();
        }
    };

    const createAction = async () => {
        const body = {
            issue_id: issue_id,
            corrective_action: currentAction.corrective_action,
            user_id: getCurrentUserId(),
        };
        if (currentAction.responsible_id) {
            body.responsible_id = currentAction.responsible_id;
        }
        if (currentAction.action_deadline) {
            body.action_deadline = formatDateDEStringForSQL(currentAction.action_deadline);
        }
        try {
            await insertSimpleData(queryPostIssueCorrectiveAction, body);
            setCurrentAction(formInitialState);
            if (data.length === 0) {
                setNumberOfTabsToShow(numberOfTabsToShow + 1);
            }
            setLoading(false);
            setUpdateList('afterCreation' + Date.now().toString());
        } catch (error) {
            console.log(error);
        }
    };

    const updateAction = async () => {
        const body = {
            issue_id: issue_id,
            corrective_action: currentAction.corrective_action,
            user_id: getCurrentUserId(),
            responsible_id: currentAction.responsible_id,
            action_deadline:
                currentAction.action_deadline == null ? null : formatDateDEStringForSQL(currentAction.action_deadline),
        };
        const where = { id: currentAction.id };
        try {
            await updateData(queryUpdateIssueCorrectiveAction, body, where);
            setLoading(false);
            setUpdateList('afterUpdate' + Date.now().toString());
            setCurrentAction(formInitialState);
        } catch (error) {
            console.log(error);
        }
    };

    const deleteAction = async actionId => {
        if (issue_status === 3) {
            alert('Die Meldung darf nicht bearbeitet werden.');
        } else {
            const where = { id: actionId };
            try {
                await deleteData(queryDeleteIssueCorrectiveAction, where);
                setLoading(false);
                setUpdateList('afterDeletion' + Date.now().toString());
                setCurrentAction(formInitialState);
            } catch (error) {
                console.log(error);
            }
        }
    };

    React.useEffect(() => {
        const getIssueActions = async () => {
            setLoading(true);
            setData([]);
            try {
                const response = await getData(`${queryGetIssueCorrectiveActions}?issue_id=${issue_id}`);
                setData(response);
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        };
        getIssueActions();
    }, [updateList]);

    return (
        <Segment basic loading={loading}>
            <List relaxed="very">
                {data.map(obj => {
                    return (
                        <Action
                            {...obj}
                            issue_status={issue_status}
                            setCurrentAction={setCurrentAction}
                            deleteAction={deleteAction}
                            setUpdateList={setUpdateList}
                        />
                    );
                })}
            </List>
            <Divider />
            <br />
            <Form>
                <Form.TextArea
                    label={
                        <Popup
                            content="Die nötigen, im Rahmen der Analyse indentifizierten, Korrekturmaßnahmen können hier als Aufgaben hinterlegt werden. Bitte 'Was' und 'Wer' hinterlegen. Der Blattverantwortliche überprüft die Abarbeitung. Bitte mögliche Änderungen oder zusätzliche Infos im Reiter 'Kommentare' hinterlegen.​"
                            trigger={
                                <label>
                                    {currentAction.id ? 'Korrekturmaßnahme bearbeiten' : 'Neue Korrekturmaßnahme'}
                                </label>
                            }
                            wide="very"
                        />
                    }
                    name="corrective_action"
                    value={currentAction.corrective_action}
                    onChange={handleChange}
                    rows={2}
                />
                <Form.Group>
                    <SearchableInput
                        label="Verantwortlicher"
                        placeholder="Verantwortlicher auswählen"
                        name="responsible_id"
                        value={currentAction.responsible_id}
                        onChange={handleChange}
                        query={queryUsers}
                        width={4}
                        required={false}
                    />

                    <StandardDatePicker
                        label="Termin"
                        name="action_deadline"
                        value={currentAction.action_deadline}
                        handleChange={handleChange}
                        width={4}
                        fluid={false}
                        required={true}
                        classNames="four wide field"
                    />
                </Form.Group>
            </Form>
            <br />
            <Button.Group fluid>
                <Button primary onClick={submitForm} disabled={currentAction.corrective_action.length === 0 || loading}>
                    Speichern
                </Button>

                <Button disabled={loading} onClick={resetForm}>
                    Abbrechen
                </Button>
            </Button.Group>
        </Segment>
    );
};

export default comp;

const Action = props => {
    const [done, setDone] = React.useState(props.done);
    const { issue_status } = props;
    const updateActionDoneState = async newValue => {
        if (issue_status === 3) {
            alert('Die Meldung darf nicht bearbeitet werden.');
        } else {
            try {
                let body;
                if (newValue == true) {
                    body = {
                        done: newValue,
                        done_date: new Date().toISOString(),
                        actual_executor_id: getCurrentUserId(),
                    };
                } else {
                    body = { done: newValue, done_date: null, actual_executor_id: null };
                }

                const where = { id: props.id };
                await updateData(queryUpdateIssueCorrectiveAction, body, where);
                props.setUpdateList('afterActionDone' + Date.now().toString());
            } catch (error) {
                console.log(error);
            }
        }
    };

    const updateAction = () => {
        props.setCurrentAction({
            id: props.id,
            corrective_action: props.corrective_action,
            responsible_id: props.responsible_id,
            action_deadline: props.action_deadline,
        });
    };

    return (
        <List.Item key={props.id}>
            <List.Content>
                <Grid columns={16}>
                    <Grid.Row>
                        <Grid.Column width={1}>
                            <Checkbox checked={done} onChange={() => updateActionDoneState(!done)} />
                        </Grid.Column>
                        <Grid.Column width={14}>{props.corrective_action}</Grid.Column>
                        <Grid.Column width={1}>
                            <Icon name="edit" link onClick={updateAction} />
                            <Icon name="remove" link color="red" onClick={() => props.deleteAction(props.id)} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </List.Content>
            <List.Content>
                <Grid columns={16}>
                    <Grid.Row style={{ paddingTop: '0px' }}>
                        <Grid.Column width={1}></Grid.Column>
                        <Grid.Column width={4}>
                            Verantwortlicher : <strong>{props.responsible_name}</strong>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            Deadline : <strong>{formatDateToDE(props.action_deadline)}</strong>
                        </Grid.Column>
                        <Grid.Column width={3}>
                            Erledigt am :<strong>{formatDateToDE(props.done_date)}</strong>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            durch : <strong>{props.executor_name}</strong>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </List.Content>
        </List.Item>
    );
};

const queryGetIssueCorrectiveActions = 'crud/issues/get_issue_corrective_actions';
const queryPostIssueCorrectiveAction = 'crud/issues/post_issue_corrective_action';
const queryUpdateIssueCorrectiveAction = 'crud/issues/update_issue_corrective_action';
const queryDeleteIssueCorrectiveAction = 'crud/issues/delete_issue_corrective_action';

export const isTitleValid = title => {
    if (title !== '' && title.length < 3) return false;
    return true;
};

export const isDescriptionValid = description => {
    if (description !== '' && description.length < 8) return false;
    return true;
};

export const isEmailValid = email => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email !== '' && (email.length < 6 || !emailRegex.test(email))) return false;
    return true;
};

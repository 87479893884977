import React from 'react';
import { Tab } from 'semantic-ui-react';
import FieldItems from './FieldItems';
import ProtocolForm from './ProtocolForm';
import FieldForm from './FieldForm';
import FormGroupAccordeon from './FormGroupAccordeon';

const ProtocolFormTabs = props => {
    const [activeTab, setActiveTab] = React.useState(0);
    const [formFieldId, setFormFieldId] = React.useState(null);
    const handleTabChange = index => {
        if (index !== 2) {
            setFormFieldId(null);
        }
        setActiveTab(index);
    };

    const tabs = [
        {
            menuItem: 'Protokoll-Info',
            render: () => (
                <Tab.Pane>
                    <ProtocolForm {...props} />
                </Tab.Pane>
            ),
        },
    ];

    if (props.formData.id) {
        tabs.push({
            menuItem: 'Protokollfelder',
            render: () => (
                <Tab.Pane>
                    <FormGroupAccordeon
                        {...props}
                        setActiveTab={setActiveTab}
                        setFormFieldId={setFormFieldId}
                        formId={props.formData.id}
                    />
                </Tab.Pane>
            ),
        });

        tabs.push({
            menuItem: formFieldId == null ? 'Feld anlegen' : 'Feld bearbeiten',
            render: () => (
                <Tab.Pane>
                    <FieldForm {...props} formId={props.formData.id} formFieldId={formFieldId} />
                </Tab.Pane>
            ),
        });
    }

    return <Tab panes={tabs} activeIndex={activeTab} onTabChange={(e, d) => handleTabChange(d.activeIndex)} />;
};

export default ProtocolFormTabs;

import React from 'react';
import { List, Card, Icon } from 'semantic-ui-react';
import { userFormInitialState } from '.';
import { getData } from '../UtilComponents/requestsUtils';

const cardFullWidth = {
    width: '100%',
};

const UserList = ({ updateUserList, setUserModalOpen, setUserFormData }) => {
    const [users, setUsers] = React.useState([]);

    const handleEdit = (data, user) => {
        const { roles_names, ...rest } = user;
        setUserModalOpen(true);
        setUserFormData(rest);
    };

    React.useEffect(() => {
        const getCompanyUsers = async () => {
            try {
                const response = await getData(`crud/settings/get_users`);
                if (response) {
                    setUsers(response);
                }
            } catch (error) {}
        };
        getCompanyUsers();
    }, [updateUserList]);

    const openUserModalForCreation = () => {
        setUserFormData(userFormInitialState);
        setUserModalOpen(true);
    };
    return (
        <Card style={cardFullWidth}>
            <Card.Content>
                <Card.Header style={{ display: 'flex', justifyContent: 'space-between' }}>
                    Benutzer
                    <Icon name="plus" style={{ cursor: 'pointer' }} onClick={openUserModalForCreation} />
                </Card.Header>
            </Card.Content>
            <Card.Content>
                <List selection>
                    {users.map(user => (
                        <List.Item key={user.email}>
                            <List.Content floated="right">
                                <Icon name="edit" onClick={(e, d) => handleEdit(d, user)} />
                            </List.Content>
                            <List.Icon name="user" size="large" verticalAlign="middle" />
                            <List.Content>
                                <List.Header as="a">
                                    {user.first_name}.{user.last_name}
                                </List.Header>
                                <List.Description as="b">{user.email}</List.Description>
                                <List.Description as="a"> {user.roles_names.join(', ')}</List.Description>
                            </List.Content>
                        </List.Item>
                    ))}
                </List>
            </Card.Content>
        </Card>
    );
};

export default UserList;

import React, { useState, useEffect } from 'react';
import { Segment } from 'semantic-ui-react';
import styled from 'styled-components';
import { COLORS } from '../global-styles';
import FiltersView from '../FiltersView';
import TimeLine from './TimeLine';
import TextSearchForColumns from '../Table/TextSearchForColumns';
import { formatForSmartTextSearch } from '../Utils/Tables';
// text search for table columns context specific infomation and logic
const searchTableOptions = [
    { key: 'Bezeichnung', text: 'Bezeichnung', value: 'Bezeichnung' },
    { key: 'Seriennummern', text: 'Seriennummern', value: 'Seriennummern' },
    { key: 'PPSOrderID', text: 'PPSOrderID', value: 'PPSOrderID' },
    { key: 'Haupt-PA', text: 'Haupt-PA', value: 'Haupt-PA' },
    { key: 'Status', text: 'Status', value: 'Status' },
];

const TimeLineView = props => {
    const {
        filterOpen,
        filters,
        setFilters,
        setFilter,
        removeFilter,
        filteredPAs,
        applyFilteredPAs,
        removeFilteredPAs,
        tab,
        sessionID,
        userID,
    } = props;

    const [loading, setLoading] = useState(true);
    const [selectedField, setSelectedField] = useState('Bezeichnung'); // for table colum filter

    const searchTableOptionsLogic = () => {
        const input = document.getElementById('textToSearchFor');

        if (!input.value) return;

        if (selectedField == 'Bezeichnung') {
            // for the filter to work the final string should be formatted like
            // ALL(ARRAY['word1','word2', 'word3'])
            const [operator, formattedString] = formatForSmartTextSearch(input.value);
            setFilter({
                id: selectedField,
                Attribute: selectedField,
                Operator: operator,
                Value: `ALL(ARRAY${formattedString})`,
            });

            return;
        }

        setFilter({
            id: selectedField,
            Attribute: selectedField,
            Operator: '~*',
            Value: `'${input.value}'`,
        });
    };

    return (
        <TabContent>
            <FiltersView
                filterOpen={filterOpen}
                filters={filters}
                setFilters={setFilters}
                setFilter={setFilter}
                removeFilter={removeFilter}
                filteredPAs={filteredPAs}
                applyFilteredPAs={applyFilteredPAs}
                removeFilteredPAs={removeFilteredPAs}
                userID={userID}
                sessionID={sessionID}
                tab={tab}
            />
            <ContentWrapper loading={loading}>
                <TextSearchForColumns
                    setFilter={setFilter}
                    setSelectedField={setSelectedField}
                    searchTableOptions={searchTableOptions}
                    searchTableOptionsLogic={searchTableOptionsLogic}
                    defaultValue="Bezeichnung"
                />
                <br />
                <TimeLine
                    setFilter={setFilter}
                    filters={filters}
                    filteredPAs={filteredPAs}
                    userID={userID}
                    sessionID={sessionID}
                    setLoading={setLoading}
                />
            </ContentWrapper>
        </TabContent>
    );
};

export default TimeLineView;

const TabContent = styled.div`
    th {
        color: ${COLORS.BLUE}!important;
    }
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

const ContentWrapper = styled(Segment)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 0 1 auto;
    padding-right: 1rem;
    width: calc(100vw - 280px);
    & .ui.three.statistics {
        margin: 1rem 1rem;
    }
    /* css for the beams */
    .vis-item.vis-range.expected {
        background-color: transparent;
        border: dashed 1px black !important;
        z-index: 2;
    }
    .vis-item.vis-range.early {
        background-color: ${COLORS.GREEN};
        border: solid 1px ${COLORS.GREEN} !important;
        text-align: center;
        z-index: 1;
    }
    .vis-item.vis-range.late {
        background-color: ${COLORS.RED};
        border: solid 1px ${COLORS.RED} !important;
        text-align: center;
        z-index: 1;
    }

    /* css for the points */

    .vis-item.vis-dot.early {
        border-color: ${COLORS.GREEN};
        z-index: 1;
    }

    .vis-item.vis-dot.late {
        border-color: ${COLORS.RED};
        z-index: 1;
    }

    .vis-item.vis-selected {
        opacity: 0.6;
    }
    .vis-item.vis-background.marker {
        border-left: 2px solid green;
    }
`;

import React from 'react';
import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';
import { COLORS } from '../../global-styles';
import UserList from './UsersList';
import RolesList from './RolesList';
import RoleFormModal from './RoleFormModal';
import UserFormModal from './UserFormModal';
import AsyncSearchableInput from '../UtilComponents/AsyncSearchableInput';

const SettingsView = props => {
    const [roleFormData, setRoleFormData] = React.useState(roleFormInitialState);
    const [userFormData, setUserFormData] = React.useState(userFormInitialState);
    const [roleModalOpen, setRoleModalOpen] = React.useState(false);
    const [userModalOpen, setUserModalOpen] = React.useState(false);

    const [updateRoleList, setUpdateRoleList] = React.useState('');
    const [updateUserList, setUpdateUserList] = React.useState('');

    const [test, setTest] = React.useState('');
    const handleChange = (evt, data) => {
        setTest(data.value);
    };
    return (
        <TabContent>
            <Grid>
                {/* <Grid.Column width={3}>
                    <AsyncSearchableInput
                        label="Artikelnummer"
                        placeholder="Artikelnummer auswählen"
                        name="po_number"
                        value={test}
                        query="qm/get_article_numbers_test"
                        onChange={handleChange}
                        width={6}
                        fluid={false}
                    />
                </Grid.Column> */}
                <Grid.Column width={3}>
                    <RolesList
                        updateRoleList={updateRoleList}
                        setRoleModalOpen={setRoleModalOpen}
                        setRoleFormData={setRoleFormData}
                    />
                </Grid.Column>
                <Grid.Column width={3}>
                    <UserList
                        updateUserList={updateUserList}
                        setUserModalOpen={setUserModalOpen}
                        setUserFormData={setUserFormData}
                    />
                </Grid.Column>
            </Grid>
            <RoleFormModal
                open={roleModalOpen}
                setOpen={setRoleModalOpen}
                formData={roleFormData}
                setFormData={setRoleFormData}
                setUpdateRoleList={setUpdateRoleList}
            />
            <UserFormModal
                open={userModalOpen}
                setOpen={setUserModalOpen}
                formData={userFormData}
                setFormData={setUserFormData}
                setUpdateUserList={setUpdateUserList}
            />
        </TabContent>
    );
};

export default SettingsView;

export const roleFormInitialState = {
    role_name: '',
    role_description: '',
};

export const userFormInitialState = {
    first_name: '',
    last_name: '',
    email: '',
    roles_ids: [],
};

const TabContent = styled.div`
    th {
        color: ${COLORS.BLUE}!important;
    }
    display: flex;
    width: calc(100vw - 2rem);
    .ui.grid {
        width: 100%;
    }
`;

import React from 'react';
import { Button, Form, Checkbox } from 'semantic-ui-react';
import { insertSimpleData, updateData } from '../UtilComponents/requestsUtils';
import { isTitleValid, isDescriptionValid } from '../UtilComponents/validations';
import SearchableInput from '../UtilComponents/SearchableInput';
import StandardDatePicker, { formatDateDEStringForSQL } from '../UtilComponents/StandardDatePicker';

const ProtocolForm = ({ formData, setFormData, setUpdateProtocols }) => {
    const [validInputs, setValidInputs] = React.useState(protocolFormInputsValidityState);
    const [loading, setLoading] = React.useState(false);

    const handleChange = (event, data) => {
        const newObjState = Object.assign({}, formData, {
            [data.name]: data.type === 'checkbox' ? data.checked : data.value,
        });
        setFormData(newObjState);
    };

    const validateName = () => {
        const { form_name } = formData;
        const newObjState = Object.assign({}, validInputs, { form_name: isTitleValid(form_name) });
        setValidInputs(newObjState);
    };

    const validateDescription = () => {
        const { form_description } = formData;
        const newObjState = Object.assign({}, validInputs, {
            form_description: isDescriptionValid(form_description),
        });
        setValidInputs(newObjState);
    };

    const isFormValid = () => {
        if (!Object.values(validInputs).every(i => i === true)) return false;
        if (!Object.values(formData).every(i => i !== '')) return false;
        return true;
    };

    // handling form submition
    const submitForm = () => {
        !formData.id ? postNewProtocol() : updateProtocol();
    };

    const postNewProtocol = async () => {
        setLoading(true);
        const { form_valid_from_date, ...rest } = formData;
        const body = {
            ...rest,
            form_valid_from_date: formatDateDEStringForSQL(form_valid_from_date),
        };
        try {
            const response = await insertSimpleData('crud/protocols/post_protocol', body);
            setLoading(false);
            setFormData(response);
            setUpdateProtocols('afterCreation' + Date.now().toString());
        } catch (error) {
            console.log(error);
        }
    };

    const updateProtocol = async () => {
        setLoading(true);
        const { id, form_valid_from_date, ...rest } = formData;
        const body = {
            ...rest,
            form_valid_from_date: formatDateDEStringForSQL(form_valid_from_date),
        };
        try {
            const response = await updateData('crud/protocols/update_protocol', body, { id: id });
            setLoading(false);
            setFormData(response);
            setUpdateProtocols('afterUpdate' + Date.now().toString());
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <Form loading={loading}>
                <Form.Input
                    label="Protokollname"
                    name="form_name"
                    value={formData.form_name}
                    required
                    onChange={handleChange}
                    onBlur={validateName}
                    error={!validInputs.form_name}
                />
                <Form.TextArea
                    label="Beschreibung"
                    name="form_description"
                    value={formData.form_description}
                    required
                    onChange={handleChange}
                    onBlur={validateDescription}
                    error={!validInputs.form_description}
                />
                <Form.Group>
                    <SearchableInput
                        label="Artikelnummer"
                        placeholder="Artikelnummer"
                        name="article_number"
                        value={formData.article_number}
                        query="crud/protocols/get_articles_number"
                        onChange={handleChange}
                        width={6}
                    />
                    <Form.Input
                        label="Revision"
                        name="form_revision_number"
                        value={formData.form_revision_number}
                        required
                        onChange={handleChange}
                        width={5}
                    />
                    <StandardDatePicker
                        label="Gültig ab"
                        name="form_valid_from_date"
                        value={formData.form_valid_from_date}
                        handleChange={handleChange}
                        fluid={false}
                        required={true}
                        classNames="five wide field"
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Field>
                        <Checkbox
                            label="Qualitätssicherung Freigabe"
                            name="approved_by_qa"
                            checked={formData.approved_by_qa}
                            onClick={handleChange}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Checkbox
                            label="Active"
                            name="form_active"
                            toggle
                            checked={formData.form_active}
                            onClick={handleChange}
                        />
                    </Form.Field>
                </Form.Group>
            </Form>
            <br />
            <Button fluid primary onClick={submitForm} disabled={!isFormValid() || loading}>
                Speichern
            </Button>
        </>
    );
};

export default ProtocolForm;

const protocolFormInputsValidityState = {
    form_name: true,
    form_description: true,
    article_number: true,
    form_revision_number: true,
    form_valid_from_date: true,
    form_active: true,
    approved_by_qa: true,
};

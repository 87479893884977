import React, { useEffect, useState, useMemo } from 'react';
import { LinesWidgetFrame, KNZ, GWTitle } from '../../Utils/widgetsStyles';
import { formatDecimal, formatDate, loadData } from '../../Utils';
import { Rueckstand } from './TooltipsTexts';
import { ChartLabelColors } from '../../config';
import { queryOrdersK4 } from '../Queries';
import { XYPlot, XAxis, YAxis, HorizontalGridLines, LineSeries, DiscreteColorLegend } from 'react-vis';
import Modal from '../../Utils/Modal';
import Tooltip from '../../Utils/Tooltip';

const Comp = props => {
    const { filters, title, width, height, modalOpen, sessionID, userID } = props;
    const [dataCurrent, setDataCurrent] = useState([]);
    const [dataTarget, setDataTarget] = useState([]);
    const [dataLabels, setDataLabels] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const [lastVal, setLastVal] = useState();

    const setDataWithMod = d => {
        const dc = [];
        const dt = [];
        const dl = [];
        const p = d.length / 20;
        let lastPlan = 0;
        let lastIst = 0;
        for (let i = 0; i < d.length; i++) {
            if (i > 0) {
                if (d[i].ist_ende_cum == null) {
                    d[i].ist_ende_cum = d[i - 1].ist_ende_cum;
                }
                if (d[i].plan_ende_cum == null) {
                    d[i].plan_ende_cum = d[i - 1].plan_ende_cum;
                }
            } else {
                if (d[i].ist_ende_cum == null) {
                    d[i].ist_ende_cum = 0;
                }
                if (d[i].plan_ende_cum == null) {
                    d[i].plan_ende_cum = 0;
                }
            }
            if (i % p >= 0 && i % p < 1) {
                dl.push(d[i].dat);
            }
            dc.push({ x: d[i].dat, y: d[i].ist_ende_cum });
            dt.push({ x: d[i].dat, y: d[i].plan_ende_cum });
            lastPlan = d[i].plan_ende_cum;
            lastIst = d[i].ist_ende_cum;
        }
        setLastVal(lastPlan - lastIst);
        setDataLabels(dl);
        setDataCurrent(dc);
        setDataTarget(dt);
    };
    useEffect(() => {
        //const filtersToUse = filteredPAs.length == 1 ? filteredPAs : filters;
        loadData(setLoadingData, setDataWithMod, queryOrdersK4, filters, sessionID, userID, 0);
    }, [filters, sessionID, userID]);
    return useMemo(() => {
        return (
            <LinesWidgetFrame loading={loadingData}>
                <KNZ>
                    <GWTitle>
                        <Tooltip text={Rueckstand}></Tooltip>
                        <span>{title}</span>
                        {modalOpen ? <span></span> : <Modal graphWidget={<Comp />} config={props} />}
                    </GWTitle>
                    <div className="kflist">
                        <div>
                            <span className="val">{formatDecimal(lastVal)}</span>
                        </div>
                    </div>
                </KNZ>
                <XYPlot
                    className="stackedLine"
                    margin={{ bottom: 80, left: 60 }}
                    xType="ordinal"
                    width={width}
                    height={height || 200}
                >
                    <HorizontalGridLines />
                    <YAxis tickFormat={v => formatDecimal(v, 0)} />
                    <XAxis tickFormat={v => formatDate(v)} tickLabelAngle={-60} tickValues={dataLabels} />
                    <LineSeries data={dataCurrent} color={ChartLabelColors[0]} />
                    <LineSeries data={dataTarget} color={ChartLabelColors[1]} />
                    <DiscreteColorLegend
                        style={{ position: 'absolute', top: '0px', left: '50px' }}
                        orientation="vertical"
                        items={[
                            {
                                title: 'Plan-Ende',
                                color: ChartLabelColors[1],
                            },
                            {
                                title: 'Ist-Ende',
                                color: ChartLabelColors[0],
                            },
                        ]}
                    />
                </XYPlot>
            </LinesWidgetFrame>
        );
    }, [dataCurrent, loadingData, width]);
};

export default Comp;

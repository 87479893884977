import React from 'react';
import { Segment } from 'semantic-ui-react';
import styled from 'styled-components';
import { buildTableColumns } from './TableColumnsDeclaration';
import { biRequestWithoutCache } from '../../UtilComponents/requestsUtils';
import { formatForSmartTextSearch } from '../../../Utils/Tables';
import TableTemplate from './TableTemplate';

const NSTable = props => {
    const today = Date.now();

    const { setFilter, sorts, setSort, filters } = props;

    const [data, setData] = React.useState(null);
    const [selectedField, setSelectedField] = React.useState('PA-Nummer'); // for table colum filter
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        const getIssues = async () => {
            try {
                setLoading(true);
                const response = await biRequestWithoutCache(`ns/get_issues_for_ns`, filters);
                setData(response);
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        };
        getIssues();
    }, [filters, sorts]);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Details',
                columns: buildTableColumns(setSort, sorts),
            },
        ],
        [sorts],
    );
    // text search for table columns context specific infomation and logic
    const searchTableOptions = [
        { key: 'ID', text: 'ID', value: 'serial_id' },
        { key: 'Beschreibung', text: 'Beschreibung', value: 'beschreibung' },
        { key: 'Blattverantwortlicher', text: 'Blattverantwortlicher', value: 'issue_responsible' },
    ];
    // Wenn (TA Abgang >0), dann „rot“ oder wenn((TA Abgang = empty) und (Plan-Ende < heute()), dann „rot“
    const shouldRowBeRed = row => {
        if (row.values['TA Abgang'] > 0) {
            return true;
        } else if (row.values['TA Abgang'] == null && Date.parse(row.values['Plan-Ende']) < today) {
            return true;
        } else {
            return false;
        }
    };

    const searchTableOptionsLogic = () => {
        const input = document.getElementById('textToSearchFor');
        if (!input.value) return;

        if (selectedField == 'Bezeichnung') {
            const [operator, formattedString] = formatForSmartTextSearch(input.value);
            setFilter({
                id: selectedField,
                Attribute: selectedField,
                Operator: operator,
                Value: `ALL(ARRAY${formattedString})`,
            });
            return;
        } else {
            setFilter({
                id: selectedField,
                Attribute: selectedField,
                Operator: '~*',
                Value: `'${input.value}'`,
            });
        }
    };
    // text search for table columns context specific infomation and logic
    return React.useMemo(() => {
        return (
            <Segment100 loading={loading}>
                {columns && data ? (
                    <TableTemplate
                        columns={columns}
                        data={data}
                        loading={loading}
                        setSelectedField={setSelectedField}
                        searchTableOptions={searchTableOptions}
                        searchTableOptionsLogic={searchTableOptionsLogic}
                        getRowProps={row => {
                            const rule = shouldRowBeRed(row);
                            return {
                                className: `${rule ? 'red' : ''}`,
                            };
                        }}
                    />
                ) : (
                    <TablePlaceholder />
                )}
            </Segment100>
        );
    }, [data, loading, selectedField]);
};

const Segment100 = styled(Segment)`
    width: 100% !important;
`;
const TablePlaceholder = styled.div`
    width: 100%;
`;

export default NSTable;

import React from 'react';
import { Form, List, Button, Divider } from 'semantic-ui-react';
import { formatStringDateToLongDE } from '../../../UtilComponents/dateFormats';
import { getCurrentUserId, getData, insertSimpleData } from '../../../UtilComponents/requestsUtils';

const CommentForm = ({ orderNumber }) => {
    const [commentList, setCommentList] = React.useState([]);
    const [comment, setComment] = React.useState('');
    const [loading, setLoading] = React.useState('');
    const [updateComments, setUpdateComments] = React.useState('');

    const submitForm = async () => {
        try {
            setLoading(true);
            const body = { order_number: orderNumber, order_comment: comment, created_by: getCurrentUserId() };
            await insertSimpleData('crud/d8h/add_comment_to_production_orders', body);
            setComment('');
            setUpdateComments('afterCreation' + Date.now().toString());
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    React.useEffect(() => {
        const getIssueComments = async () => {
            try {
                const response = await getData(`crud/d8h/get_comments_by_production_order?order_number=${orderNumber}`);
                setCommentList(response);
            } catch (error) {
                console.log(error);
            }
        };
        getIssueComments();
    }, [updateComments]);

    return (
        <>
            <List relaxed="very">
                {commentList.map(obj => {
                    return <Comment {...obj} key={obj.id} />;
                })}
            </List>
            <Divider />
            <br />
            <Form>
                <Form.TextArea
                    label="Neuer Kommentar"
                    value={comment}
                    onChange={(e, data) => setComment(data.value)}
                    rows={2}
                />
            </Form>
            <br />
            <Button fluid primary onClick={submitForm} disabled={comment.length === 0 || loading}>
                Speichern
            </Button>
        </>
    );
};

export default CommentForm;

const Comment = props => {
    return (
        <List.Item>
            <List.Content>
                <List.Header>{props.created_by_name}</List.Header>
                <List.Description>
                    {props.order_comment}
                    <br /> {formatStringDateToLongDE(props.created_at)}
                </List.Description>
            </List.Content>
        </List.Item>
    );
};

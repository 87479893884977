import React from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { issueFormDataInitialState } from './sfUtils';
import 'regenerator-runtime/runtime';
import NotificationFormTabs from './NotificationFormTabs';

export const NotificationModal = ({ open, setOpen, formData, setFormData, setUpdateColumns }) => {
    const title = !formData.id ? 'Meldung erstellen' : `${formData.issue_type} bearbeiten`;

    const openModal = () => {
        setFormData(issueFormDataInitialState);
        setOpen(true);
    };

    const closeModal = () => {
        setOpen(false);
        setUpdateColumns('closeModal' + Date.now().toString());
    };

    return (
        <Modal
            closeIcon
            closeOnDimmerClick={false}
            size="large"
            open={open}
            onOpen={openModal}
            onClose={closeModal}
            trigger={
                <Button primary fluid>
                    Meldung erstellen
                </Button>
            }
            style={{ width: 1280 }}
        >
            <Modal.Header style={{ textAlign: 'center' }}>{title}</Modal.Header>
            <Modal.Content>
                <NotificationFormTabs formData={formData} closeModal={closeModal} setUpdateColumns={setUpdateColumns} />
            </Modal.Content>
        </Modal>
    );
};

import React, { useEffect, useState, useMemo } from 'react';
import { formatDecimal, formatDate, loadData } from '../Utils';
import { ChartLabelColors } from '../config';
import { queryDUDIZugangStart, queryDUDIkurve, queryCapacitykurve } from './Queries';
import { FlexibleXYPlot, XAxis, YAxis, HorizontalGridLines, LineSeries, DiscreteColorLegend } from 'react-vis';
import Modal from '../Utils/Modal';
import Tooltip from '../Utils/Tooltip';
import { DUDI_TEXT } from './GraphicWidgets/TooltipsTexts';
import { LinesWidgetFrame, KNZ, GWTitle } from '../Utils/widgetsStyles';

const today = new Date().toISOString().slice(0, 10);
const getOperativeDays = collection => {
    const allDays = collection.length;
    const weekendDays = [];
    collection.forEach(obj => {
        const dateObj = new Date(obj['Plan-Start']);
        if (dateObj.getDay() == 6 || dateObj.getDay() == 0) {
            weekendDays.push(obj['Plan-Start']);
        }
    });
    const operativeDays = allDays - weekendDays.length - 1;
    return operativeDays > 0 ? operativeDays : 1;
};

const Comp = props => {
    const {
        useFilters,
        filters,
        setBestandHeute,
        setMLeistung,
        setMReichweite,
        setHintCardsLoading,
        sessionID,
        title,
        userID,
        width,
        height,
        modalOpen,
    } = props;
    const [zugangsStart, setDataZugangsStart] = useState(0);
    const [zugangskurve, setDataZugangskurve] = useState({ name: 'Zugang', disabled: false, data: [] });
    const [abgangskurve, setDataAbgangskurve] = useState({ name: 'Ist-Abgang', disabled: false, data: [] });
    const [abgangskurve2, setDataAbgangskurve2] = useState({ name: 'Plan-Abgang', disabled: true, data: [] });
    const [ruckstandkurve, setDataRuckstandkurve] = useState({ name: 'Rückstand', disabled: false, data: [] });
    const [bestandkurve, setDataBestandkurve] = useState({ name: 'Ist-Bestand', disabled: false, data: [] });
    const [planBestandkurve, setDataPlanBestandkurve] = useState({ name: 'Plan-Bestand', disabled: true, data: [] });
    const [bestandProgkurve, setDataBestandProgkurve] = useState({ name: 'Bestand prog', disabled: false, data: [] });
    const [capacitykurve, setDataCapacitykurve] = useState({ name: 'Kapazität', disabled: false, data: [] });
    const [dataHeutekurve, setDataHeutekurve] = useState([]);
    const [dataLabels, setDataLabels] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const [lastVal, setLastVal] = useState();
    const clickLegenHandler = (item, i) => {
        if (item.title == 'Kapazität') {
            const newValue = !capacitykurve.disabled;
            setDataCapacitykurve({ ...capacitykurve, disabled: newValue });
        }
        if (item.title == 'Zugang') {
            const newValue = !zugangskurve.disabled;
            setDataZugangskurve({ ...zugangskurve, disabled: newValue });
        }
        if (item.title == 'Ist-Abgang') {
            const newValue = !abgangskurve.disabled;
            setDataAbgangskurve({ ...abgangskurve, disabled: newValue });
        }
        if (item.title == 'Plan-Abgang') {
            const newValue = !abgangskurve2.disabled;
            setDataAbgangskurve2({ ...abgangskurve2, disabled: newValue });
        }
        if (item.title == 'Ist-Bestand') {
            const newValue = !bestandkurve.disabled;
            setDataBestandkurve({ ...bestandkurve, disabled: newValue });
        }
        if (item.title == 'Rückstand') {
            const newValue = !ruckstandkurve.disabled;
            setDataRuckstandkurve({ ...ruckstandkurve, disabled: newValue });
        }
        if (item.title == 'Plan-Bestand') {
            const newValue = !planBestandkurve.disabled;
            setDataPlanBestandkurve({ ...planBestandkurve, disabled: newValue });
        }
        if (item.title == 'Bestand Prog') {
            const newValue = !bestandProgkurve.disabled;
            setDataBestandProgkurve({ ...bestandProgkurve, disabled: newValue });
        }
    };
    const displayZugangStart = d => {
        const zsv = d.length == 1 ? d[0].zugang_cum : 0;
        setDataZugangsStart(zsv);
    };
    const setDataDUDI = d => {
        const zugang = [];
        const abgang = [];
        const abgang2 = [];
        const ruckstand = [];
        const bestand = [];
        const planBestand = [];
        // from today to the future
        const bestandProg = [];
        const heute = [];
        const dl = [];
        const dataLength = d.length;
        const p = dataLength / 24;
        //console.log(d);
        let todayBestand = 0;
        let todayPlanBestand = 0;
        // variables for indicators
        let startIstAbgang = 0;
        let endIstAbgang = 0;
        for (let i = 0; i < dataLength; i++) {
            if (i == 0) {
                console.log('zugangsStart', zugangsStart);
            }
            if (i == 0 && d[i].zugang_cum == null) {
                d[i].zugang_cum = 0;
            }
            if (i == 0 && d[i].abgang_cum == null) {
                d[i].abgang_cum = 0;
            }
            if (i == 0 && d[i].abgang2_cum == null) {
                d[i].abgang2_cum = 0;
            }
            if (i > 0 && d[i].zugang_cum == null) {
                d[i].zugang_cum = d[i - 1].zugang_cum;
            }
            if (i > 0 && d[i].abgang_cum == null) {
                d[i].abgang_cum = d[i - 1].abgang_cum;
            }
            if (i > 0 && d[i].abgang2_cum == null) {
                d[i].abgang2_cum = d[i - 1].abgang2_cum;
            }

            heute.push({ x: today, y: d[i].abgang2_cum });
            zugang.push({ x: d[i]['Plan-Start'], y: zugangsStart + d[i].zugang_cum });

            if (Date.parse(d[i]['Plan-Start']) < Date.parse(today)) {
                abgang.push({ x: d[i]['Plan-Start'], y: d[i].abgang_cum });
            }
            abgang2.push({ x: d[i]['Plan-Start'], y: d[i].abgang2_cum });
            if (Date.parse(d[i]['Plan-Start']) <= Date.parse(today)) {
                bestand.push({ x: d[i]['Plan-Start'], y: zugangsStart + d[i].zugang_cum - d[i].abgang_cum });
                if (Date.parse(d[i]['Plan-Start']) == Date.parse(today)) {
                    todayBestand = zugangsStart + d[i].zugang_cum - d[i].abgang_cum;
                    todayPlanBestand = zugangsStart + d[i].zugang_cum - d[i].abgang2_cum;
                    bestandProg.push({ x: d[i]['Plan-Start'], y: todayBestand });
                    setBestandHeute(formatDecimal(todayBestand)); // function to update for hint
                }
            }
            if (Date.parse(d[i]['Plan-Start']) > Date.parse(today)) {
                bestandProg.push({
                    x: d[i]['Plan-Start'],
                    y: todayBestand + (zugangsStart + d[i].zugang_cum - d[i].abgang2_cum - todayPlanBestand),
                });
            }
            ruckstand.push({ x: d[i]['Plan-Start'], y: d[i].abgang2_cum - d[i].abgang_cum });
            planBestand.push({ x: d[i]['Plan-Start'], y: zugangsStart + d[i].zugang_cum - d[i].abgang2_cum });

            if (i % p >= 0 && i % p < 1) {
                dl.push(d[i]['Plan-Start']);
            }
            // logic for indicators
            if (i == 0) {
                startIstAbgang = d[i].abgang_cum;
            }
            if (i == dataLength - 1 && Date.parse(d[i]['Plan-Start']) <= Date.parse(today)) {
                endIstAbgang = d[i].abgang_cum;
            }
            if (i == dataLength - 1 && Date.parse(d[i]['Plan-Start']) > Date.parse(today)) {
                endIstAbgang = d[i].abgang2_cum;
            }
        }
        const mittLeistung = (endIstAbgang - startIstAbgang) / getOperativeDays(d);
        setMLeistung(formatDecimal(mittLeistung));
        mittLeistung > 0 ? setMReichweite(formatDecimal(todayBestand / mittLeistung)) : setMReichweite(0);
        setHintCardsLoading(false);
        setDataLabels(dl);
        setDataHeutekurve(heute);
        setDataZugangskurve({ ...zugangskurve, data: zugang });
        setDataAbgangskurve({ ...abgangskurve, data: abgang });
        setDataAbgangskurve2({ ...abgangskurve2, data: abgang2 });
        setDataBestandkurve({ ...bestandkurve, data: bestand });
        setDataRuckstandkurve({ ...ruckstandkurve, data: ruckstand });
        setDataPlanBestandkurve({ ...planBestandkurve, data: planBestand });
        setDataBestandProgkurve({ ...bestandProgkurve, data: bestandProg });
        loadData(setLoadingData, setDataCapacity, queryCapacitykurve, filters, sessionID, userID, 0);
    };

    const setDataCapacity = d => {
        const capacity = [];
        const dataLength = d.length;
        for (let i = 0; i < dataLength; i++) {
            capacity.push({ x: d[i]['isdate'], y: d[i].total_accum_capacity_day });
        }
        setDataCapacitykurve({ ...capacitykurve, data: capacity });
    };

    useEffect(() => {
        // if (!useFilters) return;
        loadData(() => {}, displayZugangStart, queryDUDIZugangStart, filters, sessionID, userID, 0);
    }, [filters, sessionID, userID]);

    useEffect(() => {
        // if (!useFilters) return;
        setLoadingData(true);
        setHintCardsLoading(true);
        loadData(setLoadingData, setDataDUDI, queryDUDIkurve, filters, sessionID, userID, 0);
    }, [zugangsStart]);

    return useMemo(() => {
        return (
            <LinesWidgetFrame loading={loadingData}>
                <KNZ>
                    <GWTitle>
                        <Tooltip text={DUDI_TEXT}></Tooltip>
                        <span>{title}</span>
                        {modalOpen ? <span></span> : <Modal graphWidget={<Comp />} config={props} />}
                    </GWTitle>
                    <div className="kflist">
                        <div>
                            <span className="val">{formatDecimal(lastVal)}</span>
                        </div>
                    </div>
                </KNZ>

                <FlexibleXYPlot
                    className="stackedLine"
                    margin={{ bottom: 80, left: 200, right: 40 }}
                    xType="ordinal"
                    height={height || 395}
                    // width={width}
                >
                    <HorizontalGridLines />
                    <YAxis tickFormat={v => formatDecimal(v, 0)} />
                    <XAxis tickFormat={v => formatDate(v)} tickLabelAngle={-60} tickValues={dataLabels} />
                    <LineSeries
                        curve={'curveStepAfter'}
                        data={zugangskurve.data}
                        color={ChartLabelColors[0]}
                        opacity={zugangskurve.disabled ? 0 : 1}
                    />
                    <LineSeries curve={'curveStepAfter'} data={dataHeutekurve} color={ChartLabelColors[1]} />
                    <LineSeries
                        curve={'curveStepAfter'}
                        data={abgangskurve2.data}
                        color={ChartLabelColors[4]}
                        opacity={abgangskurve2.disabled ? 0 : 1}
                        style={{ strokeDasharray: '6 3' }}
                    />
                    <LineSeries
                        curve={'curveStepAfter'}
                        data={abgangskurve.data}
                        color={ChartLabelColors[4]}
                        opacity={abgangskurve.disabled ? 0 : 1}
                    />
                    <LineSeries
                        curve={'curveStepAfter'}
                        data={planBestandkurve.data}
                        color={ChartLabelColors[3]}
                        opacity={planBestandkurve.disabled ? 0 : 1}
                        style={{ strokeDasharray: '6 3' }}
                    />
                    <LineSeries
                        curve={'curveStepAfter'}
                        data={bestandkurve.data}
                        color={ChartLabelColors[3]}
                        opacity={bestandkurve.disabled ? 0 : 1}
                    />
                    <LineSeries
                        curve={'curveStepAfter'}
                        data={bestandProgkurve.data}
                        color={ChartLabelColors[2]}
                        opacity={bestandProgkurve.disabled ? 0 : 1}
                    />
                    <LineSeries
                        curve={'curveStepAfter'}
                        data={ruckstandkurve.data}
                        color={ChartLabelColors[5]}
                        opacity={ruckstandkurve.disabled ? 0 : 1}
                    />
                    <LineSeries
                        curve={'curveStepAfter'}
                        data={capacitykurve.data}
                        color={ChartLabelColors[6]}
                        opacity={capacitykurve.disabled ? 0 : 1}
                    />
                    <DiscreteColorLegend
                        style={{ position: 'absolute', top: '10px', left: '10px' }}
                        orientation="vertical"
                        onItemClick={clickLegenHandler}
                        items={[
                            {
                                title: 'Kapazität',
                                color: ChartLabelColors[6],
                            },
                            {
                                title: 'Zugang',
                                color: ChartLabelColors[0],
                            },
                            {
                                title: 'Plan-Abgang',
                                color: ChartLabelColors[4],
                                strokeStyle: 'dashed',
                            },
                            {
                                title: 'Ist-Abgang',
                                color: ChartLabelColors[4],
                            },
                            {
                                title: 'Ist-Bestand',
                                color: ChartLabelColors[3],
                            },
                            {
                                title: 'Plan-Bestand',
                                color: ChartLabelColors[3],
                                strokeStyle: 'dashed',
                            },
                            {
                                title: 'Bestand Prog',
                                color: ChartLabelColors[2],
                            },
                            {
                                title: 'Rückstand',
                                color: ChartLabelColors[5],
                            },
                        ]}
                    />
                </FlexibleXYPlot>
            </LinesWidgetFrame>
        );
    }, [
        zugangskurve,
        abgangskurve,
        abgangskurve2,
        bestandkurve,
        ruckstandkurve,
        planBestandkurve,
        bestandProgkurve,
        capacitykurve,
        loadingData,
        width,
    ]);
};

export default Comp;

import React from 'react';
import { Label, Tab } from 'semantic-ui-react';
import IssueForm from './IssueForm/IssueForm';
import IssueComments from './IssueForm/IssueComments';
import ImmediateActions from './IssueForm/ImmediateActions';
import CauseAnalysisList from './IssueForm/CauseAnalysisList';
import IssueDetailsForm from './IssueForm/IssueDetailsForm';
import IssueBrainStorming from './IssueForm/IssueBrainStorming';
import IssueCauses from './IssueForm/IssueCauses';
import IssueLessonsLearned from './IssueForm/IssueLessonsLearned';
import IssueCorrectiveActions from './IssueForm/IssueCorrectiveActions';
import { getData } from '../UtilComponents/requestsUtils';
import IssueDocuments from './IssueForm/IssueDocuments';
import ChangeDetailsForm from './ChangeForm/ChangeDetailsForm';
import ActionsForm from './ChangeForm/ActionsForm';
import getPanelsForChangeForm from './ChangeForm/ChangeFormPanes';
import ImprovementsProposalDetailsForm from './IssueForm/ImprovementsProposalDetailsForm';
import ReworkDetailsForm from './IssueForm/ReworkDetailsForm';
import LessonsLearnedDetailsForm from './IssueForm/LessonsLearnedDetailsForm';
import ArticleRequest from './ArticleRequest/ArticleRequest';
import ArticleCreationForm from './ArticleRequest/ArticleCreationForm';

const panes = (
    props,
    numberOfTabsToShow,
    setNumberOfTabsToShow,
    totalComments,
    setTotalComments,
    totalFiles,
    setTotalFiles,
) => {
    // ISSUE TYPE A3 has ID = 1 in DB
    // On ISSUE creation (No issue_id) just show the Basic notification Form
    if (!props.formData.id) {
        return [
            {
                menuItem: 'Meldung Information',
                render: () => (
                    <Tab.Pane>
                        <IssueForm issue_id={props.formData.id} issue_status={props.formData.issue_status} {...props} />
                    </Tab.Pane>
                ),
            },
        ];
    }

    // Just in case issue_type_id == 2 @Fehler
    if (props.formData.issue_type_id == 2) {
        return [
            {
                menuItem: `ID ${props.formData.serial_id} - Meldung Info`,
                render: () => (
                    <Tab.Pane>
                        <IssueForm issue_id={props.formData.id} issue_status={props.formData.issue_status} {...props} />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: 'Details',
                render: () => (
                    <Tab.Pane>
                        <IssueDetailsForm
                            issue_id={props.formData.id}
                            issue_status={props.formData.issue_status}
                            {...props}
                        />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: totalComments + ' - Kommentare',
                render: () => (
                    <Tab.Pane>
                        <IssueComments
                            issue_id={props.formData.id}
                            issue_status={props.formData.issue_status}
                            setTotalComments={setTotalComments}
                        />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: totalFiles + ' - Anlagen',
                render: () => (
                    <Tab.Pane>
                        <IssueDocuments
                            issue_id={props.formData.id}
                            ppsordernumber={props.formData.ppsordernumber}
                            issue_type_id={props.formData.issue_type_id}
                            issue_status={props.formData.issue_status}
                            setTotalFiles={setTotalFiles}
                        />
                    </Tab.Pane>
                ),
            },
        ];
    }

    // case issue_type_id == 3 @Änderung OR issue_type_id == 10 @Quickchange Extern OR issue_type_id == 11 @Sammeländerung OR issue_type_id == 12 @Bauabweichung
    // if (props.formData.issue_type_id == 3 || props.formData.issue_type_id == 10 || props.formData.issue_type_id == 11 || props.formData.issue_type_id == 12) {
    if ([3, 10, 11, 12].includes(props.formData.issue_type_id)) {
        return getPanelsForChangeForm(props, totalComments, setTotalComments, totalFiles, setTotalFiles);
    }

    // Just in case issue_type_id == 6  @Verbesserungsvorschlag
    if (props.formData.issue_type_id == 6) {
        return [
            {
                menuItem: `ID ${props.formData.serial_id} - Meldung Information`,
                render: () => (
                    <Tab.Pane>
                        <IssueForm issue_id={props.formData.id} issue_status={props.formData.issue_status} {...props} />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: 'Details',
                render: () => (
                    <Tab.Pane>
                        <ImprovementsProposalDetailsForm
                            issue_id={props.formData.id}
                            issue_status={props.formData.issue_status}
                            {...props}
                        />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: totalComments + ' - Kommentare',
                render: () => (
                    <Tab.Pane>
                        <IssueComments
                            issue_id={props.formData.id}
                            issue_status={props.formData.issue_status}
                            setTotalComments={setTotalComments}
                        />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: totalFiles + ' - Anlagen',
                render: () => (
                    <Tab.Pane>
                        <IssueDocuments
                            issue_id={props.formData.id}
                            ppsordernumber={props.formData.ppsordernumber}
                            issue_type_id={props.formData.issue_type_id}
                            issue_status={props.formData.issue_status}
                            setTotalFiles={setTotalFiles}
                        />
                    </Tab.Pane>
                ),
            },
        ];
    }

    // Just in case issue_type_id == 8  @Nacharbeit
    if (props.formData.issue_type_id == 8) {
        return [
            {
                menuItem: `ID ${props.formData.serial_id} - Meldung Information`,
                render: () => (
                    <Tab.Pane>
                        <IssueForm issue_id={props.formData.id} issue_status={props.formData.issue_status} {...props} />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: 'Details',
                render: () => (
                    <Tab.Pane>
                        <ReworkDetailsForm
                            issue_id={props.formData.id}
                            issue_status={props.formData.issue_status}
                            {...props}
                        />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: totalComments + ' - Kommentare',
                render: () => (
                    <Tab.Pane>
                        <IssueComments
                            issue_id={props.formData.id}
                            issue_status={props.formData.issue_status}
                            setTotalComments={setTotalComments}
                        />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: totalFiles + ' - Anlagen',
                render: () => (
                    <Tab.Pane>
                        <IssueDocuments
                            issue_id={props.formData.id}
                            ppsordernumber={props.formData.ppsordernumber}
                            issue_type_id={props.formData.issue_type_id}
                            issue_status={props.formData.issue_status}
                            setTotalFiles={setTotalFiles}
                        />
                    </Tab.Pane>
                ),
            },
        ];
    }

    // Just in case issue_type_id == 9  @Lessons Learned
    if (props.formData.issue_type_id == 9) {
        return [
            {
                menuItem: `ID ${props.formData.serial_id} - Meldung Information`,
                render: () => (
                    <Tab.Pane>
                        <IssueForm issue_id={props.formData.id} issue_status={props.formData.issue_status} {...props} />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: 'Details',
                render: () => (
                    <Tab.Pane>
                        <LessonsLearnedDetailsForm
                            issue_id={props.formData.id}
                            issue_status={props.formData.issue_status}
                            {...props}
                        />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: totalComments + ' - Kommentare',
                render: () => (
                    <Tab.Pane>
                        <IssueComments
                            issue_id={props.formData.id}
                            issue_status={props.formData.issue_status}
                            setTotalComments={setTotalComments}
                        />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: totalFiles + ' - Anlagen',
                render: () => (
                    <Tab.Pane>
                        <IssueDocuments
                            issue_id={props.formData.id}
                            ppsordernumber={props.formData.ppsordernumber}
                            issue_type_id={props.formData.issue_type_id}
                            issue_status={props.formData.issue_status}
                            setTotalFiles={setTotalFiles}
                        />
                    </Tab.Pane>
                ),
            },
        ];
    }

    // @Artikelantrag
    if (props.formData.issue_type_id == 13) {
        const tabs_to_show = [
            {
                menuItem: `ID ${props.formData.serial_id} - Meldung Info`,
                render: () => (
                    <Tab.Pane>
                        <IssueForm issue_id={props.formData.id} issue_status={props.formData.issue_status} {...props} />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: 'Antrag',
                render: () => (
                    <Tab.Pane>
                        <ArticleRequest
                            issue_id={props.formData.id}
                            issue_status={props.formData.issue_status}
                            serial_id={props.formData.serial_id}
                            issue_title={props.formData.issue_title}
                        />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: 'Artikelanlage',
                render: () => (
                    <Tab.Pane>
                        <ArticleCreationForm
                            issue_id={props.formData.id}
                            issue_status={props.formData.issue_status}
                            serial_id={props.formData.serial_id}
                            issue_title={props.formData.issue_title}
                            creator_email={props.formData.creator_email}
                        />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: totalComments + ' - Kommentare',
                render: () => (
                    <Tab.Pane>
                        <IssueComments
                            issue_id={props.formData.id}
                            issue_status={props.formData.issue_status}
                            setTotalComments={setTotalComments}
                        />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: totalFiles + ' - Anlagen',
                render: () => (
                    <Tab.Pane>
                        <IssueDocuments
                            issue_id={props.formData.id}
                            ppsordernumber={props.formData.ppsordernumber}
                            issue_type_id={props.formData.issue_type_id}
                            issue_status={props.formData.issue_status}
                            setTotalFiles={setTotalFiles}
                        />
                    </Tab.Pane>
                ),
            },
        ];
        return tabs_to_show;
    }

    // All other types of issues should allow coments
    if (props.formData.issue_type_id && props.formData.issue_type_id !== 1) {
        return [
            {
                menuItem: `ID ${props.formData.serial_id} - Meldung Information`,
                render: () => (
                    <Tab.Pane>
                        <IssueForm issue_id={props.formData.id} issue_status={props.formData.issue_status} {...props} />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: totalComments + ' - Kommentare',
                render: () => (
                    <Tab.Pane>
                        <IssueComments
                            issue_id={props.formData.id}
                            issue_status={props.formData.issue_status}
                            setTotalComments={setTotalComments}
                        />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: totalFiles + ' - Anlagen',
                render: () => (
                    <Tab.Pane>
                        <IssueDocuments
                            issue_id={props.formData.id}
                            ppsordernumber={props.formData.ppsordernumber}
                            issue_type_id={props.formData.issue_type_id}
                            issue_status={props.formData.issue_status}
                            setTotalFiles={setTotalFiles}
                        />
                    </Tab.Pane>
                ),
            },
        ];
    }

    if (props.formData.issue_type_id == 1) {
        const tabs_to_show = [
            {
                menuItem: `ID ${props.formData.serial_id} - Meldung Info`,
                render: () => (
                    <Tab.Pane>
                        <IssueForm issue_id={props.formData.id} issue_status={props.formData.issue_status} {...props} />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: 'Details',
                render: () => (
                    <Tab.Pane>
                        <IssueDetailsForm
                            issue_id={props.formData.id}
                            issue_status={props.formData.issue_status}
                            numberOfTabsToShow={numberOfTabsToShow}
                            setNumberOfTabsToShow={setNumberOfTabsToShow}
                            {...props}
                        />
                    </Tab.Pane>
                ),
            },
        ];

        const available_tabs = [
            {
                menuItem: 'Sofortmaßnahmen',
                render: () => (
                    <Tab.Pane>
                        <ImmediateActions
                            issue_id={props.formData.id}
                            issue_status={props.formData.issue_status}
                            numberOfTabsToShow={numberOfTabsToShow}
                            setNumberOfTabsToShow={setNumberOfTabsToShow}
                        />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: 'Wirkungsanalyse',
                render: () => (
                    <Tab.Pane>
                        <IssueBrainStorming
                            issue_id={props.formData.id}
                            issue_status={props.formData.issue_status}
                            numberOfTabsToShow={numberOfTabsToShow}
                            setNumberOfTabsToShow={setNumberOfTabsToShow}
                        />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: 'Ursachenanalyse',
                render: () => (
                    <Tab.Pane>
                        <CauseAnalysisList
                            issue_id={props.formData.id}
                            issue_status={props.formData.issue_status}
                            numberOfTabsToShow={numberOfTabsToShow}
                            setNumberOfTabsToShow={setNumberOfTabsToShow}
                        />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: 'Ursachen',
                render: () => (
                    <Tab.Pane>
                        <IssueCauses
                            issue_id={props.formData.id}
                            issue_status={props.formData.issue_status}
                            numberOfTabsToShow={numberOfTabsToShow}
                            setNumberOfTabsToShow={setNumberOfTabsToShow}
                        />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: 'Korrekturmaßnahmen',
                render: () => (
                    <Tab.Pane>
                        <IssueCorrectiveActions
                            issue_id={props.formData.id}
                            issue_status={props.formData.issue_status}
                            numberOfTabsToShow={numberOfTabsToShow}
                            setNumberOfTabsToShow={setNumberOfTabsToShow}
                        />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: 'Nachverfolgung',
                render: () => (
                    <Tab.Pane>
                        <IssueLessonsLearned issue_id={props.formData.id} issue_status={props.formData.issue_status} />
                    </Tab.Pane>
                ),
            },
        ];

        if (numberOfTabsToShow) {
            for (let i = 0; i < numberOfTabsToShow; i++) {
                tabs_to_show.push(available_tabs[i]);
            }
        }

        tabs_to_show.push({
            menuItem: totalComments + ' - Kommentare',
            render: () => (
                <Tab.Pane>
                    <IssueComments
                        issue_id={props.formData.id}
                        issue_status={props.formData.issue_status}
                        setTotalComments={setTotalComments}
                    />
                </Tab.Pane>
            ),
        });

        tabs_to_show.push({
            menuItem: totalFiles + ' - Anlagen',
            render: () => (
                <Tab.Pane>
                    <IssueDocuments
                        issue_id={props.formData.id}
                        ppsordernumber={props.formData.ppsordernumber}
                        issue_type_id={props.formData.issue_type_id}
                        issue_status={props.formData.issue_status}
                        setTotalFiles={setTotalFiles}
                    />
                </Tab.Pane>
            ),
        });

        return tabs_to_show;
    }
};

const NotificationFormTabs = props => {
    const [numberOfTabsToShow, setNumberOfTabsToShow] = React.useState(0);
    const [totalComments, setTotalComments] = React.useState(0);
    const [totalFiles, setTotalFiles] = React.useState(0);

    React.useState(() => {
        const id = props.formData.id;
        if (id) {
            const getIssueNumberOfTabs = async () => {
                try {
                    const response = await getData(`crud/issues/get_issue_tabs_with_data?id=${id}`);
                    if (response[0]) {
                        const newNumberOfTabs = response[0].issue_tabs_with_data;
                        setNumberOfTabsToShow(newNumberOfTabs);
                    }
                } catch (error) {
                    console.log(error);
                }
            };
            const getTotalCommentsAndFiles = async () => {
                try {
                    const response = await getData(`crud/issues/get_total_comments_files_by_issue?id=${id}`);
                    setTotalComments(response[0].total_comments);
                    setTotalFiles(response[0].total_files);
                } catch (error) {
                    console.log(error);
                }
            };

            getIssueNumberOfTabs();
            getTotalCommentsAndFiles();
        }
    }, []);

    return (
        <Tab
            panes={panes(
                props,
                numberOfTabsToShow,
                setNumberOfTabsToShow,
                totalComments,
                setTotalComments,
                totalFiles,
                setTotalFiles,
            )}
        />
    );
};

export default NotificationFormTabs;

const trafficLight = () =>
    value > 0 ? (
        <Label empty circular color="green" />
    ) : value < 0 ? (
        <Label empty circular color="red" />
    ) : (
        <Label empty circular color="yellow" />
    );

import React from 'react';
import debounce from 'lodash.debounce';
import { Form, Popup } from 'semantic-ui-react';
import { deleteData, getData, insertSimpleData } from './requestsUtils';

const MultiSelectWithSearchAsync = props => {
    const {
        name,
        placeholder,
        label,
        value,
        handleChange,
        query,
        primaryKey,
        primaryKeyValue,
        foreignKey,
        deletionQuery,
        additionQuery,
        required = true,
        fluid,
        width,
        tooltip = '',
    } = props;

    const [defaultOption, setDefaultOption] = React.useState([]);
    const [options, setOptions] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        if (value.length > 0) {
            loadDefaultData();
        }

        return () => {
            debouncedChangeHandler.cancel();
        };
    }, [value]);

    const loadDefaultData = async () => {
        setLoading(true);
        try {
            const queryParams = value.map(item => 'value=' + item).join('&');
            const response = await getData(query + '?' + queryParams);
            setDefaultOption(response);
            setLoading(false);
        } catch (error) {
            console.log('queryID', query);
            setLoading(false);
        }
    };

    const loadData = async (event, data) => {
        setLoading(true);
        const searchValue = data.searchQuery;
        try {
            const response = await getData(
                query + '?search_for_text=' + searchValue + '&columns_to_search=text&page_number=1&page_size=6',
            );
            setOptions(response);
            setLoading(false);
        } catch (error) {
            console.log('queryID', query);
            setLoading(false);
        }
    };

    const debouncedChangeHandler = React.useMemo(() => debounce(loadData, 500), []);

    const onChange = async (e, data) => {
        const initialState = value;
        const newState = data.value;
        // deletion case
        if (primaryKeyValue && newState.length < initialState.length) {
            let deletedId = '';
            for (let i = 0; i < initialState.length; i++) {
                if (!newState.includes(initialState[i])) {
                    deletedId = initialState[i];
                    break;
                }
            }
            try {
                await deleteData(deletionQuery, { [primaryKey]: primaryKeyValue, [foreignKey]: deletedId });
                handleChange(
                    {},
                    {
                        name: name,
                        value: data.value,
                    },
                );
            } catch (error) {}
        }
        // addition case
        if (primaryKeyValue && newState.length > initialState.length) {
            const addedId = newState[newState.length - 1];
            try {
                await insertSimpleData(additionQuery, { [primaryKey]: primaryKeyValue, [foreignKey]: addedId });
                handleChange(
                    {},
                    {
                        name: name,
                        value: data.value,
                    },
                );
            } catch (error) {}
        } else {
            handleChange(
                {},
                {
                    name: name,
                    value: data.value,
                },
            );
        }
    };

    return (
        <Form.Select
            loading={loading}
            selectOnBlur={false}
            label={<Popup content={tooltip} trigger={<label>{label}</label>} disabled={tooltip === ''} />}
            options={[...defaultOption, ...options]}
            search
            multiple
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            required={required}
            fluid={fluid}
            width={width}
            onSearchChange={debouncedChangeHandler}
        />
    );
};

export default MultiSelectWithSearchAsync;

import React from 'react';
import { Form } from 'semantic-ui-react';
import SFWMSFilter from '../UtilComponents/SearchableFilterWithMultiSelection';
import { queryResourcesStatus } from './Queries';
import { queryGetResourcesCategories } from './RCategoriesModal';

const RPFiltersForm = ({ rpFilters, handleFiltersChange }) => {
    return (
        <Form size="tiny">
            <SFWMSFilter
                label="Kategorie"
                query={queryGetResourcesCategories}
                name="resource_category_id"
                value={rpFilters.resource_category_id}
                onChange={handleFiltersChange}
            />
            <SFWMSFilter
                label="Ressource-Status"
                query={queryResourcesStatus + '?1=1'} // to play nice with the WHERE SQL clause in the query Template
                name="resource_status_id"
                value={rpFilters.resource_status_id}
                onChange={handleFiltersChange}
            />
        </Form>
    );
};

export default RPFiltersForm;

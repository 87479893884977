import React from 'react';
import { Form } from 'semantic-ui-react';
import { getData, biRequestWithoutCache } from './requestsUtils';
// this is a "standard" form field to be used in forms,
// allows users to pick just one value and also search for values
const AsyncSearchableInput = ({
    label,
    name,
    value,
    placeholder,
    query,
    required = true,
    onChange,
    fluid = true,
    width,
}) => {
    const [options, setOptions] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    React.useEffect(() => {
        if (options.length > 0) return;
        const loadData = async () => {
            setLoading(true);
            try {
                //const response = await getData(query);
                const response = await biRequestWithoutCache(query, [
                    { attribute: name, value: ['keine'], operator: 'LIKE' },
                ]);
                setOptions(response);
                setLoading(false);
            } catch (error) {
                console.log('queryID', query);
            }
        };
        loadData();
    }, [value]);

    return (
        <Form.Select
            onChange={onChange}
            loading={loading}
            label={label}
            name={name}
            value={value}
            placeholder={placeholder}
            options={options}
            selection
            search={() => options}
            fluid={fluid}
            required={required}
            width={width}
        />
    );
};

export default AsyncSearchableInput;

import React, { useEffect, useState, useMemo } from 'react';
import { get } from 'lodash';

import { COLORS } from '../../global-styles';
import { formatDecimal, loadData, setDataWithClasses, getRangeValues } from '../../Utils';
import { queryAVG_DLZ, queryAVG_MST } from '../Queries';
import { XYPlot, XAxis, YAxis, HorizontalGridLines, VerticalGridLines, VerticalBarSeries } from 'react-vis';
import Modal from '../../Utils/Modal';
import Tooltip from '../../Utils/Tooltip';
import { LinesWidgetFrame, KNZ, GWTitle } from '../../Utils/widgetsStyles';
const BarSeries = VerticalBarSeries;
const Comp = props => {
    const { filters, setFilter, sessionID, title, userID, width, height, modalOpen } = props;
    const [data, setData] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const [kf, SetKf] = useState();
    const [, setLoadingKf] = useState(false);
    // useEffect(() => {
    //     loadData(setLoadingKf, SetKf, queryAVG_MST, filters, sessionID, userID, 0);
    //     loadData(
    //         setLoadingData,
    //         d => {
    //             setDataWithClasses(d, setData, 'kf6');
    //         },
    //         queryAVG_DLZ,
    //         filters,
    //         sessionID,
    //         userID,
    //         0,
    //     );
    // }, [filters, sessionID, userID]);
    return useMemo(() => {
        return (
            <LinesWidgetFrame loading={loadingData}>
                <KNZ>
                    <GWTitle>
                        <Tooltip text="This is the information about this graphic"></Tooltip>
                        <span>{title}</span>
                        {modalOpen ? <span></span> : <Modal graphWidget={<Comp />} config={props} />}
                    </GWTitle>

                    <div className="kflist">
                        <div>
                            <span className="label"></span>
                            <span className="val">-</span>
                            <span className="unit"></span>
                        </div>
                    </div>
                </KNZ>
                <XYPlot
                    className="stackedBar"
                    margin={{ bottom: 70, left: 60 }}
                    xType="ordinal"
                    width={width}
                    height={height || 200}
                    xDistance={100}
                    color={COLORS.BLUE}
                >
                    {/* <VerticalGridLines />
                    <HorizontalGridLines />
                    <YAxis tickFormat={v => formatDecimal(v, 0)} />
                    <XAxis tickLabelAngle={-45} tickFormat={v => formatDecimal(v, 0)} />
                    {data.length > 0 && (
                        <BarSeries
                            data={data}
                            onValueClick={datapoint => {
                                const { lower, upper } = getRangeValues(datapoint.x, datapoint.bucketSize);
                                setFilter({
                                    id: 'kf6',
                                    Attribute: 'IST-DLZ',
                                    Operator: 'between',
                                    Value: `${lower} and ${upper}`,
                                });
                            }}
                        />
                    )} */}
                </XYPlot>
            </LinesWidgetFrame>
        );
    }, [data, kf, loadingData, width]);
};

export default Comp;

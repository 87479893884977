import React from 'react';
import { Button, Modal, Header } from 'semantic-ui-react';

const ConfirmDeleteModal = ({
    open,
    setOpen,
    title,
    confirmationMessage,
    objectToDeleteInfo = 'objectToDeleteInfo',
    deleteCallback,
    idOfObjectToDelete,
}) => {
    return (
        <>
            <Modal size="mini" open={open} onClose={() => setOpen(false)}>
                <Modal.Header>{title}</Modal.Header>
                <Modal.Content>
                    <p>{confirmationMessage}</p>
                    <Modal.Description>
                        <Header>{objectToDeleteInfo}</Header>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setOpen(false)}>Abbrechen</Button>
                    <Button negative onClick={() => deleteCallback(idOfObjectToDelete)}>
                        Löschen
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};

export default ConfirmDeleteModal;

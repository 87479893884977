import React from 'react';
import { Form, Button, Grid, Popup, Checkbox } from 'semantic-ui-react';
import SInput from '../../UtilComponents/SearchableInput';
import SInputAsync from '../../UtilComponents/SearchableInputAsync';
import { getData, updateData, getCurrentUserId, insertSimpleData } from '../../UtilComponents/requestsUtils';
import { isDescriptionValid, isTitleValid } from '../../UtilComponents/validations';
import StandardDatePicker, { formatDateDEStringForSQL } from '../../UtilComponents/StandardDatePicker';
import { getIssuesTypes } from '../Queries';
import { ValidationErrorMessage } from '../../UtilComponents/Feedbackmessages';
import { issueFormInputsValidityInitialState, issueFormDataInitialState } from '../sfUtils';

const issue_types = {
    A3: 1,
    FEHLER: 2,
    AENDERUNG: 3,
    HINWEIS: 4,
    SONSTIGES: 5,
    VERBESSERUNGSVORSCHLAG: 6,
    AUFGABE: 7,
    NACHARBEIT: 8,
    LESSONSLEARNED: 9,
    QUICKCHANGEEXTERN: 10,
    SAMMELAENDERUNG: 11,
    BAUABWEICHUNG: 12,
    ARTIKELANTRAG: 13,
};

const issueTypeToDuplicate = {
    '1': [
        { key: 'issue_a3_to_a3', value: 'issue_a3_to_a3', text: 'A3' },
        { key: 'issue_a3_to_aenderung', value: 'issue_a3_to_aenderung', text: 'Änderung' },
        { key: 'issue_a3_to_aufgabe', value: 'issue_a3_to_aufgabe', text: 'Aufgabe' },
        { key: 'issue_a3_to_fehler', value: 'issue_a3_to_fehler', text: 'Fehler' },
        { key: 'issue_a3_to_hinweis', value: 'issue_a3_to_hinweis', text: 'Hinweis' },
        {
            key: 'issue_a3_to_lessons_learned',
            value: 'issue_a3_to_lessons_learned',
            text: 'Lessons Learned',
        },
    ],
    '2': [
        { key: 'issue_fehler_to_a3', value: 'issue_fehler_to_a3', text: 'A3' },
        {
            key: 'issue_fehler_to_aenderung',
            value: 'issue_fehler_to_aenderung',
            text: 'Änderung',
        },
        { key: 'issue_fehler_to_aufgabe', value: 'issue_fehler_to_aufgabe', text: 'Aufgabe' },
        { key: 'issue_fehler_to_fehler', value: 'issue_fehler_to_fehler', text: 'Fehler' },
        { key: 'issue_fehler_to_hinweis', value: 'issue_fehler_to_hinweis', text: 'Hinweis' },
        {
            key: 'issue_fehler_to_lessons_learned',
            value: 'issue_fehler_to_lessons_learned',
            text: 'Lessons Learned',
        },
        {
            key: 'issue_fehler_to_quickchange_extern',
            value: 'issue_fehler_to_quickchange_extern',
            text: 'Quickchange Extern',
        },
    ],
    '3': [
        { key: 'issue_aenderung_to_a3', value: 'issue_aenderung_to_a3', text: 'A3' },
        // {
        //     key: 'issue_aenderung_to_aenderung',
        //     value: 'issue_aenderung_to_aenderung',
        //     text: 'Änderung',
        // },
        { key: 'issue_aenderung_to_aufgabe', value: 'issue_aenderung_to_aufgabe', text: 'Aufgabe' },
        {
            key: 'issue_aenderung_to_quickchange_extern',
            value: 'issue_aenderung_to_quickchange_extern',
            text: 'Quickchange Extern',
        },
        // { key: 'issue_aenderung_to_fehler', value: 'issue_aenderung_to_fehler', text: 'Fehler' },
        // { key: 'issue_aenderung_to_hinweis', value: 'issue_aenderung_to_hinweis', text: 'Hinweis' },
        // { key: 'issue_aenderung_to_lessons_learned', value: 'issue_aenderung_to_lessons_learned', text: 'Lessons Learned' },
    ],
    '4': [
        // { key: 'issue_hinweis_to_a3', value: 'issue_hinweis_to_a3', text: 'A3' },
        // { key: 'issue_hinweis_to_aufgabe', value: 'issue_hinweis_to_aufgabe', text: 'Aufgabe' },
        // { key: 'issue_hinweis_to_fehler', value: 'issue_hinweis_to_fehler', text: 'Fehler' },
        { key: 'issue_hinweis_to_aenderung', value: 'issue_hinweis_to_aenderung', text: 'Änderung' },
        { key: 'issue_hinweis_to_hinweis', value: 'issue_hinweis_to_hinweis', text: 'Hinweis' },
        {
            key: 'issue_hinweis_to_quickchange_extern',
            value: 'issue_hinweis_to_quickchange_extern',
            text: 'Quickchange Extern',
        },
        // { key: 'issue_hinweis_to_lessons_learned', value: 'issue_hinweis_to_lessons_learned', text: 'Lessons Learned' },
    ],
    '5': [
        // { key: 'issue_sonstiges_to_a3', value: 'issue_sonstiges_to_a3', text: 'A3' },
        // { key: 'issue_sonstiges_to_aenderung', value: 'issue_sonstiges_to_aenderung', text: 'Änderung' },
        // { key: 'issue_sonstiges_to_aufgabe', value: 'issue_sonstiges_to_aufgabe', text: 'Aufgabe' },
        // { key: 'issue_sonstiges_to_fehler', value: 'issue_sonstiges_to_fehler', text: 'Fehler' },
        // { key: 'issue_sonstiges_to_hinweis', value: 'issue_sonstiges_to_hinweis', text: 'Hinweis' },
        // { key: 'issue_sonstiges_to_lessons_learned', value: 'issue_sonstiges_to_lessons_learned', text: 'Lessons Learned' },
        {
            key: 'issue_sonstiges_to_sonstiges',
            value: 'issue_sonstiges_to_sonstiges',
            text: 'Sonstiges',
        },
    ],
    '6': [
        // { key: 'issue_sonstiges_to_a3', value: 'issue_sonstiges_to_a3', text: 'A3' },
        // { key: 'issue_sonstiges_to_aenderung', value: 'issue_sonstiges_to_aenderung', text: 'Änderung' },
        // { key: 'issue_sonstiges_to_aufgabe', value: 'issue_sonstiges_to_aufgabe', text: 'Aufgabe' },
        // { key: 'issue_sonstiges_to_fehler', value: 'issue_sonstiges_to_fehler', text: 'Fehler' },
        // { key: 'issue_sonstiges_to_hinweis', value: 'issue_sonstiges_to_hinweis', text: 'Hinweis' },
        // { key: 'issue_sonstiges_to_lessons_learned', value: 'issue_sonstiges_to_lessons_learned', text: 'Lessons Learned' },
        {
            key: 'issue_verbesserungsvorschlag_to_verbesserungsvorschlag',
            value: 'issue_verbesserungsvorschlag_to_verbesserungsvorschlag',
            text: 'Verbesserungsvorschlag',
        },
    ],

    '7': [
        // { key: 'issue_sonstiges_to_a3', value: 'issue_sonstiges_to_a3', text: 'A3' },
        // { key: 'issue_sonstiges_to_aenderung', value: 'issue_sonstiges_to_aenderung', text: 'Änderung' },
        // { key: 'issue_sonstiges_to_aufgabe', value: 'issue_sonstiges_to_aufgabe', text: 'Aufgabe' },
        // { key: 'issue_sonstiges_to_fehler', value: 'issue_sonstiges_to_fehler', text: 'Fehler' },
        // { key: 'issue_sonstiges_to_hinweis', value: 'issue_sonstiges_to_hinweis', text: 'Hinweis' },
        // { key: 'issue_sonstiges_to_lessons_learned', value: 'issue_sonstiges_to_lessons_learned', text: 'Lessons Learned' },
        { key: 'issue_aufgabe_to_aufgabe', value: 'issue_aufgabe_to_aufgabe', text: 'Aufgabe' },
    ],
    '8': [
        // { key: 'issue_nacharbeit_to_a3', value: 'issue_nacharbeit_to_a3', text: 'A3' },
        // { key: 'issue_nacharbeit_to_aenderung', value: 'issue_nacharbeit_to_aenderung', text: 'Änderung' },
        // { key: 'issue_nacharbeit_to_aufgabe', value: 'issue_nacharbeit_to_aufgabe', text: 'Aufgabe' },
        // { key: 'issue_nacharbeit_to_fehler', value: 'issue_nacharbeit_to_fehler', text: 'Fehler' },
        // { key: 'issue_nacharbeit_to_hinweis', value: 'issue_nacharbeit_to_hinweis', text: 'Hinweis' },
        // { key: 'issue_nacharbeit_to_lessons_learned', value: 'issue_nacharbeit_to_lessons_learned', text: 'Lessons Learned' },
        {
            key: 'issue_nacharbeit_to_nacharbeit',
            value: 'issue_nacharbeit_to_nacharbeit',
            text: 'Nacharbeit',
        },
    ],
    '9': [
        // { key: 'issue_lessons_learned_to_a3', value: 'issue_lessons_learned_to_a3', text: 'A3' },
        // { key: 'issue_lessons_learned_to_aenderung', value: 'issue_lessons_learned_to_aenderung', text: 'Änderung' },
        // { key: 'issue_lessons_learned_to_aufgabe', value: 'issue_lessons_learned_to_aufgabe', text: 'Aufgabe' },
        // { key: 'issue_lessons_learned_to_fehler', value: 'issue_lessons_learned_to_fehler', text: 'Fehler' },
        // { key: 'issue_lessons_learned_to_hinweis', value: 'issue_lessons_learned_to_hinweis', text: 'Hinweis' },
        {
            key: 'issue_lessons_learned_to_lessons_learned',
            value: 'issue_lessons_learned_to_lessons_learned',
            text: 'Lessons Learned',
        },
    ],
    '10': [
        {
            key: 'issue_quickchange_extern_to_quickchange_extern',
            value: 'issue_quickchange_extern_to_quickchange_extern',
            text: 'Quickchange Extern',
        },
    ],
    '11': [
        {
            key: 'issue_sammelaenderung_to_aenderung',
            value: 'issue_sammelaenderung_to_aenderung',
            text: 'Änderung',
        },
    ],
};

const IssueForm = ({ issue_id, issue_status, closeModal, setUpdateColumns }) => {
    const [formData, setFormData] = React.useState(issueFormDataInitialState);
    const [loading, setLoading] = React.useState(false);
    const [formFieldsValidity, setFormFieldsValidity] = React.useState(issueFormInputsValidityInitialState);
    const [issueToDuplicate, setIssueToDuplicate] = React.useState('');

    // validation functions
    const validateTitle = () => {
        const { issue_title } = formData;
        const newObjState = Object.assign({}, formFieldsValidity, { issue_title: isTitleValid(issue_title) });
        setFormFieldsValidity(newObjState);
    };

    const validateDescription = () => {
        const { issue_description } = formData;
        const newObjState = Object.assign({}, formFieldsValidity, {
            issue_description: isDescriptionValid(issue_description),
        });
        setFormFieldsValidity(newObjState);
    };

    const handleChange = (event, data) => {
        let newFormData = { ...formData };
        if (data.name === 'issue_type_id') {
            if (
                ![
                    issue_types.A3,
                    issue_types.FEHLER,
                    issue_types.HINWEIS,
                    issue_types.SONSTIGES,
                    issue_types.AUFGABE,
                    issue_types.NACHARBEIT,
                    issue_types.LESSONSLEARNED,
                ].includes(data.value)
            ) {
                newFormData['ppsordernumber'] = '';
            }
        }
        const newObjState = Object.assign({}, newFormData, { [data.name]: data.value });
        setFormData(newObjState);
    };

    // handling form submition
    const submitForm = () => {
        if (issue_status === 3) {
            alert('Die Meldung darf nicht bearbeitet werden.');
        } else {
            setLoading(true);
            !issue_id ? createNotification() : updateNotification();
        }
    };

    const isFormValid = () => {
        if (issue_types.ARTIKELANTRAG === formData.issue_type_id) {
            return true;
        }
        if (!Object.values(formFieldsValidity).every(i => i === true)) return false;
        let { issue_description, ...rest } = formData;
        if (
            ![
                issue_types.A3,
                issue_types.FEHLER,
                issue_types.HINWEIS,
                issue_types.SONSTIGES,
                issue_types.AUFGABE,
                issue_types.NACHARBEIT,
                issue_types.LESSONSLEARNED,
            ].includes(formData.issue_type_id)
        ) {
            const { ppsordernumber, ...mehr } = rest;
            rest = { ...mehr };
        }
        const valuesWithoutDescription = Object.values(rest);
        if (!Object.values(valuesWithoutDescription).every(i => i !== '')) return false;
        return true;
    };

    const createNotification = async () => {
        if (issue_types.ARTIKELANTRAG === formData.issue_type_id) {
            formData.issue_title = 'Artikelantrag';
            formData.issue_deadline = new Date().toLocaleDateString('de', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            });
        }
        const { issue_deadline, users_ids, ...rest } = formData;
        const body = {
            ...rest,
            issue_deadline: formatDateDEStringForSQL(issue_deadline),
            issue_creator_id: getCurrentUserId(),
        };
        try {
            await insertSimpleData('crud/issues/post_issue', body);
            setFormData(issueFormDataInitialState);
            setLoading(false);
            setUpdateColumns('afterCreation' + Date.now().toString());
            closeModal();
        } catch (error) {
            console.log(error);
        }
    };

    const updateNotification = async () => {
        const { id, issue_deadline, users_ids, tabs_with_data, ...rest } = formData;
        const body = {
            ...rest,
            issue_deadline: formatDateDEStringForSQL(issue_deadline),
        };
        try {
            await updateData('crud/issues/update_issue', body, { id: id });
            setFormData(issueFormDataInitialState);
            setLoading(false);
            setUpdateColumns('afterUpdate' + Date.now().toString());
            closeModal();
        } catch (error) {
            console.log(error);
        }
    };

    const handleIssueTypeToDuplicate = (event, data) => {
        setIssueToDuplicate(data.value);
    };

    const duplicateIssue = async () => {
        try {
            setLoading(true);
            const response = await getData(
                `crud/issues/duplicate/${issueToDuplicate}?id=${issue_id}&serial_id=${formData.serial_id}&issue_type_id=${formData.issue_type_id}`,
                true,
            );

            const body = {
                issue_creator_id: getCurrentUserId(),
            };
            await updateData('crud/issues/update_issue', body, { id: response.id });

            if (issueToDuplicate === 'issue_sammelaenderung_to_aenderung') {
                const changesDetails = await getData(`crud/changes/get_change_details?issue_id=${response.id}`);
                await updateData(
                    'crud/changes/update_change_details',
                    {
                        change_responsible_id: getCurrentUserId(),
                    },
                    { change_details_id: changesDetails[0].change_details_id },
                );
            }

            setLoading(false);
            closeModal();
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    React.useEffect(() => {
        const getIssueBasicInfo = async () => {
            setLoading(true);
            try {
                const response = await getData(`crud/issues/get_issue_by_id?id=${issue_id}`);
                if (response[0]) {
                    setFormData(response[0]);
                }
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        };
        if (issue_id) getIssueBasicInfo();
    }, []);

    const renderFields = issue_type_id => {
        const fields = [
            {
                issue_type_ids: [
                    issue_types.A3,
                    issue_types.FEHLER,
                    issue_types.AENDERUNG,
                    issue_types.HINWEIS,
                    issue_types.SONSTIGES,
                    issue_types.VERBESSERUNGSVORSCHLAG,
                    issue_types.AUFGABE,
                    issue_types.NACHARBEIT,
                    issue_types.LESSONSLEARNED,
                    issue_types.QUICKCHANGEEXTERN,
                    issue_types.SAMMELAENDERUNG,
                    issue_types.BAUABWEICHUNG,
                    issue_types.BAUABWEICHUNG,
                    issue_types.ARTIKELANTRAG,
                ],
                field: (
                    <Form.Input
                        key="issue_title"
                        label={
                            <Popup
                                content="Bitte schreiben Sie ein Titel für die neue Meldung"
                                trigger={<label>Titel</label>}
                            />
                        }
                        placeholder="Bitte schreiben Sie ein Titel für die neue Meldung"
                        name="issue_title"
                        value={formData.issue_title}
                        onChange={handleChange}
                        onBlur={validateTitle}
                        width={12}
                        required
                    />
                ),
            },
            {
                issue_type_ids: [
                    issue_types.A3,
                    issue_types.FEHLER,
                    issue_types.HINWEIS,
                    issue_types.SONSTIGES,
                    issue_types.AUFGABE,
                    issue_types.NACHARBEIT,
                    issue_types.LESSONSLEARNED,
                    issue_types.BAUABWEICHUNG,
                ],
                field: (
                    <SInputAsync
                        key="ppsordernumber"
                        label="Produktionsauftrag"
                        placeholder="PA auswählen"
                        name="ppsordernumber"
                        value={formData.ppsordernumber}
                        onChange={handleChange}
                        query="crud/issues/get_pa_numbers"
                        defaultValue={formData.ppsordernumber}
                        width={4}
                        fluid={false}
                        required={true}
                    />
                ),
            },
            {
                issue_type_ids: [
                    issue_types.A3,
                    issue_types.FEHLER,
                    issue_types.AENDERUNG,
                    issue_types.HINWEIS,
                    issue_types.SONSTIGES,
                    issue_types.VERBESSERUNGSVORSCHLAG,
                    issue_types.AUFGABE,
                    issue_types.NACHARBEIT,
                    issue_types.LESSONSLEARNED,
                    issue_types.QUICKCHANGEEXTERN,
                    issue_types.SAMMELAENDERUNG,
                    issue_types.BAUABWEICHUNG,
                    issue_types.ARTIKELANTRAG,
                ],
                field: (
                    <StandardDatePicker
                        key="issue_deadline"
                        label="Termin"
                        name="issue_deadline"
                        value={formData.issue_deadline}
                        handleChange={handleChange}
                        width={4}
                        fluid={false}
                        required={true}
                        classNames="four wide field"
                    />
                ),
            },
            {
                issue_type_ids: [
                    issue_types.A3,
                    issue_types.FEHLER,
                    issue_types.HINWEIS,
                    issue_types.SONSTIGES,
                    issue_types.AUFGABE,
                ],
                field: (
                    <Form.TextArea
                        key="issue_description"
                        label="Beschreibung"
                        name="issue_description"
                        value={formData.issue_description}
                        onChange={handleChange}
                        width={16}
                        rows={16}
                    />
                ),
            },
        ];
        const result = fields
            .filter(
                field =>
                    (!issue_id &&
                        issue_type_id != issue_types.ARTIKELANTRAG &&
                        field.issue_type_ids.includes(issue_type_id)) ||
                    (issue_id && field.issue_type_ids.includes(issue_type_id)),
            )
            .map(filtered => filtered.field);
        // setFormFieldsValidity(issueFormInputsValidityState);
        return result;
    };

    return (
        <>
            <ValidationErrorMessages formFieldsValidity={formFieldsValidity} formData={formData} />
            <Form loading={loading}>
                <Grid padded>
                    <Grid.Column width={5}>
                        <SInput
                            label="Meldungstyp"
                            placeholder="Meldungstyp auswählen"
                            name="issue_type_id"
                            value={formData.issue_type_id}
                            onChange={handleChange}
                            query={getIssuesTypes}
                            width={12}
                            fluid={false}
                            disabled={!!issue_id}
                        />
                    </Grid.Column>
                    {issue_id && formData.issue_type_id != issue_types.ARTIKELANTRAG && (
                        <>
                            <Grid.Column width={5} style={{ display: 'flex', alignItems: 'center' }}>
                                <Form.Select
                                    label="Weiterverarbeiten als"
                                    placeholder="Meldungstyp auswählen"
                                    options={issueTypeToDuplicate[formData.issue_type_id]}
                                    value={issueToDuplicate}
                                    onChange={handleIssueTypeToDuplicate}
                                    width={12}
                                />
                            </Grid.Column>
                            <Grid.Column width={5} style={{ display: 'flex', alignItems: 'flex-end' }}>
                                <Button fluid primary onClick={duplicateIssue} disabled={issueToDuplicate === ''}>
                                    Weiterverarbeiten
                                </Button>
                            </Grid.Column>
                        </>
                    )}
                </Grid>
                <br />
                <Grid padded>{renderFields(formData.issue_type_id)}</Grid>
                <br />
            </Form>
            <br />

            <Button fluid primary onClick={submitForm} disabled={!isFormValid() || loading}>
                Speichern
            </Button>
        </>
    );
};

export default IssueForm;

const ValidationErrorMessages = ({ formFieldsValidity, formData }) => {
    let validationMsg = {};

    if (formData.issue_title !== '' && !formFieldsValidity.issue_title) {
        validationMsg = validationTexts.title;
    }

    // if (formData.issue_description !== '' && !formFieldsValidity.issue_description) {
    //     validationMsg = validationTexts.description;
    // }

    return Object.keys(validationMsg).length === 0 ? '' : <ValidationErrorMessage message={validationMsg} />;
};

const validationTexts = {
    title: {
        header: 'Titel ist nicht korrekt',
        description: 'Titel muss wenigstens auf 3 Buchstaben bestehen.',
    },
    description: {
        header: 'Beschreibung ist nicht korrekt',
        description: 'Beschreibung muss wenigstens auf 8 Buchstaben bestehen.',
    },
};

import React from 'react';
import { Button, Select, Input } from 'semantic-ui-react';

const opts = ['=', '!='];

const formatOperatorOptions = opts => {
    return opts.map(e => ({
        key: e,
        text: e,
        value: e,
    }));
};

const TextSearchForColumns = props => {
    const { setSelectedField, searchTableOptions, searchTableOptionsLogic, defaultValue = 'PA-Nummer' } = props;
    const listenForEnter = e => {
        if (e.keyCode === 13) {
            searchTableOptionsLogic();
        }
    };

    return (
        <Input
            id="textToSearchFor"
            type="text"
            placeholder="Suchen..."
            action
            size={props.size || 'mini'}
            onKeyUp={listenForEnter}
        >
            <input />
            <Select
                options={searchTableOptions}
                defaultValue={defaultValue}
                size="mini"
                onChange={(e, { value }) => setSelectedField(value)}
            />
            <Button id="search" type="submit" size="mini" onClick={searchTableOptionsLogic}>
                Suchen
            </Button>
        </Input>
    );
};

export default TextSearchForColumns;

import React from 'react';
import { Segment } from 'semantic-ui-react';
import styled from 'styled-components';
import { COLORS } from '../../global-styles';
import ProofProtocols from './ProofProtocols';
import ProtocolModal, { protocolFormInitialState } from './ProtocolModal';

const ProtocolsView = () => {
    const [protocolFormData, setProtocolFormData] = React.useState(protocolFormInitialState);
    const [protocolModalOpen, setProtocolModalOpen] = React.useState(false);
    const [updateProtocols, setUpdateProtocols] = React.useState('INITIAL');
    return (
        <TabContent>
            <MenuWrapper>
                <ProtocolModal
                    formData={protocolFormData}
                    setFormData={setProtocolFormData}
                    open={protocolModalOpen}
                    setOpen={setProtocolModalOpen}
                    setUpdateProtocols={setUpdateProtocols}
                />
            </MenuWrapper>
            <ContentWrapper>
                <ProofProtocols
                    setFormData={setProtocolFormData}
                    setOpen={setProtocolModalOpen}
                    updateProtocols={updateProtocols}
                />
            </ContentWrapper>
        </TabContent>
    );
};

const TabContent = styled.div`
    th {
        color: ${COLORS.BLUE}!important;
    }
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: calc(100vh - 160px);
`;

const MenuWrapper = styled.div`
    flex: 0 0 auto;
    position: relative;
    margin: 1rem 1rem 0 0;
    width: 230px;
    height: auto;
`;

const ContentWrapper = styled(Segment)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 0 1 auto;
    width: calc(100vw - 300px);
    height: 98%;
`;

export default ProtocolsView;

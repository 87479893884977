import React from 'react';
import { Image, Segment } from 'semantic-ui-react';
import { apiUrl } from '../../../config';
import { getData } from '../../UtilComponents/requestsUtils';
import fileIcon from '../../../images/file_generic_icon.svg';
import pdfIcon from '../../../images/pdf_file_icon.svg';
import excelIcon from '../../../images/excel_file_icon.svg';
import ImportFiles from './ImportFiles';
import styled from 'styled-components';

const IssueDocuments = ({ issue_id, ppsordernumber, issue_type_id, issue_status, setTotalFiles }) => {
    const [data, setData] = React.useState([]);
    const [updateFileList, setUpdateFileList] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const imageExtensions = ['png', 'jpg', 'jpeg', 'gif', 'svg', 'webp'];
    const excelExtensions = ['xlt', 'xls', 'xlsx', 'ods', 'ots'];

    React.useEffect(() => {
        const getIssueDocuments = async () => {
            try {
                const response = await getData(`crud/issues/get_issue_files?id=${issue_id}`);
                setTotalFiles(response.length);
                setData(response);
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        };
        getIssueDocuments();
    }, [updateFileList]);

    const openNewTabWithUrl = url => {
        window.open(url, '_blank').focus();
    };

    return (
        <Segment basic loading={loading}>
            <ImportFiles
                issue_id={issue_id}
                ppsordernumber={ppsordernumber}
                issue_type_id={issue_type_id}
                setLoading={setLoading}
                setUpdateFileList={setUpdateFileList}
                issue_status={issue_status}
            />
            <br />
            <br />

            <Image.Group size="small">
                <Thumbnails>
                    {data.map(obj => {
                        const url = apiUrl + '/files/' + obj.file_uri;
                        const fileExtension = obj.file_uri
                            .split('.')
                            .pop()
                            .toLowerCase();
                        let imageSource = fileIcon;
                        if (imageExtensions.includes(fileExtension)) {
                            imageSource = url;
                        } else if (fileExtension === 'pdf') {
                            imageSource = pdfIcon;
                        } else if (excelExtensions.includes(fileExtension)) {
                            imageSource = excelIcon;
                        }
                        return (
                            <div className="image-container" onClick={() => openNewTabWithUrl(url)}>
                                <Image
                                    className="thumbnails"
                                    async="on"
                                    key={obj.file_uri}
                                    src={imageSource}
                                    size="small"
                                />
                                <span className="footer-image" key={'filename_' + obj.file_uri}>
                                    {obj.file_uri.split('/').pop()}
                                </span>
                            </div>
                        );
                    })}
                </Thumbnails>
            </Image.Group>
        </Segment>
    );
};

const Thumbnails = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 30px;
    flex-wrap: wrap;
    .thumbnails {
        width: 150px !important;
        height: 150px !important;
        object-fit: cover !important;
    }
    .footer-image {
        width: 150px;
        font-size: 12px;
        text-align: center;
    }
    .image-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
    }
`;

export default IssueDocuments;

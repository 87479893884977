export const queryResourcesTable = 'resources/v_resources_table';
export const queryAVGStatus = 'resources/v_avg_status';
export const querySollIstAVG = 'resources/v_soll_ist_avg';
export const queryAVG_MST = 'resources/v_avg_m_st';
export const queryAVG_RUCKSTAND = 'resources/v_ruckstand';
export const queryAVG_Kapazitaetseinhaltung = 'resources/v_kapazitaetseinhaltung';
export const queryAVG_DLZ = 'resources/v_avg_dlz';
export const queryAVG_DLZ_MST = 'resources/v_avg_dlz_m_st';
export const queryAVGTermintreue = 'resources/v_avg_termintreue';
export const queryAVG_TA_ZUGANG = 'resources/v_avg_ta_zugang';
export const queryAVG_TA_ZUGANG_MST = 'resources/v_avg_ta_zugang_m_st';
export const queryAVG_TA_REL = 'resources/v_avg_ta_rel';
export const queryAVG_TA_REL_MST = 'resources/v_avg_ta_rel_m_st';
export const queryAVG_TA_ABGANG = 'resources/v_avg_ta_abgang';
export const queryAVG_TA_ABGANG_MST = 'resources/v_avg_ta_abgang_m_st';
export const queryResourcesGroupForFilter = 'resources/v_resources_groups_for_filter';
export const queryZugangskurve = 'resources/v_zugangskurve';
export const queryAbgangskurve = 'resources/v_abgangskurve';
export const queryDUDIkurve = 'resources/v_dudi';
export const queryDUDIZugangStart = 'resources/v_dudi_zugang_start';
export const queryCapacitykurve = 'resources/v_capacity_plan_new';
export const queryAVGStatusesForFilter = 'resources/v_avg_statuses_for_filter';
export const queryFilteredFromAVGsPAs = 'resources/v_avg_filtered_pas';

import React from 'react';
import { HistogrammWidgetFrame, Histogramm_KNZ, GWTitle } from '../../Utils/widgetsStyles';
import useDimensions from 'react-use-dimensions';
import Modal from '../../Utils/Modal';
import { biRequest } from '../UtilComponents/requestsUtils';
import { Grid, Popup, Icon, Table } from 'semantic-ui-react';

const Comp = props => {
    const { filters, addActiveFilter, title, height, modalOpen } = props;
    const [loadingData, setLoadingData] = React.useState(false);
    const [ref, { x, y, width }] = useDimensions();
    const [data, setData] = React.useState([]);

    React.useEffect(() => {
        const getWidgetData = async () => {
            try {
                setLoadingData(true);
                const response = await biRequest(`d8h/get_pas_statistics`, filters);
                setLoadingData(false);
                setData(response);
            } catch (error) {
                console.log(error);
            }
        };
        getWidgetData();
    }, [filters]);

    return React.useMemo(() => {
        return (
            <HistogrammWidgetFrame loading={loadingData}>
                <Histogramm_KNZ ref={ref}>
                    <GWTitle>
                        <Popup trigger={<Icon name="info circle" size="tiny" />} flowing hoverable>
                            <Grid padded></Grid>
                        </Popup>
                        <span></span>
                        {modalOpen ? <span></span> : <Modal graphWidget={<Comp />} config={props} />}
                    </GWTitle>
                </Histogramm_KNZ>
                <Table basic="very" textAlign="center" style={{ height: '205px' }}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell />
                            <Table.HeaderCell>Freigegebene PA</Table.HeaderCell>
                            <Table.HeaderCell>Davon gestartet PA</Table.HeaderCell>
                            <Table.HeaderCell>Abgeschlossene PA</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {data.map(obj => {
                            return (
                                <Table.Row>
                                    <Table.Cell>{obj.category}</Table.Cell>
                                    <Table.Cell>{obj.free_pas_total}</Table.Cell>
                                    <Table.Cell>{obj.currently_started}</Table.Cell>
                                    <Table.Cell>{obj.finished_pas_total}</Table.Cell>
                                </Table.Row>
                            );
                        })}
                        {data.length > 0 && (
                            <Table.Row>
                                <Table.Cell>Summe</Table.Cell>
                                <Table.Cell>
                                    {data.reduce(
                                        (accumulator, pilot) => parseInt(accumulator) + parseInt(pilot.free_pas_total),
                                        0,
                                    )}
                                </Table.Cell>
                                <Table.Cell>
                                    {data.reduce(
                                        (accumulator, pilot) =>
                                            parseInt(accumulator) + parseInt(pilot.currently_started),
                                        0,
                                    )}
                                </Table.Cell>

                                <Table.Cell>
                                    {data.reduce(
                                        (accumulator, pilot) =>
                                            parseInt(accumulator) + parseInt(pilot.finished_pas_total),
                                        0,
                                    )}
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>
            </HistogrammWidgetFrame>
        );
    }, [width, data]);
};

export default Comp;

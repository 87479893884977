import React from 'react';
import { Card, Statistic, Loader, Segment } from 'semantic-ui-react';
import styled from 'styled-components';
import { COLORS } from '../global-styles';
const Component = props => {
    const { title, value, unit, loading = true } = props;

    const header = {
        color: COLORS.GREY,
        fontSize: '1.6 rem',
        fontWeight: '400',
    };

    const hintStyle = {
        color: COLORS.TEXT_KUA,
        fontWeight: 'normal',
        textTransform: 'lowercase',
        padding: '0.5rem',
    };

    return (
        <CardFrame loading={loading}>
            <Card>
                {/* <Loader active={loading} /> */}
                <Card.Content>
                    <Card.Header textAlign="center" style={header}>
                        {title}
                    </Card.Header>
                </Card.Content>
                <Card.Content textAlign="center">
                    <Statistic size="small">
                        <br />
                        <Statistic.Value style={hintStyle}>
                            {value} {unit}
                        </Statistic.Value>
                        <br />
                    </Statistic>
                </Card.Content>
            </Card>
        </CardFrame>
    );
};

const CardFrame = styled(Segment)`
    border: none !important;
    padding: 0px !important;
    -webkit-box-shadow: none !important;
`;

export default Component;

import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.min.css';
import { Form } from 'semantic-ui-react';
import de from 'date-fns/locale/de';
registerLocale('de', de);

const StandardDatePicker = ({ label, name, value, handleChange, fluid = true, classNames, required = false }) => {
    const onChange = date => {
        handleChange(
            {},
            {
                name: name,
                value: formatDateToDE(date),
            },
        );
    };

    // to properly format the date when preloading the component with data to edit
    if (value && value.includes('-')) {
        const date = new Date(value);
        onChange(date);
    }

    return (
        <DatePicker
            wrapperClassName={classNames}
            locale="de"
            value={value}
            onChange={date => onChange(date)}
            customInput={<Form.Input label={label} fluid={fluid} required={required} />}
        />
    );
};
export default StandardDatePicker;

//expects date as String and Retruns dd.mm.yyyy
export const formatDateToDE = date => {
    if (!date) return '';
    const objDate = new Date(date);
    return objDate.toLocaleString('de-DE', { year: 'numeric', month: 'numeric', day: 'numeric' });
};

// yyyy-mm-dd
export const formatDateDEStringForSQL = date => {
    if (!date) return '';
    const d = date.split('.');
    return `${d[2]}-${d[1]}-${d[0]}`;
};

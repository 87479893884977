export const isNameValid = name => {
    if (name !== '' && name.length < 3) return false;
    return true;
};

export const isDescriptionValid = description => {
    if (description !== '' && description.length < 8) return false;
    return true;
};

export const resourceFormDataInitialState = {
    resource_name: '',
    resource_barcode: '',
    resource_description: '',
    resource_category_id: '',
    storage_location_id: '',
    status_id: '',
};
export const validCreationInputsInitialState = {
    resource_name: true,
    resource_barcode: true,
    resource_description: true,
    resource_category_id: true,
    storage_location_id: true,
    status_id: true,
};

export const rpFormDataInitialState = {
    title: '',
    ppsordernumber: '',
    resource_id: '',
    status_id: '',
    plan_start_plan_end: '',
};
export const validRPInputsInitialState = {
    title: true,
    ppsordernumber: true,
    resource_id: true,
    status_id: true,
    plan_start_plan_end: true,
};

import React from 'react';
import { Segment, Grid } from 'semantic-ui-react';
import styled from 'styled-components';
import { COLORS } from '../../global-styles';
import A3Statistics from './A3Statistics';
import A3ManagementReview from './A3ManagementReview';
import A3Bearbeitungsdauer from './A3Bearbeitungsdauer';
import QMTable from './Table/QMTable';
import QMAndNotificationsFiltersForm from './QMIAndNotificationsFiltersForm';

const QualityManagementView = () => {
    const [activeFilters, setActiveFilters] = React.useState([]);
    const activeFiltersRef = React.useRef(activeFilters); // important to keep filters in event listener up to date

    const addActiveFilter = filter => {
        const filterIndex = activeFiltersRef.current.findIndex(obj => obj['attribute'] === filter['attribute']);
        if (filterIndex === -1) {
            activeFiltersRef.current = [...activeFiltersRef.current, filter];
            setActiveFilters([...activeFiltersRef.current]);
        } else {
            activeFiltersRef.current[filterIndex] = filter;
            setActiveFilters([...activeFiltersRef.current]);
        }
    };

    const removeActiveFilter = filter => {
        const filterIndex = activeFiltersRef.current.findIndex(obj => obj['attribute'] === filter['attribute']);
        activeFiltersRef.current.splice(filterIndex, 1);
        setActiveFilters([...activeFiltersRef.current]);
    };

    const handleFiltersChange = (evt, data) => {
        const newState = data.value;
        // case remove filter completely
        if (newState.length === 0) {
            removeActiveFilter({ attribute: data.name, value: data.value, operator: 'IN' });
        } else {
            addActiveFilter({ attribute: data.name, value: data.value, operator: 'IN' });
        }
    };

    return (
        <TabContent>
            <MenuWrapper>
                <QMAndNotificationsFiltersForm filters={activeFilters} handleFiltersChange={handleFiltersChange} />
            </MenuWrapper>
            <ContentWrapper>
                <QMTable filters={activeFilters} />
                <Grid>
                    <Grid.Column width={8}>
                        <A3Statistics height={200} title="A3 Statistiken" />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <A3ManagementReview height={200} title="A3-Management-Review" />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <A3Bearbeitungsdauer height={200} title="Durchschn. A3-Bearbeitungsdauer" />
                    </Grid.Column>
                </Grid>
            </ContentWrapper>
        </TabContent>
    );
};

const TabContent = styled.div`
    th {
        color: ${COLORS.BLUE}!important;
    }
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

const MenuWrapper = styled.div`
    flex: 0 0 auto;
    position: relative;
    margin-right: 1rem;
    width: 230px;
    height: auto;
`;

const ContentWrapper = styled(Segment)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 0 1 auto;
    width: calc(100vw - 300px);
    height: 98%;
`;

export default QualityManagementView;

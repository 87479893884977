import React from 'react';
import { Segment, Grid } from 'semantic-ui-react';
import styled from 'styled-components';
import { COLORS } from '../../global-styles';
import { getData } from '../UtilComponents/requestsUtils';
import NotificationBearbeitungsdauer from './NotificationBearbeitungsdauer';
import NotificationManagementReview from './NotificationManagementReview';
import NotificationsFiltersForm from './NotificationsFiltersForm';
import NotificationStatistics from './NotificationStatistics';
import NSTable from './Table/NSTable';

const NotificationStatisticsView = () => {
    const [activeFilters, setActiveFilters] = React.useState([]);
    const [issueTypeList, setIssueTypeList] = React.useState([]);
    const [issueType, setIssueType] = React.useState([]);
    const [chartTitle, setChartTitle] = React.useState('');
    const activeFiltersRef = React.useRef(activeFilters);

    const addActiveFilter = filter => {
        const filterIndex = activeFiltersRef.current.findIndex(obj => obj['attribute'] === filter['attribute']);
        if (filterIndex === -1) {
            activeFiltersRef.current = [...activeFiltersRef.current, filter];
            setActiveFilters([...activeFiltersRef.current]);
        } else {
            activeFiltersRef.current[filterIndex] = filter;
            setActiveFilters([...activeFiltersRef.current]);
        }
        if (filter['attribute'] === 'issue_type_id') {
            const issuesTypeNames = issueTypeList
                .filter(obj => filter['value'].includes(obj.value.toString()))
                .map(obj => obj.text)
                .join(', ');
            setChartTitle(issuesTypeNames + ' - ');
            setIssueType([filter]);
        }
    };

    const removeActiveFilter = filter => {
        const filterIndex = activeFiltersRef.current.findIndex(obj => obj['attribute'] === filter['attribute']);
        activeFiltersRef.current.splice(filterIndex, 1);
        setActiveFilters([...activeFiltersRef.current]);
        if (filter['attribute'] === 'issue_type_id') {
            setIssueType([]);
            setChartTitle('');
            // if (filter.value.length === 0) {
            // } else {
            //     setIssueType([filter]);
            // }
        }
    };

    const handleFiltersChange = (evt, data) => {
        const newState = data.value;
        // case remove filter completely
        if (newState.length === 0) {
            removeActiveFilter({ attribute: data.name, value: data.value, operator: 'IN' });
        } else {
            addActiveFilter({ attribute: data.name, value: data.value, operator: 'IN' });
        }
    };

    React.useEffect(() => {
        const getIssuesTypes = async () => {
            try {
                const response = await getData('crud/issues/get_issues_types');
                setIssueTypeList(response);
            } catch (error) {
                console.log(error);
            }
        };

        getIssuesTypes();
    }, []);

    return (
        <TabContent>
            <MenuWrapper>
                <NotificationsFiltersForm filters={activeFilters} handleFiltersChange={handleFiltersChange} />
            </MenuWrapper>
            <ContentWrapper>
                <NSTable filters={activeFilters} />
                <Grid>
                    {/* <Grid.Column width={8}>
                        <NotificationStatistics height={200} filters={issueType} title={chartTitle + ' Statistiken'} />
                    </Grid.Column> */}
                    <Grid.Column width={8}>
                        <NotificationManagementReview
                            height={200}
                            filters={issueType}
                            title={chartTitle + 'Management-Review'}
                        />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <NotificationBearbeitungsdauer
                            height={200}
                            filters={issueType}
                            title={chartTitle + 'Durchschn. Bearbeitungsdauer'}
                        />
                    </Grid.Column>
                </Grid>
            </ContentWrapper>
        </TabContent>
    );
};

const TabContent = styled.div`
    th {
        color: ${COLORS.BLUE}!important;
    }
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;
const MenuWrapper = styled.div`
    flex: 0 0 auto;
    position: relative;
    margin-right: 1rem;
    width: 230px;
    height: auto;
`;

const ContentWrapper = styled(Segment)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 0 1 auto;
    width: calc(100vw - 300px);
    height: 98%;
`;

export default NotificationStatisticsView;

export const issueFormDataInitialState = {
    issue_title: '',
    issue_type_id: '',
    ppsordernumber: '',
    issue_deadline: new Date().toISOString(),
    issue_description: '',
    issue_status: 0,
};

export const issueFormInputsValidityInitialState = {
    issue_title: true,
    issue_type_id: true,
    ppsordernumber: true,
    issue_responsible_id: true,
    issue_deadline: true,
    issue_description: true,
    issue_status: true,
};

import React from 'react';
import styled from 'styled-components';
import { get } from 'lodash';
import { Button } from 'semantic-ui-react';

const explicitFilters = [
    'Plan-Start',
    'Plan-Ende',
    'Ist-Start',
    'Ist-Ende',
    'Artikelgruppe',
    'IsClosed',
    'ressourcengruppe',
    'IsHauptPA',
    'HasSeriennummern',
    'Artikelnummer',
    'Projekt-Nummer',
];
const ActiveFilters = props => {
    const { filters, removeFilter } = props;
    return filters.length > 0 ? (
        <ActiveFiltersList>
            {filters
                .filter(k => k.Value && !explicitFilters.includes(k.id))
                .map(k => {
                    const id = get(k, 'id', '');
                    const attribute = get(k, 'Attribute', '');
                    const operator = get(k, 'Operator', '');
                    let value = get(k, 'Value', '').replace(/'/g, '');

                    // if the value is an array WITH AND condition from text Search format it properly
                    const arrayRegex = /^(ALL\(ARRAY)/;
                    if (value.match(arrayRegex)) {
                        value = value.replaceAll(/[(ALL(ARRAY)\)]/g, '');
                    }

                    if (id == 'PA-Nummer' && value.match(arrayRegex)) {
                        const pasLength = get(k, 'PAsLength', '');
                        const formatLabel = `[${pasLength}] PAs : `;
                        if (pasLength < 15) {
                            value = formatLabel + value;
                        }
                        if (pasLength > 15) {
                            value = formatLabel + value.substring(0, 100) + '...)';
                        }
                    }

                    return (
                        <Button
                            key={k.id}
                            onClick={() => removeFilter(k.id)}
                            basic
                            fluid
                            icon="remove"
                            content={`${id || attribute} ${operatorToDisplay(operator)} ${value}`}
                            labelPosition="right"
                        />
                    );
                })}
        </ActiveFiltersList>
    ) : (
        <div className="info">keine</div>
    );
};

export default ActiveFilters;

const operatorToDisplay = operator => {
    switch (operator) {
        case '!~*':
            return '!:';
        case '=':
            return ':';
        case '~*':
            return ':';
        default:
            return operator;
    }
};

const ActiveFiltersList = styled.div`
    > * {
        margin: 0.5rem 0 !important;
    }
    .ui.basic.button {
        text-align: left !important;
    }
`;

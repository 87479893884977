import React from 'react';
import { HistogrammWidgetFrame, Histogramm_KNZ, GWTitle } from '../../Utils/widgetsStyles';
import { COLORS } from '../../global-styles';
import Tooltip from '../../Utils/Tooltip';
import useDimensions from 'react-use-dimensions';

import {
    XYPlot,
    XAxis,
    YAxis,
    HorizontalGridLines,
    VerticalGridLines,
    VerticalBarSeries,
    DiscreteColorLegend,
    Hint,
} from 'react-vis';
import Modal from '../../Utils/Modal';
import { getData } from '../UtilComponents/requestsUtils';
import { formatDecimal } from '../../Utils';

const BarSeries = VerticalBarSeries;

const A3Bearbeitungsdauer = props => {
    const { title, height, modalOpen } = props;
    const [loadingData, setLoadingData] = React.useState(false);
    const [hoverData, setHoverData] = React.useState(null);
    const [ref, { x, y, width }] = useDimensions();
    const [data, setData] = React.useState([]);
    const [period, setPeriod] = React.useState('');

    const formatHoverData = point => {
        return [
            { title: 'Durchschnittlich', value: formatDecimal(point.y) },
            { title: 'Monat', value: point.x },
        ];
    };

    const buildData = data => {
        return data.map(obj => ({ x: obj.month, y: obj.avg_processing_time }));
    };

    React.useEffect(() => {
        const getWidgetData = async () => {
            try {
                setLoadingData(true);
                const response = await getData(`crud/qm/a3_bearbeitungsdauer`);
                setLoadingData(false);
                getPeriod(response);
                setData(buildData(response));
            } catch (error) {
                console.log(error);
            }
        };
        getWidgetData();
    }, []);

    const getPeriod = data => {
        try {
            const period = new Set();
            data.forEach(obj => {
                period.add(obj.month_date.split('-')[0]);
            });
            setPeriod(Array.from(period).join(' / '));
        } catch (error) {
            setPeriod(new Date().getFullYear());
        }
    };

    return React.useMemo(() => {
        return (
            <HistogrammWidgetFrame loading={loadingData}>
                <Histogramm_KNZ ref={ref}>
                    <GWTitle>
                        <span></span>
                        <span>{title}</span>
                        {modalOpen ? <span></span> : <Modal graphWidget={<A3Bearbeitungsdauer />} config={props} />}
                    </GWTitle>
                    <div className="kflist">
                        <div>
                            <span className="val">{period}</span>
                        </div>
                    </div>
                </Histogramm_KNZ>
                <XYPlot
                    className="stackedBar"
                    margin={{ bottom: 60, left: 25 }}
                    xType="ordinal"
                    width={width - 20}
                    height={height || 200}
                    color={COLORS.BLUE}
                >
                    <VerticalGridLines />
                    <HorizontalGridLines />
                    <YAxis />
                    <XAxis />
                    {hoverData && <Hint value={hoverData} format={formatHoverData}></Hint>}
                    <BarSeries
                        color={COLORS.BLUE}
                        data={data}
                        onValueMouseOver={d => setHoverData(d)}
                        onValueMouseOut={d => setHoverData(null)}
                    />
                    <DiscreteColorLegend
                        style={{ position: 'absolute', bottom: '0px', left: '50px' }}
                        orientation="horizontal"
                        items={[
                            {
                                title: 'Bearbeitungsdauer',
                                color: COLORS.BLUE,
                            },
                        ]}
                    />
                </XYPlot>
            </HistogrammWidgetFrame>
        );
    }, [width, hoverData, data]);
};

export default A3Bearbeitungsdauer;

export const formatStringDateToLongDE = stringDate => {
    const date = new Date(stringDate);
    const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    };
    return date.toLocaleDateString('de-DE', options);
};

import React from 'react';
import { Grid, Segment, Form, Popup, Button } from 'semantic-ui-react';
import { getCurrentUserId, getData, insertSimpleData, updateData } from '../../UtilComponents/requestsUtils';
import { formatDateToDE } from '../../UtilComponents/StandardDatePicker';
import SInput from '../../UtilComponents/SearchableInput';

const StatusForm = ({ issue_id, department_id, setDepartmentUpdated, issue_type_id, issue_status }) => {
    const initialState = {
        id: '',
        status_id: '',
        user_id: '',
        date: '',
        status_by_user: '',
        reason_rejection: '',
    };
    const [initialForm, setInitialForm] = React.useState(initialState);
    const [formData, setFormData] = React.useState(initialState);
    const [loading, setLoading] = React.useState(false);

    // this is a workaround to update the status_by_user property without complicating
    const [infoChanged, setInfoChanged] = React.useState('');

    const postStatus = async () => {
        setLoading(true);
        const body = {
            issue_id,
            status_id: formData.status_id,
            department_id,
            user_id: getCurrentUserId(),
            date: new Date().toISOString(),
            reason_rejection: formData.reason_rejection,
        };
        try {
            await insertSimpleData('crud/changes/post_change_status_by_department', body);
            setInfoChanged('After Post' + Date.now().toString());
            setDepartmentUpdated('After Post' + Date.now().toString());
        } catch (error) {
            console.log(error);
        }
    };

    const updateStatus = async () => {
        setLoading(true);
        const body = {
            status_id: formData.status_id,
            user_id: getCurrentUserId(),
            date: new Date().toISOString(),
            reason_rejection: formData.reason_rejection,
        };
        const where = { id: formData.id };
        try {
            await updateData('crud/changes/update_change_status_by_department', body, where);
            setInfoChanged('After Update' + Date.now().toString());
            setDepartmentUpdated('After Update' + Date.now().toString());
        } catch (error) {
            console.log(error);
        }
    };

    const handleChange = (evt, data) => {
        const newObjState = Object.assign({}, formData, { [data.name]: data.value });
        setFormData(newObjState);
    };

    const submitForm = () => {
        if (issue_status === 3) {
            alert('Die Meldung darf nicht bearbeitet werden.');
        } else {
            setLoading(true);
            //Änderung = 3 - Sammeländerung = 11
            if (
                (issue_type_id === 3 || issue_type_id == 11) &&
                formData.reason_rejection.trim().length === 0 &&
                [2, 4].includes(formData.status_id)
            ) {
                alert(`Der ${statusThatEnableReasonField[formData.status_id]} ist erforderlich.`);
                setLoading(false);
                return;
            }
            formData.id ? updateStatus() : postStatus();
        }
    };

    const resetForm = () => {
        setFormData(initialForm);
    };

    React.useEffect(() => {
        const getActionDepartmentStatus = async () => {
            setLoading(true);
            try {
                const response = await getData(
                    `crud/changes/get_change_action_status_by_department?issue_id=${issue_id}&department_id=${department_id}`,
                );
                if (response.length == 1) {
                    setFormData(response[0]);
                    setInitialForm(response[0]);
                } else {
                    setFormData(initialState);
                    setInitialForm(initialState);
                }
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        };
        getActionDepartmentStatus();
    }, [department_id, infoChanged]);

    const getInputLabel = department_id => {
        switch (department_id) {
            case 1:
                return 'Produktion';
            case 2:
                return 'Elektrische Berechnung';
            case 3:
                return 'Einkauf';
            case 4:
                return 'Arbeitsvorbereitung';
            case 5:
                return 'Qualitätssicherung';
            case 6:
                return 'Konstruktion';
            case 7:
                return 'Vertrieb';
            case 8:
                return 'Mechanische Berechnung';
            case 9:
                return 'Forschung und Entwicklung';
            case 10:
                return 'Materialwirtschaft';
            case 11:
                return 'Controlling';
        }
    };

    const statusThatEnableReasonField = {
        2: 'Ablehnungsgrund',
        4: 'Prüfgrund',
    };

    return (
        <Segment basic loading={loading}>
            <Form style={{ width: '100%' }}>
                <Grid textAlign="center">
                    <h2 style={{ textAlign: 'center', width: '100%', marginBottom: '5px' }}>
                        {getInputLabel(department_id)}
                    </h2>
                    {[3, 11, 12].includes(issue_type_id) && ( // (issue_type_id === 3 || issue_type_id === 11) && (
                        <>
                            <Popup
                                content="Freigabe wird durch die jeweiligen Abteilungsleiter erteilt. Hier kann festgelegt werden ob der Änderungsantrag freigegeben werden soll. Nachdem alle ihre Freigabe erteilt haben, erhalten die in den Aufgaben zugewiesenen Mitarbeiter eine Mail mit der Info."
                                trigger={<h2 style={{ textAlign: 'center', width: '100%', margin: '0' }}>Freigabe</h2>}
                                position="top center"
                                wide="very"
                            />

                            <Grid.Row columns={3}>
                                <Grid.Column>
                                    <SInput
                                        placeholder="Status auswählen"
                                        name="status_id"
                                        value={formData.status_id}
                                        onChange={handleChange}
                                        query="crud/changes/get_changes_status"
                                        required={false}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <strong>am :</strong> {formatDateToDE(formData.date)}
                                </Grid.Column>
                                <Grid.Column>
                                    <strong>durch :</strong> {formData.status_by_user}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    {statusThatEnableReasonField[formData.status_id] && (
                                        <Form.Input
                                            key="reason_rejection"
                                            placeholder={statusThatEnableReasonField[formData.status_id]}
                                            name="reason_rejection"
                                            value={formData.reason_rejection}
                                            onChange={handleChange}
                                        />
                                    )}
                                </Grid.Column>
                            </Grid.Row>
                        </>
                    )}
                </Grid>
            </Form>
            {[3, 11, 12].includes(issue_type_id) && ( //(issue_type_id === 3 || issue_type_id === 11)
                <>
                    <br />
                    <Button.Group fluid>
                        <Button primary onClick={submitForm} disabled={formData.status_id === '' || loading}>
                            Status speichern
                        </Button>
                        <Button disabled={loading} onClick={resetForm}>
                            Abbrechen
                        </Button>
                    </Button.Group>
                </>
            )}
        </Segment>
    );
};

export default StatusForm;

import React from 'react';
import debounce from 'lodash.debounce';
import { Form } from 'semantic-ui-react';
import { getData } from './requestsUtils';

const SearchableInputAsync = ({
    label,
    name,
    value,
    placeholder,
    query,
    required = true,
    onChange,
    fluid = true,
    width,
    defaultValue,
}) => {
    const [options, setOptions] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        loadData(null, { searchQuery: defaultValue });
    }, [defaultValue]);

    const loadData = async (event, data) => {
        setLoading(true);
        const searchValue = data.searchQuery;
        try {
            const response = await getData(
                query + '?search_for_text=' + searchValue + '&columns_to_search=text&page_number=1&page_size=6',
            );
            setOptions(response);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log('queryID', query);
        }
    };

    const debouncedChangeHandler = React.useMemo(() => debounce(loadData, 500), []);

    return (
        <Form.Select
            selectOnBlur={false}
            onChange={onChange}
            loading={loading}
            label={label}
            name={name}
            value={value}
            placeholder={placeholder}
            options={options}
            selection
            search
            fluid={fluid}
            required={required}
            width={width}
            onSearchChange={debouncedChangeHandler}
        />
    );
};

export default SearchableInputAsync;

import React, { useState, useEffect } from 'react';
import { Form } from 'semantic-ui-react';
import { deleteData, getData, insertSimpleData } from './requestsUtils';

const MultiSelectWithSearch = props => {
    const {
        name,
        placeholder,
        label,
        value,
        handleChange,
        query,
        primaryKey,
        primaryKeyValue,
        foreignKey,
        deletionQuery,
        additionQuery,
        required = true,
        fluid,
        width,
    } = props;

    const [options, setOptions] = React.useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (options.length > 0) return;
        const loadData = async () => {
            setLoading(true);
            try {
                const response = await getData(query);
                setOptions(response);
                setLoading(false);
            } catch (error) {
                console.log('queryID', query);
            }
        };
        loadData();
    }, []);

    const onChange = async (e, data) => {
        const initialState = value;
        const newState = data.value;
        // deletion case
        if (primaryKeyValue && newState.length < initialState.length) {
            let deletedId = '';
            for (let i = 0; i < initialState.length; i++) {
                if (!newState.includes(initialState[i])) {
                    deletedId = initialState[i];
                    break;
                }
            }
            try {
                await deleteData(deletionQuery, { [primaryKey]: primaryKeyValue, [foreignKey]: deletedId });
                handleChange(
                    {},
                    {
                        name: name,
                        value: data.value,
                    },
                );
            } catch (error) {}
        }
        // addition case
        if (primaryKeyValue && newState.length > initialState.length) {
            const addedId = newState[newState.length - 1];
            try {
                await insertSimpleData(additionQuery, { [primaryKey]: primaryKeyValue, [foreignKey]: addedId });
                handleChange(
                    {},
                    {
                        name: name,
                        value: data.value,
                    },
                );
            } catch (error) {}
        } else {
            handleChange(
                {},
                {
                    name: name,
                    value: data.value,
                },
            );
        }
    };

    return (
        <Form.Select
            loading={loading}
            selectOnBlur={false}
            label={label}
            options={options}
            search
            multiple
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            required={required}
            fluid={fluid}
            width={width}
        />
    );
};

export default MultiSelectWithSearch;

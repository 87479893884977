import React from 'react';
import styled from 'styled-components';
import { formatDecimal, formatDate, isColumnSorted, setSortIcon } from '../Utils';

export const buildTableColumns = (setFilter, setSort, sorts) => {
    return [
        {
            XlsHeader: 'PPSOrderID',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'PPSOrderID')
                            ? setSortIcon(sorts, 'PPSOrderID') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'PPSOrderID',
                            Attribute: 'PPSOrderID',
                            Order: 'asc',
                            Value: '3',
                        })
                    }
                >
                    PPSOrderID
                </SortableHeader>
            ),
            accessor: 'PPSOrderID',
        },
        {
            XlsHeader: 'Beschreibung AVG',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'Beschreibung-AVG')
                            ? setSortIcon(sorts, 'Beschreibung-AVG') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'Beschreibung-AVG',
                            Attribute: 'Beschreibung-AVG',
                            Order: 'asc',
                            Value: '2',
                        })
                    }
                >
                    Beschreibung AVG
                </SortableHeader>
            ),
            accessor: 'Beschreibung-AVG',
        },
        {
            XlsHeader: 'PA-Nummer',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'PA-Nummer')
                            ? setSortIcon(sorts, 'PA-Nummer') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'PA-Nummer',
                            Attribute: 'PA-Nummer',
                            Order: 'asc',
                            Value: '1',
                        })
                    }
                >
                    PA-Nummer
                </SortableHeader>
            ),
            accessor: 'PA-Nummer',
            Cell: ({ cell: { value } }) => (
                <a
                    onClick={() =>
                        setFilter({
                            id: 'PA-Nummer',
                            Attribute: 'PA-Nummer',
                            Operator: '=',
                            Value: `'${value}'`,
                        })
                    }
                >
                    {value}
                </a>
            ),
        },
        {
            XlsHeader: 'Ressourcengruppe',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'ressourcengruppe')
                            ? setSortIcon(sorts, 'ressourcengruppe') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'ressourcengruppe',
                            Attribute: 'ressourcengruppe',
                            Order: 'asc',
                            Value: '4',
                        })
                    }
                >
                    Ressourcengruppe
                </SortableHeader>
            ),
            accessor: 'ressourcengruppe',
        },
        {
            XlsHeader: 'Auftragsmenge',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'Auftragsmenge')
                            ? setSortIcon(sorts, 'Auftragsmenge') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'Auftragsmenge',
                            Attribute: 'Auftragsmenge',
                            Order: 'asc',
                            Value: '4',
                        })
                    }
                >
                    Auftragsmenge
                </SortableHeader>
            ),
            accessor: 'Auftragsmenge',
        },
        {
            XlsHeader: 'Haupt-PA',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'Haupt-PA')
                            ? setSortIcon(sorts, 'Haupt-PA') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'Haupt-PA',
                            Attribute: 'Haupt-PA',
                            Order: 'asc',
                            Value: '5',
                        })
                    }
                >
                    Haupt-PA
                </SortableHeader>
            ),
            accessor: 'Haupt-PA',
        },
        {
            XlsHeader: 'Plan-Start',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'Plan-Start')
                            ? setSortIcon(sorts, 'Plan-Start') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'Plan-Start',
                            Attribute: 'Plan-Start',
                            Order: 'asc',
                            Value: '6',
                        })
                    }
                >
                    Plan-Start
                </SortableHeader>
            ),
            accessor: 'Plan-Start',
            Cell: ({ cell: { value } }) => formatDate(value),
        },
        {
            XlsHeader: 'Ist-Start',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'Ist-Start')
                            ? setSortIcon(sorts, 'Ist-Start') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'Ist-Start',
                            Attribute: 'Ist-Start',
                            Order: 'asc',
                            Value: '7',
                        })
                    }
                >
                    Ist-Start
                </SortableHeader>
            ),
            accessor: 'Ist-Start',
            Cell: ({ cell: { value } }) => formatDate(value),
        },
        {
            XlsHeader: 'Plan-Ende',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'Plan-Ende')
                            ? setSortIcon(sorts, 'Plan-Ende') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'Plan-Ende',
                            Attribute: 'Plan-Ende',
                            Order: 'asc',
                            Value: '8',
                        })
                    }
                >
                    Plan-Ende
                </SortableHeader>
            ),
            accessor: 'Plan-Ende',
            Cell: ({ cell: { value } }) => formatDate(value),
        },
        {
            XlsHeader: 'Ist-Ende',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'Ist-Ende')
                            ? setSortIcon(sorts, 'Ist-Ende') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'Ist-Ende',
                            Attribute: 'Ist-Ende',
                            Order: 'asc',
                            Value: '9',
                        })
                    }
                >
                    Ist-Ende
                </SortableHeader>
            ),
            accessor: 'Ist-Ende',
            Cell: ({ cell: { value } }) => formatDate(value),
        },
        {
            XlsHeader: 'Plan-DLZ',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'Plan-DLZ')
                            ? setSortIcon(sorts, 'Plan-DLZ') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'Plan-DLZ',
                            Attribute: 'Plan-DLZ',
                            Order: 'asc',
                            Value: '10',
                        })
                    }
                >
                    Plan-DLZ
                </SortableHeader>
            ),
            accessor: 'Plan-DLZ',
            Cell: ({ cell: { value } }) => formatDecimal(value),
        },
        {
            XlsHeader: 'IST-DLZ',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'IST-DLZ')
                            ? setSortIcon(sorts, 'IST-DLZ') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'IST-DLZ',
                            Attribute: 'IST-DLZ',
                            Order: 'asc',
                            Value: '12',
                        })
                    }
                >
                    IST-DLZ
                </SortableHeader>
            ),
            accessor: 'IST-DLZ',
            Cell: ({ cell: { value } }) => formatDecimal(value),
        },
        {
            XlsHeader: 'TA Zugang',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'TA Zugang')
                            ? setSortIcon(sorts, 'TA Zugang') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'TA Zugang',
                            Attribute: 'TA Zugang',
                            Order: 'asc',
                            Value: '14',
                        })
                    }
                >
                    TA Zugang
                </SortableHeader>
            ),
            accessor: 'TA Zugang',
            Cell: ({ cell: { value } }) => formatDecimal(value),
        },
        {
            XlsHeader: 'TA rel.',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'TA rel') ? (setSortIcon(sorts, 'TA rel') == 'asc' ? 'asc' : 'desc') : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'TA rel',
                            Attribute: 'TA rel',
                            Order: 'asc',
                            Value: '15',
                        })
                    }
                >
                    TA rel
                </SortableHeader>
            ),
            accessor: 'TA rel',
            Cell: ({ cell: { value } }) => formatDecimal(value),
        },
        {
            XlsHeader: 'TA Abgang',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'TA Abgang')
                            ? setSortIcon(sorts, 'TA Abgang') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'TA Abgang',
                            Attribute: 'TA Abgang',
                            Order: 'asc',
                            Value: '16',
                        })
                    }
                >
                    TA Abgang
                </SortableHeader>
            ),
            accessor: 'TA Abgang',
            Cell: ({ cell: { value } }) => formatDecimal(value),
        },
        {
            XlsHeader: 'Plan-Arbeit',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'Plan-Arbeit')
                            ? setSortIcon(sorts, 'Plan-Arbeit') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'Plan-Arbeit',
                            Attribute: 'Plan-Arbeit',
                            Order: 'asc',
                            Value: '11',
                        })
                    }
                >
                    Plan-Arbeit
                </SortableHeader>
            ),

            accessor: 'Plan-Arbeit',
            Cell: ({ cell: { value } }) => formatDecimal(value),
        },

        {
            XlsHeader: 'Ist-Arbeit',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'Ist-Arbeit')
                            ? setSortIcon(sorts, 'Ist-Arbeit') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'Ist-Arbeit',
                            Attribute: 'Ist-Arbeit',
                            Order: 'asc',
                            Value: '13',
                        })
                    }
                >
                    IST-Arbeit
                </SortableHeader>
            ),
            accessor: 'Ist-Arbeit',
            Cell: ({ cell: { value } }) => formatDecimal(value),
        },
        {
            XlsHeader: 'Ist-Plan-Arbeit',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'Ist-Plan-Arbeit')
                            ? setSortIcon(sorts, 'Ist-Plan-Arbeit') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'Ist-Plan-Arbeit',
                            Attribute: 'Ist-Plan-Arbeit',
                            Order: 'asc',
                            Value: '28',
                        })
                    }
                >
                    Ist-Plan-Arbeit
                </SortableHeader>
            ),
            accessor: 'Ist-Plan-Arbeit',
            Cell: ({ cell: { value } }) => formatDecimal(value),
        },
        {
            XlsHeader: 'Status',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'Status') ? (setSortIcon(sorts, 'Status') == 'asc' ? 'asc' : 'desc') : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'Status',
                            Attribute: 'Status',
                            Order: 'asc',
                            Value: '17',
                        })
                    }
                >
                    Status
                </SortableHeader>
            ),
            accessor: 'Status',
        },
        {
            XlsHeader: 'Seriennummern',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'Seriennummern')
                            ? setSortIcon(sorts, 'Seriennummern') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'Seriennummern',
                            Attribute: 'Seriennummern',
                            Order: 'asc',
                            Value: '36',
                        })
                    }
                >
                    Seriennummern
                </SortableHeader>
            ),
            accessor: 'Seriennummern',
        },
    ];
};

const SortableHeader = styled.span`
    cursor: pointer;
    &:after {
        content: '';
        border-width: 0 4px 4px;
        margin: 0px 4px;
        border-style: solid;
        border-color: #404040 transparent;
        display: inline-block;
        vertical-align: middle;
        visibility: hidden;
    }
    &.asc:after {
        border-width: 0 4px 4px;
        visibility: visible;
    }
    &.desc:after {
        border-width: 4px 4px 0;
        visibility: visible;
    }
`;

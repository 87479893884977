import React from 'react';

import { List, Card, Icon } from 'semantic-ui-react';
import { roleFormInitialState } from '.';
import { getData } from '../UtilComponents/requestsUtils';

const cardFullWidth = {
    width: '100%',
};

const RolesList = ({ updateRoleList, setRoleModalOpen, setRoleFormData }) => {
    const [roles, setRoles] = React.useState([]);

    const handleEdit = (data, role) => {
        setRoleModalOpen(true);
        setRoleFormData(role);
    };

    React.useEffect(() => {
        const getCompanyRoles = async () => {
            try {
                const response = await getData(`crud/settings/get_roles`);
                if (response) {
                    setRoles(response);
                }
            } catch (error) {}
        };
        getCompanyRoles();
    }, [updateRoleList]);

    const openRoleModalForCreation = () => {
        setRoleFormData(roleFormInitialState);
        setRoleModalOpen(true);
    };

    return (
        <Card style={cardFullWidth}>
            <Card.Content>
                <Card.Header style={{ display: 'flex', justifyContent: 'space-between' }}>
                    Rollen
                    <Icon name="plus" style={{ cursor: 'pointer' }} onClick={openRoleModalForCreation} />
                </Card.Header>
            </Card.Content>
            <Card.Content>
                <List selection>
                    {roles.length > 0 &&
                        roles.map(role => (
                            <List.Item key={role.id}>
                                <List.Content floated="right">
                                    {role.id != 1 && <Icon name="edit" onClick={(e, d) => handleEdit(d, role)} />}
                                </List.Content>
                                <List.Icon name="key" size="large" verticalAlign="middle" />
                                <List.Content>
                                    <List.Header as="a">{role.role_name}</List.Header>
                                    <List.Description as="b">{role.role_description}</List.Description>
                                </List.Content>
                            </List.Item>
                        ))}
                </List>
            </Card.Content>
        </Card>
    );
};

export default RolesList;

import React from 'react';
import { Form } from 'semantic-ui-react';
import { getData } from './requestsUtils';

const SearchableFilterWithMultiSelection = props => {
    const { label, name, value, placeholder, query, onChange } = props;
    const [options, setOptions] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    // This filters are shown at the left side, allow the user to interact
    React.useEffect(() => {
        if (options.length > 0) return;
        const loadData = async () => {
            setLoading(true);
            try {
                const response = await getData(query);
                setOptions(response);
                setLoading(false);
            } catch (error) {
                console.log('queryID', query);
            }
        };
        loadData();
    }, []);

    return (
        <Form.Select
            selectOnBlur={false}
            loading={loading}
            fluid
            label={label}
            name={name}
            options={options}
            value={value}
            search
            multiple
            placeholder={placeholder}
            onChange={onChange}
        />
    );
};

export default SearchableFilterWithMultiSelection;

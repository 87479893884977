import React from 'react';
import styled from 'styled-components';
import { Button, Input, Select } from 'semantic-ui-react';

const TextSearchForView = props => {
    const { searchViewOptionsLogic, searchViewOptions, setSelectedField, defaultValue = 'issue_title' } = props;
    const listenForEnter = e => {
        if (e.keyCode === 13) {
            searchViewOptionsLogic();
        }
    };

    return (
        <Container>
            <Input
                id="textToSearchFor"
                type="text"
                placeholder="Suchen..."
                action
                size={props.size || 'mini'}
                onKeyUp={listenForEnter}
            >
                <input />
                <Select
                    options={searchViewOptions}
                    defaultValue={defaultValue}
                    size="mini"
                    onChange={(e, { value }) => setSelectedField(value)}
                />
                <Button id="search" type="submit" size="mini" onClick={searchViewOptionsLogic}>
                    Suchen
                </Button>
            </Input>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    justify-content: center;
    input {
        width: 400px;
    }
`;

export default TextSearchForView;

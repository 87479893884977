import React, { useState, useEffect } from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import styled from 'styled-components';
import { get, findIndex } from 'lodash';
import produce from 'immer';
import GlobalStyle, { COLORS } from '../global-styles';
import Login from '../Login';
import logo from '../images/kua-logo.svg';
import { Button, Icon } from 'semantic-ui-react';
import { backendUrl } from '../config';
import request from 'superagent';
//import backGroundImg from '../images/back.jpg';
import backGroundImg from '../images/back1.jpg';
import Tabs from '../Tabs/Tabs';

const App = props => {
    const [sessionID, setSessionID] = useState(localStorage.getItem('deepingBiToken'));
    const [userID, setUserID] = useState(localStorage.getItem('deepingBiUser'));
    const [isAuthenticated, setIsAuthenticated] = useState();

    const [filters, setFilters] = useState([{ id: 'Limit', Attribute: 'limit', Operator: '=', Value: '100' }]); // logical filters
    const filtersRef = React.useRef(filters); // important to keep filters in event listener up to date

    const [filteredPAs, setFilteredPAs] = useState([]);

    const setSessionIDAndSave = s => {
        setSessionID(s);
        localStorage.setItem('deepingBiToken', s);
    };
    const setUserIDAndSave = s => {
        setUserID(s);
        localStorage.setItem('deepingBiUser', s);
    };

    const setFilter = item => {
        const f = produce(filtersRef.current, draft => {
            const existingIndex = findIndex(draft, ['id', get(item, 'id', '')]);
            if (existingIndex >= 0) {
                draft[existingIndex] = item;
                return;
            } else {
                draft.push(item);
                return;
            }
        });
        filtersRef.current = f;
        setFilters(f);
    };

    const removeFilter = id => {
        const f = produce(filtersRef.current, draft => draft.filter(item => get(item, 'id', '') !== id));
        filtersRef.current = f;
        setFilters(f);
    };

    const applyFilteredPAs = item => {
        setFilteredPAs(
            produce(filteredPAs, draft => {
                const existingIndex = findIndex(draft, ['id', get(item, 'id', '')]);
                if (existingIndex >= 0) {
                    draft[existingIndex] = item;
                } else {
                    draft.push(item);
                }
            }),
        );
    };

    const removeFilteredPAs = id => {
        setFilteredPAs([]);
    };

    useEffect(() => {
        request
            .post(`${backendUrl}/auth/token/check`)
            .set('Content-Type', 'application/json')
            .send(sessionID)
            .then(() => {
                setIsAuthenticated(true);
            })
            .catch(() => {
                setIsAuthenticated(false);
            });
    }, [userID, sessionID]);

    const logout = () => {
        setIsAuthenticated(false);
        setSessionIDAndSave('');
    };
    return (
        <React.Fragment>
            <Flex>
                {isAuthenticated ? (
                    <React.Fragment>
                        <Header>
                            <Link to="/">
                                <img src={logo} style={{ height: '60px', margin: '0 1rem' }} />
                            </Link>

                            <Button onClick={logout} primary pa>
                                Abmelden
                            </Button>
                        </Header>
                        <Main>
                            <Switch location={props.location}>
                                <Route
                                    exact
                                    path="/"
                                    render={props => (
                                        <Tabs
                                            {...props}
                                            filters={filters}
                                            setFilters={setFilters}
                                            setFilter={setFilter}
                                            removeFilter={removeFilter}
                                            filteredPAs={filteredPAs}
                                            applyFilteredPAs={applyFilteredPAs}
                                            removeFilteredPAs={removeFilteredPAs}
                                            userID={userID}
                                            sessionID={sessionID}
                                        />
                                    )}
                                />
                            </Switch>
                        </Main>
                    </React.Fragment>
                ) : (
                    <LoginWrapper>
                        <Login
                            setSessionID={setSessionIDAndSave}
                            setUserIDAndSave={setUserIDAndSave}
                            setIsAuthenticated={setIsAuthenticated}
                        />
                    </LoginWrapper>
                )}
            </Flex>
            <GlobalStyle />
        </React.Fragment>
    );
};

export default App;

const LoginWrapper = styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url(${backGroundImg}) left bottom no-repeat;
    background-size: cover;
`;

const Header = styled.div`
    height: 70px;
    border-bottom: 1px solid ${COLORS.RED_KUA};
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    background: white;
    z-index: 99;
    width: 100vw;
    top: 0;
    left: 0;
`;

const Main = styled.div`
    padding: 1rem 1rem 1rem 1rem;
    flex: 0 1 auto;
`;

const Flex = styled.div`
    width: 100%;
    min-height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
`;

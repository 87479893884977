import React from 'react';
import { Segment, Label } from 'semantic-ui-react';
import { biRequestWithoutCache, updateData } from '../UtilComponents/requestsUtils';

import { NotificationCard } from './NotificationCard';
import { queryIssues } from './Queries';

export const DADColumn = ({
    label,
    color,
    issue_status,
    modalOpen,
    setModalOpen,
    setNotificationFormData,
    updateColumns,
    setUpdateColumns,
    activeFilters,
}) => {
    const [data, setData] = React.useState([]);
    const [loadingData, setLoadingData] = React.useState(false);

    let container = React.useRef(null);
    const allowDrop = ev => {
        ev.stopPropagation();
        ev.preventDefault();
    };

    const drop = async ev => {
        ev.preventDefault();
        const issue_id = ev.dataTransfer.getData('id');
        try {
            await updateData('crud/issues/update_issue_status', { issue_status: issue_status }, { id: issue_id });
            setUpdateColumns('afterDrag&Drop' + Date.now().toString());
        } catch (error) {
            console.log(error);
        }
    };

    React.useEffect(() => {
        const issueStatusFilter = { attribute: 'issue_status', operator: '=', value: [`${issue_status}`] };

        const getIssues = async () => {
            setLoadingData(true);
            try {
                const response = await biRequestWithoutCache(queryIssues, [...activeFilters, issueStatusFilter]);
                setData(response);
                setLoadingData(false);
            } catch (error) {
                console.log(error);
                setLoadingData(false);
            }
        };
        getIssues();
    }, [updateColumns, activeFilters]);

    return (
        <Segment onDragOver={allowDrop} onDrop={drop} loading={loadingData}>
            <Label attached="top left" color={color}>
                {label}
            </Label>
            <div ref={container}>
                {data.map(obj => {
                    return (
                        <NotificationCard
                            {...obj}
                            key={obj.id}
                            modalOpen={modalOpen}
                            setModalOpen={setModalOpen}
                            setNotificationFormData={setNotificationFormData}
                        />
                    );
                })}
            </div>
        </Segment>
    );
};

import React, { useEffect, useState, useMemo } from 'react';
import { get } from 'lodash';
import { HistogrammWidgetFrame, Histogramm_KNZ, GWTitle } from '../../Utils/widgetsStyles';
import { COLORS } from '../../global-styles';
import produce from 'immer';
import { formatDecimal, loadData, setDataWithClasses, getRangeValues } from '../../Utils';
import { querySollIstAVG, queryAVG_MST } from '../Queries';
import { XYPlot, XAxis, YAxis, HorizontalGridLines, VerticalGridLines, VerticalBarSeries, Hint } from 'react-vis';
import Modal from '../../Utils/Modal';
import Tooltip from '../../Utils/Tooltip';
import { Planzeiten_Soll_Ist } from './TooltipsTexts';

const BarSeries = VerticalBarSeries;

const Comp = props => {
    const { useFilters, filters, setFilter, title, width, height, modalOpen, sessionID, userID } = props;
    const [data, setData] = useState([]);
    const [hoverData, setHoverData] = useState(null);
    const [loadingData, setLoadingData] = useState(false);
    const [kf, SetKf] = useState();
    const [, setLoadingKf] = useState(false);
    const formatHoverData = point => {
        return [
            { title: 'Anzahl', value: point.y },
            { title: 'Klassenbreite', value: point.bucketSize },
        ];
    };
    useEffect(() => {
        // if (!useFilters) return;
        loadData(setLoadingKf, SetKf, queryAVG_MST, filters, sessionID, userID, 0);
        loadData(
            setLoadingData,
            d => {
                setDataWithClasses(d, setData, 'kf6');
            },
            querySollIstAVG,
            filters,
            sessionID,
            userID,
            0,
        );
    }, [filters, sessionID, userID]);
    return useMemo(() => {
        return (
            <HistogrammWidgetFrame loading={loadingData}>
                <Histogramm_KNZ>
                    <GWTitle>
                        <Tooltip text={Planzeiten_Soll_Ist}></Tooltip>
                        <span>{title}</span>
                        {modalOpen ? <span></span> : <Modal graphWidget={<Comp />} config={props} />}
                    </GWTitle>
                    <div className="kflist">
                        <div>
                            <span className="label">M</span>
                            <span className="val"> {formatDecimal(get(kf, '0.avg_m', 0))}</span>
                            <span className="unit">h</span>
                        </div>
                        <div>
                            <span className="label">SD</span>
                            <span className="val"> {formatDecimal(get(kf, '0.avg_st', 0))}</span>
                            <span className="unit">h</span>
                        </div>
                    </div>
                </Histogramm_KNZ>
                <XYPlot
                    className="stackedBar"
                    margin={{ bottom: 70, left: 60 }}
                    xType="ordinal"
                    width={width}
                    height={height || 200}
                    xDistance={100}
                    color={COLORS.RED_KUA}
                >
                    <VerticalGridLines />
                    <HorizontalGridLines />
                    <YAxis tickFormat={v => formatDecimal(v, 0)} />
                    <XAxis tickLabelAngle={-45} tickFormat={v => formatDecimal(v, 0)} />
                    {hoverData && <Hint value={hoverData} format={formatHoverData}></Hint>}
                    {data.length > 0 && (
                        <BarSeries
                            data={data}
                            onValueMouseOver={d => setHoverData(d)}
                            onValueMouseOut={d => setHoverData(null)}
                            onValueClick={datapoint => {
                                props.setFilters(
                                    produce(filters, draftState => {
                                        draftState['IstPlanFrom'] = parseInt(datapoint.x);
                                        draftState['IstPlanTo'] =
                                            parseInt(datapoint.x) +
                                            parseInt(datapoint.bucketSize) -
                                            (parseInt(datapoint.x) < 0 ? -1 : 1);
                                    }),
                                );
                            }}
                            onValueClick={datapoint => {
                                const { lower, upper } = getRangeValues(datapoint.x, datapoint.bucketSize);
                                setFilter({
                                    id: 'Ist-Plan-Arbeit',
                                    Attribute: 'Ist-Plan-Arbeit',
                                    Operator: 'between',
                                    Value: `${lower} and ${upper}`,
                                });
                            }}
                        />
                    )}
                </XYPlot>
            </HistogrammWidgetFrame>
        );
    }, [data, kf, loadingData, width, hoverData]);
};

export default Comp;

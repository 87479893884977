import React, { useState } from 'react';
import styled from 'styled-components';
import { COLORS } from '../global-styles';
import useDimensions from 'react-use-dimensions';
import 'react-vis/dist/style.css';
import produce from 'immer';
import dayjs from 'dayjs';
import { get, set, findIndex } from 'lodash';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import FiltersView from '../FiltersView';
import PIE_ORDERS_STATUS from './GraphicWidgets/PIE_ORDERS_STATUS';
import HIST_SOLL_IST from './GraphicWidgets/HIST_SOLL_IST';
import LINE_RUCKSTAND from './GraphicWidgets/LINE_RUCKSTAND';
import PIE_DISPOSITION from './GraphicWidgets/PIE_DISPOSITION';
import HIST_DLZ from './GraphicWidgets/HIST_DLZ';
import PIE_TERMINTREUE from './GraphicWidgets/PIE_TERMINTREUE';
import HIST_TA_ZUGANG from './GraphicWidgets/HIST_TA_ZUGANG';
import HIST_TA_REL from './GraphicWidgets/HIST_TA_REL';
import HIST_TA_ABGANG from './GraphicWidgets/HIST_TA_ABGANG';
import OrdersViewTable from './OrdersViewTable';
import { areFiltersValidInContext } from '../Utils/filters';
dayjs.extend(customParseFormat);

const OrderView = props => {
    const {
        filterOpen,
        filters,
        setFilters,
        setFilter,
        removeFilter,
        filteredPAs,
        applyFilteredPAs,
        removeFilteredPAs,
        sessionID,
        userID,
        tab,
    } = props;
    const [loadingExcel, setLoadingExcel] = useState();
    const [sorts, setSorts] = useState([]);
    const [paClickCount, setPaClickCount] = useState(0);
    const useFilters = areFiltersValidInContext(filters, tab);
    const setSort = item => {
        setSorts(
            produce(sorts, draft => {
                const existingIndex = findIndex(draft, ['id', get(item, 'id', '')]);
                if (existingIndex >= 0) {
                    get(item, 'Order') === get(draft[existingIndex], 'Order')
                        ? set(item, 'Order', 'desc')
                        : set(item, 'Order', 'asc');
                    draft[existingIndex] = item;
                } else {
                    // sort by just one column at the time, when sorting by multiple columns draft.push(item);
                    draft[0] = item;
                }
            }),
        );
    };

    const [ref, { x, y, width }] = useDimensions();
    const colWidth = (width || 600) / 3 - 20;
    const renderWidget = (Widget, title) => {
        return (
            <Widget
                title={title}
                setFilter={setFilter}
                useFilters={useFilters}
                removeFilter={removeFilter}
                filters={filters}
                width={colWidth}
                sessionID={sessionID}
                userID={userID}
            />
        );
    };
    return (
        <Styled>
            <FiltersView
                filterOpen={filterOpen}
                filters={filters}
                setFilters={setFilters}
                setFilter={setFilter}
                removeFilter={removeFilter}
                filteredPAs={filteredPAs}
                applyFilteredPAs={applyFilteredPAs}
                removeFilteredPAs={removeFilteredPAs}
                userID={userID}
                sessionID={sessionID}
                tab={tab}
            />
            <ContentWrapper className={filterOpen ? 'open' : 'closed'}>
                <OrdersViewTable
                    filters={filters}
                    setFilter={setFilter}
                    removeFilter={removeFilter}
                    filteredPAs={filteredPAs}
                    applyFilteredPAs={applyFilteredPAs}
                    sorts={sorts}
                    setSort={setSort}
                    loadingExcel={loadingExcel}
                    setLoadingExcel={setLoadingExcel}
                    paClickCount={paClickCount}
                    setPaClickCount={setPaClickCount}
                    sessionID={sessionID}
                    userID={userID}
                />
                <KnzSection ref={ref} width={colWidth}>
                    {renderWidget(PIE_ORDERS_STATUS, 'Anzahl Aufträge')}
                    {renderWidget(HIST_SOLL_IST, 'Planzeiten Soll-Ist')}
                    {renderWidget(LINE_RUCKSTAND, 'Rückstand')}
                    {renderWidget(PIE_DISPOSITION, 'Disposition offene Aufträge')}
                    {renderWidget(HIST_DLZ, 'DLZ')}
                    {renderWidget(PIE_TERMINTREUE, 'Termintreue')}
                    {renderWidget(HIST_TA_ZUGANG, 'TA Zugang')}
                    {renderWidget(HIST_TA_REL, 'rel. TA')}
                    {renderWidget(HIST_TA_ABGANG, 'TA Abgang')}
                </KnzSection>
            </ContentWrapper>
        </Styled>
    );
};

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 0 1 auto;
    padding-right: 1rem;
    width: calc(100vw - 270px);
`;

export default OrderView;

const Styled = styled.div`
    th {
        color: ${COLORS.BLUE}!important;
    }
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

const KnzSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    padding-right: -1rem;
    > * {
        flex: 0 0 ${props => props.width || 300}px !important;
        margin: 1rem 0 !important;
        padding: 1rem 0 !important;
        overflow: hidden;
        > * {
            padding: 0 !important;
            margin: 0 10px 0 0 !important;
        }
    }
`;

import React from 'react';
import styled from 'styled-components';
import { Label } from 'semantic-ui-react';
import { formatDecimal, formatTimeStamp, formatDate, isColumnSorted, setSortIcon } from '../Utils';

export const buildTableColumns = (setFilter, setSort, sorts, paClickCOunt, setPaClickCount) => {
    return [
        {
            XlsHeader: 'Abgeschlosen',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'PAAmpelStatus')
                            ? setSortIcon(sorts, 'PAAmpelStatus') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                >
                    Abgeschlossen
                </SortableHeader>
            ),
            accessor: 'PAAmpelStatus',
            Cell: PAStatusAmpel,
        },
        {
            XlsHeader: 'PA-Nummer',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'PA-Nummer')
                            ? setSortIcon(sorts, 'PA-Nummer') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'PA-Nummer',
                            Attribute: 'PA-Nummer',
                            Order: 'asc',
                            Value: '1',
                        })
                    }
                >
                    PA-Nummer
                </SortableHeader>
            ),
            accessor: 'PA-Nummer',
            Cell: ({ cell: { value } }) => (
                <a
                    onClick={() => {
                        /*Beim Anklicken eines PA’s soll als erstes nicht nur der PA sondern auch die dazugehörigen Unter PA‘s angezeigt werden
                         und beim 2. Mal dann nur der PA */
                        setPaClickCount((paClickCOunt = paClickCOunt + 1));
                        if (paClickCOunt % 2 == 0) {
                            setFilter({
                                id: 'PA-Nummer',
                                Attribute: 'PA-Nummer',
                                Operator: '=',
                                Value: `'${value}'`,
                            });
                        } else {
                            setFilter({
                                id: 'Haupt-PA',
                                Attribute: 'Haupt-PA',
                                Operator: `= '${value}' OR "PA-Nummer" = `,
                                Value: `'${value}'`,
                            });
                        }
                    }}
                >
                    {value}
                </a>
            ),
        },
        {
            XlsHeader: 'Bezeichnung',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'Bezeichnung')
                            ? setSortIcon(sorts, 'Bezeichnung') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'Bezeichnung',
                            Attribute: 'Bezeichnung',
                            Order: 'asc',
                            Value: '2',
                        })
                    }
                >
                    Bezeichnung
                </SortableHeader>
            ),
            accessor: 'Bezeichnung',
        },
        {
            XlsHeader: 'Artikelnummer',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'Artikelnummer')
                            ? setSortIcon(sorts, 'Artikelnummer') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'Artikelnummer',
                            Attribute: 'Artikelnummer',
                            Order: 'asc',
                            Value: '3',
                        })
                    }
                >
                    Artikelnummer
                </SortableHeader>
            ),
            accessor: 'Artikelnummer',
        },
        {
            XlsHeader: 'Artikelgruppe',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'Artikelgruppe')
                            ? setSortIcon(sorts, 'Artikelgruppe') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'Artikelgruppe',
                            Attribute: 'Artikelgruppe',
                            Order: 'asc',
                            Value: '3',
                        })
                    }
                >
                    Artikelgruppe
                </SortableHeader>
            ),
            accessor: 'Artikelgruppe',
        },
        {
            XlsHeader: 'Auftragsmenge',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'Auftragsmenge')
                            ? setSortIcon(sorts, 'Auftragsmenge') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'Auftragsmenge',
                            Attribute: 'Auftragsmenge',
                            Order: 'asc',
                            Value: '4',
                        })
                    }
                >
                    Auftragsmenge
                </SortableHeader>
            ),
            accessor: 'Auftragsmenge',
        },
        {
            XlsHeader: 'Haupt-PA',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'Haupt-PA')
                            ? setSortIcon(sorts, 'Haupt-PA') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'Haupt-PA',
                            Attribute: 'Haupt-PA',
                            Order: 'asc',
                            Value: '5',
                        })
                    }
                >
                    Haupt-PA
                </SortableHeader>
            ),
            accessor: 'Haupt-PA',
        },
        {
            XlsHeader: 'Plan-Start',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'Plan-Start')
                            ? setSortIcon(sorts, 'Plan-Start') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'Plan-Start',
                            Attribute: 'Plan-Start',
                            Order: 'asc',
                            Value: '6',
                        })
                    }
                >
                    Plan-Start
                </SortableHeader>
            ),
            accessor: 'Plan-Start',
            Cell: ({ cell: { value } }) => formatDate(value),
        },
        {
            XlsHeader: 'IST-Start',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'Ist-Start')
                            ? setSortIcon(sorts, 'Ist-Start') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'Ist-Start',
                            Attribute: 'Ist-Start',
                            Order: 'asc',
                            Value: '7',
                        })
                    }
                >
                    Ist-Start
                </SortableHeader>
            ),
            accessor: 'Ist-Start',
            Cell: ({ cell: { value } }) => formatDate(value),
        },
        {
            XlsHeader: 'Plan-Ende',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'Plan-Ende')
                            ? setSortIcon(sorts, 'Plan-Ende') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'Plan-Ende',
                            Attribute: 'Plan-Ende',
                            Order: 'asc',
                            Value: '8',
                        })
                    }
                >
                    Plan-Ende
                </SortableHeader>
            ),
            accessor: 'Plan-Ende',
            Cell: ({ cell: { value } }) => formatDate(value),
        },
        {
            XlsHeader: 'IST-Ende',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'Ist-Ende')
                            ? setSortIcon(sorts, 'Ist-Ende') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'Ist-Ende',
                            Attribute: 'Ist-Ende',
                            Order: 'asc',
                            Value: '9',
                        })
                    }
                >
                    Ist-Ende
                </SortableHeader>
            ),
            accessor: 'Ist-Ende',
            Cell: ({ cell: { value } }) => formatDate(value),
        },
        {
            XlsHeader: 'Plan-DLZ',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'Plan-DLZ')
                            ? setSortIcon(sorts, 'Plan-DLZ') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'Plan-DLZ',
                            Attribute: 'Plan-DLZ',
                            Order: 'asc',
                            Value: '10',
                        })
                    }
                >
                    Plan-DLZ
                </SortableHeader>
            ),
            accessor: 'Plan-DLZ',
            Cell: ({ cell: { value } }) => formatDecimal(value),
        },
        {
            XlsHeader: 'IST-DLZ',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'IST-DLZ')
                            ? setSortIcon(sorts, 'IST-DLZ') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'IST-DLZ',
                            Attribute: 'IST-DLZ',
                            Order: 'asc',
                            Value: '12',
                        })
                    }
                >
                    IST-DLZ
                </SortableHeader>
            ),
            accessor: 'IST-DLZ',
            Cell: ({ cell: { value } }) => formatDecimal(value),
        },
        {
            XlsHeader: 'TA Zugang',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'TA Zugang')
                            ? setSortIcon(sorts, 'TA Zugang') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'TA Zugang',
                            Attribute: 'TA Zugang',
                            Order: 'asc',
                            Value: '14',
                        })
                    }
                >
                    TA Zugang
                </SortableHeader>
            ),
            accessor: 'TA Zugang',
            Cell: ({ cell: { value } }) => formatDecimal(value),
        },
        {
            XlsHeader: 'TA rel.',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'TA rel') ? (setSortIcon(sorts, 'TA rel') == 'asc' ? 'asc' : 'desc') : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'TA rel',
                            Attribute: 'TA rel',
                            Order: 'asc',
                            Value: '15',
                        })
                    }
                >
                    TA rel
                </SortableHeader>
            ),
            accessor: 'TA rel',
            Cell: ({ cell: { value } }) => formatDecimal(value),
        },
        {
            XlsHeader: 'TA Abgang',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'TA Abgang')
                            ? setSortIcon(sorts, 'TA Abgang') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'TA Abgang',
                            Attribute: 'TA Abgang',
                            Order: 'asc',
                            Value: '16',
                        })
                    }
                >
                    TA Abgang
                </SortableHeader>
            ),
            accessor: 'TA Abgang',
            Cell: ({ cell: { value } }) => formatDecimal(value),
        },
        {
            XlsHeader: 'Plan-Arbeit',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'Plan-Arbeit')
                            ? setSortIcon(sorts, 'Plan-Arbeit') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'Plan-Arbeit',
                            Attribute: 'Plan-Arbeit',
                            Order: 'asc',
                            Value: '11',
                        })
                    }
                >
                    Plan-Arbeit
                </SortableHeader>
            ),

            accessor: 'Plan-Arbeit',
            Cell: ({ cell: { value } }) => formatDecimal(value),
        },
        {
            XlsHeader: 'IST-Arbeit',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'IST-Arbeit')
                            ? setSortIcon(sorts, 'IST-Arbeit') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'IST-Arbeit',
                            Attribute: 'IST-Arbeit',
                            Order: 'asc',
                            Value: '13',
                        })
                    }
                >
                    IST-Arbeit
                </SortableHeader>
            ),
            accessor: 'IST-Arbeit',
            Cell: ({ cell: { value } }) => formatDecimal(value),
        },
        {
            XlsHeader: 'Ist-Plan-Arbeit',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'Ist-Plan-Arbeit')
                            ? setSortIcon(sorts, 'Ist-Plan-Arbeit') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'Ist-Plan-Arbeit',
                            Attribute: 'Ist-Plan-Arbeit',
                            Order: 'asc',
                            Value: '29',
                        })
                    }
                >
                    Ist-Plan-Arbeit
                </SortableHeader>
            ),
            accessor: 'Ist-Plan-Arbeit',
        },
        {
            XlsHeader: 'Buchungsart',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'Buchungsart')
                            ? setSortIcon(sorts, 'Buchungsart') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'Buchungsart',
                            Attribute: 'Buchungsart',
                            Order: 'asc',
                            Value: '17',
                        })
                    }
                >
                    Buchungsart
                </SortableHeader>
            ),
            accessor: 'Buchungsart',
        },
        {
            XlsHeader: 'Bestellung',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'Bestellung')
                            ? setSortIcon(sorts, 'Bestellung') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'Bestellung',
                            Attribute: 'Bestellung',
                            Order: 'asc',
                            Value: '18',
                        })
                    }
                >
                    Bestellung
                </SortableHeader>
            ),
            accessor: 'Bestellung',
            Cell: ({ cell: { value } }) => value || ''.toString().split('.')[0],
        },
        {
            XlsHeader: 'Status',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'Status') ? (setSortIcon(sorts, 'Status') == 'asc' ? 'asc' : 'desc') : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'Status',
                            Attribute: 'Status',
                            Order: 'asc',
                            Value: '19',
                        })
                    }
                >
                    Status
                </SortableHeader>
            ),
            accessor: 'Status',
        },
        {
            XlsHeader: 'Status erstellt',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'Status erstellt')
                            ? setSortIcon(sorts, 'Status erstellt') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'Status erstellt',
                            Attribute: 'Status erstellt',
                            Order: 'asc',
                            Value: '20',
                        })
                    }
                >
                    Erstellt
                </SortableHeader>
            ),
            accessor: 'Status erstellt',
        },
        {
            XlsHeader: 'Status eingeplant',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'Status eingeplant')
                            ? setSortIcon(sorts, 'Status eingeplant') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'Status eingeplant',
                            Attribute: 'Status eingeplant',
                            Order: 'asc',
                            Value: '21',
                        })
                    }
                >
                    Eingeplant
                </SortableHeader>
            ),
            accessor: 'Status eingeplant',
        },
        {
            XlsHeader: 'Status freigegeben',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'Status freigegeben')
                            ? setSortIcon(sorts, 'Status freigegeben') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'Status freigegeben',
                            Attribute: 'Status freigegeben',
                            Order: 'asc',
                            Value: '22',
                        })
                    }
                >
                    Freigegeben
                </SortableHeader>
            ),
            accessor: 'Status freigegeben',
        },
        {
            XlsHeader: 'Status fertiggemeldet',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'Status fertiggemeldet')
                            ? setSortIcon(sorts, 'Status fertiggemeldet') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'Status fertiggemeldet',
                            Attribute: 'Status fertiggemeldet',
                            Order: 'asc',
                            Value: '23',
                        })
                    }
                >
                    Fertiggemeldet
                </SortableHeader>
            ),
            accessor: 'Status fertiggemeldet',
        },
        {
            XlsHeader: 'Status freigeben',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'Status Freigeben')
                            ? setSortIcon(sorts, 'Status Freigeben') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'Status Freigeben',
                            Attribute: 'Status Freigeben',
                            Order: 'asc',
                            Value: '24',
                        })
                    }
                >
                    Freigeben
                </SortableHeader>
            ),
            accessor: 'Status Freigeben',
        },
        {
            XlsHeader: 'Status Fertigmelden',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'Status Fertigmelden')
                            ? setSortIcon(sorts, 'Status Fertigmelden') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'Status Fertigmelden',
                            Attribute: 'Status Fertigmelden',
                            Order: 'asc',
                            Value: '25',
                        })
                    }
                >
                    Fertigmelden
                </SortableHeader>
            ),
            accessor: 'Status Fertigmelden',
        },
        {
            XlsHeader: 'Freigabe',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'Freigabe')
                            ? setSortIcon(sorts, 'Freigabe') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'Freigabe',
                            Attribute: 'Freigabe',
                            Order: 'asc',
                            Value: '26',
                        })
                    }
                >
                    Datum Freigabe
                </SortableHeader>
            ),
            accessor: 'Freigabe',
            Cell: ({ cell: { value } }) => formatDate(value),
        },
        {
            XlsHeader: 'Freigeber Name',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'Freigeber Name')
                            ? setSortIcon(sorts, 'Freigeber Name') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'Freigeber Name',
                            Attribute: 'Freigeber Name',
                            Order: 'asc',
                            Value: '28',
                        })
                    }
                >
                    Freigeber Name
                </SortableHeader>
            ),
            accessor: 'Freigeber Name',
        },
        {
            XlsHeader: 'Unter-PA',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'Unter-PA-Flag')
                            ? setSortIcon(sorts, 'Unter-PA-Flag') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'Unter-PA-Flag',
                            Attribute: 'Unter-PA-Flag',
                            Order: 'asc',
                            Value: '30',
                        })
                    }
                >
                    Unter-PA
                </SortableHeader>
            ),
            accessor: 'Unter-PA-Flag',
            Cell: trafficLight,
        },
        {
            XlsHeader: 'Material',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'Stock') ? (setSortIcon(sorts, 'Stock') == 'asc' ? 'asc' : 'desc') : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'Stock',
                            Attribute: 'Stock',
                            Order: 'asc',
                            Value: '31',
                        })
                    }
                >
                    Material
                </SortableHeader>
            ),
            accessor: 'Stock',
            Cell: trafficLight,
        },
        {
            XlsHeader: 'Vollst.',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'Vollstaendig')
                            ? setSortIcon(sorts, 'Vollstaendig') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'Vollstaendig',
                            Attribute: 'Vollstaendig',
                            Order: 'asc',
                            Value: '32',
                        })
                    }
                >
                    Vollst.
                </SortableHeader>
            ),
            accessor: 'Vollstaendig',
            Cell: trafficLight,
        },
        {
            XlsHeader: 'AB',
            Header: () => (
                <SortableHeader
                    className={isColumnSorted(sorts, 'AB') ? (setSortIcon(sorts, 'AB') == 'asc' ? 'asc' : 'desc') : ''}
                    onClick={() =>
                        setSort({
                            id: 'AB',
                            Attribute: 'AB',
                            Order: 'asc',
                            Value: '33',
                        })
                    }
                >
                    AB
                </SortableHeader>
            ),
            accessor: 'AB',
        },
        {
            XlsHeader: 'AB LT',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'AB LT') ? (setSortIcon(sorts, 'AB LT') == 'asc' ? 'asc' : 'desc') : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'AB LT',
                            Attribute: 'AB LT',
                            Order: 'asc',
                            Value: '34',
                        })
                    }
                >
                    AB LT
                </SortableHeader>
            ),
            accessor: 'AB LT',
            Cell: ({ cell: { value } }) => formatDate(value),
        },
        {
            XlsHeader: 'Lieferscheine',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'Lieferschein')
                            ? setSortIcon(sorts, 'Lieferschein') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'Lieferschein',
                            Attribute: 'Lieferschein',
                            Order: 'asc',
                            Value: '35',
                        })
                    }
                >
                    Lieferscheine
                </SortableHeader>
            ),
            accessor: 'Lieferschein',
        },
        {
            XlsHeader: 'Seriennummern',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'Seriennummern')
                            ? setSortIcon(sorts, 'Seriennummern') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'Seriennummern',
                            Attribute: 'Seriennummern',
                            Order: 'asc',
                            Value: '36',
                        })
                    }
                >
                    Seriennummern
                </SortableHeader>
            ),
            accessor: 'Seriennummern',
        },
        {
            XlsHeader: 'Projekt-Nummer',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'Projekt-Nummer')
                            ? setSortIcon(sorts, 'Projekt-Nummer') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'Projekt-Nummer',
                            Attribute: 'Projekt-Nummer',
                            Order: 'asc',
                            Value: '5',
                        })
                    }
                >
                    Projekt-Nummer
                </SortableHeader>
            ),
            accessor: 'Projekt-Nummer',
        },
    ];
};

const PAStatusAmpel = ({ cell: { value } }) => {
    if (value == 1) return <Label empty circular color="green" />;
    if (value == 2) return <Label empty circular color="yellow" />;
    if (value == 3) return <Label empty circular color="red" />;
    else return '';
};

const trafficLight = ({ cell: { value } }) =>
    value > 0 ? (
        <Label empty circular color="green" />
    ) : value < 0 ? (
        <Label empty circular color="red" />
    ) : (
        <Label empty circular color="yellow" />
    );

const SortableHeader = styled.span`
    cursor: pointer;
    &:after {
        content: '';
        border-width: 0 4px 4px;
        margin: 0px 4px;
        border-style: solid;
        border-color: #404040 transparent;
        display: inline-block;
        vertical-align: middle;
        visibility: hidden;
    }
    &.asc:after {
        border-width: 0 4px 4px;
        visibility: visible;
    }
    &.desc:after {
        border-width: 4px 4px 0;
        visibility: visible;
    }
`;

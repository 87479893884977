import React from 'react';
import { Form, Button } from 'semantic-ui-react';
import SInput from '../../UtilComponents/SearchableInput';
import { getData, insertSimpleData, updateData } from '../../UtilComponents/requestsUtils';
import { queryUsers } from '../Queries';
import SearchableInput from '../../UtilComponents/SearchableInput';

const defaultFormData = {
    lessons_learned_details_id: '',
    lessons_learned_employee_id: '',
    lessons_learned_department_id: '',
    lessons_learned_description: '',
};

const defaultFormFieldsValidity = {
    lessons_learned_employee_id: true,
    lessons_learned_department_id: true,
    lessons_learned_description: true,
};

const LessonsLearnedDetailsForm = ({ issue_id, issue_status }) => {
    const [loading, setLoading] = React.useState(false);
    const [formData, setFormData] = React.useState(defaultFormData);
    const [formFieldsValidity, setFormFieldsValidity] = React.useState(defaultFormFieldsValidity);

    const handleChange = (event, data) => {
        const newObjState = Object.assign({}, formData, { [data.name]: data.value });
        setFormData(newObjState);
    };

    const isFormValid = () => {
        if (!Object.values(formFieldsValidity).every(i => i === true)) return false;
        let { lessons_learned_description, lessons_learned_details_id, ...rest } = formData;
        const valuesWithoutDescription = Object.values(rest);
        if (!Object.values(valuesWithoutDescription).every(i => i !== '')) return false;
        return true;
    };

    React.useEffect(() => {
        const getImprovementsProposalDetails = async () => {
            setLoading(true);
            try {
                const response = await getData(`crud/issues/get_issue_lessons_learned_details?issue_id=${issue_id}`);
                if (response[0]) {
                    setFormData(response[0]);
                }
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        };
        getImprovementsProposalDetails();
    }, []);

    const createDetails = async () => {
        setLoading(true);
        try {
            const { lessons_learned_details_id, ...rest } = formData;
            const body = {
                ...rest,
                issue_id: issue_id,
            };
            const response = await insertSimpleData('crud/issues/post_issue_lessons_learned_details', body);
            setFormData({ ...response });

            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const updateDetails = async () => {
        setLoading(true);
        try {
            const { issue_id, lessons_learned_details_id, ...rest } = formData;
            const response = await updateData('crud/issues/update_issue_lessons_learned_details', rest, {
                lessons_learned_details_id: lessons_learned_details_id,
            });
            setFormData({ ...response });
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const submitForm = () => {
        if (issue_status === 3) {
            alert('Die Meldung darf nicht bearbeitet werden.');
        } else {
            !formData.lessons_learned_details_id ? createDetails() : updateDetails();
        }
    };

    return (
        <Form loading={loading}>
            <Form.Group>
                <SInput
                    label="Mitarbeiter"
                    placeholder="Mitarbeiter auswählen"
                    name="lessons_learned_employee_id"
                    value={formData.lessons_learned_employee_id}
                    onChange={handleChange}
                    query={queryUsers}
                    width={8}
                    required={true}
                />
                <SearchableInput
                    label="Abteilung"
                    placeholder="Abteilung auswählen"
                    name="lessons_learned_department_id"
                    value={formData.lessons_learned_department_id}
                    query="crud/issues/get_departments_for_lessons_lerned"
                    onChange={handleChange}
                    width={8}
                    required={true}
                />
            </Form.Group>

            <Form.TextArea
                label="Beschreibung​"
                name="lessons_learned_description"
                value={formData.lessons_learned_description}
                onChange={handleChange}
                width={16}
                rows={8}
            />
            <Button fluid primary disabled={!isFormValid()} onClick={submitForm}>
                Speichern
            </Button>
        </Form>
    );
};

export default LessonsLearnedDetailsForm;

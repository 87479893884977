export const AnzahlAuftraege =
    'Es wird der aktuelle Status der Aufträge unterschieden und in der jeweiligen Anzahl dargstellt. Die Unterscheidung hilft bei der Disposition oder Auswertung von Aufträgen.';
export const Planzeiten_Soll_Ist =
    'Für jeden Auftrag wird eine Plan-Arbeit festgelegt. Nach Rückmeldung der tatsächlich benötigten Ist-Arbeit wird hier für jeden Auftrag die Abweichung von Plan und Ist ausgewertet. Ein negativer Wert bedeutet, dass weniger Zeit benötigt wurde, ein positiver Wert bedeutet, dass zusätzliche Zeit benötigt wurde. Die Auswertung hilft bei der Bestimmung angemessener Plan-Zeiten und zeigt, ob die Produktion die Zeiten einhalten kann. Eine positiver Mittelwert (M) bedeutet, dass die geplante Arbeit im Mittel nicht eingehalten werden kann. Eine hohe Standardabweichung (SD) deutet auf instabile Prozesse hin. ';
export const Rueckstand =
    'Der Rückstand gibt an, wie viele Aufträge an einem Tag noch nicht zum Plan-Ende fertiggestellt worden sind. Dies lässt Schlüsse auf die allgemeine Termin- und Kapazitätssituation zu.';
export const Disposition_Offene_Auftraege =
    'Sofern für einen Auftrag Material fehlt oder Unter-PA noch offen sind, gilt ein Auftrag als unvollständig - ist alles Material vorhanden und alle Unter-PA zurückgemeldet, so gilt ein Auftrag als vollständig. Dies hilft der Disposition bei der Freigabe von Aufträgen.';
export const DLZ =
    'Für jeden Auftrag wird die Durchlaufzeit (DLZ) aus Ist-Endtermin und Start-Endtermin berechnet. Ein negativer Wert bedeutet, dass ein Auftrag früher als geplant fertig war, ein positiver Wert bedeutet, dass ein Auftrag später als geplant fertig war. Eine positiver Mittelwert (M) bedeutet, dass die geplante DLZ im Mittel nicht eingehalten werden kann. Eine hohe Standardabweichung (SD) deutet auf instabile Prozesse hin.';
export const Termintreue =
    'Für jeden Auftrag wird berechnet, ob er zu spät fertiggestellt wurde (Ist-Ende>Plan-Ende) oder nicht mehr pünktlich zu schaffen ist (Plan-Ende<heute) bzw. pünktlich fertiggestellt wurde (Ist-Ende<Plan-Ende). Im Fall zu später Fertigstellung hat der Auftrag eine positive Terminabweichung (TA). Im Fall zu später Fertigstellung hat der Auftrag eine negative Terminabweichung (TA) oder den Wert Null. Es werden alle pünktlichen Aufträge gezählt und ins Verhältnis zur Gesamtzahl gesetzt (Termintreue). Eine geringe Termintreue deutet daraufhin, dass die Kundenanforderungen nicht gut eingehalten werden.';
export const TA_Zugang =
    'Für jeden Auftrag wird berechnet, ob er zu spät begonnen wurde (Ist-Start> Plan-Start) oder pünktlich fertiggestellt wurde (Ist-Start <=Plan-Start). Im Fall zu frühen Beginns hat der Auftrag eine positive Terminabweichung (TA). Im Fall zu späten Beginns hat der Auftrag eine negative Terminabweichung (TA) oder den Wert Null. Eine positiver Mittelwert (M) bedeutet, dass im Mittel zu spät begonnen wird. Das kann an Vorprozessen, der Materialverfügbarkeit oder Reihenfolgevertauschungen liegen. Eine hohe Standardabweichung (SD) deutet auf instabile Prozesse hin.';
export const rel_TA =
    'Für jeden Auftrag wird berechnet, ob er innerhalb der zur Verfügung stehenden Dauer (Plan-Arbeit + Übergangszeit) bearbeitet wurde: zu lange Dauer (Ist-DLZ - Plan-DLZ > 0) oder zu kurze Dauer (Ist-DLZ - Plan-DLZ <= 0).  Im Fall zu langer Dauer  hat der Auftrag eine positive relative Terminabweichung (rel TA). Im Fall zu kurzer Dauer hat der Auftrag eine negative relative Terminabweichung (rel. TA) oder den Wert Null. Eine positiver Mittelwert (M) bedeutet, dass im Mittel zu lange gedauert hat. Das kann an zu kurzen Plan-Zeiten oder mangelnder Kapazität liegen. Eine hohe Standardabweichung (SD) deutet auf instabile Prozesse hin.';
export const TA_Abgang =
    'Für jeden Auftrag wird berechnet, ob er zu spät fertiggestellt wurde (Ist-Ende > Plan-Ende) oder pünktlich fertiggestellt wurde (Ist-Ende <=Plan-Ende). Im Fall zu früher Fertigstellung hat der Auftrag eine positive Terminabweichung (TA). Im Fall zu später Fertigstellung hat der Auftrag eine negative Terminabweichung (TA) oder den Wert Null. Eine positiver Mittelwert (M) bedeutet, dass die Aufträge im Mittel zu spät fertiggestellt werden. Es ist das Ergebnis aus der TA im Zugang und der rel. TA. Eine hohe Standardabweichung (SD) deutet auf instabile Prozesse hin.';

import React from 'react';
import { Button, Modal } from 'semantic-ui-react';

import ProtocolFormTabs from './ProtocolFormTabs';

const centered = {
    textAlign: 'center',
};

const ProtocolModal = props => {
    const { open, formData, setOpen, setFormData } = props;
    const title = formData.id ? 'Protokoll bearbeiten' : 'Protokoll anlegen';
    return (
        <Modal
            size="tiny"
            onClose={() => {
                setFormData(protocolFormInitialState);
                setOpen(false);
            }}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={
                <Button primary fluid>
                    Protokoll anlegen
                </Button>
            }
        >
            <Modal.Header style={centered}>{title}</Modal.Header>
            <Modal.Content>
                <ProtocolFormTabs {...props} />
            </Modal.Content>
        </Modal>
    );
};

export default ProtocolModal;

export const protocolFormInitialState = {
    form_name: '',
    form_description: '',
    article_number: '',
    form_revision_number: '',
    form_valid_from_date: '',
    form_active: true,
    approved_by_qa: false,
};

import React from 'react';
import { Card } from 'semantic-ui-react';
import { getData } from '../UtilComponents/requestsUtils';
import { formatDateToDE } from '../UtilComponents/StandardDatePicker';

const ProofProtocols = ({ setFormData, setOpen, updateProtocols }) => {
    const [data, setData] = React.useState([]);
    React.useEffect(() => {
        const getProofProtocols = async () => {
            try {
                const response = await getData('crud/protocols/get_proof_protocols');
                setData(response);
            } catch (error) {
                console.log(error);
            }
        };
        getProofProtocols();
    }, [updateProtocols]);

    const openForm = formData => {
        setFormData(formData);
        setOpen(true);
    };

    return (
        <>
            <Card.Group>
                {data.map(pp => {
                    return (
                        <Card style={{ cursor: 'pointer' }} onClick={() => openForm(pp)}>
                            <Card.Content>
                                <Card.Header>{pp.form_name}</Card.Header>
                                <Card.Meta>
                                    Rev. {pp.form_revision_number} -- gültig ab:{' '}
                                    {formatDateToDE(pp.form_valid_from_date)}{' '}
                                </Card.Meta>
                                <Card.Description>{pp.form_description}</Card.Description>
                            </Card.Content>
                        </Card>
                    );
                })}
            </Card.Group>
        </>
    );
};

export default ProofProtocols;

import { backendUrl } from '../../config';

const getToken = () => {
    return localStorage.getItem('deepingBiToken');
};

export const getCurrentUserId = () => {
    const token = getToken();
    if (!token) return;
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    const claims = JSON.parse(window.atob(base64));
    return claims.id;
};

const buildFetchOptions = () => {
    const options = {
        mode: 'cors',
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getToken(),
        },
        redirect: 'follow', // manual, *follow, error
    };
    return options;
};

export async function getData(queryId = '', ignoreSqlWrapperHeader = false) {
    const getPath = '/crud/get/';
    let options = buildFetchOptions();
    options.headers['X-IGNORE-SQL-WRAPPER'] = ignoreSqlWrapperHeader;
    const response = await fetch(backendUrl + getPath + queryId, {
        method: 'GET',
        ...options,
    });

    return response.json();
}

export async function insertSimpleData(query = '', body = {}) {
    const postPath = '/crud/post';
    const payload = {
        QueryID: query,
        body: JSON.stringify(body),
    };

    const response = await fetch(backendUrl + postPath, {
        ...buildFetchOptions(),
        method: 'POST',
        body: JSON.stringify(payload),
    });
    return response.json();
}

export async function insertDataWithColateralValues(query = '', body = {}, primaryKey = '', colateralValues = ['']) {
    const postPath = '/crud/post';
    const payload = {
        QueryID: query,
        body: JSON.stringify(body),
        key: primaryKey,
        colateral_values: JSON.stringify(colateralValues),
    };

    const response = await fetch(backendUrl + postPath, {
        ...buildFetchOptions(),
        method: 'POST',
        body: JSON.stringify(payload),
    });
    return response.json();
}

export async function updateData(query = '', body = {}, where = {}) {
    const putPath = '/crud/put';
    const payload = {
        QueryID: query,
        body: JSON.stringify(body),
        where: JSON.stringify(where),
    };
    const response = await fetch(backendUrl + putPath, {
        ...buildFetchOptions(),
        method: 'PUT',
        body: JSON.stringify(payload),
    });
    return response.json();
}

export async function deleteData(query = '', where = {}) {
    const deletePath = '/crud/delete';
    const payload = {
        QueryID: query,
        where: JSON.stringify(where),
    };
    const response = await fetch(backendUrl + deletePath, {
        ...buildFetchOptions(),
        method: 'DELETE',
        body: JSON.stringify(payload),
    });
    return response.json();
}

export async function biRequestWithoutCache(query = '', filters = [], sorts = [], page_size = 0, page_number = 0) {
    const biPath = '/bi';
    const payload = {
        query_id: query,
        filters,
        sorts,
        page_size,
        page_number,
    };

    const opts = buildFetchOptions();
    // disable cache query in the backed for this request
    opts.headers = { ...opts.headers, 'deeping-cache-query': 'false' };

    const response = await fetch(backendUrl + biPath, {
        ...opts,
        method: 'POST',
        body: JSON.stringify(payload),
    });
    return response.json();
}

export async function biRequest(query = '', filters = [], sorts = [], page_size = 0, page_number = 0) {
    const biPath = '/bi';
    const payload = {
        query_id: query,
        filters,
        sorts,
        page_size,
        page_number,
    };

    const opts = buildFetchOptions();
    const response = await fetch(backendUrl + biPath, {
        ...opts,
        method: 'POST',
        body: JSON.stringify(payload),
    });
    return response.json();
}

export async function fetchImageWithAuthentication(imageURI) {
    let objectUrl = '/placeholder.png';
    try {
        if (imageURI !== null) {
            const { headers, ...fetchOptionsWithoutHeaders } = buildFetchOptions();
            const response = await fetch(backendUrl + '/files/' + imageURI, {
                method: 'GET',
                ...fetchOptionsWithoutHeaders,
            });
            if (response.status === 401) {
                localStorage.clear();
                window.location.href = '/';
                return;
            }
            const blob = await response.blob();
            objectUrl = URL.createObjectURL(blob);
            return objectUrl;
        }
    } catch (error) {}
    return objectUrl;
}

export async function multipartRequest(formData) {
    const path = '/multipart';
    const options = buildFetchOptions();
    options.headers = {
        Authorization: 'Bearer ' + getToken(),
    };
    const response = await fetch(backendUrl + path, {
        ...options,
        method: 'POST',
        body: formData,
    });
    return response;
}

export async function sendEmail(payload) {
    const emailPath = '/emails';
    const response = await fetch(backendUrl + emailPath, {
        ...buildFetchOptions(),
        method: 'POST',
        body: JSON.stringify(payload),
    });
    return response;
}

import React from 'react';
import { Card, Label } from 'semantic-ui-react';
import { getData, insertSimpleData, sendEmail } from '../UtilComponents/requestsUtils';
import { formatDateToDE } from '../UtilComponents/StandardDatePicker';

const drag = ev => {
    ev.dataTransfer.setData('id', ev.target.id);
};

const limitTo = (string, length) => {
    if (!string) return;
    if (string.length < length) return string;
    return string.slice(0, length) + '...';
};

export const NotificationCard = props => {
    const {
        id,
        serial_id,
        issue_title,
        issue_type_id,
        issue_type,
        ppsordernumber,
        issue_description,
        issue_creation_date,
        issue_deadline,
        issue_location,
        creator_name,
        users_ids,
        setModalOpen,
        setNotificationFormData,
        departments_status,
        issue_responsible_email,
        creator_email,
        issue_status,
        order_completed_date,
        order_placed_date,
    } = props;

    const getColor = () => {
        if (departments_status) {
            let departments_name_rejected = [];
            let total_not_required = 0;
            let total_approved = 0;
            let approved_total_task = 0;
            let approved_total_done = 0;
            let total_rejected = 0;
            if (issue_type === 'Änderung' || issue_type === 'Sammeländerung' || issue_type === 'Bauabweichung') {
                departments_status.forEach(department => {
                    if (department.status_id === 3) total_not_required++;

                    if (department.status_id === 1) {
                        total_approved++;
                        approved_total_task += department.total;
                        approved_total_done += department.done;
                    }

                    if (department.status_id === 2) {
                        departments_name_rejected.push(department.abbreviation);
                        total_rejected++;
                    }
                });

                let subject = '';
                let template = '';

                if (total_rejected > 0) {
                    if (issue_type === 'Änderung') {
                        subject = 'Änderung abgelehnt:';
                        template = 'meldungen_nach_abgelehnt_aenderung';
                    } else if (issue_type === 'Sammeländerung') {
                        subject = 'Sammeländerung abgelehnt:';
                        template = 'meldungen_nach_abgelehnt_sammelaenderung';
                    } else {
                        subject = 'Bauabweichung abgelehnt:';
                        template = 'meldungen_nach_abgelehnt_bauabweichung';
                    }
                    sendNotification(2, subject, template, departments_name_rejected);
                    return 'red';
                }

                if (
                    total_not_required === departments_status.length ||
                    (total_approved + total_not_required === departments_status.length &&
                        approved_total_task === approved_total_done)
                ) {
                    if (issue_type === 'Änderung') {
                        subject = 'Änderung abgeschlossen:';
                        template = 'meldungen_nach_abgeschlossen_aenderung';
                    } else if (issue_type === 'Sammeländerung') {
                        subject = 'Sammeländerung abgeschlossen:';
                        template = 'meldungen_nach_abgeschlossen_sammelaenderung';
                    } else {
                        subject = 'Bauabweichung abgeschlossen:';
                        template = 'meldungen_nach_abgeschlossen_bauabweichung';
                    }
                    sendNotification(5, subject, template);
                    return 'green';
                }

                if (
                    total_approved + total_not_required === departments_status.length &&
                    approved_total_task > approved_total_done
                ) {
                    if (issue_type === 'Änderung') {
                        subject = 'Änderung freigegeben:';
                        template = 'meldungen_nach_freigegeben_aenderung';
                    } else if (issue_type === 'Sammeländerung') {
                        subject = 'Sammeländerung freigegeben:';
                        template = 'meldungen_nach_freigegeben_sammelaenderung';
                    } else {
                        subject = 'Bauabweichung freigegeben:';
                        template = 'meldungen_nach_freigegeben_bauabweichung';
                    }
                    sendNotification(1, subject, template);
                    return 'yellow';
                }
            }
            if (issue_type === 'Quickchange Extern') {
                total_not_required = 0;
                total_approved = 0;
                approved_total_task = 0;
                approved_total_done = 0;

                departments_status.forEach(department => {
                    if (department.abbreviation === 'AV' || department.abbreviation === 'KO') {
                        if (department.status_id === 3) total_not_required++;
                        if (department.status_id === 1) {
                            total_approved++;
                            approved_total_task += department.total;
                            approved_total_done += department.done;
                        }
                        if (department.status_id === 2) {
                            departments_name_rejected.push(department.abbreviation);
                            total_rejected++;
                        }
                    }
                });

                if (
                    total_not_required === 2 ||
                    (total_approved + total_not_required === 2 && approved_total_task === approved_total_done)
                ) {
                    sendNotification(
                        5,
                        'Quickchange Extern abgeschlossen:',
                        'meldungen_nach_abgeschlossen_quickchange',
                    );
                    return 'green';
                }
                if (total_approved + total_not_required === 2 && approved_total_task > approved_total_done) {
                    // sendNotification(1, 'Quickchange Extern freigegeben:', 'meldungen_nach_freigegeben');
                    return 'yellow';
                }
            }
        }

        return 'grey';
    };

    const getOpenTask = () => {
        let openTask = [];
        departments_status.forEach(department => {
            if (
                ((department.status_id == null || department.status_id == 1) && department.done < department.total) ||
                (department.total == 0 && department.done == 0 && department.status_id == null) ||
                department.status_id == 4
            ) {
                if (issue_type === 'Änderung' || issue_type === 'Sammeländerung' || issue_type === 'Bauabweichung') {
                    openTask.push(department.abbreviation);
                }
                if (
                    issue_type === 'Quickchange Extern' &&
                    (department.abbreviation === 'AV' || department.abbreviation === 'KO')
                ) {
                    openTask.push(department.abbreviation);
                }
            }
        });
        return openTask;
    };

    const sendNotification = async (status_id, subject, template_id, departments_name_rejected = []) => {
        const emailStatus = await getData('crud/changes/get_email_status?issue_id=' + id);
        if (emailStatus[0].email_sent_status[0] == null || !emailStatus[0].email_sent_status.includes(status_id)) {
            const issueInfoRespose = await getData(`crud/issues/get_issue_info_for_email?issue_id=${id}`);
            const issueDetail = issueInfoRespose[0];
            subject += ' ' + issueDetail.serial_id + ' - ' + issueDetail.issue_title;
            if (status_id === 1) {
                const actionsWithResponsible = await getData(
                    'crud/changes/get_actions_for_email_by_issue_id?issue_id=' + id,
                );
                const articleNumbers = issueDetail.article_numbers ? issueDetail.article_numbers.join(', ') : '-';
                const body = `<h3>Titel:</h3>${issueDetail.issue_title}<br/><br/>
                            <h3>Verantwortlicher:</h3>${issueDetail.responsible_name}<br/><br/>
                            <h3>Beschreibung der Änderung​sgrundes:</h3>${issueDetail.change_reasons_description}<br/><br/>
                            <h3>Beschreibung der Änderung:</h3>${issueDetail.change_description}<br/><br/>
                            <h3>Umgang mit Restmaterial​​:</h3>${issueDetail.rest_material_action}<br/><br/>
                            <h3>Zu ändernde Artikel​:</h3>${articleNumbers}<br/><br/>
                            `;
                actionsWithResponsible.forEach(item => {
                    const taskAsHTML = item.tasks.map(task => `<li>${task}</li>`).join('');
                    const emailTask = `<h3>Zugewiesene Aufgabe:</h3>
                                                    <ul>${taskAsHTML}</ul>`;
                    const payload = {
                        template_id: template_id,
                        to: [item.responsible_email],
                        subject: subject,
                        body: body + emailTask,
                    };
                    sendEmail(payload);
                });
            }
            if (status_id === 2) {
                // let to = ['arteta@deeping.de', 'garcia@deeping.de', 'roehrs@deeping.de', creator_email];
                // TODO: Please uncomment when it is deployed to production.
                let to = [
                    't.lipsius@krebsundaulich.de',
                    'berechnung@krebsundaulich.de',
                    'c.sievert@krebsundaulich.de',
                    'r.moshake@krebsundaulich.de',
                    'arbeitsvorbereitung@krebsundaulich.de',
                    'konstruktion@krebsundaulich.de​',
                    's.krummhaar@krebsundaulich.de',
                    't.krug@krebsundaulich.de',
                    'y.fulst@krebsundaulich.de',
                    'mechanik@krebsundaulich.de',
                    'einkauf@krebsundaulich.de',
                    creator_email,
                ];
                if (issue_responsible_email) {
                    to.push(issue_responsible_email);
                }
                departments_name_rejected.forEach(async department => {
                    const issueRejectReason = await getData(
                        `crud/issues/get_issue_reject_reason_for_email?issue_id=${id}&abbreviation=${department}`,
                    );
                    const body = `Der Antrag wurde von Abteilung <b>${department}</b> abgelehnt <br/>Ablehnungsgrund: ${issueRejectReason[0].reason_rejection}<br/>`;
                    const payload = {
                        template_id: template_id,
                        to: to,
                        subject: subject,
                        body: body,
                    };
                    sendEmail(payload);
                });
            }

            if (status_id === 5) {
                let to = [creator_email];
                if (issue_responsible_email) {
                    to.push(issue_responsible_email);
                }
                const body = `<h2>${serial_id} - ${issue_type} - ${issueDetail.issue_title}</h2>
                                Alle Aufgaben sind abgeschlossen.<br/>`;
                const payload = {
                    template_id: template_id,
                    to: to,
                    subject: subject,
                    body: body,
                };
                sendEmail(payload);
            }
            insertSimpleData('crud/changes/post_email_send', { issue_id: id, email_status: status_id });
        }
    };

    const handleClick = () => {
        setNotificationFormData({
            id,
            issue_title,
            issue_type_id,
            issue_type,
            ppsordernumber,
            issue_description,
            issue_creation_date,
            issue_deadline,
            issue_location,
            serial_id,
            issue_status,
            creator_email,
        });
        setModalOpen(true);
    };
    const getArticleOrderColor = () => {
        if (order_placed_date && !order_completed_date) return 'yellow';
        if (order_completed_date) return 'green';
        return 'grey';
    };
    return (
        <Card fluid draggable="true" onDragStart={drag} id={id}>
            <Card.Content onClick={handleClick}>
                <Card.Header style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {serial_id} - {issue_title}
                    {(issue_type === 'Änderung' ||
                        issue_type === 'Sammeländerung' ||
                        issue_type === 'Quickchange Extern' ||
                        issue_type === 'Bauabweichung') && (
                        <div style={{ width: '30px' }}>
                            <Label color={getColor()} style={{ width: '30px', height: '30px' }}></Label>
                        </div>
                    )}
                    {issue_type === 'Artikelantrag' && (
                        <div style={{ width: '30px' }}>
                            <Label color={getArticleOrderColor()} style={{ width: '30px', height: '30px' }}></Label>
                        </div>
                    )}
                </Card.Header>
                <Card.Description>
                    {ppsordernumber} - {limitTo(issue_description, 80)}
                    <br /> <br />
                    {(issue_type === 'Änderung' ||
                        issue_type === 'Sammeländerung' ||
                        issue_type === 'Quickchange Extern' ||
                        issue_type === 'Bauabweichung') && (
                        <div>
                            <b>Offene Aufgaben: </b>
                            <div style={{ marginTop: '5px' }}>
                                {getOpenTask().map(task => (
                                    <span style={{ marginRight: '15px' }}>{task}</span>
                                ))}
                            </div>
                        </div>
                    )}
                </Card.Description>
                <br />
                {/* <Card.Meta>{users_ids.length} Benutzer</Card.Meta> */}
            </Card.Content>

            <br />
            <br />
            <Label attached="bottom left">
                {creator_name} - {issue_type} - {formatDateToDE(issue_creation_date)}
            </Label>
            <Label attached="bottom right">{formatDateToDE(issue_deadline)}</Label>
        </Card>
    );
};

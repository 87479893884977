import React from 'react';
import { Segment, Grid } from 'semantic-ui-react';
import styled from 'styled-components';

import FinishedPOTable from './TableFinishedProdutionOrders/FinishedPOTable';
import FPOTable from './TableFreeProdutionOrders/FPOTable';
import PAStatistics from './PAStatistics';
import PAMengeStatistics from './PAMengeStatistics';
import IstPlanStatistics from './IstPlanArbeitStatistics';
import PAAnzahl from './PAAnzahl';

import FPOFilters from './FPOFilters';

const Digital8HourView = () => {
    const [activeFilters, setActiveFilters] = React.useState([]);
    const activeFiltersRef = React.useRef(activeFilters); // important to keep filters in event listener up to date

    const addActiveFilter = filter => {
        const filterIndex = activeFiltersRef.current.findIndex(obj => obj['attribute'] === filter['attribute']);
        if (filterIndex === -1) {
            activeFiltersRef.current = [...activeFiltersRef.current, filter];
            setActiveFilters([...activeFiltersRef.current]);
        } else {
            activeFiltersRef.current[filterIndex] = filter;
            setActiveFilters([...activeFiltersRef.current]);
        }
    };

    const removeActiveFilter = filter => {
        const filterIndex = activeFilters.findIndex(obj => obj['attribute'] === filter['attribute']);
        activeFiltersRef.current.splice(filterIndex, 1);
        setActiveFilters([...activeFiltersRef.current]);
    };

    // for Searchable filters
    const handleFiltersChange = (evt, data) => {
        const newState = data.value;
        // case remove filter completely
        if (newState.length === 0) {
            removeActiveFilter({ attribute: data.name, value: data.value, operator: 'IN' });
        } else {
            if (data.name == 'abteilung') {
                const obj = data.options.find(obj => obj.value === data.value);
                addActiveFilter({ attribute: 'resourcegroupid', value: obj.rg_ids, operator: 'IN' });
            } else {
                addActiveFilter({ attribute: data.name, value: data.value, operator: 'IN' });
            }
        }
    };

    return (
        <TabContent>
            <MenuWrapper>
                <FPOFilters
                    activeFilters={activeFilters}
                    addActiveFilter={addActiveFilter}
                    removeActiveFilter={removeActiveFilter}
                    handleFiltersChange={handleFiltersChange}
                />
            </MenuWrapper>
            <ContentWrapper>
                <FPOTable filters={activeFilters} setFilter={addActiveFilter} />
                <FinishedPOTable filters={activeFilters} />
                <Grid>
                    <Grid.Column width={8}>
                        <PAStatistics
                            filters={activeFilters}
                            addActiveFilter={addActiveFilter}
                            height={200}
                            title="PA-Wochenüberblick"
                        />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <PAMengeStatistics
                            filters={activeFilters}
                            addActiveFilter={addActiveFilter}
                            height={200}
                            title="PA-Mengenüberblick"
                        />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <IstPlanStatistics
                            filters={activeFilters}
                            addActiveFilter={addActiveFilter}
                            height={200}
                            title="Zeitabweichung Ist-Plan"
                        />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <PAAnzahl
                            filters={activeFilters}
                            addActiveFilter={addActiveFilter}
                            height={200}
                            title="PA-Anzahl"
                        />
                    </Grid.Column>
                </Grid>
            </ContentWrapper>
        </TabContent>
    );
};

const MenuWrapper = styled.div`
    flex: 0 0 auto;
    position: relative;
    margin-right: 1rem;
    width: 230px;
    height: auto;
`;

const TabContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

const ContentWrapper = styled(Segment)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 0 1 auto;
    width: calc(100vw - 270px);
    height: 98%;
`;

export default Digital8HourView;

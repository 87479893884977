import React from 'react';
import styled from 'styled-components';
import { formatDate, formatDecimal, isColumnSorted, setSortIcon } from '../../../Utils';

export const buildTableColumns = (setFilter, setSort, sorts, handleCellClick) => {
    return [
        {
            XlsHeader: 'Kategorie',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'categorie')
                            ? setSortIcon(sorts, 'categorie') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'categorie',
                            Attribute: 'categorie',
                            Order: 'asc',
                        })
                    }
                >
                    Kategorie
                </SortableHeader>
            ),
            accessor: 'category',
        },
        {
            XlsHeader: 'PA-Nummer',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'ppsordernumber')
                            ? setSortIcon(sorts, 'ppsordernumber') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'ppsordernumber',
                            Attribute: 'ppsordernumber',
                            Order: 'asc',
                        })
                    }
                >
                    PA-Nummer
                </SortableHeader>
            ),
            accessor: 'ppsordernumber',
        },
        {
            XlsHeader: 'Mitarbeiter',
            isClickable: true,
            handleCellClick: handleCellClick,
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'responsible_name')
                            ? setSortIcon(sorts, 'responsible_name') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'responsible_name',
                            Attribute: 'responsible_name',
                            Order: 'asc',
                        })
                    }
                >
                    Mitarbeiter
                </SortableHeader>
            ),
            accessor: 'responsible_name',
        },
        {
            XlsHeader: 'Bezeichnung',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'designation')
                            ? setSortIcon(sorts, 'designation') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'designation',
                            Attribute: 'designation',
                            Order: 'asc',
                        })
                    }
                >
                    Bezeichnung
                </SortableHeader>
            ),
            accessor: 'designation',
        },
        {
            XlsHeader: 'Artikelnummer',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'article_number')
                            ? setSortIcon(sorts, 'article_number') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'article_number',
                            Attribute: 'article_number',
                            Order: 'asc',
                        })
                    }
                >
                    Artikelnummer
                </SortableHeader>
            ),
            accessor: 'article_number',
            Cell: ({ cell: { value } }) => (
                <a
                    onClick={() => {
                        setFilter({
                            nameToDisplay: 'Artikelnummer',
                            attribute: 'article_number',
                            operator: '=',
                            value: [value],
                        });
                    }}
                >
                    {value}
                </a>
            ),
        },
        {
            XlsHeader: 'Artikelgruppe',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'article_group')
                            ? setSortIcon(sorts, 'article_group') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'article_group',
                            Attribute: 'article_group',
                            Order: 'asc',
                        })
                    }
                >
                    Artikelgruppe
                </SortableHeader>
            ),
            accessor: 'article_group',
        },
        {
            XlsHeader: 'Auftragsmenge',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'quantity')
                            ? setSortIcon(sorts, 'quantity') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'quantity',
                            Attribute: 'quantity',
                            Order: 'asc',
                            Value: '4',
                        })
                    }
                >
                    Auftragsmenge
                </SortableHeader>
            ),
            accessor: 'quantity',
        },
        {
            XlsHeader: 'Plan-Start',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'plan_start')
                            ? setSortIcon(sorts, 'plan_start') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'plan_start',
                            Attribute: 'plan_start',
                            Order: 'asc',
                        })
                    }
                >
                    Plan-Start
                </SortableHeader>
            ),
            accessor: 'plan_start',
            Cell: ({ cell: { value } }) => formatDate(value),
        },
        {
            XlsHeader: 'Plan-Ende',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'plan_ende')
                            ? setSortIcon(sorts, 'plan_ende') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'plan_ende',
                            Attribute: 'plan_ende',
                            Order: 'asc',
                        })
                    }
                >
                    Plan-Ende
                </SortableHeader>
            ),
            accessor: 'plan_ende',
            Cell: ({ cell: { value } }) => formatDate(value),
        },
        {
            XlsHeader: 'Plan-Arbeit',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'plan_arbeit')
                            ? setSortIcon(sorts, 'plan_arbeit') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'plan_arbeit',
                            Attribute: 'plan_arbeit',
                            Order: 'asc',
                        })
                    }
                >
                    Plan-Arbeit
                </SortableHeader>
            ),

            accessor: 'plan_arbeit',
            Cell: ({ cell: { value } }) => formatDecimal(value),
        },
        {
            XlsHeader: 'Ist-Start',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'ist_start')
                            ? setSortIcon(sorts, 'ist_start') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'ist_start',
                            Attribute: 'ist_start',
                            Order: 'asc',
                        })
                    }
                >
                    Ist-Start
                </SortableHeader>
            ),
            accessor: 'ist_start',
            Cell: ({ cell: { value } }) => formatDate(value),
        },
        {
            XlsHeader: 'Ist-Arbeit',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'ist_arbeit')
                            ? setSortIcon(sorts, 'ist_arbeit') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'ist_arbeit',
                            Attribute: 'ist_arbeit',
                            Order: 'asc',
                        })
                    }
                >
                    Ist-Arbeit
                </SortableHeader>
            ),
            accessor: 'ist_arbeit',
            Cell: ({ cell: { value } }) => formatDecimal(value),
        },
        {
            XlsHeader: 'Ist-Plan-Arbeit',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'ist_plan_arbeit')
                            ? setSortIcon(sorts, 'ist_plan_arbeit') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'ist_plan_arbeit',
                            Attribute: 'ist_plan_arbeit',
                            Order: 'asc',
                        })
                    }
                >
                    Ist-Plan-Arbeit
                </SortableHeader>
            ),
            accessor: 'ist_plan_arbeit',
        },

        {
            XlsHeader: 'Ressourcengruppen',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'resource_groups')
                            ? setSortIcon(sorts, 'resource_groups') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                >
                    Ressourcengruppen
                </SortableHeader>
            ),
            accessor: 'resource_groups',
        },
    ];
};

const SortableHeader = styled.span`
    cursor: pointer;
    &:after {
        content: '';
        border-width: 0 4px 4px;
        margin: 0px 4px;
        border-style: solid;
        border-color: #404040 transparent;
        display: inline-block;
        vertical-align: middle;
        visibility: hidden;
    }
    &.asc:after {
        border-width: 0 4px 4px;
        visibility: visible;
    }
    &.desc:after {
        border-width: 4px 4px 0;
        visibility: visible;
    }
`;

import React from 'react';
import { Message, Icon } from 'semantic-ui-react';

export const ValidationErrorMessage = ({ message }) => {
    return (
        <Message data-testid="validation-error-message" warning icon>
            <Icon name="exclamation circle" />
            <Message.Content>
                <Message.Header>{message.header}</Message.Header>
                <p>{message.description}</p>
            </Message.Content>
        </Message>
    );
};

export const SuccessMessage = ({ message }) => {
    return (
        <Message data-testid="success-message" positive icon compact>
            <Icon name="check circle" />
            <Message.Content>
                <Message.Header>{message.header}</Message.Header>
                <p>{message.description}</p>
            </Message.Content>
        </Message>
    );
};

export const FaliureMessage = ({ message }) => {
    if (message === undefined) return '';
    return (
        <Message data-testid="failure-message" negative icon compact>
            <Icon name="x" />
            <Message.Content>
                <Message.Header>{message.header}</Message.Header>
                <p>{message.description}</p>
            </Message.Content>
        </Message>
    );
};

import React, { useEffect, useState, useMemo } from 'react';
import { get } from 'lodash';
import { formatDecimal, loadData } from '../../Utils';
import { AnzahlAuftraege } from './TooltipsTexts';
import { ChartLabelColors } from '../../config';
import { queryOrdersK1, queryOrdersK2 } from '../Queries';
import { PieWidgetFrame, KNZ, GWTitle } from '../../Utils/widgetsStyles';
import { DiscreteColorLegend, RadialChart } from 'react-vis';
import Modal from '../../Utils/Modal';
import Tooltip from '../../Utils/Tooltip';

const Comp = props => {
    const { filters, setFilter, title, width, height, modalOpen, sessionID, userID } = props;
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [kf, SetKf] = useState(0);
    const [loadingKF, setLoadingKf] = useState(false);

    useEffect(() => {
        //const filtersToUse = filteredPAs.length == 1 ? filteredPAs : filters;
        loadData(setLoadingKf, SetKf, queryOrdersK1, filters, sessionID, userID, 0);
        loadData(setLoading, setData, queryOrdersK2, filters, sessionID, userID, 0);
    }, [filters, sessionID, userID]);
    return useMemo(() => {
        return (
            <PieWidgetFrame loading={loading}>
                <KNZ>
                    <GWTitle>
                        <Tooltip text={AnzahlAuftraege}></Tooltip>
                        <span>{title}</span>
                        {modalOpen ? <span></span> : <Modal graphWidget={<Comp />} config={props} />}
                    </GWTitle>
                    <span className="val">{formatDecimal(get(kf, '0.anzahl', 0))}</span>
                </KNZ>
                <RadialChart
                    colorType="literal"
                    width={width}
                    height={height || 200}
                    data={data.map((d, idx) => ({
                        angle: d.anzahl,
                        color: ChartLabelColors[idx],
                        radius: 30,
                        innerRadius: 15,
                        status: d.Status,
                    }))}
                    onValueClick={datapoint => {
                        setFilter({
                            id: 'Status',
                            Attribute: 'Status',
                            Operator: '=',
                            Value: `'${datapoint.status}'`,
                        });
                    }}
                >
                    <DiscreteColorLegend
                        style={{ position: 'absolute', top: '-10px', left: '10px' }}
                        orientation="horizontal"
                        items={data.map((d, idx) => ({
                            title: `${get(d, 'Status', '') || 'nicht spezifiziert'} (${formatDecimal(
                                get(d, 'anzahl', 0) || 0,
                            )})`,
                            color: ChartLabelColors[idx],
                        }))}
                    />
                </RadialChart>
            </PieWidgetFrame>
        );
    }, [data, loading, loadingKF, width]);
};

export default Comp;

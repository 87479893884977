import React from 'react';
import { Tab } from 'semantic-ui-react';
import styled from 'styled-components';
import OrderView from '../OrderView';
import ResourcesView from '../ResourcesView';
import TimeLineView from '../TimeLineView';
import SettingsView from '../App/SettingsView';
import ResourcesPlanView from '../App/ResourcesPlanView';
import { NotificationsView } from '../App/NotificationsView';
import QualityManagementView from '../App/QualityManagementView';
import NotificationStatisticsView from '../App/NotificationStatisticsView';
import { getCurrentUserId, getData } from '../App/UtilComponents/requestsUtils';
import ProtocolsView from '../App/ProtocolsView';
import Digital8HourView from '../App/Digital8Hour.js';

const panes = (props, allowedTabs) => {
    let tabsToShow = [];

    if (allowedTabs.includes('auftragssicht')) {
        tabsToShow.push({
            menuItem: 'Auftragssicht',
            render: () => (
                <Tab.Pane attached={false}>
                    <OrderView {...props} tab="Auftragssicht" />
                </Tab.Pane>
            ),
        });
    }

    if (allowedTabs.includes('arbeitssystemsicht')) {
        tabsToShow.push({
            menuItem: 'Arbeitssystemsicht',
            render: () => (
                <Tab.Pane attached={false}>
                    <ResourcesView {...props} tab="Arbeitssystemsicht" />
                </Tab.Pane>
            ),
        });
    }

    if (allowedTabs.includes('ta_plan_ist')) {
        tabsToShow.push({
            menuItem: 'TA-Plan-Ist',
            render: () => (
                <Tab.Pane attached={false}>
                    <TimeLineView {...props} tab="TA-Plan-Ist" />
                </Tab.Pane>
            ),
        });
    }

    if (allowedTabs.includes('meldungen')) {
        tabsToShow.push({
            menuItem: 'Meldungen',
            render: () => (
                <Tab.Pane attached={false}>
                    <NotificationsView {...props} tab="ShopFloorNotifications" />
                </Tab.Pane>
            ),
        });
    }

    if (allowedTabs.includes('notification_statistics')) {
        tabsToShow.push({
            menuItem: 'Meldungsstatistiken',
            render: () => (
                <Tab.Pane attached={false}>
                    <NotificationStatisticsView {...props} tab="Meldungsstatistiken" />
                </Tab.Pane>
            ),
        });
    }

    if (allowedTabs.includes('quality_management')) {
        tabsToShow.push({
            menuItem: 'Qualitätsmanagement',
            render: () => (
                <Tab.Pane attached={false}>
                    <QualityManagementView {...props} tab="QualityManagement" />
                </Tab.Pane>
            ),
        });
    }

    if (allowedTabs.includes('ressourcen_planung')) {
        tabsToShow.push({
            menuItem: 'Ressourcen Planung',
            render: () => (
                <Tab.Pane attached={false}>
                    <ResourcesPlanView {...props} tab="ResourcesPlan" />
                </Tab.Pane>
            ),
        });
    }

    if (allowedTabs.includes('settings')) {
        tabsToShow.push({
            menuItem: 'Settings',
            render: () => (
                <Tab.Pane attached={false}>
                    <SettingsView {...props} tab="Settings" />
                </Tab.Pane>
            ),
        });
    }

    if (allowedTabs.includes('protokolle')) {
        tabsToShow.push({
            menuItem: 'Protokolle',
            render: () => (
                <Tab.Pane attached={false}>
                    <ProtocolsView {...props} tab="Protocols" />
                </Tab.Pane>
            ),
        });
    }

    if (allowedTabs.includes('digital_8_hour')) {
        tabsToShow.push({
            menuItem: 'Fertigung',
            render: () => (
                <Tab.Pane attached={false}>
                    <Digital8HourView />
                </Tab.Pane>
            ),
        });
    }

    return tabsToShow;
};

const Tabs = props => {
    const [userAllowedTabs, setUserAllowedTabs] = React.useState([]);
    React.useEffect(() => {
        const getUserPermissions = async () => {
            try {
                const response = await getData(`crud/settings/get_user_permissions?user_id=${getCurrentUserId()}`);
                let tabs = [];
                for (var key in response[0]) {
                    if (response[0][key] === true) {
                        tabs.push(key);
                    }
                }
                setUserAllowedTabs(tabs);
            } catch (error) {}
        };
        getUserPermissions();
    }, []);

    return (
        <Wrapper>
            <Tab panes={panes(props, userAllowedTabs)} />
        </Wrapper>
    );
};

export default Tabs;

const Wrapper = styled.div`
    display: flex;
    padding-top: 73px;
`;

import React from 'react';
import { Form, Button, Modal, Tab } from 'semantic-ui-react';
import 'regenerator-runtime/runtime';
import MultiSelectWithSearch from '../../../UtilComponents/MultiSelectWithSearch';
import SelectResponsible from './SelectResponsible';
import CommentForm from './CommentForm';

const SelectResponsibleModal = ({ open, setOpen, orderResponsible, setRefreshTable }) => {
    const openModal = () => {
        setOpen(true);
    };

    const closeModal = () => {
        setOpen(false);
        setRefreshTable('closeModal' + Date.now().toString());
    };

    const panes = [
        {
            menuItem: 'Zuweisung',
            render: () => (
                <Tab.Pane>
                    <SelectResponsible orderResponsible={orderResponsible}></SelectResponsible>
                    <br />
                    <Button fluid primary onClick={closeModal}>
                        Schließen
                    </Button>
                </Tab.Pane>
            ),
        },
        {
            menuItem: 'Kommentare',
            render: () => (
                <Tab.Pane>
                    <CommentForm orderNumber={orderResponsible.orderNumber}></CommentForm>
                </Tab.Pane>
            ),
        },
    ];

    return (
        <Modal
            closeIcon
            closeOnDimmerClick={false}
            size="large"
            open={open}
            onOpen={openModal}
            onClose={closeModal}
            style={{ width: 650 }}
        >
            <Modal.Header style={{ textAlign: 'center' }}>Mitarbeiter zuweisen</Modal.Header>
            <Modal.Content>
                <Tab panes={panes}></Tab>
            </Modal.Content>
        </Modal>
    );
};

export default SelectResponsibleModal;

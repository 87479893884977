import React from 'react';
import { Button, Modal, Form } from 'semantic-ui-react';
import SInput from '../UtilComponents/SearchableInput';
import { queryStorageLocations, queryResourcesStatus } from './Queries';
import { insertSimpleData, updateData } from '../UtilComponents/requestsUtils';
import {
    resourceFormDataInitialState,
    validCreationInputsInitialState,
    isNameValid,
    isDescriptionValid,
} from './rpUtils';
import { queryGetResourcesCategories } from './RCategoriesModal';

const centered = {
    textAlign: 'center',
};

function ResourceModal(props) {
    const { open, setOpen, formData, setFormData, formAction, setFormAction, setRefreshTimeLine } = props;
    const [validInputs, setValidInputs] = React.useState(validCreationInputsInitialState);
    const [loading, setLoading] = React.useState(false);

    const title = formAction === 'creation' ? 'Tool anlegen' : 'Tool bearbeiten';

    const handleChange = (event, data) => {
        const newObjState = Object.assign({}, formData, { [data.name]: data.value });
        setFormData(newObjState);
    };

    const validateName = () => {
        const { resource_name } = formData;
        const newObjState = Object.assign({}, validInputs, { resource_name: isNameValid(resource_name) });
        setValidInputs(newObjState);
    };

    const validateDescription = () => {
        const { resource_description } = formData;
        const newObjState = Object.assign({}, validInputs, {
            resource_description: isDescriptionValid(resource_description),
        });
        setValidInputs(newObjState);
    };

    const isFormValid = () => {
        if (!Object.values(validInputs).every(i => i === true)) return false;
        // just barcode is not required initially
        const { resource_barcode, ...rest } = formData;
        if (!Object.values(rest).every(i => i !== '')) return false;
        return true;
    };

    // handling form submition
    const submitForm = () => {
        formAction === 'creation' ? postNewResource() : updateResource();
    };

    const postNewResource = async () => {
        setLoading(true);
        try {
            await insertSimpleData('crud/resources_plan/new_resource', formData);
            setLoading(false);
            setFormData(resourceFormDataInitialState);
            setOpen(false);
            setRefreshTimeLine('afterCreation' + Date.now().toString());
        } catch (error) {
            console.log(error);
        }
    };

    const updateResource = async () => {
        setLoading(true);
        const { id, ...rest } = formData;
        try {
            await updateData('crud/resources_plan/update_resource', rest, { id: id });
            setLoading(false);
            setFormData(resourceFormDataInitialState);
            setOpen(false);
            setRefreshTimeLine('afterUpdate' + Date.now().toString());
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Modal
            size="mini"
            onClose={() => {
                setFormAction('creation');
                setFormData(resourceFormDataInitialState);
                setOpen(false);
            }}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={
                <Button primary fluid>
                    Tool anlegen
                </Button>
            }
        >
            <Modal.Header style={centered}>{title}</Modal.Header>
            <Modal.Content>
                <Form loading={loading}>
                    <Form.Input
                        label="Benennung"
                        name="resource_name"
                        value={formData.resource_name}
                        required
                        onChange={handleChange}
                        onBlur={validateName}
                        error={!validInputs.resource_name}
                    />
                    <Form.Input
                        label="Matchcode"
                        name="resource_barcode"
                        value={formData.resource_barcode}
                        onChange={handleChange}
                    />
                    <Form.TextArea
                        label="Beschreibung"
                        name="resource_description"
                        value={formData.resource_description}
                        required
                        onChange={handleChange}
                        onBlur={validateDescription}
                        error={!validInputs.resource_description}
                    />
                    <SInput
                        label="Kategorie"
                        placeholder="Kategorie auswählen"
                        name="resource_category_id"
                        value={formData.resource_category_id}
                        query={queryGetResourcesCategories}
                        onChange={handleChange}
                    />
                    <SInput
                        label="Lagerort"
                        placeholder="Lagerort auswählen"
                        name="storage_location_id"
                        value={formData.storage_location_id}
                        query={queryStorageLocations}
                        onChange={handleChange}
                    />
                    <SInput
                        label="Ist-Status"
                        placeholder="Aktueller Ressourcestatus auswählen"
                        name="status_id"
                        value={formData.status_id}
                        query={`${queryResourcesStatus}?id=1&id=2&id=3`}
                        onChange={handleChange}
                    />
                </Form>
                <br />
                <Button fluid primary onClick={submitForm} disabled={!isFormValid() || loading}>
                    Speichern
                </Button>
            </Modal.Content>
        </Modal>
    );
}

export default ResourceModal;

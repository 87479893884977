import React from 'react';
import { List, Icon } from 'semantic-ui-react';
import { getData } from '../UtilComponents/requestsUtils';

const FieldItems = ({ formData, formGroupId, setActiveTab, setFormFieldId }) => {
    const formId = formData.id;
    const [data, setData] = React.useState([]);
    React.useEffect(() => {
        const getFormFields = async () => {
            try {
                const response = await getData(
                    `crud/protocols/get_form_fields?form_id=${formId}&form_field_group_id=${formGroupId}`,
                );
                setData(response);
            } catch (error) {
                console.log(error);
            }
        };
        getFormFields();
    }, []);

    const handleFormFieldEdition = ffid => {
        setFormFieldId(ffid);
        setActiveTab(2);
    };

    return (
        <List selection>
            {data.map(item => {
                return (
                    <List.Item key={item.form_field_id}>
                        <List.Content floated="right">
                            <Icon name="edit" onClick={() => handleFormFieldEdition(item.form_field_id)} />
                        </List.Content>
                        <List.Icon name="ticket alternate" size="large" verticalAlign="middle" />
                        <List.Content>
                            <List.Header as="a">{item.form_field_name}</List.Header>
                            <List.Description as="b">
                                {item.form_field_type_id === 2 ? 'Nummer' : 'Text'}
                            </List.Description>
                        </List.Content>
                    </List.Item>
                );
            })}
        </List>
    );
};

export default FieldItems;

import dayjs from 'dayjs';
import { get, find, findIndex } from 'lodash';
import { MD5 } from 'crypto-js';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import request from 'superagent';
import { backendUrl } from '../config';

dayjs.extend(customParseFormat);

export const get3rdSize = () => {
    return Math.round((window.innerWidth - 224) / 3) - 40;
};
export const formatDecimal = (val, dec) => {
    if (dec === null || dec === undefined) {
        dec = 2;
    }
    return val != undefined && val != null ? parseFloat(Math.round(val * 100) / 100).toLocaleString('de-DE') : '';
};
export const formatTimeStamp = val => {
    const d = dayjs(val, 'YYYY-MM-DDTHH:mm:ss');
    return d.isValid() ? d.format('DD.MM.YYYY HH:mm') : '';
};
export const formatDate = val => {
    const d = dayjs(val, 'YYYY-MM-DD');
    return d.isValid() ? d.format('DD.MM.YYYY') : val;
};
export const getDayStringFromDate = val => {
    const d = val ? dayjs(val) : dayjs();
    return d.format('YYYY-MM-DD');
};
export const getDateFromString = val => {
    return dayjs(val, 'YYYY-MM-DD').toDate();
};
export const getDateFromStringDE = val => {
    return dayjs(val, 'DD.MM.YYYY').toDate();
};
export const getRangeValues = (datapointValue, bucketsize) => {
    console.log('--', datapointValue, bucketsize);
    return {
        lower: datapointValue,
        upper:
            bucketsize > 1
                ? parseInt(datapointValue) + (parseInt(bucketsize) - (parseInt(datapointValue) < 0 ? -1 : 1))
                : parseInt(datapointValue),
    };
};
export const loadData = (setLoading, setData, query, filters, sessionID, userID, retry) => {
    const url = `${backendUrl}/biqueries`;
    const payload = {
        QueryID: query,
        Filter: filters
            .filter(k => get(k, 'Attribute', '') != 'limit')
            .map(k => ({
                Attribute: `"${get(k, 'Attribute', '')}"`,
                Operator: get(k, 'Operator', '='),
                Value: get(k, 'Value', ''),
            })),
        Sort: [],
        Limit: parseInt(get(filters.filter(k => get(k, 'Attribute', '') === 'limit')[0] || {}, 'Value', 10000), 10),
        Offset: 0,
    };
    if (sessionID.length > 2) {
        setLoading(true);
        request
            .post(url)
            .set('content-type', 'application/json')
            .set('X-Deepingbi-User', userID)
            .set('Authorization', 'Bearer ' + sessionID)
            .set('X-Deeping-Ui', '')
            .set('Accept', 'application/json')
            .timeout(30000)
            .send(payload)
            .then(resp => {
                setData(get(resp, 'body', []));
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
            });
    }
};

export const loadTableData = (setLoading, setData, query, filters, sorts, sessionID, userID, retry) => {
    const url = `${backendUrl}/biqueries`;
    const payload = {
        QueryID: query,
        Filter: filters
            .filter(k => get(k, 'Attribute', '') != 'limit')
            .map(k => ({
                Attribute: `"${get(k, 'Attribute', '')}"`,
                Operator: get(k, 'Operator', '='),
                Value: get(k, 'Value', ''),
            })),
        Sort: sorts.map(k => ({
            Attribute: `"${get(k, 'Attribute', '')}"`,
            Order: get(k, 'Order', 'asc'),
            //Sortpos: get(k, 'Sortpos', findIndex(sorts, k) + 1),
        })),
        Limit: parseInt(get(filters.filter(k => get(k, 'Attribute', '') === 'limit')[0] || {}, 'Value', 10000), 10),
        Offset: 0,
    };
    if (sessionID.length > 2) {
        setLoading(true);
        request
            .post(url)
            .set('content-type', 'application/json')
            .set('X-Deepingbi-User', userID)
            .set('Authorization', 'Bearer ' + sessionID)
            .set('X-Deeping-Ui', '')
            .set('Accept', 'application/json')
            .timeout(30000)
            .send(payload)
            .then(resp => {
                setData(get(resp, 'body', []));
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
            });
    }
};

export const isColumnSorted = (sorts, id) => {
    const sort = find(sorts, { id: id });
    if (sort === undefined) return false;
    return true;
};
export const setSortIcon = (sorts, id) => {
    const sort = find(sorts, { id: id });
    return sort.Order;
};

export const setDataWithClasses = (d, setData, sourceComp) => {
    let minVal = 999999;
    let maxVal = minVal * -1;
    let bucketSize = minVal * 2;
    let bucketsRaw = {};
    d.forEach(ds => {
        const v = get(ds, 'bucketfrom', 0) || 0;
        bucketSize = get(ds, 'bucketsize', 0) || bucketSize;
        if (v < minVal) {
            minVal = v;
        }
        if (v > maxVal) {
            maxVal = v;
        }
        bucketsRaw[`v${MD5(v.toString())}`] = get(ds, 'count', 0);
    });
    const buckets = [];
    for (let i = minVal; i <= maxVal; i += bucketSize) {
        buckets.push({ x: i, y: get(bucketsRaw, `v${MD5(i.toString())}`, 0), bucketSize });
    }
    setData(buckets);
};

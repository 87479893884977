import React, { useState, useEffect, useMemo } from 'react';
import Timeline from 'react-visjs-timeline';
import { loadData } from '../Utils';
import { queryRotorPlanIst } from './Queries';
import { formatDate } from '../Utils/index';

const options = {
    height: '80vh',
    groupEditable: true,
    //clickToUse: true,
    stack: true,
    stackSubgroups: false,
    selectable: false,
};

const TimeLine = ({ setFilter, filters, sessionID, userID, setLoading }) => {
    const [items, setItems] = useState([]);
    const [groups, setGroups] = useState([]);

    const groupsClickHander = event => {
        if (event.event.type != 'click' || event.what != 'group-label') return;
        let group = event.group;
        group = group.split('_')[0];
        setFilter({
            id: group,
            Attribute: 'Seriennummern',
            Operator: `= '${group}' OR "PA-Nummer" = `,
            Value: `'${group}'`,
        });
    };

    const setTimelineData = data => {
        const dataLength = data.length;
        const itemsToShow = [];
        const groupsToShow = [];
        const firstPlanDate = new Date(data[0]['Plan-Start']);
        const lastPlanDate = new Date(data[dataLength - 1]['Plan-Ende']);
        options.start = firstPlanDate;
        options.end = lastPlanDate;

        for (let i = 0; i < dataLength; i++) {
            const obj = data[i];
            // generate a unique id is ist fundamental
            const groupId = obj['Seriennummern'] + '_' + i;
            // filling groups array
            groupsToShow.push({
                id: groupId,
                content: `${obj['Seriennummern']}`,
            });
            // preparing data to fill items collection
            const planEnde = Date.parse(obj['Plan-Ende']);
            const istEnde = Date.parse(obj['Ist-Ende']);
            let classLabel = '';
            if (istEnde > planEnde) {
                classLabel = 'late';
            }
            if (istEnde <= planEnde) {
                classLabel = 'early';
            }
            // creating plan object bean to display
            const planObj = {
                id: 5 * i + 1,
                content: obj['Plan-DLZ'].toString(),
                className: 'expected',
                start: obj['Plan-Start'],
                end: obj['Plan-Ende'],
                group: groupId,
                title: `${obj['PA-Nummer']}<br/>
                ${obj['Artikelnummer']}<br/>
                ${obj['Bezeichnung']}
                `,
            };
            itemsToShow.push(planObj);
            // creating ist object bean to display; even without real ist ende
            if (obj['IST-DLZ'] != null) {
                const istObj = {
                    id: 5 * i + 2,
                    content: obj['IST-DLZ'].toString(),
                    className: classLabel,
                    start: obj['Ist-Start'],
                    end: obj['Ist-Ende'],
                    group: groupId,
                };
                itemsToShow.push(istObj);
            }
            // create a Deadline point if the deadline is planend
            if (obj['AB LT'] != null) {
                const lfObj = {
                    id: 5 * i + 3,
                    content: formatDate(obj['AB LT']),
                    start: obj['AB LT'],
                    group: groupId,
                    type: 'point',
                };
                itemsToShow.push(lfObj);
            }
            // layout formatting
            const planStart = new Date(obj['Plan-Start']);

            // create a Material point green = 1, red = -1
            const materialObj = {
                id: 5 * i + 4,
                title: 'Material',
                className: obj['Stock'] === 1 ? 'early' : 'late',
                start: new Date(planStart.getFullYear(), planStart.getMonth(), planStart.getDate() - 2),
                group: groupId,
                type: 'point',
            };
            itemsToShow.push(materialObj);

            // create a Unter PA point green = 1, red = -1
            const uPAObj = {
                id: 5 * i + 5,
                title: 'Unter-PA-Flag',
                className: obj['Unter-PA-Flag'] === 1 ? 'early' : 'late',
                start: new Date(planStart.getFullYear(), planStart.getMonth(), planStart.getDate() - 4),
                group: groupId,
                type: 'point',
            };
            itemsToShow.push(uPAObj);
        }
        setGroups(groupsToShow);
        setItems(itemsToShow);
    };

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            loadData(setLoading, setTimelineData, queryRotorPlanIst, filters, sessionID, userID, 0);
        }, 500);
    }, [filters, sessionID, userID]);

    // return useMemo(() => {
    //     const groupsClickHander = event => {
    //         if (event.event.type != 'click' || event.what != 'group-label') return;
    //         let group = event.group;
    //         group = group.split('_')[0];
    //         console.log('filters', filters);
    //         setFilter({
    //             id: group,
    //             Attribute: 'Seriennummern',
    //             Operator: `= '${group}' OR "PA-Nummer" = `,
    //             Value: `'${group}'`,
    //         });
    //     };
    //     return <Timeline options={options} items={items} groups={groups} clickHandler={groupsClickHander} />;
    // }, [items, filters]);

    return <Timeline options={options} items={items} groups={groups} clickHandler={groupsClickHander} />;
};

export default TimeLine;

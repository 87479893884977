import React from 'react';
import { List, Form, Button, Divider } from 'semantic-ui-react';
import { formatStringDateToLongDE } from '../../UtilComponents/dateFormats';
import { getCurrentUserId, getData, insertSimpleData, sendEmail } from '../../UtilComponents/requestsUtils';
import { formatDateToDE } from '../../UtilComponents/StandardDatePicker';

const comp = ({ issue_id, issue_status, setTotalComments }) => {
    const [data, setData] = React.useState([]);
    const [comment, setComment] = React.useState('');
    const [loading, setLoading] = React.useState('');
    const [updateComments, setUpdateComments] = React.useState('');

    // handling form submition
    const submitForm = () => {
        if (issue_status === 3) {
            alert('Die Meldung darf nicht bearbeitet werden.');
        } else {
            setLoading(true);
            createComment();
        }
    };

    const createComment = async () => {
        const body = { issue_id: issue_id, issue_comment: comment, user_id: getCurrentUserId() };
        try {
            const newComment = await insertSimpleData('crud/issues/post_issue_comment', body);
            setComment('');
            setLoading(false);
            setUpdateComments('afterCreation' + Date.now().toString());
            const response = await getData(`crud/issues/get_issue_comments_for_email?id=${newComment.id}`);
            const commentDetailsForEmail = response[0];
            if (commentDetailsForEmail.responsible_email != '' && commentDetailsForEmail.responsible_email != null) {
                const payload = {
                    template_id: 'meldungen_nach_erstellung_komments',
                    to: [commentDetailsForEmail.responsible_email],
                    subject:
                        'Neuer Kommentar: ' +
                        commentDetailsForEmail.serial_id +
                        ' - ' +
                        commentDetailsForEmail.issue_title,
                    body: `<br/><b>${commentDetailsForEmail.user_name_comment}</b>, ${formatDateToDE(
                        commentDetailsForEmail.issue_comment_date,
                    )}: ${commentDetailsForEmail.issue_comment} <br/>`,
                };
                sendEmail(payload);
            }
        } catch (error) {
            console.log(error);
        }
    };

    React.useEffect(() => {
        const getIssueComments = async () => {
            try {
                const response = await getData(`crud/issues/get_issue_comments?issue_id=${issue_id}`);
                setData(response);
                setTotalComments(response.length);
            } catch (error) {
                console.log(error);
            }
        };
        getIssueComments();
    }, [updateComments]);

    return (
        <>
            <List relaxed="very">
                {data.map(obj => {
                    return <Comment {...obj} />;
                })}
            </List>
            <Divider />
            <br />
            <Form>
                <Form.TextArea
                    label="Neuer Kommentar"
                    value={comment}
                    onChange={(e, data) => setComment(data.value)}
                    rows={2}
                />
            </Form>
            <br />
            <Button fluid primary onClick={submitForm} disabled={comment.length === 0 || loading}>
                Speichern
            </Button>
        </>
    );
};

export default comp;

const Comment = props => {
    return (
        <List.Item>
            <List.Content>
                <List.Header>{props.user_name}</List.Header>
                <List.Description>
                    {props.issue_comment}
                    <br /> {formatStringDateToLongDE(props.issue_comment_date)}
                </List.Description>
            </List.Content>
        </List.Item>
    );
};

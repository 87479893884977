import React from 'react';
import { Form, Button, Popup } from 'semantic-ui-react';
import { getData, insertSimpleData, updateData } from '../../UtilComponents/requestsUtils';

const comp = ({ issue_id, issue_status }) => {
    const [formData, setFormData] = React.useState(formInitialState);
    const [formFieldsValidity, setFormFieldsValidity] = React.useState(formInputsValidatyInitialState);
    const [loading, setLoading] = React.useState(false);

    const handleChange = (event, data) => {
        const newObjState = Object.assign({}, formData, { [data.name]: data.value });
        setFormData(newObjState);
    };

    const isFormValid = () => {
        if (!Object.values(formFieldsValidity).every(i => i === true)) return false;
        if (!Object.values(formData).every(i => i !== '')) return false;
        return true;
    };

    React.useEffect(() => {
        const getIssueLessonsLearned = async () => {
            setLoading(true);
            try {
                const response = await getData(`${queryGetIssueLessonsLearned}?issue_id=${issue_id}`);
                if (response[0]) {
                    setFormData(response[0]);
                }
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        };
        getIssueLessonsLearned();
    }, []);

    const createIssueLessonsLearned = async () => {
        setLoading(true);
        const body = {
            ...formData,
            issue_id: issue_id,
        };
        try {
            const response = await insertSimpleData(queryPostIssueLessonsLearned, body);
            setFormData(response);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const updateIssueLessonsLearned = async () => {
        setLoading(true);
        const { issue_id, ...rest } = formData;
        try {
            await updateData(queryUpdateIssueLessonsLearned, rest, { issue_id: issue_id });
            setFormData(rest);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const submitForm = () => {
        if (issue_status === 3) {
            alert('Die Meldung darf nicht bearbeitet werden.');
        } else {
            !formData.issue_id ? createIssueLessonsLearned() : updateIssueLessonsLearned();
        }
    };

    return (
        <Form loading={loading}>
            <Form.TextArea
                label={
                    <Popup
                        content="Nachdem alle Korrekturmaßnahmen abgeschlossen sind, können hier die Erfahrungen und Erkenntnisse des Teams gesammelt und hinterlegt werden.   ​"
                        trigger={<label>Lessons learned​</label>}
                        wide="very"
                    />
                }
                name="issue_lessons_learned"
                rows={3}
                value={formData.issue_lessons_learned}
                onChange={handleChange}
            />

            <Button fluid primary disabled={!isFormValid()} onClick={submitForm}>
                Speichern
            </Button>
        </Form>
    );
};

export default comp;

const formInitialState = {
    issue_lessons_learned: '',
};

const formInputsValidatyInitialState = {
    issue_lessons_learned: true,
};

const queryGetIssueLessonsLearned = 'crud/issues/get_issue_lessons_learned';
const queryPostIssueLessonsLearned = 'crud/issues/post_issue_lessons_learned';
const queryUpdateIssueLessonsLearned = 'crud/issues/update_issue_lessons_learned';

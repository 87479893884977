import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import 'sanitize.css/sanitize.css';

// Import root app
import App from './App';

// Import Resource Environment
// import ResourceEnvironment from './resources';

// Load the favicon and the .htaccess file
/* eslint-disable import/no-unresolved, import/extensions */
import '!file-loader?name=[name].[ext]!../app/images/favicon.png';
/* eslint-enable import/no-unresolved, import/extensions */

  const MOUNT_NODE = document.getElementById('app');

  const render = () => {
    ReactDOM.render(
          <BrowserRouter>
            <App />
          </BrowserRouter>,
      MOUNT_NODE,
    );
  };

  if (module.hot) {
    // Hot reloadable React components
    // modules.hot.accept does not accept dynamic dependencies,
    // have to be constants at compile-time
    module.hot.accept('./containers/App', () => {
      ReactDOM.unmountComponentAtNode(MOUNT_NODE);
      render();
    });
  }

  render();

import React from 'react';
import { Accordion, Icon } from 'semantic-ui-react';
import { getData } from '../UtilComponents/requestsUtils';
import FieldItems from './FieldItems';

const FormGroupAccordeon = props => {
    const { formId = formData.id } = props;
    const [state, setState] = React.useState({ activeIndex: 0 });
    const [formGroups, setFormGroups] = React.useState([]);

    const handleClick = (e, titleProps) => {
        const { index } = titleProps;
        const { activeIndex } = state;
        const newIndex = activeIndex === index ? -1 : index;

        setState({ activeIndex: newIndex });
    };

    const { activeIndex } = state;

    React.useEffect(() => {
        const getFormFormGroups = async () => {
            try {
                const response = await getData(`crud/protocols/get_form_form_groups?form_id=${formId}`);
                setFormGroups(response);
            } catch (error) {
                console.log(error);
            }
        };
        getFormFormGroups();
    }, []);

    return (
        <Accordion>
            {formGroups.map((fg, index) => {
                return (
                    <>
                        <Accordion.Title active={activeIndex === index} index={index} onClick={handleClick}>
                            <Icon name="dropdown" />
                            {fg.form_field_group_name}
                        </Accordion.Title>
                        <Accordion.Content active={activeIndex === index}>
                            <FieldItems {...props} formGroupId={fg.form_field_group_id} />
                        </Accordion.Content>
                    </>
                );
            })}
        </Accordion>
    );
};

export default FormGroupAccordeon;

import React from 'react';
import { async } from 'regenerator-runtime';
import { Button, Modal, Form } from 'semantic-ui-react';
import { userFormInitialState } from '.';
import MultiSelectWithSearch from '../UtilComponents/MultiSelectWithSearch';
import { insertDataWithColateralValues, updateData } from '../UtilComponents/requestsUtils';

import { isTitleValid, isEmailValid } from '../UtilComponents/validations';

const centered = {
    textAlign: 'center',
};

function UserFormModal(props) {
    const { open, setOpen, formData, setFormData, setUpdateUserList } = props;

    const [formFieldsValidity, setFormFieldsValidity] = React.useState(userFormValidityInitialState);
    const [loading, setLoading] = React.useState(false);

    const handleChange = (event, data) => {
        const newObjState = Object.assign({}, formData, { [data.name]: data.value });
        setFormData(newObjState);
    };

    const validateFirstName = () => {
        const { first_name } = formData;
        const newObjState = Object.assign({}, formFieldsValidity, { first_name: isTitleValid(first_name) });
        setFormFieldsValidity(newObjState);
    };

    const validateLastName = () => {
        const { last_name } = formData;
        const newObjState = Object.assign({}, formFieldsValidity, { last_name: isTitleValid(last_name) });
        setFormFieldsValidity(newObjState);
    };

    const validateEmail = () => {
        const { email } = formData;
        const newObjState = Object.assign({}, formFieldsValidity, { email: isEmailValid(email) });
        setFormFieldsValidity(newObjState);
    };

    const isFormValid = () => {
        if (!Object.values(formFieldsValidity).every(i => i === true)) return false;
        if (!Object.values(formData).every(i => i !== '')) return false;
        if (formData.roles_ids.length <= 0) return false;
        return true;
    };

    // handling form submition
    const submitForm = () => {
        formData.id ? updateUser() : postNewUser();
    };

    const postNewUser = async () => {
        setLoading(true);
        const { roles_ids, ...rest } = formData;
        try {
            await insertDataWithColateralValues('crud/settings/post_user', rest, 'user_id', roles_ids);
            setUpdateUserList('afterCreation' + Date.now().toString());
            closeModal();
        } catch (error) {
            console.log(error);
        }
    };

    const updateUser = async () => {
        setLoading(true);
        const { id, roles_ids, ...rest } = formData;
        try {
            await updateData('crud/settings/update_user', rest, { id: id });
            setUpdateUserList('afterUpdate' + Date.now().toString());
            closeModal();
        } catch (error) {
            console.log(error);
        }
    };

    const closeModal = () => {
        setFormData(userFormInitialState);
        setLoading(false);
        setOpen(false);
    };

    return (
        <Modal size="mini" onClose={closeModal} onOpen={() => setOpen(true)} open={open}>
            <Modal.Header style={centered}>
                {formData.id === undefined ? 'Benutzer erstellen' : 'Benutzer bearbeiten'}
            </Modal.Header>
            <Modal.Content>
                <Form loading={loading}>
                    <Form.Input
                        label="Name"
                        name="first_name"
                        value={formData.first_name}
                        required
                        onChange={handleChange}
                        error={!formFieldsValidity.first_name}
                    />
                    <Form.Input
                        label="Nachname"
                        name="last_name"
                        value={formData.last_name}
                        required
                        onChange={handleChange}
                        onBlur={validateLastName}
                        error={!formFieldsValidity.last_name}
                    />
                    <Form.Input
                        label="E-Mail"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        onBlur={validateEmail}
                    />

                    <MultiSelectWithSearch
                        label="Benutzerrollen"
                        placeholder="Rollen zuweisen"
                        name="roles_ids"
                        query="crud/settings/get_roles_for_select"
                        primaryKey="user_id"
                        primaryKeyValue={formData.id}
                        foreignKey="role_id"
                        additionQuery="crud/settings/add_role_to_user"
                        deletionQuery="crud/settings/delete_role_from_user"
                        value={formData.roles_ids}
                        handleChange={handleChange}
                    />
                </Form>
                <br />
                <Button fluid primary onClick={submitForm} disabled={!isFormValid() || loading}>
                    Speichern
                </Button>
            </Modal.Content>
        </Modal>
    );
}

export default UserFormModal;

const userFormValidityInitialState = {
    first_name: true,
    last_name: true,
    email: true,
    roles_ids: true,
};

import React from 'react';
import { Button, Select, Input } from 'semantic-ui-react';

const TableTextSearchForColumns = props => {
    const { setSelectedField, searchTableOptions, searchTableOptionsLogic, defaultValue } = props;
    const listenForEnter = e => {
        if (e.keyCode === 13) {
            searchTableOptionsLogic();
        }
    };
    return (
        <Input
            id="textToSearchFor"
            type="text"
            placeholder="Suchen..."
            action
            size={props.size || 'mini'}
            onKeyUp={listenForEnter}
        >
            <input />
            <Select
                options={searchTableOptions}
                defaultValue={defaultValue}
                size="mini"
                onChange={(e, { value }) => setSelectedField(value)}
            />
            <Button id="search" type="submit" size="mini" onClick={searchTableOptionsLogic}>
                Suchen
            </Button>
        </Input>
    );
};

export default TableTextSearchForColumns;

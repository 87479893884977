import React from 'react';
import MultiSelectWithSearch from '../../../UtilComponents/MultiSelectWithSearch';

const SelectResponsible = ({ orderResponsible }) => {
    const [formData, setFormData] = React.useState({ order_number: '', responsible_ids: [] });

    React.useEffect(() => {
        setFormData({
            order_number: orderResponsible.orderNumber,
            responsible_ids: orderResponsible.responsibleId,
        });
    }, [orderResponsible]);

    const handleChange = (event, data) => {
        const newObjState = Object.assign({}, formData, { responsible_ids: data.value });
        setFormData(newObjState);
    };

    return (
        <MultiSelectWithSearch
            label="Mitarbeiter"
            placeholder="Mitarbeiter zuweisen"
            name="responsible_ids"
            query="crud/get_users"
            primaryKey="order_number"
            primaryKeyValue={formData.order_number}
            foreignKey="responsible_id"
            additionQuery="crud/d8h/add_responsible_to_production_orders"
            deletionQuery="crud/d8h/delete_responsible_from_production_orders"
            value={formData.responsible_ids}
            handleChange={handleChange}
            width={16}
            fluid={true}
        />
    );
};

export default SelectResponsible;

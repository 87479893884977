import React, { useEffect, useState, useMemo } from 'react';
import { Segment } from 'semantic-ui-react';
import styled from 'styled-components';
import { loadTableData } from '../Utils';
import { formatForSmartTextSearch } from '../Utils/Tables';
import { queryOrdersTable } from './Queries';
import { buildTableColumns } from './ColumnsDeclaration';
import TableCreator from '../Table/TableCreator';

const initialLimit = 100;
const today = Date.now();
const OrdersViewTable = props => {
    const {
        filters,
        setFilters,
        setFilter,
        filteredPAs,
        applyFilteredPAs,
        sorts,
        setSort,
        paClickCount,
        setPaClickCount,
        loadingExcel,
        setLoadingExcel,
        sessionID,
        userID,
    } = props;
    const [data, setData] = useState(null);
    const [selectedField, setSelectedField] = useState('PA-Nummer'); // for table colum filter
    const [loading, setLoading] = useState(false);
    const { limit } = filters || initialLimit;

    useEffect(() => {
        loadTableData(setLoading, setData, queryOrdersTable, filters, sorts, sessionID, userID, 0);
    }, [filters, sorts, sessionID]);

    const columns = useMemo(
        () => [
            {
                Header: 'Details',
                columns: buildTableColumns(setFilter, setSort, sorts, paClickCount, setPaClickCount),
            },
        ],
        [sorts],
    );
    // text search for table columns context specific infomation and logic
    const searchTableOptions = [
        { key: 'PA-Nummer', text: 'PA-Nummer', value: 'PA-Nummer' },
        { key: 'Bezeichnung', text: 'Bezeichnung', value: 'Bezeichnung' },
        // { key: 'Artikelnummer', text: 'Artikelnummer', value: 'Artikelnummer' },
        { key: 'Haupt-PA', text: 'Haupt-PA', value: 'Haupt-PA' },
        { key: 'Buchungsart', text: 'Buchungsart', value: 'Buchungsart' },
        { key: 'Artikelgruppe', text: 'Artikelgruppe', value: 'Artikelgruppe' },
        { key: 'AB', text: 'AB', value: 'AB' },
        { key: 'AB LT', text: 'AB LT', value: 'AB LT' },
        { key: 'Lieferschein', text: 'Lieferschein', value: 'Lieferschein' },
        { key: 'Ist-Ende', text: 'Ist-Ende', value: 'Ist-Ende' },
        { key: 'Seriennummern', text: 'Seriennummern', value: 'Seriennummern' },
    ];
    // Wenn (TA Abgang >0), dann „rot“ oder wenn((TA Abgang = empty) und (Plan-Ende < heute()), dann „rot“
    const shouldRowBeRed = row => {
        if (row.values['TA Abgang'] > 0) {
            return true;
        } else if (row.values['TA Abgang'] == null && Date.parse(row.values['Plan-Ende']) < today) {
            return true;
        } else {
            return false;
        }
    };

    const columnsToHide = JSON.parse(localStorage.getItem('ordersTable')) || [];

    const searchTableOptionsLogic = () => {
        const input = document.getElementById('textToSearchFor');

        if (selectedField == 'Ist-Ende') {
            setFilter({
                id: selectedField,
                Attribute: selectedField,
                Operator: '',
                Value: 'IS NOT NULL',
            });
            return;
        }

        if (!input.value) return;

        if (selectedField == 'Bezeichnung') {
            const [operator, formattedString] = formatForSmartTextSearch(input.value);
            setFilter({
                id: selectedField,
                Attribute: selectedField,
                Operator: operator,
                Value: `ALL(ARRAY${formattedString})`,
            });
            return;
        }

        if (selectedField == 'Haupt-PA' && input.value == '0') {
            setFilter({
                id: selectedField,
                Attribute: selectedField,
                Operator: '~*',
                Value: `'Haupt-PA'`,
            });
        } else if (selectedField == 'Haupt-PA' && input.value.length == 8) {
            setFilter({
                id: selectedField,
                Attribute: selectedField,
                Operator: `= '${input.value}' OR "PA-Nummer" = `,
                Value: `'${input.value}'`,
            });
        } else {
            setFilter({
                id: selectedField,
                Attribute: selectedField,
                Operator: '~*',
                Value: `'${input.value}'`,
            });
        }
    };
    // text search for table columns context specific infomation and logic
    return useMemo(() => {
        return (
            <Segment100 loading={loading}>
                {columns && data ? (
                    <TableCreator
                        columns={columns}
                        data={data}
                        limit={limit}
                        initialLimit={initialLimit}
                        loading={loading}
                        loadingExcel={loadingExcel}
                        setLoadingExcel={setLoadingExcel}
                        setFilters={setFilters}
                        filters={filters}
                        setFilter={setFilter}
                        setSelectedField={setSelectedField}
                        searchTableOptions={searchTableOptions}
                        searchTableOptionsLogic={searchTableOptionsLogic}
                        getRowProps={row => {
                            const rule = shouldRowBeRed(row);
                            return {
                                className: `${rule ? 'red' : ''}`,
                            };
                        }}
                        columnsToHide={columnsToHide}
                        tableName={'ordersTable'}
                    />
                ) : (
                    <TablePlaceholder />
                )}
            </Segment100>
        );
    }, [data, loading, selectedField]);
};

const Segment100 = styled(Segment)`
    width: 100% !important;
`;
const TablePlaceholder = styled.div`
    height: 40vh;
    width: 100%;
`;

export default OrdersViewTable;

import React from 'react';
import { List, Form, Button, Divider, Checkbox, Icon, Popup, Grid } from 'semantic-ui-react';
import {
    getCurrentUserId,
    getData,
    insertSimpleData,
    updateData,
    deleteData,
} from '../../UtilComponents/requestsUtils';

const comp = ({ issue_id, numberOfTabsToShow, setNumberOfTabsToShow, issue_status }) => {
    const formInitialState = { id: '', cause: '' };
    const [cause, setCause] = React.useState(formInitialState);

    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState('');
    const [updateList, setUpdateList] = React.useState('');

    // handling form submition
    const submitForm = () => {
        if (issue_status === 3) {
            alert('Die Meldung darf nicht bearbeitet werden.');
        } else {
            setLoading(true);
            cause.id ? updateCause() : createCause();
        }
    };

    const handleChange = (evt, data) => {
        const newObjState = Object.assign({}, cause, { [data.name]: data.value });
        setCause(newObjState);
    };

    const createCause = async () => {
        const body = { issue_id: issue_id, cause: cause.cause, user_id: getCurrentUserId() };
        try {
            await insertSimpleData('crud/issues/post_issue_cause_analisis', body);
            setCause(formInitialState);
            setLoading(false);
            if (data.length === 0) {
                setNumberOfTabsToShow(numberOfTabsToShow + 1);
            }
            setUpdateList('afterCreation' + Date.now().toString());
        } catch (error) {
            console.log(error);
        }
    };

    const updateCause = async () => {
        const body = { issue_id: issue_id, cause: cause.cause, user_id: getCurrentUserId() };
        const where = { id: cause.id };
        try {
            await updateData('crud/issues/update_issue_cause_analisis', body, where);
            setLoading(false);
            setUpdateList('afterUpdate' + Date.now().toString());
            setCause(formInitialState);
        } catch (error) {
            console.log(error);
        }
    };

    const deleteCause = async causeId => {
        if (issue_status === 3) {
            alert('Die Meldung darf nicht bearbeitet werden.');
        } else {
            const where = { id: causeId };
            try {
                await deleteData('crud/issues/delete_issue_cause_analisis', where);
                setLoading(false);
                setUpdateList('afterDeletion' + Date.now().toString());
                setCause(formInitialState);
            } catch (error) {
                console.log(error);
            }
        }
    };

    React.useEffect(() => {
        const getIssueCauses = async () => {
            try {
                const response = await getData(`crud/issues/get_issue_causes_analisis_list?issue_id=${issue_id}`);
                setData(response);
            } catch (error) {
                console.log(error);
            }
        };
        getIssueCauses();
    }, [updateList]);

    return (
        <>
            <List relaxed="very">
                {data.map(obj => {
                    return <Cause {...obj} setCause={setCause} deleteCause={deleteCause} issue_status={issue_status} />;
                })}
            </List>
            <Divider />
            <br />
            <Form>
                <Form.TextArea
                    label={
                        <Popup
                            content="Aus dem vorhergehenden Schritt können hier die genauer zu prüfenden Ursachen hinterlegt werden. Bitte 'Was' und 'Wer' hinterlegen. Der Blattverantwortliche überprüft die Abarbeitung. Bitte mögliche Änderungen oder zusätzliche Infos im Reiter 'Kommentare' hinterlegen.​​​​​"
                            trigger={<label>{cause.id ? 'Ursachenanalyse bearbeiten' : 'Neue Ursachenanalyse'}</label>}
                            wide="very"
                        />
                    }
                    name="cause"
                    value={cause.cause}
                    onChange={handleChange}
                    rows={2}
                />
            </Form>
            <br />
            <Button fluid primary onClick={submitForm} disabled={cause.cause.length === 0 || loading}>
                Speichern
            </Button>
        </>
    );
};

export default comp;

const Cause = props => {
    const [proofed, setProofed] = React.useState(props.proofed);
    const { issue_status } = props;
    const updateCauseProofed = async newValue => {
        if (issue_status === 3) {
            alert('Die Meldung darf nicht bearbeitet werden.');
        } else {
            try {
                await updateData('crud/issues/update_issue_cause_analisis', { proofed: newValue }, { id: props.id });
                setProofed(newValue);
            } catch (error) {
                console.log(error);
            }
        }
    };

    const updateCause = () => {
        props.setCause({ id: props.id, cause: props.cause });
    };

    return (
        <List.Item key={props.id}>
            <List.Content>
                <Grid columns={16}>
                    <Grid.Row>
                        <Grid.Column width={1}>
                            <Checkbox checked={proofed} onChange={() => updateCauseProofed(!proofed)} />
                        </Grid.Column>
                        <Grid.Column width={14}>{props.cause}</Grid.Column>
                        <Grid.Column width={1}>
                            <Icon name="edit" link onClick={updateCause} />
                            <Icon name="remove" link color="red" onClick={() => props.deleteCause(props.id)} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </List.Content>
        </List.Item>
    );
};

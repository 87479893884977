import React, { useState } from 'react';
import styled from 'styled-components';
import { COLORS } from '../global-styles';
import logo from '../images/kua-logo.png';
import { Form, Button, Message } from 'semantic-ui-react';
import { backendUrl } from '../config';
import request from 'superagent';
import produce from 'immer';

const Login = props => {
    const [loading, setLoading] = useState(false);
    const [loginData, setLoginData] = useState({ user: '', password: '' });
    const [loginError, setLoginError] = useState();
    const [userValid, setUserValid] = useState(true);
    const [userInitialized, setUserInitialized] = useState(true);

    const handleChange = ({ currentTarget }) => {
        setLoginData(
            produce(loginData, draftState => {
                draftState[currentTarget.name] = currentTarget.value;
            }),
        );
    };
    const handleSubmit = () => {
        const { user, password } = loginData;
        if (userInitialized && user && password) {
            setLoading(true);
            request
                .post(`${backendUrl}/auth/login`)
                .set('Accept', 'application/json')
                .send({ email: user, password: password })
                .then(resp => {
                    props.setUserIDAndSave(user);
                    props.setSessionID(resp.body);
                    props.setIsAuthenticated(true);
                    setLoginError(false);
                    setLoading(false);
                })
                .catch(err => {
                    setLoginError(true);
                    setLoading(false);
                });
        } else if (!userInitialized && userValid && user && password) {
            setLoading(true);
            request
                .post(`${backendUrl}/auth/user/change_password`)
                .set('Accept', 'application/json')
                .send({ email: user, new_password: password })
                .then(resp => {
                    checkUser();
                    setLoginError(false);
                    setLoading(false);
                })
                .catch(err => {
                    setLoginError(true);
                    setLoading(false);
                });
        }
    };
    const { user, password } = loginData;
    const checkUser = () => {
        if (user) {
            request
                .post(`${backendUrl}/auth/user/check`)
                .set('Accept', 'application/json')
                .send(user)
                .then(() => {
                    setUserValid(true);
                    setUserInitialized(true);
                })
                .catch(err => {
                    if (err.status == 404) {
                        setUserValid(false);
                        setUserInitialized(false);
                    }
                    if (err.status == 412) {
                        setUserValid(true);
                        setUserInitialized(false);
                        setLoginError(false);
                    }
                });
        }
    };
    return (
        <LoginStyled>
            <div>
                <img src={logo} style={{ width: '100%' }} />
            </div>
            <Form onSubmit={handleSubmit} loading={loading}>
                <Form.Field error={loginError || !userValid}>
                    <label>Benutzername</label>
                    <input
                        name="user"
                        placeholder="Benutzername"
                        onBlur={checkUser}
                        onChange={handleChange}
                        value={user}
                        type="email"
                    />
                </Form.Field>
                <Form.Field error={loginError}>
                    <label>{userValid && !userInitialized ? 'Neues Passwort' : 'Passwort'}</label>
                    <input
                        name="password"
                        type="password"
                        placeholder="Passwort"
                        onChange={handleChange}
                        value={password}
                    />
                </Form.Field>

                <Button type="submit" fluid primary>
                    {userValid && !userInitialized ? 'Passwort speichern' : 'Anmelden'}
                </Button>

                {!userValid && user && <Message negative>Benutzername unbekannt.</Message>}
                {loginError && userValid && userInitialized && (
                    <Message negative>Benutzername oder Passwort nicht korrekt.</Message>
                )}
            </Form>
        </LoginStyled>
    );
};

const LoginStyled = styled.div`
    display: flex;
    width: 360px;
    padding: 2rem;
    background: rgba(255, 255, 255, 0.9);
    flex-direction: column;
    label {
        color: ${COLORS.RED_KUA}!important;
    }
`;

export default Login;

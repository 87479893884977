import React from 'react';
import { Form, Button, Popup } from 'semantic-ui-react';
import { getData, insertSimpleData, updateData } from '../../UtilComponents/requestsUtils';

const comp = ({ issue_id, numberOfTabsToShow, setNumberOfTabsToShow, issue_status }) => {
    const [formData, setFormData] = React.useState(formInitialState);
    const [formFieldsValidity, setFormFieldsValidity] = React.useState(formInputsValidatyInitialState);
    const [loading, setLoading] = React.useState(false);

    const handleChange = (event, data) => {
        const newObjState = Object.assign({}, formData, { [data.name]: data.value });
        setFormData(newObjState);
    };

    const isFormValid = () => {
        if (!Object.values(formFieldsValidity).every(i => i === true)) return false;
        if (!Object.values(formData).every(i => i !== '')) return false;
        return true;
    };

    React.useEffect(() => {
        const getIssueBrainStormingFactors = async () => {
            setLoading(true);
            try {
                const response = await getData(`crud/issues/get_issue_brain_storming_factors?issue_id=${issue_id}`);
                if (response[0]) {
                    setFormData(response[0]);
                }
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        };
        getIssueBrainStormingFactors();
    }, []);

    const createBrainStormingFactors = async () => {
        setLoading(true);
        const body = {
            ...formData,
            issue_id: issue_id,
        };
        try {
            const response = await insertSimpleData('crud/issues/post_issue_brain_storming_factors', body);
            setFormData(response);
            setNumberOfTabsToShow(numberOfTabsToShow + 1);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const updateBrainStormingFactors = async () => {
        setLoading(true);
        const { issue_id, ...rest } = formData;
        try {
            await updateData('crud/issues/update_issue_brain_storming_factors', rest, { issue_id: issue_id });
            setFormData(rest);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const submitForm = () => {
        if (issue_status === 3) {
            alert('Die Meldung darf nicht bearbeitet werden.');
        } else {
            !formData.issue_id ? createBrainStormingFactors() : updateBrainStormingFactors();
        }
    };

    return (
        <Form loading={loading}>
            <Form.TextArea
                label={
                    <Popup
                        content="Hat der Mensch den Fehler bzw. das Problem verursacht? Was hat der Mensch evtl. Falsch machen können? (Mindestens: 'hat keinen Einfluss' in das Feld schreiben)"
                        trigger={<label>Mensch</label>}
                        wide="very"
                    />
                }
                name="issue_person_factor"
                rows={3}
                value={formData.issue_person_factor}
                onChange={handleChange}
            />
            <Form.TextArea
                label={
                    <Popup
                        content="Hat eine Maschine oder ein Gerät den Fehler oder das Problem verursacht? Konnte Sie falsch verwendet werden? (Mindestens: 'hat keinen Einfluss' in das Feld schreiben)​"
                        trigger={<label>Maschine</label>}
                        wide="very"
                    />
                }
                name="issue_machine_factor"
                rows={3}
                value={formData.issue_machine_factor}
                onChange={handleChange}
            />
            <Form.TextArea
                label={
                    <Popup
                        content="Hat die Umwelt einen Einfluss gehabt? Z.B. war es zu dunkel oder zu kalt oder ähnliches? (Mindestens: 'hat keinen Einfluss' in das Feld schreiben)​​"
                        trigger={<label>Umwelt</label>}
                        wide="very"
                    />
                }
                name="issue_environment_factor"
                rows={3}
                value={formData.issue_environment_factor}
                onChange={handleChange}
            />
            <Form.TextArea
                label={
                    <Popup
                        content="Ist der Prozess oder das Vorgehen fehleranfällig? Ist er z.B. unklar oder missverständlich? (Mindestens: 'hat keinen Einfluss' in das Feld schreiben)​​​"
                        trigger={<label>Methode</label>}
                        wide="very"
                    />
                }
                name="issue_method_factor"
                rows={3}
                value={formData.issue_method_factor}
                onChange={handleChange}
            />
            <Form.TextArea
                label={
                    <Popup
                        content=" Ist das Material nicht geeignet oder wurde etwas anderes verwendet? (Mindestens: 'hat keinen Einfluss' in das Feld schreiben)​​​​"
                        trigger={<label>Material</label>}
                        wide="very"
                    />
                }
                name="issue_material_factor"
                rows={3}
                value={formData.issue_material_factor}
                onChange={handleChange}
            />

            <Button fluid primary disabled={!isFormValid()} onClick={submitForm}>
                Speichern
            </Button>
        </Form>
    );
};

export default comp;

const formInitialState = {
    issue_person_factor: '',
    issue_machine_factor: '',
    issue_environment_factor: '',
    issue_method_factor: '',
    issue_material_factor: '',
};

const formInputsValidatyInitialState = {
    issue_person_factor: true,
    issue_machine_factor: true,
    issue_environment_factor: true,
    issue_method_factor: true,
    issue_material_factor: true,
};

import React from 'react';
import { HistogrammWidgetFrame, Histogramm_KNZ, GWTitle } from '../../Utils/widgetsStyles';
import { COLORS } from '../../global-styles';
import useDimensions from 'react-use-dimensions';

import { XYPlot, YAxis, HorizontalGridLines, VerticalBarSeries, Hint } from 'react-vis';
import Modal from '../../Utils/Modal';
import { biRequest } from '../UtilComponents/requestsUtils';
import { Grid, Popup, Icon } from 'semantic-ui-react';

const BarSeries = VerticalBarSeries;

const Comp = props => {
    const { filters, addActiveFilter, title, height, modalOpen } = props;
    const [loadingData, setLoadingData] = React.useState(false);
    const [ref, { x, y, width }] = useDimensions();
    const [hoverData, setHoverData] = React.useState(null);
    const [okData, setokData] = React.useState([]);
    const [notOkData, setnotOkData] = React.useState([]);

    const formatHoverData = point => {
        return [
            { title: 'Abweichung', value: point.y },
            { title: 'PA-Nummer', value: point.x },
        ];
    };

    const buildData = (data, label) => {
        if (label === 'ok') {
            // when deviation is - more time planned than used ii is ok
            return data.filter(obj => obj.deviation <= 0).map(obj => ({ x: obj.ppsordernumber, y: obj.deviation }));
        }
        if (label === 'notOk') {
            return data.filter(obj => obj.deviation > 0).map(obj => ({ x: obj.ppsordernumber, y: obj.deviation }));
        }
    };

    React.useEffect(() => {
        const getWidgetData = async () => {
            try {
                setLoadingData(true);
                const response = await biRequest(`d8h/get_pa_ist_plan`, filters);
                setLoadingData(false);
                setokData(buildData(response, 'ok'));
                setnotOkData(buildData(response, 'notOk'));
            } catch (error) {
                console.log(error);
            }
        };
        getWidgetData();
    }, [filters]);

    return React.useMemo(() => {
        return (
            <HistogrammWidgetFrame loading={loadingData}>
                <Histogramm_KNZ ref={ref}>
                    <GWTitle>
                        <Popup trigger={<Icon name="info circle" size="tiny" />} flowing hoverable>
                            <Grid padded>Aktuelle Abweichungen von den Planzeiten bei den gestarteten PAs</Grid>
                        </Popup>
                        <span>{title}</span>
                        {modalOpen ? <span></span> : <Modal graphWidget={<Comp />} config={props} />}
                    </GWTitle>
                </Histogramm_KNZ>
                <XYPlot
                    className="stackedBar"
                    margin={{ bottom: 60, left: 25 }}
                    xType="ordinal"
                    width={width - 20}
                    height={height || 200}
                    color={COLORS.BLUE}
                >
                    <HorizontalGridLines />
                    <YAxis />

                    {hoverData && <Hint value={hoverData} format={formatHoverData}></Hint>}

                    <BarSeries
                        color={COLORS.GREEN}
                        data={okData}
                        onValueMouseOver={d => setHoverData(d)}
                        onValueMouseOut={d => setHoverData(null)}
                        onValueClick={datapoint => {
                            addActiveFilter({
                                nameToDisplay: 'PA-Nummer',
                                attribute: 'ppsordernumber',
                                operator: '=',
                                value: [datapoint.x],
                            });
                        }}
                    />

                    <BarSeries
                        color={COLORS.RED_KUA}
                        data={notOkData}
                        onValueMouseOver={d => setHoverData(d)}
                        onValueMouseOut={d => setHoverData(null)}
                        onValueClick={datapoint => {
                            addActiveFilter({
                                nameToDisplay: 'PA-Nummer',
                                attribute: 'ppsordernumber',
                                operator: '=',
                                value: [datapoint.x],
                            });
                        }}
                    />
                </XYPlot>
            </HistogrammWidgetFrame>
        );
    }, [width, hoverData, notOkData]);
};

export default Comp;

export const queryOrdersTable = 'orders/v_orders';
export const queryOrdersK1 = 'orders/v_orders_k01';
export const queryOrdersK2 = 'orders/v_orders_k02';
export const queryOrdersK3 = 'orders/v_orders_k03';
export const queryOrdersK3MST = 'orders/v_orders_k03_m_st';
export const queryOrdersK4 = 'orders/v_orders_k04';
export const queryOrdersK5 = 'orders/v_orders_k05';
export const queryOrdersK6 = 'orders/v_orders_k06';
export const queryOrdersK6MST = 'orders/v_orders_k06_m_st';
export const queryOrdersK7 = 'orders/v_orders_k07';
export const queryOrdersK8 = 'orders/v_orders_k08';
export const queryOrdersK8MST = 'orders/v_orders_k08_m_st';
export const queryOrdersK9 = 'orders/v_orders_k09';
export const queryOrdersK9MST = 'orders/v_orders_k09_m_st';
export const queryOrdersK10 = 'orders/v_orders_k10';
export const queryOrdersK10MST = 'orders/v_orders_k10_m_st';
export const queryOrdersPanummer = 'orders/v_orders_panummer';
export const queryOrdersProductsGroupsForFilter = 'orders/v_orders_products_groups_for_filter';
export const queryOrdersStatusesForFilter = 'orders/v_orders_statuses_for_filter';
export const queryOrdersCompletedForFilter = 'orders/v_orders_completed_for_filter';
export const queryOrdersIsClosedForFilter = 'orders/v_orders_isclosed_for_filter';
export const queryOrdersIsHauptPAForFilter = 'orders/v_orders_isHauptPA_for_filter';
export const queryOrdersHasSeriennummernForFilter = 'orders/v_orders_hasSeriennummern_for_filter';
export const queryFilteredFromOrdersPAs = 'orders/v_orders_filtered_pas';
export const queryArticleNumbersForFilter = 'orders/v_orders_article_numbers_for_filter';
export const queryProjectNumbersForFilter = 'orders/v_orders_project_numbers_for_filter';

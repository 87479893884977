import React from 'react';
import styled from 'styled-components';
import { Button } from 'semantic-ui-react';

const explicitFilters = [
    'category',
    'article_group',
    'resource_group',
    'resourcegroupid',
    'buchungsart',
    'responsible_id',
];
const ActiveFilterList = props => {
    const { filters, removeFilter } = props;
    return filters.length > 0 ? (
        <AFList>
            {filters
                .filter(obj => obj.value && !explicitFilters.includes(obj.attribute))
                .map(obj => {
                    const attribute = obj.attribute || '';
                    const operator = obj.operator || '';

                    return (
                        <Button
                            key={attribute}
                            onClick={() => removeFilter(attribute)}
                            basic
                            fluid
                            icon="remove"
                            content={`${obj.nameToDisplay} ${operatorToDisplay(operator)} ${obj.value}`}
                            labelPosition="right"
                        />
                    );
                })}
        </AFList>
    ) : (
        <div className="info">keine</div>
    );
};

export default ActiveFilterList;

const operatorToDisplay = operator => {
    switch (operator) {
        case '!~*':
            return '!:';
        case '=':
            return ':';
        case '~*':
            return ':';
        default:
            return operator;
    }
};

const AFList = styled.div`
    > * {
        margin: 0.5rem 0 !important;
    }
    .ui.basic.button {
        text-align: left !important;
    }
`;

import React from 'react';
import { Form } from 'semantic-ui-react';
import SInput from '../UtilComponents/SearchableInput';
import styled from 'styled-components';
import SearchableFilterWithMultiSelection from '../UtilComponents/SearchableFilterWithMultiSelection';
import ActiveFilterList from './ActiveFilterList';
import DepartmentsFilter from './DepartmentsFilter';

const FPOFilters = ({ activeFilters, removeActiveFilter, handleFiltersChange }) => {
    const getCurrentFilterValue = (activeFilters, field) => {
        const currentFilter = activeFilters.find(obj => obj['attribute'] === field);
        return currentFilter ? [...currentFilter.value] : [];
    };
    return (
        <FilterWrapper>
            <strong>Filter</strong>
            <ActiveFilterList filters={activeFilters} removeFilter={removeActiveFilter} />
            <br />
            <Form size="tiny">
                <DepartmentsFilter
                    label="Abteilung"
                    query="crud/d8h/departments_for_filter"
                    name="abteilung"
                    placeholder={'Abteilung auswählen'}
                    onChange={handleFiltersChange}
                    required={false}
                />

                <SearchableFilterWithMultiSelection
                    label="Kategorie"
                    query="crud/d8h/production_orders_categories_for_filter"
                    name="category"
                    value={getCurrentFilterValue(activeFilters, 'category')}
                    onChange={handleFiltersChange}
                />

                <SearchableFilterWithMultiSelection
                    label="Artikelgruppe"
                    query="crud/d8h/article_groups_for_filter"
                    name="article_group"
                    value={getCurrentFilterValue(activeFilters, 'article_group')}
                    onChange={handleFiltersChange}
                />

                <SearchableFilterWithMultiSelection
                    label="Ressourcengruppen"
                    query="crud/d8h/resource_groups_for_filter"
                    name="resourcegroupid"
                    value={getCurrentFilterValue(activeFilters, 'resourcegroupid')}
                    onChange={handleFiltersChange}
                />
                <SearchableFilterWithMultiSelection
                    label="Buchungsart"
                    query="crud/d8h/get_buchungsart"
                    name="buchungsart"
                    value={getCurrentFilterValue(activeFilters, 'buchungsart')}
                    onChange={handleFiltersChange}
                />
                <SearchableFilterWithMultiSelection
                    label="Mitarbeiter"
                    query="crud/get_users"
                    name="responsible_id"
                    value={getCurrentFilterValue(activeFilters, 'responsible_id')}
                    onChange={handleFiltersChange}
                />
            </Form>
        </FilterWrapper>
    );
};
export default FPOFilters;

const FilterWrapper = styled.div`
    flex: 0 0 auto;
    position: relative;
    width: 240px;
    padding: 0 1rem 1rem 0;
    height: auto;

    .react-datepicker-wrapper {
        width: 100%;
        overflow: show;
    }
`;

export const AnzahlAVG =
    'Es wird der aktuelle Status der AVG unterschieden und in der jeweiligen Anzahl dargstellt. Die Unterscheidung hilft bei der Disposition oder Auswertung von Aufträgen.';
export const Planzeiten_Soll_Ist =
    'Für jeden AVG wird eine Plan-Arbeit festgelegt. Nach Rückmeldung der tatsächlich benötigten Ist-Arbeit wird hier für jeden AVG die Abweichung von Plan und Ist ausgewertet. Ein negativer Wert bedeutet, dass weniger Zeit benötigt wurde, ein positiver Wert bedeutet, dass zusätzliche Zeit benötigt wurde. Die Auswertung hilft bei der Bestimmung angemessener Plan-Zeiten und zeigt, ob die Produktion die Zeiten einhalten kann. Eine positiver Mittelwert (M) bedeutet, dass die geplante Arbeit im Mittel nicht eingehalten werden kann. Eine hohe Standardabweichung (SD) deutet auf instabile Prozesse hin. ';
export const Rueckstand =
    'Der Rückstand gibt an, wie viele Aufträge an einem Tag noch nicht zum Plan-Ende fertiggestellt worden sind. Dies lässt Schlüsse auf die allgemeine Termin- und Kapazitätssituation zu.';
export const Disposition_Offene_Auftraege =
    'Sofern für einen AVG Material fehlt oder Unter-PA noch offen sind, gilt ein AVG als unvollständig - ist alles Material vorhanden und alle Unter-PA zurückgemeldet, so gilt ein AVG als vollständig. Dies hilft der Disposition bei der Freigabe von Aufträgen.';
export const DLZ =
    'Für jeden AVG wird die Durchlaufzeit (DLZ) aus Ist-Endtermin und Start-Endtermin berechnet. Ein negativer Wert bedeutet, dass ein AVG früher als geplant fertig war, ein positiver Wert bedeutet, dass ein AVG später als geplant fertig war. Eine positiver Mittelwert (M) bedeutet, dass die geplante DLZ im Mittel nicht eingehalten werden kann. Eine hohe Standardabweichung (SD) deutet auf instabile Prozesse hin.';
export const Termintreue =
    'Für jeden AVG wird berechnet, ob er zu spät fertiggestellt wurde (Ist-Ende>Plan-Ende) oder nicht mehr pünktlich zu schaffen ist (Plan-Ende<heute) bzw. pünktlich fertiggestellt wurde (Ist-Ende<Plan-Ende). Im Fall zu später Fertigstellung hat der AVG eine positive Terminabweichung (TA). Im Fall zu später Fertigstellung hat der AVG eine negative Terminabweichung (TA) oder den Wert Null. Es werden alle pünktlichen Aufträge gezählt und ins Verhältnis zur Gesamtzahl gesetzt (Termintreue). Eine geringe Termintreue deutet daraufhin, dass die Kundenanforderungen nicht gut eingehalten werden.';
export const TA_Zugang =
    'Für jeden AVG wird berechnet, ob er zu spät begonnen wurde (Ist-Start> Plan-Start) oder pünktlich fertiggestellt wurde (Ist-Start <=Plan-Start). Im Fall zu frühen Beginns hat der AVG eine positive Terminabweichung (TA). Im Fall zu späten Beginns hat der AVG eine negative Terminabweichung (TA) oder den Wert Null. Eine positiver Mittelwert (M) bedeutet, dass im Mittel zu spät begonnen wird. Das kann an Vorprozessen, der Materialverfügbarkeit oder Reihenfolgevertauschungen liegen. Eine hohe Standardabweichung (SD) deutet auf instabile Prozesse hin.';
export const rel_TA =
    'Für jeden AVG wird berechnet, ob er innerhalb der zur Verfügung stehenden Dauer (Plan-Arbeit + Übergangszeit) bearbeitet wurde: zu lange Dauer (Ist-DLZ - Plan-DLZ > 0) oder zu kurze Dauer (Ist-DLZ - Plan-DLZ <= 0).  Im Fall zu langer Dauer  hat der AVG eine positive relative Terminabweichung (rel TA). Im Fall zu kurzer Dauer hat der AVG eine negative relative Terminabweichung (rel. TA) oder den Wert Null. Eine positiver Mittelwert (M) bedeutet, dass im Mittel zu lange gedauert hat. Das kann an zu kurzen Plan-Zeiten oder mangelnder Kapazität liegen. Eine hohe Standardabweichung (SD) deutet auf instabile Prozesse hin.';
export const TA_Abgang =
    'Für jeden AVG wird berechnet, ob er zu spät fertiggestellt wurde (Ist-Ende > Plan-Ende) oder pünktlich fertiggestellt wurde (Ist-Ende <=Plan-Ende). Im Fall zu früher Fertigstellung hat der AVG eine positive Terminabweichung (TA). Im Fall zu später Fertigstellung hat der AVG eine negative Terminabweichung (TA) oder den Wert Null. Eine positiver Mittelwert (M) bedeutet, dass die Aufträge im Mittel zu spät fertiggestellt werden. Es ist das Ergebnis aus der TA im Zugang und der rel. TA. Eine hohe Standardabweichung (SD) deutet auf instabile Prozesse hin.';

export const DUDI_TEXT =
    'Das Durchlaufdiagramm (DUDI) beschreibt jede beliebige Kapazitätseinheit einer Fertigung durch die Größen Zugang, Bestand und Abgang im seinem Durchlaufverhalten. Dazu werden die fertiggestellten Aufträge mit ihrem Arbeitsinhalt (in Plan-Stunden) über dem Fertigstellungstermin kumulativ aufgetragen (Abgangskurve). Analog dazu erfolgt der Aufbau der Zugangskurve, indem die zugehenden Aufträge mit ihrem Arbeitsinhalt über dem Zugangstermin aufgetragen werden. Der Beginn der Zugangskurve wird durch den Bestand bestimmt, der sich zu Beginn des Bezugszeitraumes am Arbeitssystem befindet (Anfangsbestand). Am Ende des Untersuchungszeitraums läßt sich aus dem Diagramm der Endbestand ablesen. Die mittlere Steigung der Zugangskurve heißt mittlere Belastung, die mittlere Steigung der Abgangskurve entspricht der mittleren Leistung. Generell gilt, daß bei einem stabilen, eingeschwungenen System und einem langen Betrachtungszeitraum Zugang und Abgang parallel zueinander verlaufen müssen. Das Durchlaufdiagramm beschreibt das dynamische Systemverhalten qualitativ und zeitpunktgenau. Es zeigt die Wirkungszusammenhänge zwischen den logistischen Zielgrößen auf und macht sie einer mathematischen Beschreibung zugänglich.';

export const Kapazitaetseinhaltung = '';

import React from 'react';
import { Button, Form } from 'semantic-ui-react';
import { insertSimpleData, updateData, getData } from '../UtilComponents/requestsUtils';

import SearchableInput from '../UtilComponents/SearchableInput';

const FieldForm = ({ formId, formFieldId }) => {
    const [formData, setFormData] = React.useState(fieldFormInitialState);
    const [validInputs, setValidInputs] = React.useState(fieldFormInputsValidityState);
    const [loading, setLoading] = React.useState(false);

    const handleChange = (event, data) => {
        const newObjState = Object.assign({}, formData, { [data.name]: data.value });
        setFormData(newObjState);
    };

    const isFormValid = () => {
        if (!Object.values(validInputs).every(i => i === true)) return false;
        const { form_field_type_id, form_field_name } = formData;
        if (form_field_type_id !== '' && form_field_name !== '') return true;
    };

    React.useEffect(() => {
        const getFormFieldInfo = async () => {
            try {
                const response = await getData(`crud/protocols/get_form_field_info?form_field_id=${formFieldId}`);
                if (response[0]) {
                    setFormData(response[0]);
                }
            } catch (error) {
                console.log(error);
            }
        };
        if (formFieldId) getFormFieldInfo();
    }, []);

    // handling form submition
    const submitForm = () => {
        !formData.form_field_id ? postNewFormField() : updateFormField();
    };

    const postNewFormField = async () => {
        setLoading(true);
        formData.form_id = formId;
        try {
            await insertSimpleData('crud/protocols/post_form_field', formData);
            setLoading(false);
            setFormData(fieldFormInitialState);
        } catch (error) {
            console.log(error);
        }
    };

    const updateFormField = async () => {
        setLoading(true);
        const { form_field_id, ...rest } = formData;
        try {
            const response = await updateData('crud/protocols/update_form_field', rest, { form_field_id });
            setLoading(false);
            setFormData(response);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <Form loading={loading}>
                <Form.Group>
                    <Form.Input
                        label="Feldname"
                        name="form_field_name"
                        value={formData.form_field_name}
                        required
                        onChange={handleChange}
                        width={10}
                    />
                    <SearchableInput
                        label="Feldtyp"
                        placeholder="Feldertyp auswählen"
                        name="form_field_type_id"
                        value={formData.form_field_type_id}
                        query="crud/protocols/get_form_field_types"
                        onChange={handleChange}
                        width={6}
                    />
                </Form.Group>
                <SearchableInput
                    label="Feldgruppe"
                    placeholder="FelderGruppe auswählen"
                    name="form_field_group_id"
                    value={formData.form_field_group_id}
                    query="crud/protocols/get_forms_fields_groups"
                    onChange={handleChange}
                    width={16}
                />
                <Form.Group>
                    <Form.Input
                        label="Position / Zeichnugsnummer"
                        name="form_field_position"
                        value={formData.form_field_position}
                        onChange={handleChange}
                        width={8}
                    />
                    <SearchableInput
                        label="Abteilung"
                        placeholder="Abteilung auswählen"
                        name="form_field_responsible_department"
                        value={formData.form_field_responsible_department}
                        query="crud/protocols/get_departments_list"
                        onChange={handleChange}
                        width={8}
                        required={false}
                    />
                </Form.Group>
                {formData.form_field_type_id === 2 && (
                    <>
                        <Form.Group>
                            <Form.Input
                                label="SollWert"
                                name="form_field_wanted_value"
                                value={formData.form_field_wanted_value}
                                required
                                onChange={handleChange}
                                width={5}
                            />

                            <Form.Input
                                label="Toleranz"
                                name="form_field_tolerance_value"
                                value={formData.form_field_tolerance_value}
                                required
                                onChange={handleChange}
                                width={5}
                            />

                            <SearchableInput
                                label="Einheit"
                                placeholder="Einheit auswählen"
                                name="form_field_value_units"
                                value={formData.form_field_value_units}
                                query="crud/protocols/get_units"
                                onChange={handleChange}
                                width={6}
                            />
                        </Form.Group>
                    </>
                )}
            </Form>
            <br />
            <Button fluid primary onClick={submitForm} disabled={!isFormValid() || loading}>
                Speichern
            </Button>
        </>
    );
};

export default FieldForm;

const fieldFormInitialState = {
    form_field_type_id: '',
    form_field_name: '',
    form_field_group_id: '',
    form_field_wanted_value: '',
    form_field_tolerance_value: '',
    form_field_value_units: '', // ex, kg, cm, ohms ....
    form_field_position: '', // wetf it means
    form_field_responsible_department: '',
};

const fieldFormInputsValidityState = {
    form_field_type: true,
    form_field_name: true,
    form_field_group_id: true,
    form_field_wanted_value: true,
    form_field_tolerance_value: true,
    form_field_value_units: true, // ex, kg, cm, ohms ....
    form_field_position: true, // wetf it means
    form_field_responsible_department: true,
};

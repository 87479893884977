import React, { useEffect, useState, useMemo } from 'react';
import { get } from 'lodash';
import { PieWidgetFrame, KNZ, GWTitle } from '../../Utils/widgetsStyles';
import produce from 'immer';
import { formatDecimal, loadData } from '../../Utils';
import { Disposition_Offene_Auftraege } from './TooltipsTexts';
import { ChartLabelColors } from '../../config';
import { queryOrdersK5 } from '../Queries';
import { DiscreteColorLegend, RadialChart } from 'react-vis';
import Modal from '../../Utils/Modal';
import Tooltip from '../../Utils/Tooltip';

const Comp = props => {
    const { filters, setFilter, title, width, height, modalOpen, sessionID, userID } = props;
    const [data, setData] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const [kf, setKf] = useState(0);
    const [] = useState(false);

    const setDataWithKF = d => {
        setData(d);
        let kfFromData = 0;
        d.forEach(elem => {
            kfFromData = kfFromData + get(elem, 'anzahl', 0);
        });
        setKf(kfFromData);
    };

    useEffect(() => {
        //const filtersToUse = filteredPAs.length == 1 ? filteredPAs : filters;
        loadData(setLoadingData, setDataWithKF, queryOrdersK5, filters, sessionID, userID, 0);
    }, [filters, sessionID, userID]);

    return useMemo(() => {
        return (
            <PieWidgetFrame loading={loadingData}>
                <KNZ>
                    <GWTitle>
                        <Tooltip text={Disposition_Offene_Auftraege}></Tooltip>
                        <span>{title}</span>
                        {modalOpen ? <span></span> : <Modal graphWidget={<Comp />} config={props} />}
                    </GWTitle>
                    <span className="val">{formatDecimal(kf)}</span>
                </KNZ>
                <RadialChart
                    colorType="literal"
                    width={width}
                    height={height || 200}
                    data={data.map((d, idx) => ({
                        angle: d.anzahl,
                        color: ChartLabelColors[idx],
                        radius: 30,
                        innerRadius: 15,
                        vollst: d.vollst,
                    }))}
                    onValueClick={datapoint => {
                        setFilter({
                            id: 'Vollständigkeit',
                            Attribute: 'vollst',
                            Operator: '=',
                            Value: `'${datapoint.vollst}'`,
                        });
                    }}
                >
                    <DiscreteColorLegend
                        style={{ position: 'absolute', top: '-10px', left: '10px' }}
                        orientation="horizontal"
                        items={data.map((d, idx) => ({
                            title: `${get(d, 'vollst', '') || 'nicht spezifiziert'} (${formatDecimal(
                                get(d, 'anzahl', 0) || 0,
                            )})`,
                            color: ChartLabelColors[idx],
                            onclick: () =>
                                props.setFilters(
                                    produce(filters, draftState => {
                                        draftState['vollst'] = get(d, 'vollst', '');
                                    }),
                                ),
                        }))}
                    />
                </RadialChart>
            </PieWidgetFrame>
        );
    }, [data, loadingData, width]);
};

export default Comp;

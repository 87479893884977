import React from 'react';
import { HistogrammWidgetFrame, Histogramm_KNZ, GWTitle } from '../../Utils/widgetsStyles';
import { COLORS } from '../../global-styles';
import Tooltip from '../../Utils/Tooltip';
import useDimensions from 'react-use-dimensions';

import {
    XYPlot,
    XAxis,
    YAxis,
    HorizontalGridLines,
    VerticalGridLines,
    VerticalBarSeries,
    DiscreteColorLegend,
    Hint,
} from 'react-vis';
import Modal from '../../Utils/Modal';
import { getData } from '../UtilComponents/requestsUtils';
import { Grid, Popup, Button, Header, Icon } from 'semantic-ui-react';

const BarSeries = VerticalBarSeries;

const Comp = props => {
    const { title, height, modalOpen } = props;
    const [loadingData, setLoadingData] = React.useState(false);
    const [ref, { x, y, width }] = useDimensions();
    const [hoverData, setHoverData] = React.useState(null);
    const [createdData, setCreatedData] = React.useState([]);
    const [startedData, setStartedData] = React.useState([]);
    const [inProgressData, setInProgressData] = React.useState([]);
    const [abortedData, setAbortedData] = React.useState([]);
    const [solvedData, setSolvedData] = React.useState([]);
    const [period, setPeriod] = React.useState('');

    const formatHoverData = point => {
        return [
            { title: 'Anzahl', value: point.y },
            { title: 'Monat', value: point.x },
        ];
    };

    const buildData = (data, label) => {
        switch (label) {
            case 'created':
                return data.map(obj => ({ x: obj.month, y: obj.created_issues }));
            case 'started':
                return data.map(obj => ({ x: obj.month, y: obj.started_issues }));
            case 'in_progress':
                return data.map(obj => ({ x: obj.month, y: obj.in_progress_issues }));
            case 'aborted':
                return data.map(obj => ({ x: obj.month, y: obj.aborted_issues }));
            case 'solved':
                return data.map(obj => ({ x: obj.month, y: obj.solved_issues }));
        }
    };

    React.useEffect(() => {
        const getWidgetData = async () => {
            try {
                setLoadingData(true);
                const response = await getData(`crud/qm/a3_statistics`);
                setLoadingData(false);
                getPeriod(response);
                setCreatedData(buildData(response, 'created'));
                setStartedData(buildData(response, 'started'));
                setInProgressData(buildData(response, 'in_progress'));
                setAbortedData(buildData(response, 'aborted'));
                setSolvedData(buildData(response, 'solved'));
            } catch (error) {
                console.log(error);
            }
        };
        getWidgetData();
    }, []);

    const getPeriod = data => {
        try {
            const period = new Set();
            data.forEach(obj => {
                period.add(obj.month_date.split('-')[0]);
            });
            setPeriod(Array.from(period).join(' / '));
        } catch (error) {
            setPeriod(new Date().getFullYear());
        }
    };

    return React.useMemo(() => {
        return (
            <HistogrammWidgetFrame loading={loadingData}>
                <Histogramm_KNZ ref={ref}>
                    <GWTitle>
                        <Popup trigger={<Icon name="info circle" size="tiny" />} flowing hoverable>
                            <Grid padded>
                                Neu = A3 als Meldungstyp ausgewählt <br />
                                Gestartet = A3 Verantwortlicher zugewiesen In <br />
                                Bearbeitung = Zeitraum seit Gestartet <br />
                                Beendet = Karte in "Erledigt" geschoben und alle Phasen durchlaufen
                                <br />
                                Abgebrochen = Karte in "Erledigt" geschoben und nicht alle Phasen durchlaufen
                            </Grid>
                        </Popup>
                        <span>{title}</span>
                        {modalOpen ? <span></span> : <Modal graphWidget={<Comp />} config={props} />}
                    </GWTitle>
                    <div className="kflist">
                        <div>
                            <span className="val">{period}</span>
                        </div>
                    </div>
                </Histogramm_KNZ>
                <XYPlot
                    className="stackedBar"
                    margin={{ bottom: 60, left: 25 }}
                    xType="ordinal"
                    width={width - 20}
                    height={height || 200}
                    color={COLORS.BLUE}
                >
                    <VerticalGridLines />
                    <HorizontalGridLines />
                    <YAxis />
                    <XAxis />
                    {hoverData && <Hint value={hoverData} format={formatHoverData}></Hint>}
                    <BarSeries
                        color={COLORS.YELLOW}
                        data={createdData}
                        onValueMouseOver={d => setHoverData(d)}
                        onValueMouseOut={d => setHoverData(null)}
                    />
                    <BarSeries
                        color={COLORS.GREY}
                        data={startedData}
                        onValueMouseOver={d => setHoverData(d)}
                        onValueMouseOut={d => setHoverData(null)}
                    />
                    <BarSeries
                        color={COLORS.BLUE}
                        data={inProgressData}
                        onValueMouseOver={d => setHoverData(d)}
                        onValueMouseOut={d => setHoverData(null)}
                    />
                    <BarSeries
                        color={COLORS.RED}
                        data={abortedData}
                        onValueMouseOver={d => setHoverData(d)}
                        onValueMouseOut={d => setHoverData(null)}
                    />
                    <BarSeries
                        color={COLORS.GREEN}
                        data={solvedData}
                        onValueMouseOver={d => setHoverData(d)}
                        onValueMouseOut={d => setHoverData(null)}
                    />
                    <DiscreteColorLegend
                        style={{ position: 'absolute', bottom: '0px', left: '50px' }}
                        orientation="horizontal"
                        items={[
                            {
                                title: 'Neu',
                                color: COLORS.YELLOW,
                            },
                            {
                                title: 'Gestartet',
                                color: COLORS.GREY,
                            },

                            {
                                title: 'in Bearbeitung',
                                color: COLORS.BLUE,
                            },
                            {
                                title: 'Abgebrochen',
                                color: COLORS.RED,
                            },
                            {
                                title: 'Beendet',
                                color: COLORS.GREEN,
                            },
                        ]}
                    />
                </XYPlot>
            </HistogrammWidgetFrame>
        );
    }, [width, hoverData, solvedData]);
};

export default Comp;

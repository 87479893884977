import React, { useEffect, useState, useMemo } from 'react';
import { get } from 'lodash';
import { PieWidgetFrame, KNZ, GWTitle } from '../../Utils/widgetsStyles';
import { formatDecimal, loadData } from '../../Utils';
import { Termintreue } from './TooltipsTexts';
import { ChartLabelColors } from '../../config';
import { queryOrdersK7 } from '../Queries';
import { RadialChart, DiscreteColorLegend } from 'react-vis';
import Modal from '../../Utils/Modal';
import Tooltip from '../../Utils/Tooltip';
const Comp = props => {
    const { filters, setFilter, title, width, height, modalOpen, sessionID, userID } = props;
    const [data, setData] = useState([]);
    const [loadingData, setLoadingData] = useState(false);

    useEffect(() => {
        //const filtersToUse = filteredPAs.length == 1 ? filteredPAs : filters;
        loadData(setLoadingData, setData, queryOrdersK7, filters, sessionID, userID, 0);
    }, [filters, sessionID, userID]);

    return useMemo(() => {
        return (
            <PieWidgetFrame loading={loadingData}>
                <KNZ>
                    <GWTitle>
                        <Tooltip text={Termintreue}></Tooltip>
                        <span>{title}</span>
                        {modalOpen ? <span></span> : <Modal graphWidget={<Comp />} config={props} />}
                    </GWTitle>
                    <span className="val">{formatDecimal((get(data, '0.ok', 0) / get(data, '0.all', 0)) * 100)}%</span>
                </KNZ>
                <RadialChart
                    colorType="literal"
                    width={width}
                    height={height || 200}
                    data={[
                        {
                            angle: get(data, '0.ok', 0),
                            color: ChartLabelColors[0],
                            radius: 30,
                            innerRadius: 15,
                            taValue: 'ohneTA',
                        },
                        {
                            angle: get(data, '0.ta_less_than_0_not_possible', 0),
                            color: ChartLabelColors[1],
                            radius: 30,
                            innerRadius: 15,
                            taValue: 'TA < 0 nicht erreichbar',
                        },
                        {
                            angle: get(data, '0.nok', 0),
                            color: ChartLabelColors[2],
                            radius: 30,
                            innerRadius: 15,
                            taValue: 'mitTA',
                        },
                    ]}
                    onValueClick={datapoint => {
                        if (datapoint.taValue == 'mitTA') {
                            setFilter({
                                id: 'TA Abgang',
                                Attribute: 'TA Abgang',
                                Operator: '>',
                                Value: '0',
                            });
                        }
                        if (datapoint.taValue == 'ohneTA') {
                            setFilter({
                                id: 'TA Abgang',
                                Attribute: 'TA Abgang',
                                Operator: '<=',
                                Value: '0',
                            });
                        }
                        if (datapoint.taValue == 'TA < 0 nicht erreichbar') {
                            setFilter({
                                id: 'TA Abgang',
                                Attribute: 'TA Abgang',
                                Operator: '<',
                                Value: '0',
                            });
                        }
                    }}
                >
                    <DiscreteColorLegend
                        style={{ position: 'absolute', top: '-10px', left: '10px' }}
                        orientation="horizontal"
                        items={[
                            { title: `ohne TA (${formatDecimal(get(data, '0.ok', 0))})`, color: ChartLabelColors[0] },

                            { title: `mit TA (${formatDecimal(get(data, '0.nok', 0))})`, color: ChartLabelColors[2] },
                            {
                                title: `TA nicht erreichbar (${formatDecimal(
                                    get(data, '0.ta_less_than_0_not_possible', 0),
                                )})`,
                                color: ChartLabelColors[1],
                            },
                        ]}
                    />
                </RadialChart>
            </PieWidgetFrame>
        );
    }, [data, loadingData, width]);
};

export default Comp;

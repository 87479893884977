import React, { useEffect, useMemo } from 'react';
import Timeline from 'react-visjs-timeline';
import { queryResourcesPlanning } from './Queries';
import { getData } from '../UtilComponents/requestsUtils';

const TimeLine = props => {
    const {
        setResourceModalOpen,
        setResourceFormData,
        setResourceFormAction,
        setResourcePlaningFormData,
        setResourcePlaningModalOpen,
        setResourcePlaningFormAction,
        setLoading,
        refreshTimeLine,
        setDeleteModalOpen,
        setItemToDelete,
        rpFilters,
    } = props;
    const [items, setItems] = React.useState([]);
    const [groups, setGroups] = React.useState([]);

    const dataRef = React.useRef([]);
    const now = new Date();
    const options = {
        locale: 'de',
        editable: true,
        height: '80vh',
        groupEditable: true,
        stack: true,
        stackSubgroups: false,
        start: Date.now(),
        end: new Date(now.getFullYear(), now.getMonth(), now.getDate() + 7),
        selectable: true,
        onUpdate: function(item, callback) {
            // case new resource plan
            if (item['ppsordernumber'] === undefined) {
                const newState = {
                    title: 'Neue Resoursseplan Title',
                    ppsordernumber: item['ppsordernumber'],
                    resource_id: item['group_id'],
                };
                setResourcePlaningFormAction('creation');
                setResourcePlaningFormData(newState);
                setResourcePlaningModalOpen(true);
            }
            // case uppdate resource plan
            if (!!item['ppsordernumber']) {
                const newState = {
                    id: item['id'],
                    title: item['title'],
                    ppsordernumber: item['ppsordernumber'],
                    resource_id: item['resource_id'],
                    status_id: item['status_id'],
                    plan_start: item['start'],
                    plan_end: item['end'],
                };
                setResourcePlaningFormAction('update');
                setResourcePlaningFormData(newState);
                setResourcePlaningModalOpen(true);
            }
        },

        onMove: function(item, callback) {
            const newState = {
                id: item['id'],
                title: item['title'],
                ppsordernumber: item['ppsordernumber'],
                resource_id: item['group'],
                status_id: item['status_id'],
                plan_start: item['start'],
                plan_end: item['end'],
            };
            setResourcePlaningFormAction('update');
            setResourcePlaningFormData(newState);
            setResourcePlaningModalOpen(true);
        },

        onRemove: function(item, callback) {
            setItemToDelete(item);
            setDeleteModalOpen(true);
        },
    };

    const setTimelineData = data => {
        dataRef.current = data;
        const dataLength = data.length;
        const itemsToShow = [];
        const groupsToShow = [];
        // Guarantee that there are no duplicated groups ids in groups to show
        const map = new Map();
        for (const item of data) {
            if (!map.has(item['resource_id'])) {
                map.set(item['resource_id'], true); // set any value to Map
                groupsToShow.push({
                    id: item['resource_id'],
                    content: item['resource_name'] + ' - - ' + item['resource_status_name'],
                });
            }
        }

        for (let i = 0; i < dataLength; i++) {
            const obj = data[i];
            // preparing data to fill items collection
            const planObj = {
                id: obj['id'],
                content:
                    obj['ppsordernumber'] +
                    ' - ' +
                    obj['article_number'] +
                    ' - ' +
                    obj['sl_name'] +
                    ' - ' +
                    obj['status_name'],

                ppsordernumber: obj['ppsordernumber'],
                className: 'expected',
                start: obj['plan_start'],
                // when the resource_status is no free and plan_end is past then push the beam length to today
                end: new Date(obj['plan_end']) < now && obj['resource_status_id'] != 1 ? now : obj['plan_end'],
                group: obj['resource_id'],
                title: obj['title'],
                resource_id: obj['resource_id'],
                status_id: obj['status_id'],
                className: obj['status_name'] ? obj['status_name'].toLowerCase() : '',
            };
            if (obj['plan_start']) {
                itemsToShow.push(planObj);
            }
        }
        setGroups(groupsToShow);
        setItems(itemsToShow);
    };

    const groupsClickHander = event => {
        if (event.event.type != 'click' || event.what != 'group-label') return;
        let group = event.group;
        const obj = dataRef.current.find(obj => obj['resource_id'] === group);
        const newState = {
            id: obj['resource_id'],
            resource_name: obj['resource_name'],
            resource_description: obj['resource_description'],
            storage_location_id: obj['storage_location_id'],
            status_id: obj['resource_status_id'],
            resource_category_id: obj['resource_category_id'],
            resource_barcode: obj['resource_barcode'],
        };
        setResourceFormAction('update');
        setResourceFormData(newState);
        setResourceModalOpen(true);
    };

    useEffect(() => {
        const getResourcesPlaning = async () => {
            setLoading(true);
            try {
                // just to appply filters if necesary
                let filterParams = '';
                if (Object.keys(rpFilters).length > 0) {
                    for (const property in rpFilters) {
                        const value = rpFilters[property];
                        if (value && value.length > 0) {
                            for (let i = 0; i < value.length; i++) {
                                filterParams += `&${property}=${value[i]}`;
                            }
                        }
                    }
                }
                const response = await getData(`${queryResourcesPlanning}?1=1${filterParams}`);
                if (response) {
                    setLoading(false);
                    setTimelineData(response);
                }
            } catch (error) {
                console.log(error);
            }
        };
        setTimeout(() => {
            getResourcesPlaning();
        }, 400);
    }, [refreshTimeLine, rpFilters]);

    return useMemo(() => {
        return <Timeline options={options} items={items} groups={groups} clickHandler={groupsClickHander} />;
    }, [groups, items]);
};

export default TimeLine;

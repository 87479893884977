import React from 'react';
import { Form, Button, Popup } from 'semantic-ui-react';
import { getData, insertSimpleData, updateData } from '../../UtilComponents/requestsUtils';

const comp = ({ issue_id, numberOfTabsToShow, setNumberOfTabsToShow, issue_status }) => {
    const [formData, setFormData] = React.useState(formInitialState);
    const [formFieldsValidity, setFormFieldsValidity] = React.useState(formInputsValidatyInitialState);
    const [loading, setLoading] = React.useState(false);

    const handleChange = (event, data) => {
        const newObjState = Object.assign({}, formData, { [data.name]: data.value });
        setFormData(newObjState);
    };

    const isFormValid = () => {
        if (!Object.values(formFieldsValidity).every(i => i === true)) return false;
        if (!Object.values(formData).every(i => i !== '')) return false;
        return true;
    };

    React.useEffect(() => {
        const getIssueCauses = async () => {
            setLoading(true);
            try {
                const response = await getData(`crud/issues/get_issue_causes?issue_id=${issue_id}`);
                if (response[0]) {
                    setFormData(response[0]);
                }
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        };
        getIssueCauses();
    }, []);

    const createIssueCauses = async () => {
        setLoading(true);
        const body = {
            ...formData,
            issue_id: issue_id,
        };
        try {
            const response = await insertSimpleData('crud/issues/post_issue_causes', body);
            setFormData(response);
            setNumberOfTabsToShow(numberOfTabsToShow + 1);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const updateIssueCauses = async () => {
        setLoading(true);
        const { issue_id, ...rest } = formData;
        try {
            await updateData('crud/issues/update_issue_causes', rest, { issue_id: issue_id });
            setFormData(rest);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const submitForm = () => {
        if (issue_status === 3) {
            alert('Die Meldung darf nicht bearbeitet werden.');
        } else {
            !formData.issue_id ? createIssueCauses() : updateIssueCauses();
        }
    };

    return (
        <Form loading={loading}>
            <Form.TextArea
                label={
                    <Popup
                        content="Aus der Ursachenanalyse sollen hier die direkten Urachen hinterlegt und hinterfragt werden. Dafür sollte mindestens 5x Warum gefragt werden, um die direkte Ursache so lange zu hinterfragen, bis die tatsächliche Ursache gefunden wurde. ​"
                        trigger={<label>Direkte Ursache</label>}
                        wide="very"
                    />
                }
                name="issue_direct_cause"
                rows={3}
                value={formData.issue_direct_cause}
                onChange={handleChange}
            />
            <Form.TextArea
                label={
                    <Popup
                        content="Die tatsächliche Ursache ist das Ergebnis der Ursachenanalyse. Es geht darum den Kern des Problems zu indentifizieren"
                        trigger={<label>Tatsächliche Ursache</label>}
                        wide="very"
                    />
                }
                name="issue_real_cause"
                rows={3}
                value={formData.issue_real_cause}
                onChange={handleChange}
            />

            <Button fluid primary disabled={!isFormValid()} onClick={submitForm}>
                Speichern
            </Button>
        </Form>
    );
};

export default comp;

const formInitialState = {
    issue_direct_cause: '',
    issue_real_cause: '',
};

const formInputsValidatyInitialState = {
    issue_direct_cause: true,
    issue_real_cause: true,
};

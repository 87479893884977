import React from 'react';
import { Segment, Grid } from 'semantic-ui-react';
import styled from 'styled-components';
import { COLORS } from '../../global-styles';
import { NotificationModal } from './NotificationModal';
import { DADColumn } from './Column';
import { issueFormDataInitialState } from './sfUtils';

import TextSearchForView from '../UtilComponents/TextSearchForView';
import { formatForSmartTextSearch } from '../../Utils/Tables';
import ActiveFilterList from './ActiveFilterList';
import IssuesFiltersForm from './IssuesFiltersForm';

export const NotificationsView = () => {
    const [notificationFormData, setNotificationFormData] = React.useState(issueFormDataInitialState);
    const [notificationModalOpen, setNotificationModalOpen] = React.useState(false);
    const [updateColumns, setUpdateColumns] = React.useState('');
    const [selectedField, setSelectedField] = React.useState('issue_title');

    const [activeFilters, setActiveFilters] = React.useState([]);
    const activeFiltersRef = React.useRef(activeFilters); // important to keep filters in event listener up to date

    const searchViewOptions = [
        { key: 'serial_id', text: 'ID', value: 'serial_id' },
        { key: 'issue_title', text: 'Titel', value: 'issue_title' },
        { key: 'issue_description', text: 'Beschreibung', value: 'issue_description' },
    ];

    const addActiveFilter = filter => {
        const filterIndex = activeFiltersRef.current.findIndex(obj => obj['attribute'] === filter['attribute']);
        if (filterIndex === -1) {
            activeFiltersRef.current = [...activeFiltersRef.current, filter];
            setActiveFilters([...activeFiltersRef.current]);
        } else {
            activeFiltersRef.current[filterIndex] = filter;
            setActiveFilters([...activeFiltersRef.current]);
        }
    };

    const removeActiveFilter = filter => {
        const filterIndex = activeFiltersRef.current.findIndex(obj => obj['attribute'] === filter['attribute']);
        activeFiltersRef.current.splice(filterIndex, 1);
        setActiveFilters([...activeFiltersRef.current]);
    };

    const handleFiltersChange = (evt, data) => {
        const newState = data.value;
        // case remove filter completely
        if (newState.length === 0) {
            removeActiveFilter({ attribute: data.name, value: data.value, operator: 'IN' });
        } else {
            addActiveFilter({ attribute: data.name, value: data.value, operator: 'IN' });
        }
    };

    const searchViewOptionsLogic = () => {
        const input = document.getElementById('textToSearchFor');

        const filter = {
            nameToDisplay: searchViewOptions.find(obj => obj.value === selectedField).text,
            attribute: selectedField,
            operator: '',
            value: '',
        };

        if (!input.value) return;

        const [operator, formattedString] = formatForSmartTextSearch(input.value);
        filter.operator = operator;
        filter.value = [input.value];
        addActiveFilter(filter);
    };

    return (
        <TabContent>
            <div>
                <TextSearchForView
                    searchViewOptionsLogic={searchViewOptionsLogic}
                    searchViewOptions={searchViewOptions}
                    setSelectedField={setSelectedField}
                />
            </div>
            <MeldungForm>
                <MenuWrapper>
                    <NotificationModal
                        title="Meldung erstellen"
                        open={notificationModalOpen}
                        setOpen={setNotificationModalOpen}
                        formData={notificationFormData}
                        setFormData={setNotificationFormData}
                        setUpdateColumns={setUpdateColumns}
                    />
                    <br />
                    <strong>Filter</strong>
                    <ActiveFilterList filters={activeFilters} removeFilter={removeActiveFilter} />
                    <br />
                    <IssuesFiltersForm filters={activeFilters} handleFiltersChange={handleFiltersChange} />
                </MenuWrapper>
                <ContentWrapper>
                    <Grid columns="equal">
                        <Grid.Column stretched>
                            <DADColumn
                                label="Neue Meldung"
                                color="red"
                                issue_status={0}
                                setModalOpen={setNotificationModalOpen}
                                setNotificationFormData={setNotificationFormData}
                                updateColumns={updateColumns}
                                activeFilters={activeFilters}
                                setUpdateColumns={setUpdateColumns}
                            />
                        </Grid.Column>
                        <Grid.Column stretched>
                            <DADColumn
                                label="In Arbeit"
                                color="green"
                                issue_status={1}
                                setModalOpen={setNotificationModalOpen}
                                setNotificationFormData={setNotificationFormData}
                                updateColumns={updateColumns}
                                activeFilters={activeFilters}
                                setUpdateColumns={setUpdateColumns}
                            />
                        </Grid.Column>
                        {/* <Grid.Column stretched>
                            <DADColumn
                                label="Rückfragen"
                                color="orange"
                                issue_status={2}
                                setModalOpen={setNotificationModalOpen}
                                setNotificationFormData={setNotificationFormData}
                                updateColumns={updateColumns}
                                activeFilters={activeFilters}
                                setUpdateColumns={setUpdateColumns}
                            />
                        </Grid.Column> */}
                        <Grid.Column stretched>
                            <DADColumn
                                label="Erledigt"
                                color="blue"
                                issue_status={3}
                                setModalOpen={setNotificationModalOpen}
                                setNotificationFormData={setNotificationFormData}
                                updateColumns={updateColumns}
                                activeFilters={activeFilters}
                                setUpdateColumns={setUpdateColumns}
                            />
                        </Grid.Column>
                    </Grid>
                </ContentWrapper>
            </MeldungForm>
        </TabContent>
    );
};

const TabContent = styled.div`
    th {
        color: ${COLORS.BLUE}!important;
    }
    display: flex;
    flex-direction: column;
`;

const MeldungForm = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
`;

const MenuWrapper = styled.div`
    flex: 0 0 auto;
    position: relative;
    width: 230px;
    margin: 1rem 1rem 0 0;
    height: auto;
`;

const ContentWrapper = styled(Segment)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 0 1 auto;
    padding-right: 1rem;
    width: calc(100vw - 300px);
    min-height: 97%;
    & > div {
        height: 100%;
    }
`;

import React from 'react';
import { Form } from 'semantic-ui-react';
import SFWMSFilter from '../UtilComponents/SearchableFilterWithMultiSelection';
import { getIssuesTypesForFilter, queryUsers, queryChangesDepartments } from '../NotificationsView/Queries';

const QMAndNotificationsFiltersForm = props => {
    const { filters, handleFiltersChange } = props;
    const getCurrentFilterValue = (filters, field) => {
        const currentFilter = filters.find(obj => obj['attribute'] === field);
        return currentFilter ? [...currentFilter.value] : [];
    };

    return (
        <Form size="tiny">
            <SFWMSFilter
                label="Meldungstyp"
                query={getIssuesTypesForFilter}
                name="issue_type_id"
                value={getCurrentFilterValue(filters, 'issue_type_id')}
                onChange={handleFiltersChange}
            />
            <SFWMSFilter
                label="Produktionsauftrag"
                query="crud/issues/get_pa_numbers"
                name="ppsordernumber"
                value={getCurrentFilterValue(filters, 'ppsordernumber')}
                onChange={handleFiltersChange}
            />
            <SFWMSFilter
                label="Meldungsersteller"
                query={queryUsers}
                name="issue_creator_id"
                value={getCurrentFilterValue(filters, 'issue_creator_id')}
                onChange={handleFiltersChange}
            />
            <SFWMSFilter
                label="Verantwortlicher"
                query={queryUsers}
                name="issue_responsible_id"
                value={getCurrentFilterValue(filters, 'issue_responsible_id')}
                onChange={handleFiltersChange}
            />
            {/* <SFWMSFilter
                label="Aufgabenverantwortung"
                query={queryUsers}
                name="issue_action_responsible_id"
                value={getCurrentFilterValue(filters, 'issue_action_responsible_id')}
                onChange={handleFiltersChange}
            />
            <SFWMSFilter
                label="Offene Aufgaben"
                query={queryChangesDepartments}
                name="department_with_offene_aufgabe"
                value={getCurrentFilterValue(filters, 'department_with_offene_aufgabe')}
                onChange={handleFiltersChange}
            /> */}
        </Form>
    );
};

export default QMAndNotificationsFiltersForm;

import React from 'react';
import { List, Form, Button, Divider, Icon, Modal } from 'semantic-ui-react';
import { deleteData, getData, insertSimpleData, updateData } from '../UtilComponents/requestsUtils';

const RCategoriesModal = ({ open, setOpen }) => {
    const formInitialState = { id: '', category: '' };
    const [currentCategory, setCurrentCategory] = React.useState(formInitialState);
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState('');
    const [updateList, setUpdateList] = React.useState('');

    const handleChange = (evt, data) => {
        const newObjState = Object.assign({}, currentCategory, { category: data.value });
        setCurrentCategory(newObjState);
    };

    // handling form submition
    const submitForm = () => {
        setLoading(true);
        !currentCategory.id ? createCategory() : updateCategory();
    };

    const createCategory = async () => {
        const body = { category: currentCategory.category };
        try {
            await insertSimpleData(queryPostResourceCategory, body);
            setCurrentCategory(formInitialState);
            setLoading(false);
            setUpdateList('afterCreation' + Date.now().toString());
        } catch (error) {
            console.log(error);
        }
    };

    const updateCategory = async () => {
        const body = { category: currentCategory.category };
        const where = { id: currentCategory.id };
        try {
            await updateData(queryUpdateResourceCategory, body, where);
            setLoading(false);
            setUpdateList('afterUpdate' + Date.now().toString());
            setCurrentCategory(formInitialState);
        } catch (error) {
            console.log(error);
        }
    };

    const deleteAction = async actionId => {
        const where = { id: actionId };
        try {
            await deleteData(queryDeleteIssueCorrectiveAction, where);
            setLoading(false);
            setUpdateList('afterDeletion' + Date.now().toString());
            setCurrentCategory(formInitialState);
        } catch (error) {
            console.log(error);
        }
    };

    React.useEffect(() => {
        const getResourcesCategories = async () => {
            try {
                const response = await getData(queryGetResourcesCategories);
                setData(response);
            } catch (error) {
                console.log(error);
            }
        };
        getResourcesCategories();
    }, [updateList]);

    return (
        <Modal
            size="mini"
            onClose={() => {
                setCurrentCategory(formInitialState);
                setOpen(false);
            }}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={
                <Button primary fluid>
                    Kategorien
                </Button>
            }
        >
            <Modal.Header style={{ textAlign: 'center' }}>Kategorien</Modal.Header>
            <Modal.Content>
                <List relaxed="very">
                    {data.map(obj => {
                        return (
                            <Category {...obj} setCurrentCategory={setCurrentCategory} deleteAction={deleteAction} />
                        );
                    })}
                </List>
                <Divider />
                <br />
                <Form>
                    <Form.TextArea
                        label={currentCategory.id ? 'Kategorie bearbeiten' : 'Kategorie erstellen'}
                        value={currentCategory.category}
                        onChange={handleChange}
                        rows={2}
                    />
                </Form>
                <br />
                <Button fluid primary onClick={submitForm} disabled={currentCategory.category.length === 0 || loading}>
                    Speichern
                </Button>
            </Modal.Content>
        </Modal>
    );
};

export default RCategoriesModal;

const Category = props => {
    const updateAction = () => {
        props.setCurrentCategory({ id: props.id, category: props.category });
    };
    return (
        <List.Item>
            <List.Content style={{ display: 'flex', alignItems: 'stretch' }}>
                <span style={{ flexGrow: '15' }}>
                    {props.id} - {props.category}
                </span>
                <span style={{ flexGrow: '1', display: 'flex', justifyContent: 'flex-end' }}>
                    <Icon name="edit" link onClick={updateAction} />
                </span>
            </List.Content>
        </List.Item>
    );
};

export const queryGetResourcesCategories = 'crud/resources_plan/get_resources_categories';
const queryPostResourceCategory = 'crud/resources_plan/post_resource_category';
const queryUpdateResourceCategory = 'crud/resources_plan/update_resource_category';
const queryDeleteIssueCorrectiveAction = 'crud/issues/delete_issue_corrective_action';

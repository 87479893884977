export const formatForSmartTextSearch = input => {
    const operatorRegex = /^\!:/; // looking for negation symbol
    const trimmedString = input.trim();
    const operator = operatorRegex.test(trimmedString) ? '!~*' : '~*';
    const cleanedString = trimmedString.replace('!:', '').trim();
    const wordsRegex = /[-_\s]+/gi;
    const words = cleanedString.split(wordsRegex);
    const wordsAsString = JSON.stringify(words);
    const formattedString = wordsAsString.replaceAll('"', "'");
    return [operator, formattedString];
    // for the filter to work the final string should be formatted like
    // ALL(ARRAY['word1','word2', 'word3'])
};

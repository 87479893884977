import React from 'react';
import { Form, Button, Popup } from 'semantic-ui-react';
import { getData, insertSimpleData, updateData } from '../../UtilComponents/requestsUtils';
import SInput from '../../UtilComponents/SearchableInput';
import MultiSelectWithSearch from '../../UtilComponents/MultiSelectWithSearch';
import { queryUsers } from '../Queries';

const comp = ({ issue_id, issue_status, numberOfTabsToShow, setNumberOfTabsToShow }) => {
    // when there is not numberOfTabsToShow passes as an argument is issue_type_id == 2 @Fehler.
    const [loading, setLoading] = React.useState(false);

    const [formData, setFormData] = React.useState({
        issue_responsible_id: '',
        users_ids: [],
        issue_location: '',
        issue_process_step: '',
        issue_numbers_data_facts: '',
    });

    const [formFieldsValidity, setFormFieldsValidity] = React.useState({
        issue_responsible_id: true,
        users_ids: true,
        issue_location: true,
        issue_process_step: true,
        issue_numbers_data_facts: true,
    });

    const handleChange = (event, data) => {
        const newObjState = Object.assign({}, formData, { [data.name]: data.value });
        setFormData(newObjState);
    };

    const isFormValid = () => {
        if (!Object.values(formFieldsValidity).every(i => i === true)) return false;
        //if (!Object.values(formData).every(i => i !== '')) return false;
        return true;
    };

    React.useEffect(() => {
        const getIssueDetails = async () => {
            setLoading(true);
            try {
                const response = await getData(`crud/issues/get_issue_details?issue_id=${issue_id}`);
                if (response[0]) {
                    setFormData(response[0]);
                }
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        };
        getIssueDetails();
    }, []);

    const createDetails = async () => {
        setLoading(true);
        const { users_ids, ...rest } = formData;
        const body = {
            ...rest,
            issue_id: issue_id,
        };
        try {
            const response = await insertSimpleData('crud/issues/post_issue_details', body);
            setFormData({ ...response, users_ids });
            if (numberOfTabsToShow >= 0) setNumberOfTabsToShow(numberOfTabsToShow + 1);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const updateDetails = async () => {
        setLoading(true);
        const { issue_id, users_ids, issue_details_id, ...rest } = formData;
        try {
            const response = await updateData('crud/issues/update_issue_details', rest, {
                issue_details_id: issue_details_id,
            });
            setFormData({ ...response, users_ids });
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const submitForm = () => {
        if (issue_status === 3) {
            alert('Die Meldung darf nicht bearbeitet werden.');
        } else {
            !formData.issue_details_id ? createDetails() : updateDetails();
        }
    };

    return (
        <Form loading={loading}>
            <Form.Group>
                <SInput
                    label="Verantwortlicher"
                    placeholder="Verantwortlicher auswählen"
                    name="issue_responsible_id"
                    value={formData.issue_responsible_id}
                    onChange={handleChange}
                    query={queryUsers}
                    width={4}
                    required={false}
                />

                <MultiSelectWithSearch
                    label="Team"
                    placeholder="Team Mitglieder auswählen"
                    name="users_ids"
                    query={queryUsers}
                    primaryKey="issue_id"
                    primaryKeyValue={issue_id}
                    foreignKey="user_id"
                    additionQuery="crud/issues/add_user_to_issue"
                    deletionQuery="crud/issues/delete_user_from_issue"
                    value={formData.users_ids}
                    handleChange={handleChange}
                    width={12}
                    fluid={false}
                    required={false}
                />
            </Form.Group>
            <Form.Input
                label={
                    <Popup
                        content=" Wo ist der Fehler aufgetreten? Es muss kein Ort sein, sondern kann auch eine Stelle an einem Bauteil sein.​"
                        trigger={<label>Fehlerort</label>}
                    />
                }
                name="issue_location"
                value={formData.issue_location}
                onChange={handleChange}
            />
            <Form.Input
                label={
                    <Popup
                        content="Was wurde gerade gemacht, als der Fehler aufgefallen ist oder in welchem Produktionsschritt hat sich z.B. das Bauteil gerade befunden?​"
                        trigger={<label>Prozessschritt</label>}
                    />
                }
                name="issue_process_step"
                value={formData.issue_process_step}
                onChange={handleChange}
            />
            <Form.TextArea
                label={
                    <Popup
                        content="Bitte trage hier alles ein, was zum Verständnis für alle Teammitglieder beiträgt (z.B. Messdaten)​"
                        trigger={
                            <label>{numberOfTabsToShow ? 'Zahlen, Daten, Fakten' : 'Fehlerdokumentation'} ​</label>
                        }
                    />
                }
                name="issue_numbers_data_facts"
                rows={4}
                value={formData.issue_numbers_data_facts}
                onChange={handleChange}
            />

            <Button fluid primary disabled={!isFormValid()} onClick={submitForm}>
                Speichern
            </Button>
        </Form>
    );
};
export default comp;

import React from 'react';
import styled from 'styled-components';
import { formatDate, isColumnSorted, setSortIcon } from '../../../Utils';

export const buildTableColumns = (setSort, sorts) => {
    return [
        {
            XlsHeader: 'ID',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'serial_id')
                            ? setSortIcon(sorts, 'serial_id') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'serial_id',
                            Attribute: 'serial_id',
                            Order: 'asc',
                        })
                    }
                >
                    ID
                </SortableHeader>
            ),
            accessor: 'serial_id',
        },
        {
            XlsHeader: 'Meldungstyp',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'issue_type')
                            ? setSortIcon(sorts, 'issue_type') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'issue_type',
                            Attribute: 'issue_type',
                            Order: 'asc',
                        })
                    }
                >
                    Meldungstyp
                </SortableHeader>
            ),
            accessor: 'issue_type',
        },
        {
            XlsHeader: 'Titel',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'issue_title')
                            ? setSortIcon(sorts, 'issue_title') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'issue_title',
                            Attribute: 'issue_title',
                            Order: 'asc',
                        })
                    }
                >
                    Titel
                </SortableHeader>
            ),
            accessor: 'issue_title',
        },
        {
            XlsHeader: 'Verantwortlicher',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'issue_responsible')
                            ? setSortIcon(sorts, 'issue_responsible') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'issue_responsible',
                            Attribute: 'issue_responsible',
                            Order: 'asc',
                        })
                    }
                >
                    Verantwortlicher
                </SortableHeader>
            ),
            accessor: 'responsible_name',
        },
        {
            XlsHeader: 'Status',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'status') ? (setSortIcon(sorts, 'status') == 'asc' ? 'asc' : 'desc') : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'status',
                            Attribute: 'status',
                            Order: 'asc',
                        })
                    }
                >
                    Status
                </SortableHeader>
            ),
            accessor: 'status',
        },
        {
            XlsHeader: 'Erstellungsdatum',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'issue_creation_date')
                            ? setSortIcon(sorts, 'issue_creation_date') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'issue_creation_date',
                            Attribute: 'issue_creation_date',
                            Order: 'asc',
                        })
                    }
                >
                    Erstellungsdatum
                </SortableHeader>
            ),
            accessor: 'issue_creation_date',
            Cell: ({ cell: { value } }) => formatDate(value),
        },
        {
            XlsHeader: 'aktueller Stand​',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'aktueller Stand​')
                            ? setSortIcon(sorts, 'aktueller Stand​') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'aktueller Stand​',
                            Attribute: 'aktueller Stand​',
                            Order: 'asc',
                        })
                    }
                >
                    aktueller Stand​
                </SortableHeader>
            ),
            accessor: 'aktueller Stand​',
        },
        {
            XlsHeader: 'Nächster Termin',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'Nächster Termin')
                            ? setSortIcon(sorts, 'Nächster Termin') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'Nächster Termin',
                            Attribute: 'Nächster Termin',
                            Order: 'asc',
                        })
                    }
                >
                    Nächster Termin
                </SortableHeader>
            ),
            accessor: 'issue_deadline',
            Cell: ({ cell: { value } }) => formatDate(value),
        },
        {
            XlsHeader: 'Enddatum',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'issue_solved_date')
                            ? setSortIcon(sorts, 'issue_solved_date') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'issue_solved_date',
                            Attribute: 'issue_solved_date',
                            Order: 'asc',
                        })
                    }
                >
                    Enddatum
                </SortableHeader>
            ),
            accessor: 'issue_solved_date',
            Cell: ({ cell: { value } }) => formatDate(value),
        },
        {
            XlsHeader: 'Beschreibung',
            Header: () => (
                <SortableHeader
                    className={
                        isColumnSorted(sorts, 'beschreibung')
                            ? setSortIcon(sorts, 'beschreibung') == 'asc'
                                ? 'asc'
                                : 'desc'
                            : ''
                    }
                    onClick={() =>
                        setSort({
                            id: 'beschreibung',
                            Attribute: 'beschreibung',
                            Order: 'asc',
                        })
                    }
                >
                    Beschreibung
                </SortableHeader>
            ),
            accessor: 'issue_description',
            maxWidth: 70,
        },
    ];
};

const SortableHeader = styled.span`
    cursor: pointer;
    &:after {
        content: '';
        border-width: 0 4px 4px;
        margin: 0px 4px;
        border-style: solid;
        border-color: #404040 transparent;
        display: inline-block;
        vertical-align: middle;
        visibility: hidden;
    }
    &.asc:after {
        border-width: 0 4px 4px;
        visibility: visible;
    }
    &.desc:after {
        border-width: 4px 4px 0;
        visibility: visible;
    }
`;

import React, { useState, useEffect } from 'react';
import { Form } from 'semantic-ui-react';
import { loadData } from '../Utils';

const comp = props => {
    const { filters, setFilter, removeFilter, field, query, label, sessionID, userID } = props;

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [value, setValue] = React.useState(getCurrentFilterValue(filters, field));
    // This filters are shown at the left side, allow the user to interact
    useEffect(() => {
        loadData(setLoading, setData, query, filters, sessionID, userID, 0);
    }, [sessionID]);

    const options = data.map(d => ({
        key: d[field],
        value: d[field],
        text: d['Anzahl'] ? `${d[field]} (${d['Anzahl']})` : d[field],
    }));

    return (
        <Form loading={loading} size="tiny">
            <Form.Select
                selectOnBlur={false}
                fluid
                label={label}
                options={options}
                search
                multiple
                value={value}
                onChange={(event, data) => {
                    if (data.value && data.value.length == 0) {
                        setValue([]);
                        removeFilter(field);
                    }
                    if (data.value && data.value.length > 0) {
                        setValue(data.value);
                        setFilter({
                            id: field,
                            Attribute: field,
                            Operator: ' = ANY ',
                            Value: `${formatArrayForSQL_IN(data.value)}`,
                        });
                    }
                }}
            />
        </Form>
    );
};

const formatArrayForSQL_IN = array => {
    if (!array || array.length < 0) return array;
    const arrayAsString = JSON.stringify(array);
    const formattedString = arrayAsString.replaceAll('"', "'");
    return `(ARRAY${formattedString})`;
};

const formatStringForInputValue = s => {
    const formattedString = s.replaceAll("'", '"');
    return JSON.parse(formattedString);
};

const getCurrentFilterValue = (filters, field) => {
    const currentFilter = filters.find(obj => obj['Attribute'] === field);
    if (currentFilter) {
        const regexpValue = /(\[.+\])/;
        const match = currentFilter.Value.match(regexpValue);
        return formatStringForInputValue(match[1]);
    } else {
        return [];
    }
};

export default comp;

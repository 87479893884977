import React, { useState } from 'react';
import { Segment } from 'semantic-ui-react';
import styled from 'styled-components';
import { COLORS } from '../../global-styles';
import TimeLine from './TimeLine';
import ResourcePlaningModal from './ResourcePlaningModal';
import ResourceModal from './ResourceModal';
import { resourceFormDataInitialState, rpFormDataInitialState } from './rpUtils';
import ConfirmDeleteModal from '../UtilComponents/ConfirmDeleteModal';
import { deleteData } from '../UtilComponents/requestsUtils';
import RPFiltersForm from './RPFiltersForm';
import RCategoriesModal from './RCategoriesModal';

const ResourcesPlanView = props => {
    const [refreshTimeLine, setRefreshTimeLine] = React.useState('');
    const [loading, setLoading] = useState(true);
    // Resource modal variables
    const [resourceModalOpen, setResourceModalOpen] = useState(false);
    const [resourceFormData, setResourceFormData] = React.useState(resourceFormDataInitialState);
    const [resourceFormAction, setResourceFormAction] = React.useState('creation');
    // Resource planing modal variables
    const [resourcePlaningModalOpen, setResourcePlaningModalOpen] = useState(false);
    const [resourcePlaningData, setResourcePlaningFormData] = React.useState(rpFormDataInitialState);
    const [resourcePlaningFormAction, setResourcePlaningFormAction] = React.useState('creation');
    // Resoruce Categories Modal variables
    const [resourceCategoriesModalOpen, setResourceCategoriesModalOpen] = useState(false);

    // Deleting
    const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
    const [itemToDelete, setItemToDelete] = React.useState({});

    // Filters
    const [rpFilters, setRPFilters] = React.useState({ resource_category_id: [], resource_status_id: [] });
    const handleFiltersChange = (event, data) => {
        const newObjState = Object.assign({}, rpFilters, { [data.name]: data.value });
        setRPFilters(newObjState);
    };

    const deleteResourcePlan = async rpId => {
        const where = { id: rpId };
        try {
            await deleteData('crud/resources_plan/delete_resource_plan', where);
            setRefreshTimeLine('AfterDelete' + Date.now().toString());
            setDeleteModalOpen(false);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <TabContent>
            <MenuWrapper>
                <ResourcePlaningModal
                    open={resourcePlaningModalOpen}
                    setOpen={setResourcePlaningModalOpen}
                    formData={resourcePlaningData}
                    setFormData={setResourcePlaningFormData}
                    setRefreshTimeLine={setRefreshTimeLine}
                    formAction={resourcePlaningFormAction}
                    setFormAction={setResourcePlaningFormAction}
                />
                <br />
                <ResourceModal
                    open={resourceModalOpen}
                    setOpen={setResourceModalOpen}
                    formData={resourceFormData}
                    setFormData={setResourceFormData}
                    formAction={resourceFormAction}
                    setFormAction={setResourceFormAction}
                    setRefreshTimeLine={setRefreshTimeLine}
                />
                <br />
                <RCategoriesModal open={resourceCategoriesModalOpen} setOpen={setResourceCategoriesModalOpen} />
                <br />
                <RPFiltersForm rpFilters={rpFilters} handleFiltersChange={handleFiltersChange} />
            </MenuWrapper>
            <ContentWrapper loading={loading}>
                <TimeLine
                    refreshTimeLine={refreshTimeLine}
                    setResourceFormData={setResourceFormData}
                    setResourceModalOpen={setResourceModalOpen}
                    setResourceFormAction={setResourceFormAction}
                    setResourcePlaningFormData={setResourcePlaningFormData}
                    setResourcePlaningModalOpen={setResourcePlaningModalOpen}
                    setResourcePlaningFormAction={setResourcePlaningFormAction}
                    setLoading={setLoading}
                    setDeleteModalOpen={setDeleteModalOpen}
                    setItemToDelete={setItemToDelete}
                    rpFilters={rpFilters}
                />
            </ContentWrapper>
            <ConfirmDeleteModal
                open={deleteModalOpen}
                setOpen={setDeleteModalOpen}
                title="Ressourceplan löschen"
                confirmationMessage="Sind Sie sicher dass Sie dieser Ressourceplan löschen möchten?"
                objectToDeleteInfo={itemToDelete['content']}
                deleteCallback={deleteResourcePlan}
                idOfObjectToDelete={itemToDelete['id']}
            />
        </TabContent>
    );
};

export default ResourcesPlanView;

const TabContent = styled.div`
    th {
        color: ${COLORS.BLUE}!important;
    }
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

const ContentWrapper = styled(Segment)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 0 1 auto;
    padding-right: 1rem;
    width: calc(100vw - 300px);

    .vis-item.expected {
        background-color: transparent;
        border: dashed 1px black !important;
        z-index: 2;
    }

    .vis-item.frei {
        background-color: ${COLORS.GREEN};
        border: solid 1px ${COLORS.GREEN} !important;
        text-align: center;
        z-index: 1;
    }

    .vis-item.belegt {
        background-color: ${COLORS.RED};
        border: solid 1px ${COLORS.RED} !important;
        text-align: center;
        z-index: 1;
    }

    .vis-item.reinigung {
        background-color: ${COLORS.YELLOW};
        border: solid 1px ${COLORS.YELLOW} !important;
        text-align: center;
        z-index: 1;
    }

    .vis-item.vis-selected {
        opacity: 0.6;
    }
    .vis-item.vis-background.marker {
        border-left: 2px solid green;
    }

    /* gray background in weekends, white text color */
    .vis-time-axis .vis-grid.vis-saturday,
    .vis-time-axis .vis-grid.vis-sunday {
        background: #d1d3d4;
    }

    .vis-item .vis-delete {
        padding: 5px;
    }
`;

const MenuWrapper = styled.div`
    flex: 0 0 auto;
    position: relative;
    width: 230px;
    margin: 1rem 1rem 0 0;
    height: auto;
`;

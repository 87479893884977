import React from 'react';
import { get } from 'lodash';
import XLSX from 'xlsx';
import styled from 'styled-components';
import { useTable, usePagination, useResizeColumns } from 'react-table';
import { Table, Button, Dropdown, Icon } from 'semantic-ui-react';
import TextSearchForColumns from '../../../Table/TextSearchForColumns';

// Create a default prop getter to implement the conditional row styling
const defaultPropGetter = () => ({});
const TableTemplate = ({
    columns,
    data,
    loading,
    loadingExcel,
    setLoadingExcel,
    setFilter,
    setSelectedField,
    searchTableOptions,
    searchTableOptionsLogic,
    getRowProps = defaultPropGetter, // for styling the row conditionally
}) => {
    const {
        getTableProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
        },
        usePagination,
        useResizeColumns,
    );
    // Render the UI for your table
    //
    return (
        <TableW>
            <UpperMenu>
                <div />
                {/* <TextSearchForColumns
                    setFilter={setFilter}
                    setSelectedField={setSelectedField}
                    searchTableOptions={searchTableOptions}
                    searchTableOptionsLogic={searchTableOptionsLogic}
                /> */}
                <div>
                    <Button
                        basic
                        compact
                        loading={loadingExcel}
                        onClick={() => exportExcel(columns, data, setLoadingExcel)}
                    >
                        <Icon name="file excel outline" />
                        Export Excel
                    </Button>
                    <Button
                        basic
                        compact
                        icon="chevron left"
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                    />
                    <div>
                        Seite {pageIndex + 1 || 1} von {pageOptions.length}
                    </div>
                    <Button basic compact icon="chevron right" onClick={() => nextPage()} disabled={!canNextPage} />
                    <span>
                        Datensätze pro Seite{' '}
                        <Dropdown
                            inline
                            onChange={(x, y) => {
                                setPageSize(Number(y.value));
                            }}
                            options={[
                                { key: 10, text: '10', value: 10 },
                                { key: 20, text: '20', value: 20 },
                                { key: 50, text: '50', value: 50 },
                                { key: 100, text: '100', value: 100 },
                            ]}
                            defaultValue={pageSize}
                        />
                    </span>
                    &nbsp;&nbsp;
                </div>
            </UpperMenu>
            <DTable>
                <Table singleLine striped basic="very" compact {...getTableProps()}>
                    <thead>
                        {headerGroups.slice(1, 2).map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {!loading && (
                            <React.Fragment>
                                {page.map(row => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps(getRowProps(row))}>
                                            {row.cells.map(cell => {
                                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                                            })}
                                        </tr>
                                    );
                                })}
                            </React.Fragment>
                        )}
                    </tbody>
                </Table>
            </DTable>
        </TableW>
    );
};

const exportExcel = (columns, data) => {
    const xlsData = [get(columns, '0.columns', []).map(c => c.XlsHeader)];
    const accessors = get(columns, '0.columns', []).map(c => c.accessor);
    data.forEach(d => {
        xlsData.push(accessors.map(a => d[a]));
    });
    const worksheet = XLSX.utils.aoa_to_sheet(xlsData);
    const newWorkbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(newWorkbook, worksheet, 'sheet');
    XLSX.writeFile(newWorkbook, 'Qualitätsmanagement.xlsb');
};

export default TableTemplate;

const UpperMenu = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.5rem;
    input[id='textToSearchFor'] {
        width: 400px;
    }
    button[id='search'] {
        border: 1px solid rgba(34, 36, 38, 0.15);
        border-radius: 0px 5px 5px 0px;
    }
    div {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        button {
            box-shadow: none !important;
        }
        > * {
            margin-left: 0rem !important;
        }
    }
    height: 5vh;
`;

const DTable = styled.div`
    height: 25vh !important;
    width: 100%;
    overflow: auto;
    table {
        position: relative;
        width: 2000px !important;
        thead th {
            background: white !important;
            position: sticky !important;
            top: 0;
        }
    }
    table {
        tr.red {
            color: red;
        }
    }
`;
const TableW = styled.div`
    position: relative;
    width: 100%;
`;

import { findIndex, get } from 'lodash';

export const getFiltersNoValidInContext = context => {
    let filtersNoValidInContext = [];
    switch (context) {
        case 'Auftragssicht':
            filtersNoValidInContext = ['Ressourcengruppe', 'Beschreibung-AVG', 'PPSOrderID'];
            break;
        case 'Arbeitssystemsicht':
            filtersNoValidInContext = [
                'Artikelgruppe',
                'Vollständigkeit',
                'Abgeschlossen',
                'Bezeichnung',
                'Buchungsart',
                'Artikelgruppe',
                'AB',
                'AB LT',
                'Lieferschein',
                'Seriennummern',
                'Status',
            ];
            break;
        case 'TA-Plan-Ist':
            filtersNoValidInContext = ['Ressourcengruppe', 'Beschreibung-AVG', 'PPSOrderID'];
        default:
            filtersNoValidInContext = ['Ressourcengruppe', 'Beschreibung-AVG', 'PPSOrderID'];
    }
    return filtersNoValidInContext;
};

export const indexPAsInFilters = filters => {
    const pasIndex = findIndex(filters, { id: 'PA-Nummer', Attribute: 'PA-Nummer', Operator: 'IN' });
    return pasIndex;
};

export const arePAsEqual = (filters, pasFilter) => {
    const pasIndex = indexPAsInFilters(filters);
    if (pasIndex < 0) return false;
    const oldPasValue = filters[pasIndex]['Value'];
    const newPasValue = pasFilter['Value'];
    if (oldPasValue == newPasValue) return true;
    return false;
};

export const areFiltersValidInContext = (filters, tab) => {
    const filtersNoValidInContext = getFiltersNoValidInContext(tab);
    const contextfilters = filters.filter(obj => !filtersNoValidInContext.includes(get(obj, 'id', '')));
    if (contextfilters.length != filters.length) {
        return false;
    }
    return true;
};

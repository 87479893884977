import React from 'react';
import { Button, Modal, Form, Checkbox } from 'semantic-ui-react';
import { roleFormInitialState } from '.';
import { ValidationErrorMessage } from '../UtilComponents/Feedbackmessages';
import { insertSimpleData, updateData } from '../UtilComponents/requestsUtils';
import { isTitleValid, isDescriptionValid } from '../UtilComponents/validations';

const centered = {
    textAlign: 'center',
};

function RoleFormModal(props) {
    const { open, setOpen, formData, setFormData, setUpdateRoleList } = props;
    const [formFieldsValidity, setFormFieldsValidity] = React.useState(roleFormValidityInitialState);
    const [loading, setLoading] = React.useState(false);

    const handleChange = (event, data) => {
        const newObjState = Object.assign({}, formData, { [data.name]: data.value || data.checked });
        setFormData(newObjState);
    };

    const validateName = () => {
        const { role_name } = formData;
        const newObjState = Object.assign({}, formFieldsValidity, { role_name: isTitleValid(role_name) });
        setFormFieldsValidity(newObjState);
    };

    const validateDescription = () => {
        const { role_description } = formData;
        const newObjState = Object.assign({}, formFieldsValidity, {
            role_description: isDescriptionValid(role_description),
        });
        setFormFieldsValidity(newObjState);
    };

    const isFormValid = () => {
        if (!Object.values(formFieldsValidity).every(i => i === true)) return false;
        if (!Object.values(formData).every(i => i !== '')) return false;
        return true;
    };

    // handling form submition
    const submitForm = () => {
        formData.id ? updateRole() : postNewRole();
    };

    const postNewRole = async () => {
        setLoading(true);
        try {
            await insertSimpleData('crud/settings/post_role', formData);
            setUpdateRoleList('afterCreation' + Date.now().toString());
            closeModal();
        } catch (error) {
            console.log(error);
        }
    };

    const updateRole = async () => {
        setLoading(true);
        const { id, ...rest } = formData;
        try {
            await updateData('crud/settings/update_role', rest, { id: id });
            setUpdateRoleList('afterUpdate' + Date.now().toString());
            closeModal();
        } catch (error) {
            console.log(error);
        }
    };

    const closeModal = () => {
        setFormData(roleFormInitialState);
        setOpen(false);
        setLoading(false);
    };

    return (
        <Modal size="mini" onClose={closeModal} onOpen={() => setOpen(true)} open={open}>
            <Modal.Header style={centered}>
                {formData.id === undefined ? 'Rolle erstellen' : 'Rolle bearbeiten'}
            </Modal.Header>
            <Modal.Content>
                <ValidationErrorMessages formFieldsValidity={formFieldsValidity} formData={formData} />
                <Form loading={loading}>
                    <Form.Input
                        label="Rolle"
                        name="role_name"
                        value={formData.role_name}
                        required
                        onChange={handleChange}
                        onBlur={validateName}
                        error={!formFieldsValidity.role_name}
                    />
                    <Form.TextArea
                        label="Beschreibung"
                        name="role_description"
                        value={formData.role_description}
                        required
                        onChange={handleChange}
                        onBlur={validateDescription}
                        error={!formFieldsValidity.role_description}
                    />
                    <Form.Group>
                        <Form.Checkbox
                            label="Auftragssicht"
                            name="auftragssicht"
                            checked={formData.auftragssicht}
                            onClick={handleChange}
                        />

                        <Form.Checkbox
                            label="Arbeitssystemsicht"
                            name="arbeitssystemsicht"
                            checked={formData.arbeitssystemsicht}
                            onClick={handleChange}
                        />
                    </Form.Group>

                    <Form.Group>
                        <Form.Checkbox
                            label="TA-Plan-Ist"
                            name="ta_plan_ist"
                            checked={formData.ta_plan_ist}
                            onClick={handleChange}
                        />
                        <Form.Checkbox
                            label="Ressourcen Planung"
                            name="ressourcen_planung"
                            checked={formData.ressourcen_planung}
                            onClick={handleChange}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Checkbox
                            label="Meldungen"
                            name="meldungen"
                            checked={formData.meldungen}
                            onClick={handleChange}
                        />
                        <Form.Checkbox
                            label="Qualitätsmanagement"
                            name="quality_management"
                            checked={formData.quality_management}
                            onClick={handleChange}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Checkbox
                            label="Fertigung"
                            name="digital_8_hour"
                            checked={formData.digital_8_hour}
                            onClick={handleChange}
                        />
                        <Form.Checkbox
                            label="Protokolle"
                            name="protokolle"
                            checked={formData.protokolle}
                            onClick={handleChange}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Checkbox
                            label="Meldungsstatistiken"
                            name="notification_statistics"
                            checked={formData.notification_statistics}
                            onClick={handleChange}
                        />
                    </Form.Group>
                </Form>
                <br />
                <Button fluid primary onClick={submitForm} disabled={!isFormValid() || loading}>
                    Speichern
                </Button>
            </Modal.Content>
        </Modal>
    );
}

export default RoleFormModal;

const roleFormValidityInitialState = {
    role_name: true,
    role_description: true,
};

const ValidationErrorMessages = ({ formFieldsValidity, formData }) => {
    let validationMsg = {};

    if (formData.role_name !== '' && !formFieldsValidity.role_name) {
        validationMsg = validationTexts.name;
    }

    if (formData.role_description !== '' && !formFieldsValidity.role_description) {
        validationMsg = validationTexts.description;
    }

    return Object.keys(validationMsg).length === 0 ? '' : <ValidationErrorMessage message={validationMsg} />;
};

const validationTexts = {
    name: {
        header: 'Rolename ist nicht korrekt',
        description: 'Rolename muss wenigstens auf 3 Buchstaben bestehen.',
    },
    description: {
        header: 'Beschreibung ist nicht korrekt',
        description: 'Beschreibung muss wenigstens auf 8 Buchstaben bestehen.',
    },
};

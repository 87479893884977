import { createGlobalStyle } from 'styled-components';

import 'semantic-ui-css/semantic.min.css';

export const COLORS = {
    BLUE: '#3269B4',
    GREY: '#605F5E',
    GREEN: 'rgb(0, 176, 80)',
    RED: 'rgb(255, 0, 0)',
    YELLOW: 'rgb(251, 201, 80)',
    TXT: 'rgb(63, 63, 63)',

    TEXT_KUA: '#505a59',
    RED_KUA: '#962321',
};

const GlobalStyle = createGlobalStyle`
  html,
  body {
    min-height: 100%;
    width: 100%;
    font-size:12px;
    color:${COLORS.TEXT_KUA};
    line-height:1.2em!important;
    font-family: 'Montserrat', sans-serif;
  }
  h1{
    font-size:1.7rem!important;
  }
  h2{
    font-size:1.5rem!important;
  }
  h3{
    font-size:1.3rem!important;
  }
  h4{
    font-size:1.2rem!important;
  }
  h5{
    font-size:1.1rem!important;
  }

  code{
    font-family:monospace!important;
    *{
      font-family:monospace!important;
    }
  }
  a{
    color: ${COLORS.TEXT_KUA} !important;
    cursor:pointer;
  }  

  .ui.primary.button {
    background: ${COLORS.RED_KUA}!important;
    color: white !important;
  }

  th[role=columnheader] > span {
    color: ${COLORS.TEXT_KUA} !important;
    cursor:pointer;
  }

  #app {
    background-color: white;
    min-height: 100%;
    width: 100%;
  }
`;

export default GlobalStyle;

import React, { useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import styled from 'styled-components';
import { Form } from 'semantic-ui-react';
import de from 'date-fns/locale/de';
registerLocale('de', de);

const comp = props => {
    const { name, label, value, handleChange, plan_start, plan_end } = props;
    // Always check if plan dates are setted and if so display setted value
    const [startDate, setStartDate] = useState(!!plan_start ? new Date(plan_start) : null);
    const [endDate, setEndDate] = useState(!!plan_end ? new Date(plan_end) : null);
    const [closeDatePicker, setCloseDatePicker] = useState(false);

    React.useEffect(() => {
        // will be called normally when opening the modal
        handleChange(
            {},
            {
                name: name,
                value: buildDateToDisplayFromStrings(plan_start, plan_end),
                value_for_sql:
                    formatDateForSQL(new Date(plan_start)) + dateSeparator + formatDateForSQL(new Date(plan_end)),
            },
        );
        // set the range in the calender UI
        if (!!plan_start) setStartDate(new Date(plan_start));
        if (!!plan_end) setEndDate(new Date(plan_end));
    }, [plan_start, plan_end]);

    const onChange = (dates, e) => {
        const [start, end] = dates;
        // to make the date picker close nicely when user closed enddate
        start && !end ? setCloseDatePicker(true) : setCloseDatePicker(false);
        // both states need to be setted in the same render cycle
        setStartDate(start);
        setEndDate(end);
        if (start && end) {
            // handleChange({}, { name: name, value: formatDateForSQL(start) + ':' + formatDateForSQL(end) });
            handleChange(
                {},
                {
                    name: name,
                    value: buildDateToDisplay(start, end),
                    value_for_sql: formatDateForSQL(start) + dateSeparator + formatDateForSQL(end),
                },
            );
        }
    };

    return (
        <DatePickerStyled>
            <DatePicker
                showPopperArrow={false}
                value={value}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                locale="de"
                shouldCloseOnSelect={closeDatePicker}
                customInput={<Form.Input label={label} fluid />}
            />
        </DatePickerStyled>
    );
};

export default comp;

// yyyy-mm-dd
const formatDateForSQL = date => {
    if (!date) return '';
    return date.toLocaleString('fr-CA', { year: 'numeric', month: 'numeric', day: 'numeric' });
};
//dd.mm.yyyy
export const formatDateToDE = date => {
    if (!date) return '';
    return date.toLocaleString('de-DE', { year: 'numeric', month: 'numeric', day: 'numeric' });
};

const buildDateToDisplay = (initialDate, finalDate) => {
    return formatDateToDE(initialDate) + dateSeparator + formatDateToDE(finalDate);
};

const buildDateToDisplayFromStrings = (startDate, endDate) => {
    const sd = !!startDate ? new Date(startDate) : '';
    const ed = !!endDate ? new Date(endDate) : '';
    return formatDateToDE(sd) + dateSeparator + formatDateToDE(ed);
};

export const dateSeparator = ' - - ';

export const getPlanStartAndEndFromDateToDisplay = dtd => {
    let [start, end] = dtd.split(dateSeparator);
    start = start.split('.');
    start = `${start[2]}-${start[1]}-${start[0]}`;
    end = end.split('.');
    end = `${end[2]}-${end[1]}-${end[0]}`;
    return [start, end];
};

const DatePickerStyled = styled.div`
    .react-datepicker-wrapper {
        width: 100%;
        margin-bottom: 1rem;
    }
`;

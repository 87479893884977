import React from 'react';
import { version } from '../../package.json';
import styled from 'styled-components';
import { COLORS } from '../global-styles';
import ActiveFilters from './ActiveFilters';
import RangeDatePicker from './RangeDatePicker';
import MultiSearchSelectionDropdown from './MultiSearchSelectionDropdown';

import {
    queryOrdersIsClosedForFilter,
    queryOrdersProductsGroupsForFilter,
    queryOrdersIsHauptPAForFilter,
    queryOrdersHasSeriennummernForFilter,
    queryArticleNumbersForFilter,
    queryProjectNumbersForFilter,
} from '../OrderView/Queries';
import { queryResourcesGroupForFilter } from '../ResourcesView/Queries';

const FiltersView = props => {
    const {
        filterOpen,
        filters,
        setFilters,
        setFilter,
        removeFilter,
        filteredPAs,
        applyFilteredPAs,
        removeFilteredPAs,
        sessionID,
        userID,
        tab,
    } = props;

    const setDataFilteredPAs = d => {
        const newFilteredPAs = d[0].array_agg;
        if (newFilteredPAs != null) {
            let pas = JSON.stringify(newFilteredPAs);
            pas = pas.replaceAll('"', "'");
            pas = pas.replace('[', '(');
            pas = pas.replace(']', ')');
            applyFilteredPAs({
                id: 'PA-Nummer',
                Attribute: 'PA-Nummer',
                Operator: 'IN',
                Value: pas,
                PAsLength: newFilteredPAs.length,
            });
        }
    };

    // This are the PAs filtered when the filters from the top are setted, when commented
    // is the old filtered functionality enabled, when uncomentted the PAs < Views is enabled

    // useEffect(() => {
    //     if ((filters.length == 1 && filters[0]['Attribute'] == 'limit') || filters.length == 0) {
    //         if (filteredPAs.length == 1) removeFilteredPAs(); // clean filteredPAs when only filter remaining is limit
    //         return;
    //     }

    //     if (filteredPAs.length == 1) {
    //         const filtersNoValidInContext = getFiltersNoValidInContext(tab);
    //         const contextfilters = filters.filter(obj => !filtersNoValidInContext.includes(get(obj, 'id', '')));
    //         if (contextfilters.length != filters.length) {
    //             const pasIndex = indexPAsInFilters(contextfilters);
    //             if (pasIndex >= 0) {
    //                 contextfilters[pasIndex] = filteredPAs[0];
    //                 setFilters([...contextfilters]);
    //                 return;
    //             }
    //             if (pasIndex < 0) {
    //                 setFilters([...contextfilters, filteredPAs[0]]);
    //                 return;
    //             }
    //         }
    //     }
    //     const queryFilteredPAs = tab != 'Arbeitssystemsicht' ? queryFilteredFromOrdersPAs : queryFilteredFromAVGsPAs;
    //     loadData(() => {}, setDataFilteredPAs, queryFilteredPAs, filters, sessionID, userID, 0);
    // }, [filters]);

    const renderMultiSSDD = (label, field, query) => {
        return (
            <MultiSearchSelectionDropdown
                setFilter={setFilter}
                removeFilter={removeFilter}
                filters={filters}
                filteredPAs={filteredPAs}
                applyFilteredPAs={applyFilteredPAs}
                label={label}
                field={field}
                query={query}
                sessionID={sessionID}
                userID={userID}
            />
        );
    };

    const renderDatePicker = (label, field) => {
        return (
            <RangeDatePicker
                label={label}
                field={field}
                filters={filters}
                setFilter={setFilter}
                removeFilter={removeFilter}
            />
        );
    };

    return (
        <FilterWrapper>
            <strong>Filter</strong>
            <ActiveFilters filters={filters} filteredPAs={filteredPAs} removeFilter={removeFilter} />

            <FilterOptions>
                {/* data pickers */}
                {renderDatePicker('Plan-Start von - bis', 'Plan-Start')}
                {renderDatePicker('Plan-Ende von - bis', 'Plan-Ende')}
                {renderDatePicker('Ist-Start von - bis', 'Ist-Start')}
                {renderDatePicker('Ist-Ende von - bis', 'Ist-Ende')}
                {/* data pickers */}
                {tab != 'Arbeitssystemsicht' ? (
                    <div>
                        {renderMultiSSDD('Projekt-Nummer', 'Projekt-Nummer', queryProjectNumbersForFilter)}
                        {renderMultiSSDD('Artikelnummer', 'Artikelnummer', queryArticleNumbersForFilter)}
                        {renderMultiSSDD('Artikelgruppe', 'Artikelgruppe', queryOrdersProductsGroupsForFilter)}
                        {renderMultiSSDD('Abgeschlossen', 'IsClosed', queryOrdersIsClosedForFilter)}
                        {renderMultiSSDD('Haupt-PA', 'IsHauptPA', queryOrdersIsHauptPAForFilter)}
                        {renderMultiSSDD('Seriennummern', 'HasSeriennummern', queryOrdersHasSeriennummernForFilter)}
                    </div>
                ) : (
                    <div>{renderMultiSSDD('Ressourcengruppe', 'ressourcengruppe', queryResourcesGroupForFilter)}</div>
                )}
            </FilterOptions>
        </FilterWrapper>
    );
};

const FilterOptions = styled.div`
    padding: 1rem 0;
    > * {
        margin-bottom: 1rem;
    }
`;
const FilterWrapper = styled.div`
    flex: 0 0 auto;
    position: relative;
    width: 230px;
    padding: 0 1rem 1rem 0;
    height: auto;

    .filterSecTitle {
        font-weight: bold;
        color: ${COLORS.BLUE};
    }
    .info {
        color: ${COLORS.GREY};
        margin-bottom: 2rem;
    }
    .react-datepicker-wrapper {
        width: 100%;
        overflow: show;
    }
`;

export default FiltersView;

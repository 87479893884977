import React, { useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.min.css';
import { Form } from 'semantic-ui-react';
import de from 'date-fns/locale/de';
registerLocale('de', de);

const comp = props => {
    const { field, label, filters, setFilter, removeFilter } = props;
    // Always check if filter is setted and if so display setted values
    let [filterStart, filterEnd] = [];
    const activeFilter = filters.find(obj => obj.id == field);
    if (activeFilter) {
        [filterStart, filterEnd] = getDatesFromFilter(activeFilter);
    }
    const [startDate, setStartDate] = useState(filterStart || null);
    const [endDate, setEndDate] = useState(filterEnd || null);
    const [closeDatePicker, setCloseDatePicker] = useState(false);

    const onChange = dates => {
        const [start, end] = dates;
        // to make the date picker close nicely when user closed enddate
        start && !end ? setCloseDatePicker(true) : setCloseDatePicker(false);
        // both states need to be setted in the same render cycle
        setStartDate(start);
        setEndDate(end);

        if (start && end) {
            // at the end date 23:59:59 needs to be added modify the SQL filter to include de enddate
            setTimeout(() => {
                setFilter({
                    id: field,
                    Attribute: field,
                    Operator: 'BETWEEN',
                    Value: `'${formatDateForSQL(start)}' AND '${formatDateForSQL(end)} 23:59:59'`,
                });
            }, 500);
        }
    };

    const clearDatePicker = () => {
        setCloseDatePicker(false);
        setStartDate(null);
        setEndDate(null);
        removeFilter(field);
    };

    return (
        <Form size="tiny">
            <DatePicker
                showPopperArrow={false}
                value={`${formatDateToDE(startDate)} - - ${formatDateToDE(endDate)}`}
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                locale="de"
                shouldCloseOnSelect={closeDatePicker}
                customInput={<Form.Input label={label} fluid action={{ icon: 'close', onClick: clearDatePicker }} />}
            />
        </Form>
    );
};

export default comp;

// yyyy-mm-dd
const formatDateForSQL = date => {
    if (!date) return '';
    return date.toLocaleString('fr-CA', { year: 'numeric', month: 'numeric', day: 'numeric' });
};
//dd.mm.yyyy
const formatDateToDE = date => {
    if (!date) return '';
    return date.toLocaleString('de-DE', { year: 'numeric', month: 'numeric', day: 'numeric' });
};
// format active dates from current filter
const getDatesFromFilter = activeFilter => {
    const currentValue = activeFilter.Value;
    let [start, end] = currentValue.split(' AND ');
    end = end.replace(' 23:59:59', '');
    return [new Date(start), new Date(end)];
};

import React from 'react';
import { HistogrammWidgetFrame, Histogramm_KNZ, GWTitle } from '../../Utils/widgetsStyles';
import { COLORS } from '../../global-styles';
import useDimensions from 'react-use-dimensions';

import { XYPlot, XAxis, YAxis, HorizontalGridLines, VerticalGridLines, VerticalBarSeries, Hint } from 'react-vis';
import Modal from '../../Utils/Modal';
import { biRequest } from '../UtilComponents/requestsUtils';
import { Grid, Popup, Icon } from 'semantic-ui-react';

const BarSeries = VerticalBarSeries;

const Comp = props => {
    const { filters, addActiveFilter, title, height, modalOpen } = props;
    const [loadingData, setLoadingData] = React.useState(false);
    const [ref, { x, y, width }] = useDimensions();
    const [hoverData, setHoverData] = React.useState(null);
    const [freeData, setfreeData] = React.useState([]);
    const [finishedData, setfinishedData] = React.useState([]);

    const formatHoverData = point => {
        return [
            { title: 'Anzahl', value: point.y },
            { title: 'Kategorie', value: point.x },
        ];
    };

    const buildData = (data, label) => {
        if (label === 'free') {
            return data.map(obj => ({ x: obj.category, y: obj.free_total_article }));
        }

        if (label === 'finished') {
            return data.map(obj => ({ x: obj.category, y: obj.finished_total_article }));
        }
    };

    React.useEffect(() => {
        const getWidgetData = async () => {
            try {
                setLoadingData(true);
                const response = await biRequest(`d8h/get_pas_statistics`, filters);
                setLoadingData(false);
                setfreeData(buildData(response, 'free'));
                setfinishedData(buildData(response, 'finished'));
            } catch (error) {
                console.log(error);
            }
        };
        getWidgetData();
    }, [filters]);

    return React.useMemo(() => {
        return (
            <HistogrammWidgetFrame loading={loadingData}>
                <Histogramm_KNZ ref={ref}>
                    <GWTitle>
                        <Popup trigger={<Icon name="info circle" size="tiny" />} flowing hoverable>
                            <Grid padded>
                                Freigegebene PAs (=rot) und abgeschlossene PAs (=grün) in der aktuellen Woche <br />
                                Neu = Planstart diese Woche <br /> Aktuell = Planende diese Woche <br />
                                Rückstand = Planende in der Vergangenheit <br />
                                Vorrat = Planstart in der Vergangenheit und Planende in der Zukunft
                            </Grid>
                        </Popup>
                        <span>{title}</span>
                        {modalOpen ? <span></span> : <Modal graphWidget={<Comp />} config={props} />}
                    </GWTitle>
                </Histogramm_KNZ>
                <XYPlot
                    className="stackedBar"
                    margin={{ bottom: 60, left: 25 }}
                    xType="ordinal"
                    width={width - 20}
                    height={height || 200}
                    color={COLORS.BLUE}
                >
                    <VerticalGridLines />
                    <HorizontalGridLines />
                    <YAxis />
                    <XAxis />
                    {hoverData && <Hint value={hoverData} format={formatHoverData}></Hint>}
                    <BarSeries
                        color={COLORS.RED_KUA}
                        data={freeData}
                        onValueMouseOver={d => setHoverData(d)}
                        onValueMouseOut={d => setHoverData(null)}
                    />

                    <BarSeries
                        color={COLORS.GREEN}
                        data={finishedData}
                        onValueMouseOver={d => setHoverData(d)}
                        onValueMouseOut={d => setHoverData(null)}
                        onValueClick={datapoint => {
                            addActiveFilter({
                                attribute: 'category',
                                operator: 'IN',
                                value: [datapoint.x],
                            });
                        }}
                    />
                </XYPlot>
            </HistogrammWidgetFrame>
        );
    }, [width, hoverData, finishedData]);
};

export default Comp;

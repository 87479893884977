import React from 'react';
import { Label, Tab } from 'semantic-ui-react';
import IssueForm from '../IssueForm/IssueForm';
import IssueComments from '../IssueForm/IssueComments';
import IssueDocuments from '../IssueForm/IssueDocuments';
import ChangeDetailsForm from './ChangeDetailsForm';
import ActionsForm from './ActionsForm';
import { getData } from '../../UtilComponents/requestsUtils';

const getPanelsForChangeForm = (props, totalComments, setTotalComments, totalFiles, setTotalFiles) => {
    const [issueDepartments, setIssueDepartment] = React.useState([]);
    const [departmentUpdated, setDepartmentUpdated] = React.useState('');
    React.useEffect(() => {
        try {
            const getIssueStatus = async () => {
                try {
                    const response = await getData(`crud/changes/get_issue_status_by_id?issue_id=${props.formData.id}`);
                    setIssueDepartment(response);
                } catch (error) {
                    console.log(error);
                }
            };
            getIssueStatus();
        } catch (error) {}
    }, [departmentUpdated]);

    // if (props.formData.issue_type_id === 3 || props.formData.issue_type_id === 11) {
    if ([3, 11, 12].includes(props.formData.issue_type_id)) {
        return [
            {
                menuItem: `ID ${props.formData.serial_id} - Meldung Info`,
                render: () => (
                    <Tab.Pane>
                        <IssueForm issue_id={props.formData.id} issue_status={props.formData.issue_status} {...props} />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: 'Details',
                render: () => (
                    <Tab.Pane>
                        <ChangeDetailsForm
                            issue_id={props.formData.id}
                            issue_type_id={props.formData.issue_type_id}
                            issue_status={props.formData.issue_status}
                            {...props}
                        />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: {
                    key: 'AV',
                    icon: <TabLight issueDepartments={issueDepartments} department_id={4} />,
                    content: 'AV',
                },
                render: () => (
                    <Tab.Pane>
                        <ActionsForm
                            issue_id={props.formData.id}
                            department_id={4}
                            setDepartmentUpdated={setDepartmentUpdated}
                            issue_status={props.formData.issue_status}
                            {...props}
                        />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: {
                    key: 'C',
                    icon: <TabLight issueDepartments={issueDepartments} department_id={11} />,
                    content: 'C',
                },
                render: () => (
                    <Tab.Pane>
                        <ActionsForm
                            issue_id={props.formData.id}
                            department_id={11}
                            setDepartmentUpdated={setDepartmentUpdated}
                            issue_status={props.formData.issue_status}
                            {...props}
                        />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: {
                    key: 'EB',
                    icon: <TabLight issueDepartments={issueDepartments} department_id={2} />,
                    content: 'EB',
                },
                render: () => (
                    <Tab.Pane>
                        <ActionsForm
                            issue_id={props.formData.id}
                            department_id={2}
                            setDepartmentUpdated={setDepartmentUpdated}
                            issue_status={props.formData.issue_status}
                            {...props}
                        />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: {
                    key: 'EK',
                    icon: <TabLight issueDepartments={issueDepartments} department_id={3} />,
                    content: 'EK',
                },
                render: () => (
                    <Tab.Pane>
                        <ActionsForm
                            issue_id={props.formData.id}
                            department_id={3}
                            setDepartmentUpdated={setDepartmentUpdated}
                            issue_status={props.formData.issue_status}
                            {...props}
                        />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: {
                    key: 'KO',
                    icon: <TabLight issueDepartments={issueDepartments} department_id={6} />,
                    content: 'KO',
                },
                render: () => (
                    <Tab.Pane>
                        <ActionsForm
                            issue_id={props.formData.id}
                            department_id={6}
                            setDepartmentUpdated={setDepartmentUpdated}
                            issue_status={props.formData.issue_status}
                            {...props}
                        />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: {
                    key: 'MB',
                    icon: <TabLight issueDepartments={issueDepartments} department_id={8} />,
                    content: 'MB',
                },
                render: () => (
                    <Tab.Pane>
                        <ActionsForm
                            issue_id={props.formData.id}
                            department_id={8}
                            setDepartmentUpdated={setDepartmentUpdated}
                            issue_status={props.formData.issue_status}
                            {...props}
                        />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: {
                    key: 'MW',
                    icon: <TabLight issueDepartments={issueDepartments} department_id={10} />,
                    content: 'MW',
                },
                render: () => (
                    <Tab.Pane>
                        <ActionsForm
                            issue_id={props.formData.id}
                            department_id={10}
                            setDepartmentUpdated={setDepartmentUpdated}
                            issue_status={props.formData.issue_status}
                            {...props}
                        />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: {
                    key: 'PR',
                    icon: <TabLight issueDepartments={issueDepartments} department_id={1} />,
                    content: 'PR',
                },
                render: () => (
                    <Tab.Pane>
                        <ActionsForm
                            issue_id={props.formData.id}
                            department_id={1}
                            setDepartmentUpdated={setDepartmentUpdated}
                            issue_status={props.formData.issue_status}
                            {...props}
                        />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: {
                    key: 'QS',
                    icon: <TabLight issueDepartments={issueDepartments} department_id={5} />,
                    content: 'QS',
                },
                render: () => (
                    <Tab.Pane>
                        <ActionsForm
                            issue_id={props.formData.id}
                            department_id={5}
                            setDepartmentUpdated={setDepartmentUpdated}
                            issue_status={props.formData.issue_status}
                            {...props}
                        />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: {
                    key: 'VT',
                    icon: <TabLight issueDepartments={issueDepartments} department_id={7} />,
                    content: 'VT',
                },
                render: () => (
                    <Tab.Pane>
                        <ActionsForm
                            issue_id={props.formData.id}
                            department_id={7}
                            setDepartmentUpdated={setDepartmentUpdated}
                            issue_status={props.formData.issue_status}
                            {...props}
                        />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: totalComments + ' - Kommentare',
                render: () => (
                    <Tab.Pane>
                        <IssueComments
                            issue_id={props.formData.id}
                            issue_status={props.formData.issue_status}
                            setTotalComments={setTotalComments}
                        />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: totalFiles + ' - Anlagen',
                render: () => (
                    <Tab.Pane>
                        <IssueDocuments
                            issue_id={props.formData.id}
                            ppsordernumber={props.formData.ppsordernumber}
                            issue_type_id={props.formData.issue_type_id}
                            issue_status={props.formData.issue_status}
                            setTotalFiles={setTotalFiles}
                        />
                    </Tab.Pane>
                ),
            },
        ];
    } else {
        return [
            {
                menuItem: `ID ${props.formData.serial_id} - Meldung Info`,
                render: () => (
                    <Tab.Pane>
                        <IssueForm issue_id={props.formData.id} issue_status={props.formData.issue_status} {...props} />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: 'Details',
                render: () => (
                    <Tab.Pane>
                        <ChangeDetailsForm
                            issue_id={props.formData.id}
                            issue_type_id={props.formData.issue_type_id}
                            issue_status={props.formData.issue_status}
                            {...props}
                        />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: {
                    key: 'AV',
                    icon: <TabLight issueDepartments={issueDepartments} department_id={4} />,
                    content: 'AV',
                },
                render: () => (
                    <Tab.Pane>
                        <ActionsForm
                            issue_id={props.formData.id}
                            department_id={4}
                            setDepartmentUpdated={setDepartmentUpdated}
                            issue_status={props.formData.issue_status}
                            {...props}
                        />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: {
                    key: 'KO',
                    icon: <TabLight issueDepartments={issueDepartments} department_id={6} />,
                    content: 'KO',
                },
                render: () => (
                    <Tab.Pane>
                        <ActionsForm
                            issue_id={props.formData.id}
                            department_id={6}
                            setDepartmentUpdated={setDepartmentUpdated}
                            issue_status={props.formData.issue_status}
                            {...props}
                        />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: totalComments + ' - Kommentare',
                render: () => (
                    <Tab.Pane>
                        <IssueComments
                            issue_id={props.formData.id}
                            issue_status={props.formData.issue_status}
                            setTotalComments={setTotalComments}
                        />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: totalFiles + ' - Anlagen',
                render: () => (
                    <Tab.Pane>
                        <IssueDocuments
                            issue_id={props.formData.id}
                            ppsordernumber={props.formData.ppsordernumber}
                            issue_type_id={props.formData.issue_type_id}
                            issue_status={props.formData.issue_status}
                            setTotalFiles={setTotalFiles}
                        />
                    </Tab.Pane>
                ),
            },
        ];
    }
};

export default getPanelsForChangeForm;

const TabLight = ({ issueDepartments, department_id }) => {
    const [color, setColor] = React.useState('grey');
    React.useEffect(() => {
        if (!!issueDepartments && issueDepartments.length > 0) {
            try {
                const department = issueDepartments[0].status_by_department.filter(
                    department => department.department_id == department_id,
                );
                if (department.length > 0) {
                    const status = department[0].status_id;
                    const done = department[0].done;
                    const total = department[0].total;
                    if ((status === 1 && total === done) || status === 3) {
                        setColor('green');
                    } else if (status === 1) {
                        setColor('yellow');
                    } else if (status === 2) {
                        setColor('red');
                    } else {
                        setColor('grey');
                    }
                }
            } catch (error) {
                console.log(error);
            }
        }
    }, [issueDepartments, department_id]);
    return <Label empty circular color={color} style={{ marginRight: '3px' }}></Label>;
};

import { Segment } from 'semantic-ui-react';
import styled from 'styled-components';
import { COLORS } from '../global-styles';

export const PieWidgetFrame = styled(Segment)`
    path {
        cursor: pointer;
    }
    width: 100%;
    margin: 0 1rem 1rem 0 !important;
    svg.rv-discrete-color-legend-item__color {
        height: 4px !important;
        path {
            stroke-width: 6px !important;
        }
    }
    .rv-discrete-color-legend-item.horizontal {
        display: block;
    }
    .stackedBar {
        padding-left: 2rem;
        text {
            overflow: visible !important;
            display: inline-block;
            transform: none !important;
            right: 1em !important;
        }
    }
`;
export const HistogrammWidgetFrame = styled(Segment)`
    rect {
        cursor: pointer;
    }
    width: 100%;
    margin: 0 1rem 1rem 0 !important;
    svg.rv-discrete-color-legend-item__color {
        height: 4px !important;
        path {
            stroke-width: 6px !important;
        }
    }
    .stackedBar {
        padding-left: 2rem;
        .rv-xy-plot__axis--vertical .rv-xy-plot__axis__tick__text {
            overflow: visible !important;
            display: inline-block;
            transform: none !important;
            padding-right: 1em !important;
        }
    }
`;
export const LinesWidgetFrame = HistogrammWidgetFrame;

export const KNZ = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: ${COLORS.GREY};
    font-size: 1.5rem;
    line-height: 1.4em;
    .val {
        color: ${COLORS.TEXT_KUA};
        font-size: 1.8rem;
        font-weight: bold;
    }
`;

export const Histogramm_KNZ = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: ${COLORS.GREY};
    font-size: 1.5rem;
    line-height: 1.4em;
    .val {
        color: ${COLORS.TEXT_KUA};
        font-size: 1.8rem;
        font-weight: bold;
    }
    .kflist {
        width: 100%;
        display: flex;
        justify-content: center;
        > div {
            padding: 0 0.5rem;
            .label {
                font-size: 1.1rem;
            }
            .unit {
                font-weight: normal;
                padding-left: 0.25em;
                color: ${COLORS.TEXT_KUA};
            }
        }
    }
`;

export const GWTitle = styled.div`
box-sizing: content-box !important;
width:100%
display: flex;
flex-direction: row;
justify-content: space-between;
color: ${COLORS.GREY};
font-size: 1.5rem;
line-height: 1.4em;
i.icon {
    margin: 0 .25rem 0 1rem;
}
`;

import React from 'react';

import { Button } from 'semantic-ui-react';
import { multipartRequest } from '../../UtilComponents/requestsUtils';

const ImportFiles = ({ issue_id, ppsordernumber, issue_type_id, setLoading, setUpdateFileList, issue_status }) => {
    const inputFile = React.useRef(null);
    //const [filesToUpload, setFilesToUpload] = React.useState([]);
    const filesToSaveRef = React.useRef([]);

    const handleFileUpload = event => {
        setLoading(true);
        let fl = event.target.files;
        for (let i = 0; i < fl.length; i++) {
            filesToSaveRef.current.push(fl[i]);
        }
        //setFilesToUpload(Array.from(event.target.files));
        saveFiles();
        event.target.value = '';
    };

    const openFileExplorer = () => {
        if (issue_status === 3) {
            alert('Die Meldung darf nicht bearbeitet werden.');
        } else {
            inputFile.current.click();
        }
    };

    const saveFiles = async () => {
        let fl = filesToSaveRef.current;

        const paNumber = ppsordernumber == 'Kein PA' ? '' : ppsordernumber;
        const typeNameDir = getDirNameFromIssueType(issue_type_id);

        const relativeFilePath = paNumber == '' ? typeNameDir : paNumber + '/' + typeNameDir;

        const fd = new FormData();
        fd.append('query_id', 'crud/issues/add_files_to_issue');
        fd.append('relative_directory_for_files', relativeFilePath + '/' + issue_id);
        fd.append('where', JSON.stringify({ id: issue_id }));

        for (let i = 0; i < fl.length; i++) {
            fd.append('files', fl[i]);
        }

        try {
            const response = await multipartRequest(fd);
            if (response.status == 200) {
                filesToSaveRef.current = [];
                setUpdateFileList('After Import' + +Date.now().toString());
            }
            if (response.status == 500) {
                setLoading(false);
                alert('In der Meldung ist ein Bild mit demselben Namen vorhanden');
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getDirNameFromIssueType = type => {
        switch (type) {
            case 1:
                return 'A3';
            case 2:
                return 'Fehler';
            case 3:
                return 'Änderungen';
            case 4:
                return 'Hinweis';
            case 5:
                return 'Sonstiges';
            case 6:
                return 'Verbesserungsvorschlag';
            case 7:
                return 'Aufgabe';
        }
    };

    return (
        <>
            <Button onClick={openFileExplorer} primary>
                Anlagen hochladen
            </Button>

            {/* {filesToUpload.length > 0 && (
                <Button onClick={saveFiles} primary>
                    Anlagen Speichern
                </Button>
            )} */}

            {/* {filesToUpload.map(obj => {
                return (
                    <Label image>
                        {obj.name}
                        <Icon name="delete" />
                    </Label>
                );
            })} */}

            <input type="file" ref={inputFile} style={{ display: 'none' }} multiple onChange={handleFileUpload} />
        </>
    );
};

export default ImportFiles;

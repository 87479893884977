import React from 'react';
import { Button, Modal, Form } from 'semantic-ui-react';
import PlanDatePicker, { getPlanStartAndEndFromDateToDisplay } from './PlanDatePicker';
import SInput from '../UtilComponents/SearchableInput';
import SInputAsync from '../UtilComponents/SearchableInputAsync';
import { queryPAsNumbers, queryResources, queryResourcesStatus } from './Queries';
import { insertSimpleData, updateData } from '../UtilComponents/requestsUtils';
import { rpFormDataInitialState, validRPInputsInitialState, isNameValid } from './rpUtils';
const centered = {
    textAlign: 'center',
};

function RPModal(props) {
    const { open, setOpen, formData, setFormData, formAction, setFormAction, setRefreshTimeLine } = props;
    const titel = formAction === 'creation' ? 'Tool einplanen' : 'Tool Plan bearbeiten';

    const [validInputs, setValidInputs] = React.useState(validRPInputsInitialState);
    const [loading, setLoading] = React.useState(false);

    const handleChange = (event, data) => {
        const newObjState = Object.assign({}, formData, { [data.name]: data.value });
        // handle the case when the plan start and plan ende are initialized with the plan values
        // no user interaction and date range is automatically setted.
        if (data.name === 'ppsordernumber') {
            const selectedObject = data.options.find(obj => obj.key === data.value);
            const newObjStateWithDate = Object.assign({}, newObjState, {
                plan_start: selectedObject.plan_start,
                plan_end: selectedObject.plan_end,
                status_id: 4, // when pa selected default to belegt
            });
            setFormData(newObjStateWithDate);
        } else {
            setFormData(newObjState);
        }
    };

    const validateTitle = () => {
        const { title } = formData;
        const newObjState = Object.assign({}, validInputs, { title: isNameValid(title) });
        setValidInputs(newObjState);
    };

    const isFormValid = () => {
        if (!Object.values(validInputs).every(i => i === true)) return false;
        if (!Object.values(formData).every(i => i !== '')) return false;
        return true;
    };

    // handling form submition
    const submitForm = () => {
        formAction === 'creation' ? postResourcePlan() : updateResourcePlan();
    };

    const postResourcePlan = async () => {
        setLoading(true);
        const { plan_start_plan_end, ...rest } = formData;
        const [plan_start, plan_end] = getPlanStartAndEndFromDateToDisplay(plan_start_plan_end);

        try {
            await insertSimpleData('crud/resources_plan/post_resource_plan', {
                ...rest,
                plan_start: plan_start,
                plan_end: plan_end,
            });
            setLoading(false);
            setFormData(rpFormDataInitialState);
            setOpen(false);
            setRefreshTimeLine('afterRPCreation' + Date.now().toString());
        } catch (error) {
            console.log(error);
        }
    };

    const updateResourcePlan = async () => {
        setLoading(true);
        const { id, ...restOne } = formData;
        const { plan_start_plan_end, ...rest } = restOne;
        const [plan_start, plan_end] = getPlanStartAndEndFromDateToDisplay(plan_start_plan_end);

        try {
            await updateData(
                'crud/resources_plan/update_resource_plan',
                { ...rest, plan_start: plan_start, plan_end: plan_end },
                { id: id },
            );
            setLoading(false);
            setFormData(rpFormDataInitialState);
            setOpen(false);
            setRefreshTimeLine('afterRPUpdate' + Date.now().toString());
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Modal
            size="mini"
            onClose={() => {
                setFormAction('creation');
                setFormData(rpFormDataInitialState);
                setOpen(false);
            }}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={
                <Button primary fluid>
                    {titel}
                </Button>
            }
        >
            <Modal.Header style={centered}>{titel}</Modal.Header>
            <Modal.Content>
                <Form loading={loading}>
                    <Form.Input
                        label="Titel"
                        name="title"
                        value={formData.title}
                        onChange={handleChange}
                        onBlur={validateTitle}
                        error={!validInputs.title}
                    />
                    <SInputAsync
                        label="Produktionsauftrag"
                        name="ppsordernumber"
                        placeholder="Suche und wahle ein PA aus."
                        query={queryPAsNumbers}
                        value={formData.ppsordernumber}
                        onChange={handleChange}
                    />
                    <PlanDatePicker
                        label="Plan von-bis"
                        name="plan_start_plan_end"
                        value={formData.plan_start_plan_end}
                        handleChange={handleChange}
                        plan_start={formData.plan_start}
                        plan_end={formData.plan_end}
                    />
                    <SInput
                        label="Tool"
                        name="resource_id"
                        placeholder="Tool(Ressource)"
                        query={queryResources}
                        value={formData.resource_id}
                        onChange={handleChange}
                    />
                    <SInput
                        label="Planungsstatus"
                        placeholder="Planungsstatus"
                        name="status_id"
                        query={`${queryResourcesStatus}?id=3&id=4&id=5&id=6`}
                        value={formData.status_id}
                        onChange={handleChange}
                    />
                </Form>
                <br />
                <Button fluid onClick={submitForm} primary disabled={!isFormValid() || loading}>
                    Speichern
                </Button>
            </Modal.Content>
        </Modal>
    );
}

export default RPModal;
